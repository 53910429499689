const formatMBytes = (mBytes: number, decimals = 2) => {
  if (mBytes === 0) return '0 mBytes'

  const k = 1024
  const dm = decimals < 0 ? 0 : decimals
  const sizes = ['MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

  const i = Math.floor(Math.log(mBytes) / Math.log(k))

  return parseFloat((mBytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i]
}

const mbToBytes = (mBytes: number): number =>
  mBytes === 0 ? mBytes : Number((mBytes * 1024 * 1024).toFixed(2))

const bytesToMb = (bytes: number): number =>
  bytes === 0 ? bytes : Number((bytes / 1024 / 1024).toFixed(2))

const bytesToGb = (bytes: number): number =>
  bytes === 0 ? bytes : Number((bytes / 1024 / 1024 / 1024).toFixed(2))

const mbytesToGb = (mbytes: number): number =>
  mbytes === 0 ? mbytes : Number((mbytes / 1024).toFixed(2))

const getPercent = (used: number, max: number): string => {
  if (used !== 0 && max === 0) return '100%'
  if (used === 0 && max === 0) return '0.00%'

  const percents = used / (max / 100)

  return `${Number(percents.toFixed(2))}%`
}

const formatKBytes = (bytes: number, decimals = 2) => {
  return (bytes / 1024).toFixed(decimals)
}

const getUserSpaceInfoString = (used: number, maxStorage: number): string => {
  const usedSpace: number = mbytesToGb(used)
  const maxSpace: number = mbytesToGb(maxStorage)

  return `${usedSpace}GB (${getPercent(used, maxStorage)}) of ${maxSpace}GB`
}

const getMaxFileSize = (fileSize: number): string => `${fileSize} Mb`

export const StorageHelpers = {
  formatMBytes,
  bytesToMb,
  bytesToGb,
  mbytesToGb,
  getPercent,
  getUserSpaceInfoString,
  getMaxFileSize,
  mbToBytes,
  formatKBytes
}
