import React from 'react'
import { CircularProgress, Theme } from '@material-ui/core'
import { ButtonProps } from '@material-ui/core/Button'
import { useTheme, styled } from '@material-ui/styles'
import { CustomButton } from '../atoms/custom-button'

const Wrapper = styled('div')<Theme>(({ theme }) => ({
  marginLeft: theme.spacing(2),
  display: 'flex',
  alignItems: 'center'
}))

interface TProps extends ButtonProps {
  loading?: boolean
}

export const LoadingButton: React.FC<TProps> = ({ children, loading, ...buttonProps }) => {
  const theme: Theme = useTheme()

  return (
    <CustomButton {...buttonProps}>
      {children}
      {loading && (
        <Wrapper>
          <CircularProgress size={theme.spacing(2.5)} />
        </Wrapper>
      )}
    </CustomButton>
  )
}

LoadingButton.defaultProps = {
  loading: false
}
