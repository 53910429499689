import React, { FC } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';

const useStyles = makeStyles((theme) => ({
  container: (props: {aspectRatio?: number}) => ({
    display: 'flex',
    width: (props.aspectRatio >= 1) ? '100px' : '50px',
    height: (props.aspectRatio > 1) ? '50px' : '100px',
    flexDirection: 'column',
    alignItems: 'flex-start',
  }),
  lowerContainer: (props: {aspectRatio?: number}) => ({
    display: 'flex',
    width: (props.aspectRatio >= 1) ? '100px' : '50px',
    height: (props.aspectRatio > 1) ? `${50 * (1/3)}px` : `${100 * (1/3)}px`,
    alignItems: 'flex-start',
  }),
  lower70: (props: {aspectRatio?: number}) => ({
    height: (props.aspectRatio > 1) ? `${50 * (2/3)}px` : `${100 * (2/3)}px`,
    flexShrink: 0,
    alignSelf: 'stretch',
    border: '1px solid var(--Sytetm-Divider, currentColor)',
    borderTop: 'none',
  }),
  upperLeft10: (props: {aspectRatio?: number}) => ({
    display: 'flex',
    alignItems: 'flex-start',
    width: (props.aspectRatio >= 1) ? `${100 * (1/3)}px` : `${50 * (1/3)}px`,
    alignSelf: 'stretch',
    border: '1px solid var(--Sytetm-Divider, currentColor)',
    borderRight: 'none',
  }),
  upperMiddle10: (props: {aspectRatio?: number}) => ({
    display: 'flex',
    alignItems: 'flex-start',
    width: (props.aspectRatio >= 1) ? `${100 * (1/3)}px` : `${50 * (1/3)}px`,
    alignSelf: 'stretch',
    border: '1px solid var(--Sytetm-Divider, currentColor)',
    borderRight: 'none',
  }),
  upperRight10: (props: {aspectRatio?: number}) => ({
    display: 'flex',
    alignItems: 'flex-start',
    width: (props.aspectRatio >= 1) ? `${100 * (1/3)}px` : `${50 * (1/3)}px`,
    alignSelf: 'stretch',
    border: '1px solid var(--Sytetm-Divider, currentColor)',
  }),
  lower70Selected: (props: {aspectRatio?: number}) => ({
    border: '2px solid var(--Sytetm-Divider, #1d93f3)',
    borderTop: 'none',
  }),
  upperLeft10Selected: (props: {aspectRatio?: number}) => ({
    border: '2px solid var(--Sytetm-Divider, #1d93f3)',
    borderRight: 'none',
  }),
  upperMiddle10Selected: (props: {aspectRatio?: number}) => ({
    border: '2px solid var(--Sytetm-Divider, #1d93f3)',
    borderRight: 'none',
  }),
  upperRight10Selected: (props: {aspectRatio?: number}) => ({
    border: '2px solid var(--Sytetm-Divider, #1d93f3)',
  }),
}));

export const Layout10To10To10To70Icon: FC<{isSelected: boolean; aspectRatio?: number}> = ({
  isSelected,
  aspectRatio,
}) => {
  const {
    container,
    lowerContainer,
    lower70,
    upperLeft10,
    upperMiddle10,
    upperRight10,
    lower70Selected,
    upperLeft10Selected,
    upperMiddle10Selected,
    upperRight10Selected,
  } = useStyles({ aspectRatio });

  const upperLeft10ClassName = classNames(upperLeft10, { [upperLeft10Selected]: isSelected });
  const upperMiddle10ClassName = classNames(upperMiddle10, { [upperMiddle10Selected]: isSelected });
  const upperRight10ClassName = classNames(upperRight10, { [upperRight10Selected]: isSelected });
  const lower70ClassName = classNames(lower70, { [lower70Selected]: isSelected });

  return (
    <div className={container}>
      <div className={lowerContainer}>
        <div className={upperLeft10ClassName} />
        <div className={upperMiddle10ClassName} />
        <div className={upperRight10ClassName} />
      </div>
      <div className={lower70ClassName} />
    </div>
  );
};
