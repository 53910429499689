import gql from 'graphql-tag'

export const SC_GET_DISPLAYS = gql`
  query ScGetDisplays ($workspaceId: UUID!, $filters: displayFilters) {
    workspace(id: $workspaceId) {
      id
      displays(filters: $filters) {
        count
        page
        values
        {
          id
          name
          pixelsH
          pixelsW
          locationType
          latitude
          longitude
          brightness
          power
          playerId
          playerVersion
          timezone {
            id
          }
          color {
            id
            colorType
            description
          }
          defaultPresentation {
            id
            name
          }
          changed
          imageUrl
          playerConnectionDate
          emailNotificationsEnabled
        }
      }
    }
  }
`

export const SC_GET_DISPLAY = gql`
  query ScGetDisplay ($workspaceId: UUID!, $id: UUID!) {
    workspace(id: $workspaceId) {
      id
      display(id: $id) {
        id,
        name,
        latitude,
        locationType,
        longitude,
        timezone {
          id
          gmt
          name
          dst
          tzOffset
          jodaId
        },
        playerId,
        playerVersion
        imageUrl
        allDayAds
        blacklistAds
        operatingHours
        venueType
        displayType
        physicalHeight
        physicalWidth
      }
    }
  }
`

export const SC_GET_DISPLAYS_FOR_ADDING_TO_DISPLAY_GROUP = gql`
  query ScGetDisplays ($workspaceId: UUID!) {
    workspace(id: $workspaceId) {
      id
      displays {
        count
        page
        values
        {
          id
          name
          pixelsH
          pixelsW
          isDisplayGroup
          displayGroupId
        }
      }
    }
  }
`

export const SC_GET_DISPLAY_GROUP_DISPLAYS = gql`
  query ScFindDisplayGroup ($id: UUID!) {
    findDisplayGroup(id: $id) {
      id
      name
      pixelsH
      pixelsW
      defaultPresentation {
        id
        name
      }
      displays {
        id
        name
        pixelsH
        pixelsW
        locationType
        latitude
        longitude
        brightness
        configBrightness
        power
        playerId
        playerVersion
        timezone {
          id
          jodaId
        }
        color {
          id
          colorType
          description
        }
        defaultPresentation {
          id
          name
        }
        updatedAt
        imageUrl
        playerConnectionDate
        emailNotificationsEnabled

      }
    }
  }
`

export const SC_GET_DISPLAY_GROUPS = gql`
  query ScFindDisplayGroups ($workspaceId: UUID!) {
    findDisplayGroups(workspaceId: $workspaceId) {
      id
      name
      pixelsH
      pixelsW
      defaultPresentation {
        id
        name
      }
      updatedAt
      imageUrl
      displays {
        id
        name
      }
    }
  }
`