import React, { Children, FC } from 'react'

import { TabProps } from './types'

export const Tab: FC<TabProps> = ({ children }) => {
  if (!children || !Children.count(children)) {
    throw Error('Tab component must contain at least one child')
  }

  return <>{children}</>
}

Tab.displayName = 'Tab'
