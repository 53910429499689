import React, { FC } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';

const useStyles = makeStyles((theme) => ({
  container: (props: {aspectRatio?: number}) => ({
    display: 'flex',
    width: (props.aspectRatio >= 1) ? '100px' : '50px',
    height: (props.aspectRatio > 1) ? '50px' : '100px',
    alignItems: 'flex-start',
  }),
  columnContainer: (props: {aspectRatio?: number}) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
  }),
  right70: (props: {aspectRatio?: number}) => ({
    display: 'flex',
    alignItems: 'flex-start',
    width: (props.aspectRatio >= 1) ? `${100 * (2/3)}px` : `${50 * (2/3)}px`,
    alignSelf: 'stretch',
    border: '1px solid var(--Sytetm-Divider, currentColor)',
    borderLeft: 'none',
  }),
  upperLeft10: (props: {aspectRatio?: number}) => ({
    display: 'flex',
    alignItems: 'flex-start',
    width: (props.aspectRatio >= 1) ? `${100 * (1/3)}px` : `${50 * (1/3)}px`,
    height: (props.aspectRatio > 1) ? `${50 * (1/3)}px` : `${100 * (1/3)}px`,
    alignSelf: 'stretch',
    border: '1px solid var(--Sytetm-Divider, currentColor)',
    borderBottom: 'none',
  }),
  middleLeft10: (props: {aspectRatio?: number}) => ({
    display: 'flex',
    alignItems: 'flex-start',
    width: (props.aspectRatio >= 1) ? `${100 * (1/3)}px` : `${50 * (1/3)}px`,
    height: (props.aspectRatio > 1) ? `${50 * (1/3)}px` : `${100 * (1/3)}px`,
    alignSelf: 'stretch',
    border: '1px solid var(--Sytetm-Divider, currentColor)',
    borderBottom: 'none',
  }),
  lowerLeft10: (props: {aspectRatio?: number}) => ({
    display: 'flex',
    alignItems: 'flex-start',
    height: (props.aspectRatio > 1) ? `${50 * (1/3)}px` : `${100 * (1/3)}px`,
    alignSelf: 'stretch',
    border: '1px solid var(--Sytetm-Divider, currentColor)',
  }),
  right70Selected: (props: {aspectRatio?: number}) => ({
    border: '2px solid var(--Sytetm-Divider, #1d93f3)',
    borderLeft: 'none',
  }),
  upperLeft10Selected: (props: {aspectRatio?: number}) => ({
    border: '2px solid var(--Sytetm-Divider, #1d93f3)',
    borderBottom: 'none',
  }),
  middleLeft10Selected: (props: {aspectRatio?: number}) => ({
    border: '2px solid var(--Sytetm-Divider, #1d93f3)',
    borderBottom: 'none',
  }),
  lowerLeft10Selected: (props: {aspectRatio?: number}) => ({
    border: '2px solid var(--Sytetm-Divider, #1d93f3)',
  }),
}));

export const Layout10To70To10To10Icon: FC<{isSelected: boolean; aspectRatio?: number}> = ({
  isSelected,
  aspectRatio,
}) => {
  const {
    container,
    columnContainer,
    right70,
    upperLeft10,
    middleLeft10,
    lowerLeft10,
    right70Selected,
    upperLeft10Selected,
    middleLeft10Selected,
    lowerLeft10Selected,
  } = useStyles({ aspectRatio });

  const upperLeft10ClassName = classNames(upperLeft10, { [upperLeft10Selected]: isSelected });
  const middleLeft10ClassName = classNames(middleLeft10, { [middleLeft10Selected]: isSelected });
  const lowerLeft10ClassName = classNames(lowerLeft10, { [lowerLeft10Selected]: isSelected });
  const right70ClassName = classNames(right70, { [right70Selected]: isSelected });

  return (
    <div className={container}>
        <div className={columnContainer}>
            <div className={upperLeft10ClassName} />
            <div className={middleLeft10ClassName} />
            <div className={lowerLeft10ClassName} />
        </div>
        <div className={right70ClassName} />
    </div>
  );
};
