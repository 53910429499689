import gql from 'graphql-tag'

export const PS_GET_PRESENTATION_Q = gql`
  query PsGetPresentationQuery ($id: UUID!) {
    presentation(id: $id) {
      id
      slidesOrder
      config
      slides
    }
  }
`
