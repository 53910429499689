import React, { FC } from 'react'
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon'

export const BackBurgerIcon: FC<SvgIconProps> = (props) => (
  <SvgIcon
    {...props}
    viewBox='0 0 24 24'
  >
    <g transform='translate(0,4)'>
      <path d='M4.6256306760847625 9.246105919003115C4.6256306760847625 9.246105919003115 9.46922300706357 14.230529595015573 9.46922300706357 14.230529595015573 9.46922300706357 14.230529595015573 7.773965691220988 16 7.773965691220988 16 7.773965691220988 16 0 7.999999999999998 0 7.999999999999998 0 7.999999999999998 7.773965691220988 0 7.773965691220988 0 7.773965691220988 0 9.46922300706357 1.769470404984423 9.46922300706357 1.769470404984423 9.46922300706357 1.769470404984423 4.6256306760847625 6.753894080996885 4.6256306760847625 6.753894080996885 4.6256306760847625 6.753894080996885 23.999999999999996 6.753894080996885 23.999999999999996 6.753894080996885 23.999999999999996 6.753894080996885 23.999999999999996 9.246105919003115 23.999999999999996 9.246105919003115 23.999999999999996 9.246105919003115 4.6256306760847625 9.246105919003115 4.6256306760847625 9.246105919003115M23.999999999999996 0.5233644859813076C23.999999999999996 0.5233644859813076 23.999999999999996 3.0155763239875384 23.999999999999996 3.0155763239875384 23.999999999999996 3.0155763239875384 11.891019172552975 3.0155763239875384 11.891019172552975 3.0155763239875384 11.891019172552975 3.0155763239875384 11.891019172552975 0.5233644859813076 11.891019172552975 0.5233644859813076 11.891019172552975 0.5233644859813076 23.999999999999996 0.5233644859813076 23.999999999999996 0.5233644859813076M23.999999999999996 12.98442367601246C23.999999999999996 12.98442367601246 23.999999999999996 15.47663551401869 23.999999999999996 15.47663551401869 23.999999999999996 15.47663551401869 11.891019172552975 15.47663551401869 11.891019172552975 15.47663551401869 11.891019172552975 15.47663551401869 11.891019172552975 12.98442367601246 11.891019172552975 12.98442367601246 11.891019172552975 12.98442367601246 23.999999999999996 12.98442367601246 23.999999999999996 12.98442367601246 23.999999999999996 12.98442367601246 23.999999999999996 12.98442367601246 23.999999999999996 12.98442367601246' />
    </g>
  </SvgIcon>
)
