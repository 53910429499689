import React, { useState, useCallback } from 'react'
import { InputAdornment, IconButton } from '@material-ui/core'
import { Visibility, VisibilityOff } from '@material-ui/icons'
import { CustomField, CustomFieldProps } from './custom-field'

export const PasswordField: React.FC<CustomFieldProps> = (props) => {
  const [isPasswordVisible, setIsPasswordVisible] = useState(false)

  const togglePasswordVisible = useCallback(() => {
    setIsPasswordVisible((isPasswordVisible) => !isPasswordVisible)
  }, [])

  return (
    <CustomField
      {...props}
      inputProps={{
        ...props.inputProps,
        type: isPasswordVisible ? 'text' : 'password'
      }}
      InputProps={{
        ...props.InputProps,
        endAdornment: (
          <InputAdornment position='end'>
            <IconButton
              aria-label='Toggle password visibility'
              onClick={togglePasswordVisible}
            >
              {isPasswordVisible ? <VisibilityOff /> : <Visibility />}
            </IconButton>
          </InputAdornment>
        )
      }}
    />
  )
}
