import gql from 'graphql-tag'
import { COMM_DISPLAY_F } from '../@common/fragments'
import {
  DisplaysSetDisplayPowerMutation,
  DisplaysSetDisplayPowerMutationVariables
} from '../../types/DisplaysSetDisplayPowerMutation'
import {
  DisplaysCreateDisplayMutation,
  DisplaysCreateDisplayMutationVariables
} from '../../types/DisplaysCreateDisplayMutation'
import {
  DisplaysDeleteDisplayMutation,
  DisplaysDeleteDisplayMutationVariables
} from '../../types/DisplaysDeleteDisplayMutation'
import {
  DisplaysUpdateDisplayMutation,
  DisplaysUpdateDisplayMutationVariables
} from '../../types/DisplaysUpdateDisplayMutation'
import {
  DisplaysUploadDisplayThumbnailMutation,
  DisplaysUploadDisplayThumbnailMutationVariables
} from '../../types/DisplaysUploadDisplayThumbnailMutation'
import {
  DisplaysDeleteDisplayThumbnailMutation,
  DisplaysDeleteDisplayThumbnailMutationVariables
} from '../../types/DisplaysDeleteDisplayThumbnailMutation'

export const DISPLAYS_SET_DISPLAY_POWER_M = gql<
  DisplaysSetDisplayPowerMutation,
  DisplaysSetDisplayPowerMutationVariables
>`
  mutation DisplaysSetDisplayPowerMutation ($id: UUID!, $power: DisplayPower!) {
    setDisplayPower(id: $id, power: $power) {
      id
      code
      success
      message
    }
  }
`

export const DISPLAYS_CREATE_DISPLAY_M = gql<
  DisplaysCreateDisplayMutation,
  DisplaysCreateDisplayMutationVariables
>`
  mutation DisplaysCreateDisplayMutation ($workspaceId: UUID! $input: DisplayInput!) {
    createDisplay(workspaceId: $workspaceId, input: $input) {
      code
      success
      message
      display {
        ...CommDisplayInfoFragment
      }
    }
  }
  ${COMM_DISPLAY_F}
`

export const DISPLAYS_DELETE_DISPLAY_M = gql<
  DisplaysDeleteDisplayMutation,
  DisplaysDeleteDisplayMutationVariables
>`
  mutation DisplaysDeleteDisplayMutation ($id: UUID!) {
    deleteDisplay(id: $id) {
      id
      code
      success
      message
    }
  }
`

export const DISPLAYS_UPDATE_DISPLAY_M = gql<
  DisplaysUpdateDisplayMutation,
  DisplaysUpdateDisplayMutationVariables
>`
  mutation DisplaysUpdateDisplayMutation ($id: UUID!, $input: DisplayInput!) {
    updateDisplay(id: $id, input: $input) {
      code
      success
      message
      display {
        ...CommDisplayInfoFragment,
        configBrightness,
        enableAutoDimming
      }
    }
  }
  ${COMM_DISPLAY_F}
`

export const DISPLAYS_UPLOAD_DISPLAY_THUMBNAIL_M = gql<
  DisplaysUploadDisplayThumbnailMutation,
  DisplaysUploadDisplayThumbnailMutationVariables
>`
  mutation DisplaysUploadDisplayThumbnailMutation ($id: UUID!, $file: Upload!) {
    uploadDisplayThumbnail(id: $id, file: $file) {
      success
      display {
        id
        imageUrl
      }
    }
  }
`

export const DISPLAYS_DELETE_DISPLAY_THUMBNAIL_M = gql<
  DisplaysDeleteDisplayThumbnailMutation,
  DisplaysDeleteDisplayThumbnailMutationVariables
>`
  mutation DisplaysDeleteDisplayThumbnailMutation ($id: UUID!) {
    deleteDisplayThumbnail(id: $id) {
      success
      display {
        id
        imageUrl
      }
    }
  }
`

export const DISPLAYS_CREATE_DISPLAY_GROUP_M = gql`
  mutation DisplaysCreateDisplayGroupMutation ($createDisplayGroupInput: CreateDisplayGroupInputDTO!) {
    createDisplayGroup(createDisplayGroupInput: $createDisplayGroupInput) {
      id
      name
    }
  }
`


export const DISPLAYS_ADD_DISPLAYS_TO_DISPLAY_GROUP_M = gql`
  mutation DisplaysAddDisplaysToDisplayGroupMutation ($mutationAddDisplaysToDisplayGroupInputDTO: MutationAddDisplaysToDisplayGroupInputDTO!) {
    addDisplaysToDisplayGroup(mutationAddDisplaysToDisplayGroupInputDTO: $mutationAddDisplaysToDisplayGroupInputDTO) {
      code
      success
      message
    }
  }
`

export const DISPLAYS_UPDATE_DISPLAY_GROUP_M = gql`
  mutation DisplaysAddDisplaysToDisplayGroupMutation ($id: UUID!, $updateDisplayGroupInput: UpdateDisplayGroupInputDTO!) {
    updateDisplayGroup(id: $id, updateDisplayGroupInput: $updateDisplayGroupInput) {
      code
      success
      message
    }
  }
`

export const DISPLAYS_REMOVE_DISPLAY_FROM_DISPLAY_GROUP_M = gql`
  mutation DisplaysRemoveDisplayFromDisplayGroupMutation ($mutationRemoveDisplayFromDisplayGroupInputDTO: MutationRemoveDisplayFromDisplayGroupInputDTO!) {
    removeDisplayFromDisplayGroup(mutationRemoveDisplayFromDisplayGroupInputDTO: $mutationRemoveDisplayFromDisplayGroupInputDTO) {
      code
      success
      message
    }
  }
`

export const DISPLAYS_REMOVE_DISPLAY_GROUP_M = gql`
  mutation DisplaysRemoveDisplayGroupMutation ($id: String!) {
    removeDisplayGroup(id: $id) {
      code
      success
      message
    }
  }
`
