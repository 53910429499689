import gql from 'graphql-tag'

export const SEND_MARKETING_CIRRUS_EMAIL = gql`
  mutation SendMarketingCirrusEmail ( 
      $job: String!
      $business: String!
      $company: String!
      $country: String!
      $email: String!
      $firstName: String!
      $industry: String!
      $lastName: String!
      $phone: String!
      $revenue: String!
      $state: String!
      $screenhubFirstName: String!,
      $screenhubLastName: String!,
      $screenhubEmail: String!
      ) {
    sendMarketingCirrusEmail(
        job: $job, 
        business: $business, 
        company: $company, 
        country: $country, 
        email: $email, 
        firstName: $firstName, 
        industry: $industry, 
        lastName: $lastName, 
        phone: $phone,
        revenue: $revenue,
        state: $state,
        screenhubFirstName: $screenhubFirstName,
        screenhubLastName: $screenhubLastName,
        screenhubEmail: $screenhubEmail
        ) {
      code
      success
      message
    }
  }
`
