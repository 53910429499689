import React, { FC, useState, useEffect } from 'react'
import {
  Box,
  Button,
  List,
  ListProps,
  ListItem,
  ListItemIcon,
  ListItemText,
  Paper,
  Theme,
  Typography,
  IconButton
} from '@material-ui/core'
import CheckIcon from '@material-ui/icons/Check'
import { styled, makeStyles, createStyles, useTheme } from '@material-ui/styles'
import { useUserInfo } from 'src/services'
import StarsIcon from '@material-ui/icons/Stars'
import { useLazyQuery, useMutation, useQuery } from 'react-apollo'
import {
  PW_GET_STRIPE_CUSTOMER,
  PW_GET_SETUP_INTENT,
  PW_ACCOUNT_DISPLAYS_SUBSCRIPTION,
  PW_RETRIEVE_SUBSCRIPTION
} from 'src/apollo/api/paywall/queries'
import {
  ACTIVATE_SUBSCRIPTION,
  UPDATE_DEFAULT_PAYMENT_METHOD
} from 'src/apollo/api/paywall/mutations'
import { loadStripe } from '@stripe/stripe-js'

import { Elements } from '@stripe/react-stripe-js'
import { CardForm } from './card-form'
import { UPDATE_DISPLAY_SUBSCRIPTION } from 'src/apollo/api'
import { PlanEnums } from 'src/screenhub-shared/enum/stripe.enum'
import ArrowBackIosOutlinedIcon from '@material-ui/icons/ArrowBackIosOutlined'

interface TProps {
  subscription: any
  displays: any
  closeDialog: () => void
  firstPageIsConfirmPayment?: boolean
  setSubUpgradedNeedConfirm?: React.Dispatch<React.SetStateAction<boolean>>
  goesToDescriptionPage?: () => void
}

export enum PriceTableStep {
  CHOOSE_PLAN = 'CHOOSE_PLAN',
  CONFIRM_PAYMENT = 'CONFIRM_PAYMENT',
  SUCCEEDED = 'SUCCEEDED',
  FAILED = 'FAILED'
}

const usePaperClasses = makeStyles<Theme>({
  root: {
    width: '45%',
    padding: '24px',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    borderRadius: '10px',
    backgroundColor: 'white'
  }
})

const useInnerPaperClasses = makeStyles<Theme>({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    backgroundColor: 'white',
  }
})

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      // ...theme.typography.button,
      backgroundColor: 'white',
      padding: theme.spacing(1),
      fontWeight: 500,
      textAlign: 'center'
    },
    actionBtn: {
      minWidth: '300px',
      fontSize: '16px'
    }
  })
)

export const ResultDialogTitle = styled(Typography)({
  display: 'flex',
  padding: '24px 24px 16px 24px',
  alignItems: 'center',
  gap: '8px',
  alignSelf: 'stretch'
})

export const ResultDialogContent = styled(Typography)({
  display: 'flex',
  padding: '8px 24px',
  alignItems: 'flex-start',
  gap: '10px',
  alignSelf: 'stretch'
})

export const ResultDialogButtonBox = styled(Box)({
  display: 'flex',
  padding: '8px',
  flexDirection: 'column',
  alignItems: 'flex-end',
  gap: '10px',
  alignSelf: 'stretch'
})

export const PriceTable: FC<TProps> = ({
  subscription,
  displays,
  closeDialog,
  setSubUpgradedNeedConfirm,
  firstPageIsConfirmPayment = false,
  goesToDescriptionPage
}) => {
  const theme = useTheme()
  const classes = useStyles()
  const [progress, setProgress] = useState<PriceTableStep>(
    firstPageIsConfirmPayment ? PriceTableStep.CONFIRM_PAYMENT : PriceTableStep.CHOOSE_PLAN
  )
  const [chosenPlan, setChosenPlan] = useState<PlanEnums>(PlanEnums.PRO_PLAN)
  const [clientSecret, setClientSecret] = useState(null)
  const [stripePromise, setStripePromise] = React.useState(null)
  const [errorMsg, setErrorMsg] = React.useState('')
  const paperClasses = usePaperClasses(theme)
  const innerPaperClasses = useInnerPaperClasses(theme)

  const {
    me: {
      account: { id: accountId }
    }
  } = useUserInfo()

  const [getSetupIntent, { data: setupIntentData }] = useLazyQuery(PW_GET_SETUP_INTENT, {
    fetchPolicy: 'network-only'
  })

  const { data: stripeCustomerData } = useQuery(PW_GET_STRIPE_CUSTOMER, {
    skip: !subscription,
    variables: { stripeCustomerId: subscription.stripeCustomerId },
    fetchPolicy: 'no-cache'
  })

  const [updateDefaultPaymentMethod] = useMutation(UPDATE_DEFAULT_PAYMENT_METHOD)

  const [activateSubscription] = useMutation(ACTIVATE_SUBSCRIPTION)

  const queryStripeSetupIntent = async (planName: PlanEnums) => {
    if (stripeCustomerData?.getStripeCustomer?.invoice_settings?.default_payment_method) {
      const { accountId, category, qty, stripeSubscriptionId } = subscription
      await activateSubscription({
        variables: { accountId, stripeSubscriptionId, category }
      })
      await updateDisplaySubscription({
        variables: {
          accountId,
          quantity: qty,
          planName
        },
        refetchQueries: [
          { query: PW_ACCOUNT_DISPLAYS_SUBSCRIPTION, variables: { accountId } },
          { query: PW_RETRIEVE_SUBSCRIPTION, variables: { accountId } }
        ]
      })
      setProgress(PriceTableStep.SUCCEEDED)
    } else {
      setStripePromise(loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY))
      getSetupIntent({ variables: { accountId } })
      setProgress(PriceTableStep.CONFIRM_PAYMENT)
    }
  }

  const [updateDisplaySubscription] = useMutation(UPDATE_DISPLAY_SUBSCRIPTION)

  const standardFeatures = [
    'Account security and easy login (SSO & 2FA)',
    'Workspace management',
    'Media library and widgets',
    'File uploads (images, videos, links, etc.)',
    'Playlists',
    'Scheduling'
  ]

  const proFeatures = [
    'Everything in Standard',
    'Campaign Scheduling ',
    'Proof-of-Performance'
    // 'Display Groups',
  ]

  const StandardFeaturesElement = ({ style, ...rest }: ListProps) => {
    return (
      <List style={style} {...rest}>
        {standardFeatures.map((feature, index) => (
          <ListItem key={`${feature}_${index}`}>
            <ListItemIcon>
              <CheckIcon style={{ color: 'green' }} />
            </ListItemIcon>
            <ListItemText primary={feature} />
          </ListItem>
        ))}
      </List>
    )
  }

  const ProFeaturesElement = ({ style, ...rest }: ListProps) => {
    return (
      <List style={style} {...rest}>
        {proFeatures.map((feature, index) => (
          <ListItem key={`${feature}_${index}`}>
            <ListItemIcon>
              <CheckIcon style={{ color: 'green' }} />
            </ListItemIcon>
            <ListItemText primary={feature} />
          </ListItem>
        ))}
      </List>
    )
  }

  const FeatureListElement = () => {
    return (
      <Box style={{ width: '300px' }}>
        {chosenPlan === PlanEnums.PREMIUM_PLAN && (
          <Box>
            <Typography variant="h6" className={classes.root}>
              ScreenHub Standard
            </Typography>
            <StandardFeaturesElement />
          </Box>
        )}
        {chosenPlan === PlanEnums.PRO_PLAN && (
          <Box>
            <Typography variant="h6" className={classes.root}>
              ScreenHub Pro <StarsIcon style={{ color: '#E9D19C' }} />
            </Typography>
            <ProFeaturesElement />
          </Box>
        )}
      </Box>
    )
  }

  useEffect(() => {
    if (progress === PriceTableStep.CONFIRM_PAYMENT && setupIntentData) {
      const { getCardSetupIntentByAccountId } = setupIntentData
      setClientSecret(getCardSetupIntentByAccountId.client_secret)
    }
  }, [progress, setupIntentData])

  useEffect(() => {
    if (firstPageIsConfirmPayment && stripeCustomerData) {
      setChosenPlan(PlanEnums.PRO_PLAN)
      queryStripeSetupIntent(PlanEnums.PRO_PLAN)
    }
  }, [stripeCustomerData])

  const onSubscribeClick = (plan: PlanEnums) => {
    setChosenPlan(plan)
    queryStripeSetupIntent(plan)
  }

  if(!goesToDescriptionPage) {
    goesToDescriptionPage = () => {setProgress(PriceTableStep.CHOOSE_PLAN)}
  }

  if (!stripeCustomerData) {
    return <></>
  }

  if (progress === PriceTableStep.SUCCEEDED) {
    setSubUpgradedNeedConfirm && setSubUpgradedNeedConfirm(true)
    return (
      <Paper className={innerPaperClasses.root}>
        <ResultDialogTitle variant="h6">Your subscription setup is complete! 🎉</ResultDialogTitle>
        <ResultDialogContent variant="body1">
          Congratulations on becoming a{' '}
          {chosenPlan === PlanEnums.PRO_PLAN ? 'ScreenHub Pro' : 'ScreenHub Standard'}! Enjoy our
          new features.
        </ResultDialogContent>
        <ResultDialogButtonBox>
          <Button variant="contained" color="primary" onClick={closeDialog}>
            Ok, got it
          </Button>
        </ResultDialogButtonBox>
      </Paper>
    )
  }

  if (progress === PriceTableStep.FAILED) {
    return (
      <Paper className={innerPaperClasses.root}>
        <Typography variant="h6">Failed to complete your subscription</Typography>
        <Typography variant="body2">{errorMsg}</Typography>
      </Paper>
    )
  }

  if (progress === PriceTableStep.CONFIRM_PAYMENT && clientSecret && stripePromise) {
    return (
      <Paper className={innerPaperClasses.root} style={{ padding: '24px' }}>
        <Box display="flex" alignItems="center" alignSelf='stretch'>
          <IconButton
            onClick={goesToDescriptionPage}
          >
            <ArrowBackIosOutlinedIcon />
          </IconButton>
          <Typography variant="h6">Payment Method</Typography>
        </Box>
        <Box>
          <Elements
            stripe={stripePromise}
            options={{ clientSecret, appearance: { theme: 'stripe' } }}
          >
            <CardForm
              chosenPlan={chosenPlan}
              cirrusStandardQty={subscription.cirrusStandardQty}
              clientSecret={clientSecret}
              displayCount={displays.length}
              originalPlan={subscription.planName}
              quantity={subscription.qty}
              succeededCallback={async ({
                paymentMethodId,
                newDisplayQuantity,
                newBillingCycle
              }) => {
                try {
                  const { category, stripeSubscriptionId } = subscription

                  await updateDefaultPaymentMethod({
                    variables: { accountId, paymentMethodId }
                  })

                  await activateSubscription({
                    variables: { accountId, stripeSubscriptionId, category }
                  })

                  await updateDisplaySubscription({
                    variables: {
                      accountId,
                      quantity: newDisplayQuantity,
                      planName: chosenPlan, // XXX: Should apply hardware/window user using premium
                      billingCycle: newBillingCycle
                    },
                    refetchQueries: [
                      { query: PW_ACCOUNT_DISPLAYS_SUBSCRIPTION, variables: { accountId } },
                      { query: PW_RETRIEVE_SUBSCRIPTION, variables: { accountId } }
                    ]
                  })
                  setProgress(PriceTableStep.SUCCEEDED)
                } catch (e) {
                  console.log('Failed to complete default payment method setup and end trial')
                  setErrorMsg(e)
                  setProgress(PriceTableStep.FAILED)
                  // Show error handling dialog
                }
              }}
            >
              <FeatureListElement />
            </CardForm>
          </Elements>
        </Box>
      </Paper>
    )
  }

  if (firstPageIsConfirmPayment) {
    return <></>
  }

  return (
    <Paper className={classes.root}>
      <Box
        style={{
          fontWeight: 700,
          fontSize: 20,
          textAlign: 'center',
          margin: 24
        }}
      >
        Manage Your ScreenHub Subscriptions
      </Box>
      <Box display="flex" flexDirection="row" alignItems="flex-end" justifyContent="space-evenly">
        <Paper classes={paperClasses} elevation={1} square>
          <Typography variant="h6" className={classes.root}>
            ScreenHub Standard
          </Typography>
          <Typography variant="h6" className={classes.root}>
            <span style={{ fontSize: 24 }}>$6.99</span> / month per display
          </Typography>
          <StandardFeaturesElement style={{ minHeight: 360 }} />
          <Button
            variant="outlined"
            color="primary"
            onClick={() => {
              onSubscribeClick(PlanEnums.PREMIUM_PLAN)
            }}
            className={classes.actionBtn}
          >
            SUBSCRIBE
          </Button>
        </Paper>
        <Paper
          classes={paperClasses}
          elevation={1}
          square
          style={{ padding: 0, border: '1px solid #8CC8FB' }}
        >
          <div
            style={{
              width: '100%',
              height: '36px',
              borderTopLeftRadius: '10px',
              borderTopRightRadius: '10px',
              color: '#FFFFFF',
              fontSize: '16px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              backgroundImage: 'linear-gradient(to right, #8CC8FB, #1A85E4)'
            }}
          >
            RECOMMENDED
          </div>
          <span style={{ padding: 24 }}>
            <Typography variant="h6" className={classes.root}>
              ScreenHub Pro <StarsIcon style={{ color: '#E9D19C' }} />
            </Typography>
            <Typography variant="h6" className={classes.root}>
              <span style={{ fontSize: 24 }}>$ 20.00</span> / month per display
            </Typography>
            <ProFeaturesElement style={{ minHeight: 360 }} />
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                onSubscribeClick(PlanEnums.PRO_PLAN)
              }}
              className={classes.actionBtn}
            >
              SUBSCRIBE
            </Button>
          </span>
        </Paper>
      </Box>
    </Paper>
  )
}
