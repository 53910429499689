import React from 'react'
import { Route, Switch, withRouter } from 'react-router-dom'
import { CustomerDashboardPage } from './customer-dashboard'

const CustomerDashboardPageRaw = ({ match }) => {
  return (
    <Switch>
      <Route render={() => <CustomerDashboardPage />} />
    </Switch>
  )
}

export const CustomerDashboardRoutes = withRouter(CustomerDashboardPageRaw)
