import React, { Children, FC, isValidElement, useState } from 'react'

import { Tab } from './tab'
import { StyledTab, StyledTabsPanel } from './styled-components'
import { TabsProps } from './types'

export const Tabs: FC<TabsProps> = ({
  children,
  color = '#000000',
  activeIndex = 0,
  justify = 'center',
  fontSize = '1rem',
  padding = undefined,
  indicatorColor = undefined,
  gap = undefined,
  inativeColor = undefined,
  onMouseDown = () => {},
}) => {
  const [tabIdx, updateTabIdx] = useState(activeIndex)

  // Make sure children elements are correct
  if (!children || Children.count(children) === 0) {
    throw Error('Tabs component must contain correct children')
  }

  Children.forEach(children, (child) => {
    if (!isValidElement(child)) {
      throw Error('The child is not valid React element')
    }

    const TabElem = <Tab title='' />

    if (child && child.type && !Object.is(child.type, TabElem.type)) {
      throw new Error('Tabs children must be Tab component(s)')
    }
  })

  if (activeIndex !== undefined && activeIndex > Children.count(children) - 1) {
    throw new Error('Active index is out of range')
  }

  return (
    <div
      onMouseDown={onMouseDown}
    >
      <StyledTabsPanel justify={justify} gap={gap}>
        {Children.map(children, (child: any, idx) => {
          return (
            <StyledTab
              active={tabIdx === idx}
              color={color}
              fontSize={fontSize}
              onClick={() => {
                updateTabIdx(idx)
              }}
              padding={padding}
              indicatorColor={indicatorColor}
              inativeColor={inativeColor}
            >
              {child.props.title}
            </StyledTab>
          )
        })}
      </StyledTabsPanel>
      <div>
        <section>{Children.toArray(children)[tabIdx]}</section>
      </div>
    </div>
  )
}

Tabs.displayName = 'Tabs'
