import gql from 'graphql-tag'

export const SUBSCRIPTION_MEDIA_PROGRESS = gql`
  subscription MediaProgress {
    mediaProgress {
      id
      progress
      eta
    }
  }
`
