import React, { FC, useState } from 'react'
import { Box, FormControl, FormControlLabel, FormLabel, Radio, RadioGroup, Typography } from '@material-ui/core'
import { BillingCycle } from 'src/screenhub-shared/enum/stripe.enum'

interface TProps {
  billingCycle?: BillingCycle
  disabled?: boolean
  onBillingCycleChange?: (billingCycle: BillingCycle) => void
}

export const BillingCycleInput: FC<TProps> = ({
  billingCycle = BillingCycle.MONTH,
  disabled = false,
  onBillingCycleChange
}) => {
  const [value, setValue] = useState<BillingCycle>(billingCycle)

  return (
    <Box style={{paddingTop: 10, paddingBottom: 10}}>
      <Typography variant='body1'>Billing Period</Typography>
      <FormControl disabled={disabled}>
        <RadioGroup
          row
          value={value}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            const newValue = (event.target as HTMLInputElement).value as BillingCycle
            setValue(newValue)
            onBillingCycleChange && onBillingCycleChange(newValue)
          }}
        >
          <FormControlLabel value={BillingCycle.MONTH} control={<Radio color="primary" />} label="Monthly" />
          <FormControlLabel value={BillingCycle.YEAR} control={<Radio color="primary" />} label="Annual" />
        </RadioGroup>
      </FormControl>
    </Box>
  )
}
