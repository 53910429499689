import gql from 'graphql-tag'

export const SC_GET_COLLECTION_CAMPAIGNS_Q = gql`
  query ScGetCollectionCampaignsQuery (
    $collectionId: String!,
    $start: String!,
    $forward: Boolean,
    $sortBy: String,
    $orderBy: OrderByEnum
  ) {
    campaigns(
      getCampaignDTO: {
        collectionId: $collectionId
        limit: 4
        start: $start
        offset: 0
        forward: $forward
        sortBy: $sortBy
        orderBy: $orderBy
      }
    ) {
      items {
        id
        start
        end
        slideDuration
        slotNumber
        manualOverride
        slots {
          id
          index
          ads {
            id
            presentationId
            slotId
            name
            percentageInSlot
            index
            presentation {
              id
              name
              isDeleted
            }
          }
        }
      }
      pagination {
        forwardTotalCount
        backwardTotalCount
        forward
        limit
        offset
      }
    }
  }
`


export const SC_GET_CAMPAIGN_SCHEDULE_COLLECTION_Q = gql`
  query ScGetCampaignScheduleCollection ($displayId: String!, $isIgnoreDisplayGroupLogic: Boolean!) {
    campaignScheduleCollection(displayId: $displayId, isIgnoreDisplayGroupLogic: $isIgnoreDisplayGroupLogic) {
      id
      name
      slotNumber
      slideDuration
    }
  }
`

export const SC_END_DATE_OF_CONTINUOUS_AVAILABLE_RANGE_IN_CAMPAIGN_SCHEDULE_Q = gql`
  query ScEndDateOfContinuousAvailableRangeInCampaignSchedule ($collectionId: String!, $start: String!, $percentageInSlot: PercentageInSlotEnum!) {
    endDateOfContinuousAvailableRangeInCampaignSchedule(endDateOfContinuousAvailableRangeInCampaignScheduleDTO:{collectionId: $collectionId, start: $start, percentageInSlot: $percentageInSlot}) {
      available
      endDate
    }
  }
`

