import React from 'react'
import { SvgIcon } from '@material-ui/core'

interface TProps {
  size?: number
}

export const AdsIcon: React.FC<TProps> = ({ size }) => (
  <SvgIcon>
    <svg
      fill='none'
      height='24'
      viewBox='0 0 24 24'
      width='24'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M5 5C4.73478 5 4.48043 5.10536 4.29289 5.29289C4.10536 5.48043 4 5.73478 4 6V15C4 15.2652 4.10536 15.5196 4.29289 15.7071C4.48043 15.8946 4.73478 16 5 16H19C19.2652 16 19.5196 15.8946 19.7071 15.7071C19.8946 15.5196 20 15.2652 20 15V6C20 5.73478 19.8946 5.48043 19.7071 5.29289C19.5196 5.10536 19.2652 5 19 5H5ZM5 3H19C19.7956 3 20.5587 3.31607 21.1213 3.87868C21.6839 4.44129 22 5.20435 22 6V15C22 15.7956 21.6839 16.5587 21.1213 17.1213C20.5587 17.6839 19.7956 18 19 18H5C4.20435 18 3.44129 17.6839 2.87868 17.1213C2.31607 16.5587 2 15.7956 2 15V6C2 5.20435 2.31607 4.44129 2.87868 3.87868C3.44129 3.31607 4.20435 3 5 3ZM9 19H15C15.2652 19 15.5196 19.1054 15.7071 19.2929C15.8946 19.4804 16 19.7348 16 20C16 20.2652 15.8946 20.5196 15.7071 20.7071C15.5196 20.8946 15.2652 21 15 21H9C8.73478 21 8.48043 20.8946 8.29289 20.7071C8.10536 20.5196 8 20.2652 8 20C8 19.7348 8.10536 19.4804 8.29289 19.2929C8.48043 19.1054 8.73478 19 9 19Z'
        fill='white'
      />
      <path
        d='M13 13V8H14.6399C15.0897 8 15.4942 8.10073 15.8531 8.3022C16.2121 8.50137 16.4918 8.78411 16.6923 9.15041C16.8951 9.51442 16.9977 9.92308 17 10.3764V10.6065C17 11.0643 16.9009 11.4753 16.7028 11.8393C16.507 12.201 16.2296 12.4849 15.8706 12.6909C15.514 12.8947 15.1154 12.9977 14.6748 13H13ZM14.2273 8.93063V12.0728H14.6538C15.0058 12.0728 15.2762 11.9503 15.465 11.7054C15.6538 11.4581 15.7483 11.0918 15.7483 10.6065V10.3901C15.7483 9.90705 15.6538 9.54304 15.465 9.29808C15.2762 9.05311 15.0012 8.93063 14.6399 8.93063H14.2273Z'
        fill='white'
      />
      <path
        d='M10.3524 12.0659H8.63695L8.33738 13H7L8.90442 8H10.0813L12 13H10.6555L10.3524 12.0659ZM8.93652 11.1353H10.0528L9.49287 9.4011L8.93652 11.1353Z'
        fill='white'
      />
    </svg>

  </SvgIcon>
)
