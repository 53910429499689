import React from 'react'
import ContentLoader from 'react-content-loader'
import styled from 'styled-components'

const ContentContainer = styled.section`
  width: 80vw;
  height: 80vh;
  max-width: 900px;
  overflow: scroll;
`

export const ImageLoadPlaceholder = () => (
  <ContentContainer>
    <ContentLoader
      height={800}
      primaryColor='#f3f3f3'
      secondaryColor='#ecebeb'
      speed={2}
      width={800}
    >
      <rect
        height='40'
        rx='0'
        ry='0'
        width='140'
        x='160'
        y='0'
      />
      <rect
        height='40'
        rx='0'
        ry='0'
        width='140'
        x='320'
        y='0'
      />
      <rect
        height='40'
        rx='0'
        ry='0'
        width='140'
        x='480'
        y='0'
      />
      <rect
        height='120'
        rx='0'
        ry='0'
        width='120'
        x='30'
        y='80'
      />
      <rect
        height='120'
        rx='0'
        ry='0'
        width='120'
        x='180'
        y='80'
      />
      <rect
        height='120'
        rx='0'
        ry='0'
        width='120'
        x='330'
        y='80'
      />
      <rect
        height='120'
        rx='0'
        ry='0'
        width='120'
        x='480'
        y='80'
      />
      <rect
        height='120'
        rx='0'
        ry='0'
        width='120'
        x='630'
        y='80'
      />
      <rect
        height='120'
        rx='0'
        ry='0'
        width='120'
        x='30'
        y='230'
      />
      <rect
        height='120'
        rx='0'
        ry='0'
        width='120'
        x='180'
        y='230'
      />
      <rect
        height='120'
        rx='0'
        ry='0'
        width='120'
        x='330'
        y='230'
      />
      <rect
        height='120'
        rx='0'
        ry='0'
        width='120'
        x='480'
        y='230'
      />
      <rect
        height='120'
        rx='0'
        ry='0'
        width='120'
        x='630'
        y='230'
      />
      <rect
        height='120'
        rx='0'
        ry='0'
        width='120'
        x='30'
        y='380'
      />
      <rect
        height='120'
        rx='0'
        ry='0'
        width='120'
        x='180'
        y='380'
      />
      <rect
        height='120'
        rx='0'
        ry='0'
        width='120'
        x='330'
        y='380'
      />
      <rect
        height='120'
        rx='0'
        ry='0'
        width='120'
        x='480'
        y='380'
      />
      <rect
        height='120'
        rx='0'
        ry='0'
        width='120'
        x='630'
        y='380'
      />
      <rect
        height='120'
        rx='0'
        ry='0'
        width='120'
        x='30'
        y='530'
      />
      <rect
        height='120'
        rx='0'
        ry='0'
        width='120'
        x='180'
        y='530'
      />
      <rect
        height='120'
        rx='0'
        ry='0'
        width='120'
        x='330'
        y='530'
      />
      <rect
        height='120'
        rx='0'
        ry='0'
        width='120'
        x='480'
        y='530'
      />
      <rect
        height='120'
        rx='0'
        ry='0'
        width='120'
        x='630'
        y='530'
      />
    </ContentLoader>
  </ContentContainer>
)
