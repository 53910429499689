import gql from 'graphql-tag'

export const REP_GET_ALL_DISPLAY_REPORTS_Q = gql`
  query RepGetAllDisplayReportsQuery ($start: Datetime!, $end: Datetime!) {
    reportAllDisplaysPresentationsAppearStats(start: $start, end: $end) {
      displayId
      displayName
      presentationId
      presentationName
      duration
      views
    }
  }
`

export const REP_GET_DISPLAY_REPORT_Q = gql`
  query RepGetDisplayReportQuery (
    $displayId: UUID!
    $presentationId: UUID!
    $start: Datetime!
    $end: Datetime!
  ) {
    reportDisplayPresentationSlidesAppearStats(
      displayId: $displayId
      presentationId: $presentationId
      start: $start
      end: $end
    ) {
      slideId
      duration
      views
      presentationName
      presentationId
    }
  }
`
