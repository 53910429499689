import React, { FC } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';

const useStyles = makeStyles((theme) => ({
  container: (props: {aspectRatio?: number}) => ({
    display: 'flex',
    width: (props.aspectRatio >= 1) ? '100px' : '50px',
    height: (props.aspectRatio > 1) ? '50px' : '100px',
    alignItems: 'flex-start',
  }),
  columnContainer: (props: {aspectRatio?: number}) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
  }),
  left70: (props: {aspectRatio?: number}) => ({
    display: 'flex',
    alignItems: 'flex-start',
    width: (props.aspectRatio >= 1) ? `${100 * (2/3)}px` : `${50 * (2/3)}px`,
    alignSelf: 'stretch',
    border: '1px solid var(--Sytetm-Divider, currentColor)',
    borderRight: 'none',
  }),
  upperRight5: (props: {aspectRatio?: number}) => ({
    display: 'flex',
    alignItems: 'flex-start',
    width: (props.aspectRatio >= 1) ? `${100 * (1/3)}px` : `${50 * (1/3)}px`,
    height: (props.aspectRatio > 1) ? `${50 * (1/3)}px` : `${100 * (1/3)}px`,
    alignSelf: 'stretch',
    border: '1px solid var(--Sytetm-Divider, currentColor)',
    borderBottom: 'none',
  }),
  lowerRight25: (props: {aspectRatio?: number}) => ({
    display: 'flex',
    alignItems: 'flex-start',
    height: (props.aspectRatio > 1) ? `${50 * (2/3)}px` : `${100 * (2/3)}px`,
    alignSelf: 'stretch',
    border: '1px solid var(--Sytetm-Divider, currentColor)',
  }),
  left70Selected: (props: {aspectRatio?: number}) => ({
    border: '2px solid var(--Sytetm-Divider, #1d93f3)',
    borderRight: 'none',
  }),
  upperRight5Selected: (props: {aspectRatio?: number}) => ({
    border: '2px solid var(--Sytetm-Divider, #1d93f3)',
    borderBottom: 'none',
  }),
  lowerRight25Selected: (props: {aspectRatio?: number}) => ({
    border: '2px solid var(--Sytetm-Divider, #1d93f3)',
  }),
}));

export const Layout70To5To25Icon: FC<{isSelected: boolean; aspectRatio?: number}> = ({
  isSelected,
  aspectRatio,
}) => {
  const {
    container,
    columnContainer,
    left70,
    upperRight5,
    lowerRight25,
    left70Selected,
    upperRight5Selected,
    lowerRight25Selected,
  } = useStyles({ aspectRatio });

  const left70ClassName = classNames(left70, { [left70Selected]: isSelected });
  const upperRight5ClassName = classNames(upperRight5, { [upperRight5Selected]: isSelected });
  const lowerRight25ClassName = classNames(lowerRight25, { [lowerRight25Selected]: isSelected });

  return (
    <div className={container}>
        <div className={left70ClassName} />
        <div className={columnContainer}>
            <div className={upperRight5ClassName} />
            <div className={lowerRight25ClassName} />
        </div>
    </div>
  );
};
