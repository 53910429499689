import React from 'react'
import {
  FormControl,
  FormControlLabel,
  Checkbox,
  Typography,
  FormHelperText,
  Theme
} from '@material-ui/core'
import { styled } from '@material-ui/styles'
import { CheckboxProps } from '@material-ui/core/Checkbox'

interface TCustomCheckboxProps extends CheckboxProps {
  errorMessage?: string
  label: React.ReactNode
}

const ErrorMessage = styled(FormHelperText)<Theme>(({ theme }) => ({
  position: 'absolute',
  top: '100%',
  margin: 0,
  '&:first-letter': { textTransform: 'capitalize' },
  ...theme.typography.subtitle2,
  fontSize: 14
}))

export const CustomCheckbox: React.FC<TCustomCheckboxProps> = ({
  errorMessage,
  label,
  inputProps,
  ...rest
}) => {
  return (
    <FormControl error={!!errorMessage}>
      {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
      <FormControlLabel
        control={
          <Checkbox
            checked={inputProps.checked}
            color='primary'
            inputProps={inputProps}
            {...rest}
          />
        }
        label={<Typography variant='body2'>{label}</Typography>}
      />
    </FormControl>
  )
}
