import React, { useEffect } from 'react'
import { Query, useQuery, useLazyQuery } from 'react-apollo'
import { Route, Redirect, RouteProps } from 'react-router'
import { CircularProgress, Fade, Box } from '@material-ui/core'
import { styled } from '@material-ui/styles'
import * as ApolloAPI from 'src/apollo/api'
import { DefaultLayout } from '../templates/default-layout'
import { PrivateLayout } from '../templates/private-layout'
import { logout, signInPathWithPreviousLocationInQuery } from 'src/helpers/authHelper'

const Fullscreen = styled(Box)({
  width: '100%',
  height: '100vh',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center'
})

export const PrivateRoute: React.FC<RouteProps & { withLayout?: boolean }> = ({
  component: Component,
  withLayout,
  ...rest
}) => {
  const token = window.localStorage.getItem('token')
  const { loading, error, data } = useQuery(ApolloAPI.COMM_GET_ME_Q, {skip: !token})

  useEffect(() => {
    if (data && !data?.me && !loading) {
      logout(signInPathWithPreviousLocationInQuery());
    }
  }, [loading, data, data?.me]);

  const user = data?.me
  const isAuthenticated = Boolean(user)

  const { loading: subscriptionLoading, error: subscriptionError, data: subscriptionData } =
    useQuery(ApolloAPI.PW_RETRIEVE_SUBSCRIPTION, { variables: {accountId: user?.account?.id}, skip: !user })

  return (
    <Route
      {...rest}
      render={props => {
        if (loading) {
          return (
            <Fade
              in={loading}
              style={{
                transitionDelay: '100ms'
              }}
              unmountOnExit
            >
              <Fullscreen>
                <CircularProgress />
              </Fullscreen>
            </Fade>
          )
        }

        if (error || subscriptionError) {
          return <div>An error occurred</div>
        } else if (subscriptionLoading) {
          return <div>Loading subscription data</div>
        }

        if (isAuthenticated) {
          return withLayout ? (
            <Query query={ApolloAPI.COMM_GET_REMEMBERED_ADMIN_USER_Q}>
              {() => (
                <DefaultLayout>
                  <PrivateLayout>
                    <Component {...props} />
                  </PrivateLayout>
                </DefaultLayout>
              )}
            </Query>
          ) : (
            <Query query={ApolloAPI.COMM_GET_REMEMBERED_ADMIN_USER_Q}>
              {() => <Component {...props} />}
            </Query>
          )
        }

        return <Redirect to={signInPathWithPreviousLocationInQuery()} />
      }}
    />
  )
}

PrivateRoute.defaultProps = {
  withLayout: true
}
