import gql from 'graphql-tag'

export const SC_CREATE_WORKSPACE = gql`
  mutation  ScCreateWorkspace($name: String!) {
    createWorkspace(name: $name) {
      code
      success
      message
      workspace {
        id
        name
        userPath
        workspacePath
      }
    }
  }
`

export const SC_UPDATE_WORKSPACE = gql`
  mutation  ScUpdateWorkspace($workspaceId: UUID!, $name: String!) {
    updateWorkspace(workspaceId: $workspaceId, name: $name) {
      code
      success
      message
      workspace {
        id
        name
        userPath
        workspacePath
      }
    }
  }
`

export const SC_DELETE_WORKSPACE = gql`
  mutation  ScDeleteWorkspace($workspaceId: UUID!, $password: String!) {
    deleteWorkspace(workspaceId: $workspaceId, password: $password) {
      code
      success
      message
    }
  }
`

export const SC_TRANSFER_WORKSPACE = gql`
  mutation  ScTransferWorkspace($workspaceId: UUID!, $email: String!) {
    transferWorkspace(workspaceId: $workspaceId, email: $email) {
      code
      success
      message
    }
  }
`
