export enum SchedulingTypeEnum {
  DEFAULT = 'DEFAULT',
  CAMPAIGN = 'CAMPAIGN',
}

export enum PlaylistTypeEnum {
  DEFAULT = 'DEFAULT',
  CAMPAIGN = 'CAMPAIGN',
  ALERT = 'ALERT',
}
