import React, { FC, useCallback, useMemo, useEffect, useRef, useState } from 'react'
import { pathOr } from 'ramda'
import AnnounceKit from 'announcekit-react'
import { createStyles, makeStyles, useTheme } from '@material-ui/styles'
import { ListItemIcon, ListItemText, Badge, Tooltip, Typography, Theme } from '@material-ui/core'
import Divider from '@material-ui/core/Divider'
import Button from '@material-ui/core/Button'
import Menu from '@material-ui/core/Menu'
import MenuList from '@material-ui/core/MenuList'
import MenuItem from '@material-ui/core/MenuItem'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import PersonOutlinedIcon from '@material-ui/icons/PersonOutlined'
import GroupOutlinedIcon from '@material-ui/icons/GroupOutlined'
import LocalAtmOutlinedIcon from '@material-ui/icons/LocalAtmOutlined'
import HelpIcon from '@material-ui/icons/Help'
import ExitToAppOutlinedIcon from '@material-ui/icons/ExitToAppOutlined'
import NotificationsIcon from '@material-ui/icons/Notifications'
import { history } from 'src/app'
import { useUserInfo, useRememberedAdminUserInfo } from 'src/services'
import { useQuery } from 'react-apollo'
import * as ApolloAPI from 'src/apollo/api'
import LocalShippingOutlinedIcon from '@material-ui/icons/LocalShippingOutlined';
import { logout, stopImpersonate } from '../../helpers'
import { COLOR_BLUE, COLOR_WHITE } from '../../constants/style'
import { NewFeatModal } from '../molecules/modal-new-feat'
import { isHideFeature } from '../../helpers/white-label-helper'
import { WhiteLabelFeaturesEnum } from '../../helpers/white-label.enum'

import useWorkspaceId from 'src/hooks/useWorkspaceId';

import urlHelper from 'src/helpers/urlHelper';

const useStyles = makeStyles<Theme>(() => {
  return createStyles({
    grow: {
      flexGrow: 1
    },
    avatar: {
      width: 40,
      height: 40,
      color: COLOR_WHITE,
      marginRight: 8,
      transition: '0.3s',
      borderRadius: 20,
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: COLOR_BLUE,
      fontWeight: 'bold',
      '@media print': {
        display: 'none !important'
      }
    },
    username: {
      marginLeft: 8,
      marginRight: 12,
      color: COLOR_WHITE
    },
    impersonate: {
      color: 'red',
      fontSize: 13,
      fontWeight: 500
    },
    adminName: {
      padding: 7,
      marginLeft: 10,
      color: '#2196f3',
      fontWeight: 500
    },
    menuList: {
      paddingTop: '4px',
      paddingBottom: '4px'
    },
    menuPaper: {
      backgroundColor: '#FFFFFF'
    },
    listItemIconRoot: {
      minWidth: '32px'
    },
    listItemPrimaryText: {
      color: '#1D2026'
    },
    impersonatePrimaryText: {
      color: 'red'
    }
  })
})

export const HeaderBar: FC = () => {
  const workspaceId = useWorkspaceId();
  
  const {
    me,
    me: {
      account: { id: accountId }
    }
  } = useUserInfo()

  const { rememberedAdminUser } = useRememberedAdminUserInfo() || {}

  const { loading: subscriptionLoading, error: subscriptionError, data: subscription } =
    useQuery(ApolloAPI.PW_RETRIEVE_SUBSCRIPTION, { variables: { accountId }, skip: !accountId })

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)

  const theme = useTheme()
  const classes = useStyles(theme)
  const fullName = useMemo(
    () => `${pathOr('', ['firstName'], me)} ${pathOr('', ['lastName'], me)}`,
    [me]
  )

  const handleMenu = useCallback(
    (event: React.MouseEvent<HTMLElement>) => setAnchorEl(event.currentTarget),
    []
  )

  const handleClose = useCallback(() => setAnchorEl(null), [])

  // Refresh the news periodically and display the pop-up if a news is around
  const announceKitRef = useRef<AnnounceKit>(null)
  const [time, setTime] = useState(Date.now())
  const newsCheck = unread => {
    if (unread > 0) announceKitRef.current.open()
  }
  // check every 5 min
  useEffect(() => {
    const interval = setInterval(() => setTime(Date.now()), 300000)
    return () => {
      clearInterval(interval)
    }
  }, [])

  const getInitials = () => {
    const initialsArray = fullName.split(' ')
    return `${'' || initialsArray[0].substring(0, 1).toUpperCase()}${'' || initialsArray[1].substring(0, 1).toUpperCase()}`
  }

  const AnnounceKitBadge = (user) => {
    const styles = {
      verticalAlign: 'text-bottom'
    }

    return (
      <div
        className={classes.feedButton}
        style={{ display: 'none' }}
      >
        <AnnounceKit
          data={user?.id}
          key={time}
          onWidgetUnread={unread => newsCheck(unread)}
          ref={announceKitRef}
          widget='https://announcekit.app/widgets/v2/4ejyzS'
          widgetStyle={styles}
        />
      </div>
    )
  }

  const renderNotification = () => {
    if (isHideFeature(WhiteLabelFeaturesEnum.ANNOUNCEMENTS)) {
      return <></>
    }

    return (
      <Button
        onClick={() => { announceKitRef.current.open() }}
        style={{ textTransform: 'none', height: 50 }}
      >
        <Badge
          badgeContent={0}
          color='error'
        >
          <NotificationsIcon style={{ color: COLOR_WHITE }} />
        </Badge>
      </Button>
    )
  }
  const renderTutorial = () => {
    if (isHideFeature(WhiteLabelFeaturesEnum.QUESTION_BUTTON)) {
      return <></>
    }

    return (
      <Tooltip
        aria-label='tutorial'
        title='QUESTIONS? CHECK OUT OUR TUTORIAL VIDEOS'
      >
        <Button
          href='https://www.youtube.com/playlist?list=PLDjUHEnBheaqGnH8GRPclx0U_REy1WMdH'
          rel='noopener noreferrer'
          style={{ textTransform: 'none', height: 50 }}
          target='_blank'
        >
          <HelpIcon style={{ color: COLOR_WHITE, fontSize: '25px' }} />
        </Button>
      </Tooltip>
    )
  }
  const renderUserButton = () => {
    if (rememberedAdminUser?.id) {
      return (
        <Button
          aria-haspopup='true'
          aria-owns={open ? 'menu-account' : undefined}
          onClick={handleMenu}
          style={{ textTransform: 'none' }}
        >
          <Typography className={classes.avatar}>{getInitials()}</Typography>
          <div>
            <Typography
              className={classes.username}
              noWrap
            >
              {fullName}
            </Typography>
            <Typography
              className={classes.impersonate}
              noWrap
            >
              IMPERSONATE
            </Typography>
          </div>
          <ExpandMoreIcon style={{ color: COLOR_WHITE }} />
        </Button>
      )
    } else {
      return (
        <Button
          aria-haspopup='true'
          aria-owns={open ? 'menu-account' : undefined}
          onClick={handleMenu}
          style={{ textTransform: 'none' }}
        >
          <Typography className={classes.avatar}>{getInitials()}</Typography>
          <Typography
            className={classes.username}
            noWrap
          >
            {fullName}
          </Typography>
          <ExpandMoreIcon style={{ color: COLOR_WHITE }} />
        </Button>
      )
    }
  }

  const renderMenuListItem = ({ key, title, icon, description = null, path = '', isRedirect = false, onClickItem = null }) => {
    const defaultOnClickListItem = () => {
      if (isRedirect) {
        window.open(path)
      } else {
        history.push(path)
      }

      handleClose()
    }

    let primaryClass = classes.listItemPrimaryText

    if (title === 'Stop Impersonation' || key === 'StopImpersonation') {
      primaryClass = classes.impersonatePrimaryText
    }

    return (
      <MenuItem
        key={key}
        onClick={onClickItem || defaultOnClickListItem}
      >
        <ListItemIcon classes={{ root: classes.listItemIconRoot }}>
          {icon}
        </ListItemIcon>
        <ListItemText
          classes={{ primary: primaryClass }}
          primary={title}
        />
      </MenuItem>
    )
  }

  const renderMenuList = () => {
    const originalListItems = [
      {
        key: 'Workspace',
        title: 'Workspace Management',
        icon: <GroupOutlinedIcon />,
        description: 'Add users and manage user access to workspaces you own',
        path: urlHelper.getWorkspaceMemberPageUrl(workspaceId),
        isRedirect: false
      },
      {
        key: 'Account',
        title: 'Account Settings',
        icon: <PersonOutlinedIcon />,
        description: 'Manage email notifications and other settings',
        path: urlHelper.getAccountPageUrl(workspaceId),
        isRedirect: false
      },
      {
        key: 'Billing',
        title: 'Billing',
        icon: <LocalAtmOutlinedIcon />,
        description: 'Manage subscriptions',
        path: urlHelper.getBillingPageUrl(workspaceId),
        isRedirect: false
      },
      {
        key: 'OrderTracking',
        title: 'Order Tracking',
        icon: <LocalShippingOutlinedIcon />,
        description: 'Track your orders',
        path: urlHelper.getOrderTrackingPageUrl(workspaceId),
        isRedirect: false
      }
    ]

    const listItems = originalListItems.filter((item) => {
      // Should check '/billing' in the future. We did not have the case in originalListItems before. 
      if (isHideFeature(WhiteLabelFeaturesEnum.PAYWALL) && item.path.includes('/billing')) {
        return false
      }

      return true
    })

    if (rememberedAdminUser?.id) {
      return (
        <MenuList>
          {renderMenuListItem(listItems[0])}
          {renderMenuListItem(listItems[1])}
          <Divider />
          {renderMenuListItem({
            key: 'StopImpersonation',
            title: 'Stop Impersonation',
            icon: <ExitToAppOutlinedIcon />,
            path: '',
            onClickItem: stopImpersonate
          })}
        </MenuList>
      )
    } else {
      return (
        <MenuList>
          {listItems.map(item => renderMenuListItem(item))}
        </MenuList>
      )
    }
  }

  const isHideAnnouncements = isHideFeature(WhiteLabelFeaturesEnum.ANNOUNCEMENTS)

  return (
    <>
      {!rememberedAdminUser && !isHideAnnouncements && AnnounceKitBadge(me)}
      <div className={classes.grow} />
      {!rememberedAdminUser && renderNotification()}
      {!rememberedAdminUser && renderTutorial()}
      {renderUserButton()}
      <Menu
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        classes={{ paper: classes.menuPaper, list: classes.menuList }}
        id='menu-account'
        onClose={handleClose}
        open={open}
        style={{ top: 40, zIndex: 1400 }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
      >
        {renderMenuList()}
        <Divider />

        {renderMenuListItem({
          key: 'Logout',
          title: 'Logout',
          icon: <ExitToAppOutlinedIcon />,
          path: '',
          onClickItem: () => logout()
        })}
      </Menu>

      {rememberedAdminUser === null && <NewFeatModal
        me={me}
        subscription={subscription}
      />}
    </>
  )
}

HeaderBar.defaultProps = {}
