import gql from 'graphql-tag'

export const UPDATE_SUBSCRIBED_QUANTITY = gql`
  mutation UpdateSubscribedQuantity(
    $accountId: String!
    $quantity: Int!
    $stripeSubscriptionId: String
  ) {
    updateSubscribedQty(
      subscriptionPayloadDTO: {
        accountId: $accountId
        quantity: $quantity
        stripeSubscriptionId: $stripeSubscriptionId
      }
    ) {
      id
      accountId
      stripeSubscriptionId
      qty
      status
      planName
      stripeCustomerId
      cirrusStandardQty
      category
      trialEnd
      billingCycle
    }
  }
`

export const UPDATE_CELLULAR_SUBSCRIPTION = gql`
  mutation UpdateCellularSubscription(
    $accountId: String!
    $quantity: Int!
    $stripeSubscriptionId: String
  ) {
    updateSubscription(
      subscriptionPayloadDTO: {
        accountId: $accountId
        quantity: $quantity
        stripeSubscriptionId: $stripeSubscriptionId
        category: CELLULAR
        planName: CELLULAR_PLAN
      }
    ) {
      id
      accountId
      stripeSubscriptionId
      qty
      status
      planName
      stripeCustomerId
      cirrusStandardQty
      category
      trialEnd
      billingCycle
    }
  }
`

export const UPDATE_DEFAULT_PAYMENT_METHOD = gql`
  mutation UpdateDefaultPaymentMethod($accountId: String!, $paymentMethodId: String!) {
    defaultPaymentMethod(accountId: $accountId, paymentMethodId: $paymentMethodId)
  }
`

export const UPDATE_END_SUBSCRIPTION_TRIAL = gql`
  mutation EndSubscriptionTrial($accountId: String!) {
    endSubscriptionTrial(updateSubscriptionDTO: { accountId: $accountId }) {
      id
      accountId
      stripeSubscriptionId
      qty
      status
      planName
      stripeCustomerId
      cirrusStandardQty
      category
      trialEnd
      billingCycle
    }
  }
`

export const UPDATE_DISPLAY_SUBSCRIPTION = gql`
  mutation UpdateDisplaySubscription(
    $accountId: String!
    $billingCycle: BillingCycle
    $planName: PlanEnums
    $quantity: Int
    $cirrusStandardQty: Int
  ) {
    updateSubscription(
      subscriptionPayloadDTO: {
        accountId: $accountId
        billingCycle: $billingCycle
        planName: $planName
        quantity: $quantity
        cirrusStandardQty: $cirrusStandardQty
      }
    ) {
      id
      accountId
      stripeSubscriptionId
      qty
      status
      planName
      stripeCustomerId
      cirrusStandardQty
      category
      trialEnd
      billingCycle
    }
  }
`

export const ACTIVATE_SUBSCRIPTION = gql`
  mutation ActivateSubscription(
    $accountId: String!
    $category: SubscriptionCategoryEnum
    $stripeSubscriptionId: String
  ) {
    activateSubscription(
      subscriptionPayloadDTO: {
        accountId: $accountId
        category: $category
        stripeSubscriptionId: $stripeSubscriptionId
      }
    ) {
      id
      accountId
      stripeSubscriptionId
      qty
      status
      planName
      stripeCustomerId
      cirrusStandardQty
      category
      trialEnd
      billingCycle
    }
  }
`

export const UPDATE_CUSTOMER_ADDRESS = gql`
  mutation UpdateCustomerAddress($stripeCustomerId: String!, $shipping: ShippingDTO!) {
    updateCustomerAddress(
      stripeShippingDTO: { stripeCustomerId: $stripeCustomerId, shipping: $shipping }
    ) {
      id
      shipping {
        name
        phone
        address {
          city
          country
          line1
          line2
          postal_code
          state
        }
      }
      invoice_settings {
        default_payment_method {
          card {
            brand
            last4
            exp_month
            exp_year
          }
        }
      }
    }
  }
`

export const BIND_PLAYER_CELLULAR_SUBSCRIPTION = gql`
  mutation BindPlayerCellularSubscription($playerId: String!, $trialDays: Int) {
    bindPlayerCellularSubscription(
      bindPlayerCellularSubscriptionDTO: { playerId: $playerId, trialDays: $trialDays }
    ) {
      id
      accountId
      stripeSubscriptionId
      qty
      status
      planName
      stripeCustomerId
      cirrusStandardQty
      category
      trialEnd
      billingCycle
    }
  }
`

export const UPDATE_CIRRUS_STANDARD_QUANTITY = gql`
  mutation UpdateCirrusStandardQuantity(
    $getLatestSubscriptionDTO: GetLatestSubscriptionDTO!
    $cirrusStandardQty: Int
    $quantity: Int
  ) {
    updateCirrusStandardQuantity(
      updateCirrusStandardQtyDTO: {
        getLatestSubscriptionDTO: $getLatestSubscriptionDTO
        cirrusStandardQty: $cirrusStandardQty
        quantity: $quantity
      }
    ) {
      id
      accountId
      stripeSubscriptionId
      qty
      status
      planName
      stripeCustomerId
      cirrusStandardQty
      category
      trialEnd
      billingCycle
    }
  }
`

export const DELETE_PAYMENT_METHOD = gql`
  mutation DeletePaymentMethod($paymentMethodId: String!) {
    deletePaymentMethod(paymentMethodId: $paymentMethodId) {
      id
      type
      card {
        brand
        last4
        exp_month
        exp_year
      }
    }
  }
`

export const PW_CANCEL_SUBSCRIPTION = gql`
  mutation CancelSubscriptionByAccount ($stripeSubscriptionId: String!) {
    cancelSubscription(stripeSubscriptionId: $stripeSubscriptionId) {
      id
      stripeSubscriptionId
      status
    }
  }
`