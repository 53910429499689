import React, { FC, useContext } from 'react'
import PropTypes from 'prop-types'
import { Theme } from '@material-ui/core/styles'
import { createStyles, makeStyles, useTheme } from '@material-ui/styles'
import { LayoutContext } from '../../templates/default-layout'
import { useLocation } from 'react-router'
import { position } from 'polished'

const useStyles = makeStyles<Theme>(theme => {
  return createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      flexGrow: 1,
      transition: theme.transitions.create(['margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen
      }),
      '@media print': {
        margin: '0px !important',
        position: 'absolute',
        top: 0,
        left: 0,
        '-webkit-print-color-adjust': 'exact',
        '-moz-print-color-adjust': 'exact',
        '-ms-print-color-adjust': 'exact',
        'print-color-adjust': 'exact'
      }
    }
  })
})

interface ContentProps {
  className?: string
  component?: any
  children: any
  style?: any
}

export const LayoutContent: FC<ContentProps> = ({
  className,
  component: Component,
  style,
  ...props
}) => {
  const theme = useTheme<Theme>()
  const classes = useStyles(theme)
  const ctx = useContext(LayoutContext)
  const { pathname } = useLocation()

  const {
    navVariant,
    navWidth,
    collapsible,
    collapsed,
    collapsedWidth,
    open,
    navAnchor,
    squeezed
  } = ctx

  const getMargin = () => {
    if (navAnchor !== 'left' || pathname.endsWith('/lobby')) return 0

    if (navVariant === 'persistent' && open) {
      // open is effect only when
      // navVariant === 'persistent' ||
      // navVariant === 'temporary'
      return navWidth
    }

    if (navVariant === 'permanent') {
      if (collapsible) {
        if (collapsed) return collapsedWidth
        return navWidth
      }
      return navWidth
    }
    return 0
  }

  const getWidth = () => {
    if (navVariant === 'persistent' && open) {
      // open is effect only when
      // navVariant === 'persistent' ||
      // navVariant === 'temporary'
      if (squeezed) {
        return 'auto'
      }
      return '100%'
    }
    return 'auto'
  }

  return (
    <Component
      {...props}
      className={`${className} ${classes.root}`}
      style={{
        ...style,
        marginLeft: getMargin(),
        width: getWidth()
      }}
    />
  )
}

LayoutContent.propTypes = {
  className: PropTypes.string,
  component: PropTypes.elementType,
  style: PropTypes.shape({})
}

LayoutContent.defaultProps = {
  className: '',
  component: 'main',
  style: {}
}
