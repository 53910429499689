import React from 'react'
import { Link, LinkProps } from 'react-router-dom'

const style: React.CSSProperties = {
  textDecoration: 'none',
  color: 'inherit'
}

export const CustomLink: React.FC<LinkProps> = (props) => (
  <Link
    {...props}
    style={{ ...style, ...props.style }}
  />
)
