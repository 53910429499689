import React from 'react'
import { TextField, Theme } from '@material-ui/core'
import { OutlinedTextFieldProps } from '@material-ui/core/TextField'
import { styled, makeStyles, useTheme } from '@material-ui/styles'

export interface CustomFieldProps extends Omit<OutlinedTextFieldProps, 'margin' | 'variant'> {
  errorMessage?: string
}

const useHelperTextClasses = makeStyles<Theme>((theme) => ({
  root: {
    position: 'absolute',
    bottom: '100%',
    margin: 0,
    '&:first-letter': { textTransform: 'capitalize' },
    ...theme.typography.subtitle2,
    fontSize: 14
  }
}))

const StyledTextField = styled(TextField)({
  fontSize: 14
})

export const CustomField: React.FC<CustomFieldProps> = ({ errorMessage, inputProps, ...rest }) => {
  const theme = useTheme()
  const helperTextClasses = useHelperTextClasses(theme)

  return (
    <StyledTextField
      {...rest}
      error={!!errorMessage}
      FormHelperTextProps={{ classes: helperTextClasses }}
      helperText={errorMessage}
      inputProps={inputProps}
      margin='dense'
      variant='outlined'
    />
  )
}

CustomField.defaultProps = {
  errorMessage: ''
}
