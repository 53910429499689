import React, { useEffect } from 'react'

interface TProps {
  to: string
}

export const ExternalRedirect: React.FC<TProps> = ({ to }) => {
  useEffect(() => {
    window.location.href = to
  }, [to])

  return null
}
