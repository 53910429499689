import React, { FC } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';

const useStyles = makeStyles((theme) => ({
  container: (props: {aspectRatio?: number}) => ({
    display: 'flex',
    width: (props.aspectRatio >= 1) ? '100px' : '50px',
    height: (props.aspectRatio > 1) ? '50px' : '100px',
    flexDirection: 'column',
    alignItems: 'flex-start',
  }),
  upperContainer: (props: {aspectRatio?: number}) => ({
    display: 'flex',
    width: (props.aspectRatio >= 1) ? '100px' : '50px',
    height: (props.aspectRatio > 1) ? '40px' : '80px',
    alignItems: 'flex-start',
  }),
  lower20: (props: {aspectRatio?: number}) => ({
    height: (props.aspectRatio > 1) ? '10px' : '20px',
    flexShrink: 0,
    alignSelf: 'stretch',
    border: '1px solid var(--Sytetm-Divider, currentColor)',
    
  }),
  upperLeft26: (props: {aspectRatio?: number}) => ({
    display: 'flex',
    alignItems: 'flex-start',
    flex: '1 0 0',
    alignSelf: 'stretch',
    border: '1px solid var(--Sytetm-Divider, currentColor)',
    borderRight: 'none',
    borderBottom: 'none',
  }),
  upperMiddle26: (props: {aspectRatio?: number}) => ({
    display: 'flex',
    alignItems: 'flex-start',
    flex: '1 0 0',
    alignSelf: 'stretch',
    border: '1px solid var(--Sytetm-Divider, currentColor)',
    borderRight: 'none',
    borderBottom: 'none',
  }),
  upperRight26: (props: {aspectRatio?: number}) => ({
    display: 'flex',
    alignItems: 'flex-start',
    flex: '1 0 0',
    alignSelf: 'stretch',
    border: '1px solid var(--Sytetm-Divider, currentColor)',
    borderBottom: 'none',
  }),
  lower20Selected: (props: {aspectRatio?: number}) => ({
    border: '2px solid var(--Sytetm-Divider, #1d93f3)',
  }),
  upperLeft26Selected: (props: {aspectRatio?: number}) => ({
    border: '2px solid var(--Sytetm-Divider, #1d93f3)',
    borderRight: 'none',
    borderBottom: 'none',
  }),
  upperMiddle26Selected: (props: {aspectRatio?: number}) => ({
    border: '2px solid var(--Sytetm-Divider, #1d93f3)',
    borderRight: 'none',
    borderBottom: 'none',
  }),
  upperRight26Selected: (props: {aspectRatio?: number}) => ({
    border: '2px solid var(--Sytetm-Divider, #1d93f3)',
    borderBottom: 'none',
  }),
}));

export const Layout26To26To26To20Icon: FC<{isSelected: boolean; aspectRatio?: number}> = ({
  isSelected,
  aspectRatio,
}) => {
  const {
    container,
    upperContainer,
    lower20,
    upperLeft26,
    upperMiddle26,
    upperRight26,
    lower20Selected,
    upperLeft26Selected,
    upperMiddle26Selected,
    upperRight26Selected,
  } = useStyles({ aspectRatio });

  const lower20ClassName = classNames(lower20, { [lower20Selected]: isSelected });
  const upperLeft26ClassName = classNames(upperLeft26, { [upperLeft26Selected]: isSelected });
  const upperMiddle26ClassName = classNames(upperLeft26, { [upperMiddle26Selected]: isSelected });
  const upperRight26ClassName = classNames(upperRight26, { [upperRight26Selected]: isSelected });

  return (
    <div className={container}>
      <div className={upperContainer}>
        <div className={upperLeft26ClassName} />
        <div className={upperMiddle26ClassName} />
        <div className={upperRight26ClassName} />
      </div>
      <div className={lower20ClassName} />
    </div>
  );
};
