import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { logout } from 'src/helpers'
import { history } from 'src/app'
import { signInPathWithPreviousLocationInQuery } from 'src/helpers/authHelper'

const parseJwt = token => {
  try {
    return JSON.parse(atob(token.split('.')[1]))
  } catch (e) {
    return null
  }
}

export class AuthVerify extends Component {
  constructor(props) {
    super(props)

    history.listen(() => {
      const token = localStorage.getItem('token')

      if (token) {
        const decodedJwt = parseJwt(token)

        if (decodedJwt?.exp * 1000 >= Date.now() && decodedJwt?.userId) {
          return
        }

        logout(signInPathWithPreviousLocationInQuery())
      }
    })
  }

  render() {
    return <></>
  }
}

export default withRouter(AuthVerify)
