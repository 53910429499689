interface TAdjustMediaSizeToStretch {
  (
    initialImageWidth: number,
    initialImageHeight: number,
    targetScreenWidth: number,
    targetScreenHeight: number
  ): [number, number]
}

export const adjustMediaSizeToStretch: TAdjustMediaSizeToStretch = (
  initialImageWidth,
  initialImageHeight,
  targetScreenWidth,
  targetScreenHeight
) => {
  const horizontalRatio: number = initialImageWidth / targetScreenWidth
  const verticalRatio: number = initialImageHeight / targetScreenHeight

  if (horizontalRatio < verticalRatio) {
    const imageWidth: number = targetScreenWidth
    const imageHeight: number = (initialImageHeight / initialImageWidth) * imageWidth

    return [imageWidth, imageHeight]
  }

  const imageHeight: number = targetScreenHeight
  const imageWidth: number = (initialImageWidth / initialImageHeight) * imageHeight

  return [imageWidth, imageHeight]
}
