export const loadVideo = (src: string): Promise<{ width: number; height: number }> => {
  return new Promise((resolve, reject) => {
    const video = document.createElement('video')

    video.onerror = reject

    video.addEventListener(
      'canplaythrough',
      () => {
        const { videoHeight: height, videoWidth: width } = video

        resolve({ height, width })
      },
      false
    )

    video.preload = 'auto' // forces browser to load whole video
    video.src = src
  })
}
