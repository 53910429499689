import gql from 'graphql-tag'

export const MONITORING_POST_COMMAND_TO_CONTROLLER = gql`
  mutation MonitoringPostCommandToController ($playerId: UUID!, $command: CommandEnums!, $portId: ID, $requestId: String) {
    createNewControllerCommand(playerId: $playerId, command: $command, portId: $portId, requestId: $requestId) {
      code
      success
      message
    }
  }
`
