export const updateFields = <T extends object, K extends keyof T, V>(
  obj: T,
  callback: (val: T[K]) => V,
  fields: K[]
): Omit<T, K> & { [P in K]: V } => ({
  ...obj,
  ...fields.reduce((acc, cur) => {
    if (obj[cur]) {
      acc[cur] = callback(obj[cur])
    }
    return acc
  }, {} as { [P in K]: V })
})
