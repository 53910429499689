import React, { FC } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';

const useStyles = makeStyles((theme) => ({
  container: (props: {aspectRatio?: number}) => ({
    display: 'flex',
    width: (props.aspectRatio >= 1) ? '100px' : '50px',
    height: (props.aspectRatio > 1) ? '50px' : '100px',
    alignItems: 'flex-start',
  }),
  columnContainer: (props: {aspectRatio?: number}) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
  }),
  left70: (props: {aspectRatio?: number}) => ({
    display: 'flex',
    alignItems: 'flex-start',
    width: (props.aspectRatio >= 1) ? `${100 * (2/3)}px` : `${50 * (2/3)}px`,
    alignSelf: 'stretch',
    border: '1px solid var(--Sytetm-Divider, currentColor)',
    borderRight: 'none',
  }),
  upperRight15: (props: {aspectRatio?: number}) => ({
    display: 'flex',
    alignItems: 'flex-start',
    width: (props.aspectRatio >= 1) ? `${100 * (1/3)}px` : `${50 * (1/3)}px`,
    height: (props.aspectRatio > 1) ? `${50 * (1/2)}px` : `${100 * (1/2)}px`,
    alignSelf: 'stretch',
    border: '1px solid var(--Sytetm-Divider, currentColor)',
    borderBottom: 'none',
  }),
  lowerRight15: (props: {aspectRatio?: number}) => ({
    display: 'flex',
    alignItems: 'flex-start',
    height: (props.aspectRatio > 1) ? `${50 * (1/2)}px` : `${100 * (1/2)}px`,
    alignSelf: 'stretch',
    border: '1px solid var(--Sytetm-Divider, currentColor)',
  }),
  left70Selected: (props: {aspectRatio?: number}) => ({
    border: '2px solid var(--Sytetm-Divider, #1d93f3)',
    borderRight: 'none',
  }),
  upperRight15Selected: (props: {aspectRatio?: number}) => ({
    border: '2px solid var(--Sytetm-Divider, #1d93f3)',
    borderBottom: 'none',
  }),
  lowerRight15Selected: (props: {aspectRatio?: number}) => ({
    border: '2px solid var(--Sytetm-Divider, #1d93f3)',
  }),
}));

export const Layout70To15To15Icon: FC<{isSelected: boolean; aspectRatio?: number}> = ({
  isSelected,
  aspectRatio,
}) => {
  const {
    container,
    columnContainer,
    left70,
    upperRight15,
    lowerRight15,
    left70Selected,
    upperRight15Selected,
    lowerRight15Selected,
  } = useStyles({ aspectRatio });

  const left70ClassName = classNames(left70, { [left70Selected]: isSelected });
  const upperRight15ClassName = classNames(upperRight15, { [upperRight15Selected]: isSelected });
  const lowerRight15ClassName = classNames(lowerRight15, { [lowerRight15Selected]: isSelected });

  return (
    <div className={container}>
        <div className={left70ClassName} />
        <div className={columnContainer}>
            <div className={upperRight15ClassName} />
            <div className={lowerRight15ClassName} />
        </div>
    </div>
  );
};
