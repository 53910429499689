import React, { FC } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';

const useStyles = makeStyles((theme) => ({
  container: (props: {aspectRatio?: number}) => ({
    display: 'flex',
    width: (props.aspectRatio >= 1) ? '100px' : '50px',
    height: (props.aspectRatio > 1) ? '50px' : '100px',
    alignItems: 'flex-start',
  }),
  left33: (props: {aspectRatio?: number}) => ({
    display: 'flex',
    alignItems: 'flex-start',
    width: (props.aspectRatio >= 1) ? `${100 * (1/3)}px` : `${50 * (1/3)}px`,
    alignSelf: 'stretch',
    border: '1px solid var(--Sytetm-Divider, currentColor)',
    borderRight: 'none',
  }),
  right66: (props: {aspectRatio?: number}) => ({
    display: 'flex',
    alignItems: 'flex-start',
    flex: '1 0 0',
    alignSelf: 'stretch',
    border: '1px solid var(--Sytetm-Divider, currentColor)',
  }),
  left33Selected: (props: {aspectRatio?: number}) => ({
    border: '2px solid var(--Sytetm-Divider, #1d93f3)',
    borderRight: 'none',
  }),
  right66Selected: (props: {aspectRatio?: number}) => ({
    border: '2px solid var(--Sytetm-Divider, #1d93f3)',
  }),
}));

export const Layout33To66Icon: FC<{isSelected: boolean; aspectRatio?: number}> = ({
  isSelected,
  aspectRatio,
}) => {
  const {
    container,
    left33,
    right66,
    left33Selected,
    right66Selected,
  } = useStyles({ aspectRatio });

  const left33ClassName = classNames(left33, { [left33Selected]: isSelected });
  const right66ClassName = classNames(right66, { [right66Selected]: isSelected });

  return (
    <div className={container}>
        <div className={left33ClassName} />
        <div className={right66ClassName} />
    </div>
  );
};
