import React, { FC } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';

const useStyles = makeStyles((theme) => ({
  container: (props: {aspectRatio?: number}) => ({
    display: 'flex',
    width: (props.aspectRatio >= 1) ? '100px' : '50px',
    height: (props.aspectRatio > 1) ? '50px' : '100px',
    alignItems: 'flex-start',
  }),
  letf66: (props: {aspectRatio?: number}) => ({
    display: 'flex',
    alignItems: 'flex-start',
    width: (props.aspectRatio >= 1) ? `${100 * (2/3)}px` : `${50 * (2/3)}px`,
    alignSelf: 'stretch',
    border: '1px solid var(--Sytetm-Divider, currentColor)',
    borderRight: 'none',
  }),
  right33: (props: {aspectRatio?: number}) => ({
    display: 'flex',
    alignItems: 'flex-start',
    flex: '1 0 0',
    alignSelf: 'stretch',
    border: '1px solid var(--Sytetm-Divider, currentColor)',
  }),
  letf66Selected: (props: {aspectRatio?: number}) => ({
    border: '2px solid var(--Sytetm-Divider, #1d93f3)',
    borderRight: 'none',
  }),
  right33Selected: (props: {aspectRatio?: number}) => ({
    border: '2px solid var(--Sytetm-Divider, #1d93f3)',
  }),
}));

export const Layout66To33Icon: FC<{isSelected: boolean; aspectRatio?: number}> = ({
  isSelected,
  aspectRatio,
}) => {
  const {
    container,
    letf66,
    right33,
    letf66Selected,
    right33Selected,
  } = useStyles({ aspectRatio });

  const letf66ClassName = classNames(letf66, { [letf66Selected]: isSelected });
  const right33ClassName = classNames(right33, { [right33Selected]: isSelected });

  return (
    <div className={container}>
        <div className={letf66ClassName} />
        <div className={right33ClassName} />
    </div>
  );
};
