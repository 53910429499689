import React, { FC } from 'react'
import styled from 'styled-components'
import { Typography } from '@material-ui/core'
import { GenericAppBar } from '../molecules/generic-app-bar'

const Root = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  height: 100%;
  width: 100%;
`

const TitleWrapper = styled.div`
  margin: 40px;
`

export const PageUnderConstruction: FC<{ pageTitle: string }> = ({
  pageTitle
}) => {
  return (
    <Root>
      <GenericAppBar pageTitle={pageTitle} />
      <TitleWrapper>
        <Typography variant='h5'>
          Work in progress...
        </Typography>
      </TitleWrapper>
    </Root>
  )
}
