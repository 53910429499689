import React, { FC } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';

const useStyles = makeStyles((theme) => ({
  container: (props: {aspectRatio?: number}) => ({
    display: 'flex',
    width: (props.aspectRatio >= 1) ? '100px' : '50px',
    height: (props.aspectRatio > 1) ? '50px' : '100px',
    flexDirection: 'column',
    alignItems: 'flex-start',
  }),
  lower20: (props: {aspectRatio?: number}) => ({
    height: (props.aspectRatio > 1) ? '10px' : '20px',
    flexShrink: 0,
    alignSelf: 'stretch',
    border: '1px solid var(--Sytetm-Divider, currentColor)',
  }),
  upper80: (props: {aspectRatio?: number}) => ({
    display: 'flex',
    alignItems: 'flex-start',
    flex: '1 0 0',
    alignSelf: 'stretch',
    border: '1px solid var(--Sytetm-Divider, currentColor)',
    borderBottom: 'none',
  }),
  lower20Selected: (props: {aspectRatio?: number}) => ({
    border: '2px solid var(--Sytetm-Divider, #1d93f3)',
  }),
  upper80Selected: (props: {aspectRatio?: number}) => ({
    border: '2px solid var(--Sytetm-Divider, #1d93f3)',
    borderBottom: 'none',
  }),
}));

export const Layout80To20Icon: FC<{isSelected: boolean; aspectRatio?: number}> = ({
  isSelected,
  aspectRatio,
}) => {
  const {
    container,
    lower20,
    upper80,
    lower20Selected,
    upper80Selected,
  } = useStyles({ aspectRatio });

  const upper80ClassName = classNames(upper80, { [upper80Selected]: isSelected });
  const lower20ClassName = classNames(lower20, { [lower20Selected]: isSelected });

  return (
    <div className={container}>
      <div className={upper80ClassName} />
      <div className={lower20ClassName} />
    </div>
  );
};
