import gql from 'graphql-tag'
import * as Fragments from './fragments'
import {
  BoGetUserWithAdstashQuery,
  BoGetUserWithAdstashQueryVariables
} from '../../types/BoGetUserWithAdstashQuery'
import {
  BoGetUsersQuery,
  BoGetUsersQueryVariables
} from '../../types/BoGetUsersQuery'
import {
  BoLoadInactiveDisplaysQuery,
  BoLoadInactiveDisplaysQueryVariables
} from '../../types/BoLoadInactiveDisplaysQuery'

interface UserFilters {
  search: String
}

export const BO_GET_USERS_Q = gql<
  BoGetUsersQuery,
  BoGetUsersQueryVariables
  >`
  query BoGetUsersQuery ($page: Int, $limit: Int, $sort: String, $domain: String, $filters: UserFilters) {
    users(page: $page, limit: $limit, sort: $sort, domain: $domain, filters: $filters) {
      count
      page
      values {
        ...BoUsersFragment
      }
    }
  }
  ${Fragments.BO_USERS_F}
`

export const BO_GET_USER_DISPLAYS_INFO = gql`
  query BoGetUserDisplaysInfo ( $page: Int, $limit: Int, $accountId: UUID!, $userId: UUID!) {
    user(userId: $userId) {
      firstName
      lastName
      email
    }
    displaysByAccountId (page: $page, limit: $limit, accountId: $accountId) {
      id
      playerVersion
      pixelsH
      pixelsW
      latitude
      longitude
      created
      name
      timezone {
        name
        gmt
      }
    }
  }
`

export const BO_GET_USER_WITH_ADSTASH_Q = gql<
  BoGetUserWithAdstashQuery,
  BoGetUserWithAdstashQueryVariables
  >`
  query BoGetUserWithAdstashQuery ($userId: UUID!, $accountId: UUID!, $limit: Int, $page: Int, $sort: String) {
    user(userId: $userId) {
      ...BoUserWithFeaturesInfoFragment
    }

    displaysAdstashByAccount(accountId: $accountId, limit: $limit, page: $page, sort: $sort) {
      count
      page
      values {
        ...BoBackOfficeDisplayAdstashInfo
      }
    }

    displayAdstashStats(accountId: $accountId) {
      approvedCount
    }
  }
  ${Fragments.BO_USER_WITH_FEATURES_INFO_F}
  ${Fragments.BO_BACK_OFFICE_DISPLAY_ADSTASH_INFO_F}
`

export const BO_LOAD_INACTIVE_DISPLAYS_Q = gql<
  BoLoadInactiveDisplaysQuery,
  BoLoadInactiveDisplaysQueryVariables
  >`
  query BoLoadInactiveDisplaysQuery ($sort: String) {
    inactiveDisplaysAdstash(sort: $sort) {
      count
      page
      values {
        ...BoBackOfficeDisplayAdstashInfo
      }
    }
  }
  ${Fragments.BO_BACK_OFFICE_DISPLAY_ADSTASH_INFO_F}
`

export const USER_FEATURES_ADMIN = gql`
  query GetUserFeatures ($userId: UUID!) {
    userFeatures(userId: $userId) {
      id
      accountId
      status
      name
      updated
    }
  }
`
