import React, { FC } from 'react'
import { useQuery } from 'react-apollo'
import { Redirect, Route, Switch } from 'react-router-dom'
import { PrivateRoute } from 'src/ui'
import loadable from '@loadable/component'
import { useUserInfoWithRefetch } from 'src/services'
import { matchPath, useLocation } from 'react-router'
import { SC_GET_WORKSPACES } from '../apollo/api'
import { isHideFeature, isWhiteLabelLogo } from '../helpers/white-label-helper'
import { WhiteLabelFeaturesEnum } from '../helpers/white-label.enum'
import {
  getCurrentWorkspaceFromLocalStorage,
  setCurrentWorkspaceToLocalStorage
} from 'src/helpers/local-storage'

import { CustomerDashboardView } from './customer-dashboard'
import { RemoteBillingView } from './remote-billing'

import {
  ACCOUNT_PAGE_URL,
  BACK_OFFICE_PAGE_URL,
  BILLING_PAGE_URL,
  CAR_TRACKER_PAGE_URL,
  CONDITIONAL_CONTENT_PAGE_URL,
  CUSTOMER_DASHBOARD_URL,
  DEVICE_MANAGEMENT_PAGE_URL,
  DISPLAY_LIST_PAGE_URL,
  DISPLAY_SCHEDULE_URL,
  DOWNLOAD_PLAYER_PAGE_URL,
  HELP_PAGE_URL,
  INVALID_WORKSPACE_URL,
  PEOPLE_COUNTER_PAGE_URL,
  PLAYLIST_PAGE_URL,
  PROOF_OF_PERFORMANCE_PAGE_URL,
  MARKETING_PAGE_URL,
  MY_MEDIA_PAGE_URL,
  WORKSPACE_MEMBER_PAGE_URL,
  WORKSPACE_URL_PATTERN,
  ORDER_TRACKING_PAGE_URL
} from '../constants/urlConstants';

import urlHelper from 'src/helpers/urlHelper';

const PlaylistSlidePage = loadable(async () => {
  const { PlaylistSlidePage } = await import('./playlist-slide')
  return () => <PlaylistSlidePage />
})

const UserProfilePage = loadable(async () => {
  const { UserProfilePage } = await import('./profile')
  return () => <UserProfilePage />
})

const BillingPage = loadable(async () => {
  const { BillingPage } = await import('./billing')
  return () => <BillingPage />
})

const OrderTrackingPage = loadable(async () => {
  const { OrderTrackingPage } = await import('./order-tracking')
  return () => <OrderTrackingPage />
})

const WorkspaceMemberPage = loadable(async () => {
  const { WorkspaceMemberPage } = await import('./workspace-member')
  return () => <WorkspaceMemberPage />
})

const PlaylistsView = loadable(async () => {
  const { PlaylistsView } = await import('./playlists')
  return () => <PlaylistsView />
})

const ScheduleView = loadable(async () => {
  const { ScheduleView } = await import('./schedule')
  return () => <ScheduleView />
})

const PoPView = loadable(async () => {
  const { PoPView } = await import('./pop')
  return () => <PoPView />
})

const MyMediaPage = loadable(async () => {
  const { MyMediaPage } = await import('./mymedia')
  return () => <MyMediaPage />
})

const ConditionalContentView = loadable(async () => {
  const { ConditionalContentView } = await import('./conditional-content')
  return () => <ConditionalContentView />
})

// const ReportsView = loadable(async () => {
//   const { ReportsView } = await import('./reports')
//   return () => <ReportsView />
// })

const BackOfficeFeature = loadable(async () => {
  const { BackOfficeFeature } = await import('./back-office')
  return () => <BackOfficeFeature />
})

const AuthPageRoutes = loadable(async () => {
  const { AuthPageRoutes } = await import('./auth')
  return () => <AuthPageRoutes />
})

const TermsPage = loadable(async () => {
  const { TermsPage, SunshineTermsPage } = await import('./terms')
  return () => (isWhiteLabelLogo() === null ? <TermsPage /> : <SunshineTermsPage />)
})

const DisplaysView = loadable(async () => {
  const { DisplaysView } = await import('./displays')
  return () => <DisplaysView />
})

// const MaintenanceView = loadable(async () => {
//   const { MaintenanceView } = await import('./maintenance')
//   return () => <MaintenanceView />
// })

const PeopleCounterView = loadable(async () => {
  const { PeopleCounterView } = await import('./people-counter')
  return () => <PeopleCounterView />
})

const CarTrackerView = loadable(async () => {
  const { CarTrackerView } = await import('./car-tracker')
  return () => <CarTrackerView />
})

const DeviceManagementView = loadable(async () => {
  const { DeviceManagementView } = await import('./device-management')
  return () => <DeviceManagementView />
})

const MarketingView = loadable(async () => {
  const { MarketingView } = await import('./marketing')
  return () => <MarketingView />
})

const HelpView = loadable(async () => {
  const { HelpView } = await import('./help')
  return () => <HelpView />
})

const PlayerApplication = loadable(async () => {
  const { PlayerApplication } = await import('./downloads')
  return () => <PlayerApplication />
})

const LobbyPage = loadable(async () => {
  const { LobbyPage } = await import('./lobby')
  return () => <LobbyPage />
})

const InvalidWorkspacePage = loadable(async () => {
  const { InvalidWorkspacePage } = await import('./invalid-workspace')
  return () => <InvalidWorkspacePage />
})

export const Routes: FC = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const { data, loading } = useUserInfoWithRefetch();

  const workspacePathMatch = matchPath(location.pathname, {
    path: WORKSPACE_URL_PATTERN,
  });
  const workspaceId = (workspacePathMatch?.params as { workspaceId?: string })?.workspaceId;

  const { loading: workspacesLoading, data: workspacesData } = useQuery(SC_GET_WORKSPACES, {
    fetchPolicy: 'cache-and-network',
    skip: !data?.me
  })

  let currentWorkspace = null
  let storedWorkspace = getCurrentWorkspaceFromLocalStorage()
  let specificWorkspace;
  let isInvalidWorkspace = false;

  if (storedWorkspace && workspacesData?.workspaces) {
    const found = workspacesData.workspaces.find(w => w.id === storedWorkspace.id)

    if (!found) {
      storedWorkspace = null
    }
  }
  
  if (workspacesData?.workspaces && workspaceId) {
    specificWorkspace = workspacesData?.workspaces.find((workspace) => (workspace?.id === workspaceId));

    if (!specificWorkspace) {
      isInvalidWorkspace = true;
    }
  }

  currentWorkspace = specificWorkspace || storedWorkspace || workspacesData?.workspaces[0]

  if (currentWorkspace) {
    setCurrentWorkspaceToLocalStorage(currentWorkspace)
  }

  if (workspacesLoading || loading) return <></>

  const isHidePaywall = isHideFeature(WhiteLabelFeaturesEnum.PAYWALL)
  const isHideDownloads = isHideFeature(WhiteLabelFeaturesEnum.DOWNLOADS)
  const isHideProduct = isHideFeature(WhiteLabelFeaturesEnum.PRODUCT_PAGE)
  const isHideAnalysis = isHideFeature(WhiteLabelFeaturesEnum.ANALYTICS_PAGE)
  const isHideHelp = isHideFeature(WhiteLabelFeaturesEnum.HELP_SECTION)
  const isHideLobby = isHideFeature(WhiteLabelFeaturesEnum.LOBBY_PAGE)
  const isHideUserAdmin = isHideFeature(WhiteLabelFeaturesEnum.USER_ADMIN_PAGE)

  let logInEntryUrl = (data?.me?.role === 'admin' || data?.me?.role === 'provider') && !isHideLobby ? 
    'lobby' :
    isInvalidWorkspace ? INVALID_WORKSPACE_URL : urlHelper.getDisplayListPageUrl(currentWorkspace?.id);
  
  const redirectUrl = (location.pathname === '/sign-in') && queryParams.get('redirect');

  if (redirectUrl && currentWorkspace && data?.me?.role) {
    logInEntryUrl = redirectUrl;
  }

  return (
    <>
      <Switch>
        {currentWorkspace && data?.me?.role ? (
          <Redirect
            exact
            from={location.pathname === '/sign-in' ? '/sign-in' : '/'}
            to={logInEntryUrl}
          />
        ) : (
          <Redirect exact from="/" to="/sign-in" />
        )}
        { isInvalidWorkspace ? <Redirect to={INVALID_WORKSPACE_URL} /> : null }
        <PrivateRoute component={DisplaysView} path={DISPLAY_LIST_PAGE_URL} />
        <PrivateRoute component={UserProfilePage} path={ACCOUNT_PAGE_URL} />
        <PrivateRoute component={OrderTrackingPage} path={ORDER_TRACKING_PAGE_URL} />
        <PrivateRoute
          component={WorkspaceMemberPage}
          path={WORKSPACE_MEMBER_PAGE_URL}
        />
        <PrivateRoute component={PlaylistsView} path={PLAYLIST_PAGE_URL} />
        <PrivateRoute
          component={ScheduleView}
          path={`${DISPLAY_SCHEDULE_URL}?`}
        />
        <PrivateRoute component={PoPView} path={PROOF_OF_PERFORMANCE_PAGE_URL} />
        <PrivateRoute component={MyMediaPage} path={MY_MEDIA_PAGE_URL} />
        <PrivateRoute
          component={PlaylistSlidePage}
          path="/playlist-slide/:presentationId/:slideId?"
          withLayout={false}
        />
        <Route component={TermsPage} path="/terms" />
        <PrivateRoute
          component={InvalidWorkspacePage}
          path='/invalid-workspace'
          withLayout={false}
        />
        {!isHideUserAdmin && (
          <PrivateRoute
            component={BackOfficeFeature}
            path={BACK_OFFICE_PAGE_URL}
          />
        )}
        {!isHideLobby && <PrivateRoute component={LobbyPage} path="/lobby" />}
        {!isHideHelp && <PrivateRoute component={HelpView} path={HELP_PAGE_URL} />}
        {!isHidePaywall && (
          <PrivateRoute component={BillingPage} path={BILLING_PAGE_URL} />
        )}
        {!isHideDownloads && (
          <PrivateRoute component={PlayerApplication} path={DOWNLOAD_PLAYER_PAGE_URL} />
        )}
        {!isHideProduct && (
          <PrivateRoute component={MarketingView} path={MARKETING_PAGE_URL} />
        )}
        {!isHideAnalysis && (
          <PrivateRoute
            component={PeopleCounterView}
            path={PEOPLE_COUNTER_PAGE_URL}
          />
        )}
        {!isHideAnalysis && (
          <PrivateRoute component={CarTrackerView} path={CAR_TRACKER_PAGE_URL} />
        )}
        {!isHideAnalysis && (
          <PrivateRoute
            component={DeviceManagementView}
            path={DEVICE_MANAGEMENT_PAGE_URL}
          />
        )}
        {!isHideAnalysis && (
          <PrivateRoute
            component={ConditionalContentView}
            path={CONDITIONAL_CONTENT_PAGE_URL}
          />
        )}
        <PrivateRoute
          component={CustomerDashboardView}
          path={CUSTOMER_DASHBOARD_URL}
        />
        <AuthPageRoutes />
        <Redirect to="/" />
      </Switch>
    </>
  )
}
