import React, { Component, useCallback, ErrorInfo, useMemo } from 'react'
import { Button, Typography, Theme } from '@material-ui/core'
import { makeStyles, useTheme } from '@material-ui/styles'
import { Refresh, Home } from '@material-ui/icons'
import { handleError } from 'src/core'
import { theme as customTheme } from 'src/theme'
import brokenRobot from './broken-robot.png'
import screenHubLogo from '../../assets/logo.svg'

interface IProps {
  children?: React.ReactNode
}

interface IState {
  error: Error | null
}

export class ErrorBoundary extends Component<IProps, IState> {
  static errorMessage = 'Unknown error occurred'

  state: IState = {
    error: null
  }

  componentDidCatch (error: Error, stack: ErrorInfo) {
    this.setState({ error })

    handleError({
      error,
      stack,
      userFriendlyMessage: ErrorBoundary.errorMessage
    })
  }

  render () {
    return this.state.error
      ? <ErrorBoundaryLayout />
      : this.props.children
  }
}

const useErrorBoundaryTextStyles = makeStyles<Theme>(theme => ({
  wrapper: {
    display: 'grid',
    height: '100vh',
    alignItems: 'center',
    justifyContent: 'center',
    alignContent: 'center',
    gridTemplateRows: 'auto auto auto auto auto',
    gridRowGap: theme.spacing(2),
    backgroundColor: '#eee'
  },
  logo: {
    position: 'absolute',
    top: theme.spacing(3),
    left: theme.spacing(4)
  },
  logoLink: {
    display: 'block',
    backgroundColor: customTheme.colors.primary,
    borderRadius: 8,
    padding: theme.spacing(1)
  },
  screenHubLogo: {
    width: 150,
    height: 'auto'
  },
  imageWrapper: {
    display: 'grid',
    justifyContent: 'center'
  },
  image: {
    maxWidth: '200px'
  },
  desc: {
    fontSize: 22
  },
  buttons: {
    display: 'grid',
    gridGap: theme.spacing(1),
    gridTemplateColumns: 'auto auto',
    justifyContent: 'center'
  }
}))

const ErrorBoundaryLayout = () => {
  const theme = useTheme()
  const classes = useErrorBoundaryTextStyles(theme)

  const onReloadClick = useCallback(() => {
    window.location.reload()
  }, [])

  const onGoHomeClick = useCallback(() => {
    window.location.pathname = '/'
  }, [])

  const messages = useMemo(() => ({
    logoImgAlt: 'ScreenHub',
    robotImgAlt: 'An error occurred',
    title: 'Whoops...',
    desc: 'Something went wrong. We are already on it.',
    refresh: 'Refresh',
    goHome: 'Go home'
  }), [])

  return (
    <div className={classes.wrapper}>
      <div className={classes.logo}>
        <a
          className={classes.logoLink}
          data-title={messages.goHome}
          href='/'
        >
          <img
            alt={messages.logoImgAlt}
            className={classes.screenHubLogo}
            src={screenHubLogo}
          />
        </a>
      </div>

      <div className={classes.imageWrapper}>
        <img
          alt={messages.robotImgAlt}
          className={classes.image}
          src={brokenRobot}
        />
      </div>

      <div>
        <Typography
          align='center'
          variant='h2'
        >
          <strong>
            {messages.title}
          </strong>
        </Typography>
      </div>

      <div className={classes.desc}>
        {messages.desc}
      </div>

      <div className={classes.buttons}>
        <Button
          color='secondary'
          onClick={onReloadClick}
          startIcon={<Refresh />}
          variant='contained'
        >
          {messages.refresh}
        </Button>

        <Button
          color='primary'
          onClick={onGoHomeClick}
          startIcon={<Home />}
          variant='contained'
        >
          {messages.goHome}
        </Button>
      </div>
    </div>
  )
}
