import React, { FC } from 'react';
import { SvgIcon } from '@material-ui/core'
import { SvgIconProps } from '@material-ui/core/SvgIcon';

export const LayoutIcon: FC<SvgIconProps> = (props) => (
  <SvgIcon
    {...props}
    viewBox='0 0 24 24'
  >
    <path d="M4 5V18H21V5H4ZM6 16V7H9V16H6ZM11 16V12.5H14V16H11ZM19 16H16V12.5H19V16ZM11 10.5V7H19V10.5H11Z" />
  </SvgIcon>
);
