import gql from 'graphql-tag'

export const GET_CONDITIONAL_CONTENTS_Q = gql`
  query ScGetConditionalContents($workspaceId: String!) {
    conditionalContents(queryConditionalContentsInput: { workspaceId: $workspaceId }) {
      items {
        id
        name
        isActive
        workspaceId
        displayIds
        startDate
        endDate
        createdAt
      }
      pagination {
        totalCount
        offset
        limit
      }
    }
  }
`

export const GET_CONDITIONAL_CONTENT_Q = gql`
  query ScGetConditionalContent($id: String!) {
    conditionalContent(id: $id) {
      id
      name
      isActive
      workspaceId
      displayIds
      startDate
      endDate
      isEveryDay
      daysOfTheWeek
      createdAt
      presentationId
      conditions {
        key
        value {
          vehicleBrand
          vehicleType
        }
      }
    }
  }
`

export const GET_CONDITIONAL_CONTENTS_BY_DISPLAY_ID_Q = gql`
  query getConditionalContentsByDisplayId($display: String!) {
    getConditionalContentsByDisplayId(displayId: $display) {
      name
    }
  }
`
