/* eslint-disable no-return-assign */
import React from 'react'
import { makeStyles, useTheme } from '@material-ui/styles'
import Grid from '@material-ui/core/Grid'
import DialogContentText from '@material-ui/core/DialogContentText'

const BACKSPACE_KEY = 8

const useStyles = makeStyles({
  container: {
    margin: '20px 0 8px 0'
  },
  root: {
    flexGrow: 1,
    padding: '0 25px'
  },
  inputBox: {
    fontSize: 46,
    height: 68,
    width: 68,
    lineHeight: 56,
    border: '1px solid #ccd0d5',
    borderRadius: 3,
    textAlign: 'center'
  },
  loadingWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: 84
  }
})

interface TProps {
  onSetPin: (val: any) => void
  pin: string[]
  mutationError: boolean
  hasTwoFactor?: boolean
}

export const TwoFactorAuthenticationPin: React.FC<TProps> = ({ onSetPin, pin, mutationError, hasTwoFactor = true }) => {
  const theme = useTheme()

  const textInputRef = React.useRef([])

  const classes = useStyles(theme)

  const onInputChange = ({ value, tabIndex }: { value: string; tabIndex: number }) => {
    if (/^\d*$/.test(value) !== true) return

    const newPin = pin.slice()

    if (newPin[tabIndex - 1] !== value[0]) {
      newPin[tabIndex - 1] = value[0]
      onSetPin(newPin)

      if (tabIndex !== 6 && value) {
        textInputRef.current[tabIndex].focus()
      }
      return
    }

    newPin[tabIndex - 1] = value.slice(value.length - 1)
    onSetPin(newPin)
    if (tabIndex !== 6 && value) {
      textInputRef.current[tabIndex].focus()
    }
  }

  const onRemove = (keyCode: number, currentTarget: HTMLInputElement) => {
    const { tabIndex } = currentTarget

    if (keyCode === BACKSPACE_KEY) {
      const newPin = pin.slice()
      newPin[tabIndex - 1] = ''
      onSetPin(newPin)
      if (tabIndex !== 1) {
        textInputRef.current[tabIndex - 2].focus()
      }
    }
  }

  React.useEffect(() => {
    if (!mutationError) return
    if (textInputRef && textInputRef.current) {
      textInputRef.current[0].focus()
    }
  }, [mutationError])

  return (
    <div className={classes.container}>
      <DialogContentText>
        Please enter the confirmation code that you see on your authentication app
      </DialogContentText>
      <Grid
        className={classes.root}
        container
        spacing={2}
      >
        {[1, 2, 3, 4, 5, 6].map((tab, index) => (
          <Grid
            item
            key={`tab-${tab}`}
            xs={2}
          >
            <input
              autoComplete='off'
              autoFocus={tab === 1}
              className={classes.inputBox}
              disabled={!hasTwoFactor}
              onChange={(e) => onInputChange(e.currentTarget)}
              onKeyUp={(e) => onRemove(e.keyCode, e.currentTarget)}
              ref={ref => textInputRef.current[index] = ref}
              tabIndex={tab}
              type='tel'
              value={pin[index] || ''}
            />
          </Grid>
        ))}
      </Grid>
    </div>
  )
}
