import React, { FC, ChangeEventHandler } from 'react'
import styled from 'styled-components'

export interface TProps {
  value?: string
  placeholder?: string
  onChange: ChangeEventHandler
}

const Input = styled.input<TProps>`
  display: block;
  width: 100%;
  padding: 10px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  transition: all 200ms;
  outline: none;
  color: rgba(0, 0, 0, 0.8);
  :focus {
    border: 1px solid rgba(0, 0, 0, 0.6);
  }
  ::placeholder {
    color: rgba(0, 0, 0, 0.2);
  }
`

const SearchInputComponent: FC<TProps> = (props, ref) => {
  return (
    <Input
      type='search'
      ref={ref}
      {...props}
    />
  )
}

export const SearchInput = React.forwardRef(SearchInputComponent);