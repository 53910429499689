import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const LEFT_PAGE = 'LEFT'
const RIGHT_PAGE = 'RIGHT'

/**
 * Helper method for creating a range of numbers
 * range(1, 5) => [1, 2, 3, 4, 5]
 */
const range = (from, to, step = 1) => {
  let i = from
  const range = []

  while (i <= to) {
    range.push(i)
    i += step
  }

  return range
}

class Pagination extends Component {
  constructor(props) {
    super(props)
    this.state = {
      currentPage: 1
    }
  }

  gotoPage = page => {
    const { onPageChanged = f => f } = this.props

    console.log('gotoPage:page', page)
    console.log('gotoPage:this.totalPages', this.totalPages)

    const currentPage = Math.max(0, Math.min(page, this.totalPages))

    this.setState(
      {
        currentPage
      },
      () =>
        onPageChanged({
          currentPage
        })
    )
  }

  get totalPages() {
    return Math.ceil(this.props.totalRecords / this.props.pageLimit)
  }
  handleClick = page => evt => {
    evt.preventDefault()
    this.gotoPage(page)
  }

  handleMoveLeft = evt => {
    evt.preventDefault()
    if (this.state.currentPage === 1) return
    this.gotoPage(this.state.currentPage - 1)
  }

  handleMoveRight = evt => {
    evt.preventDefault()
    this.gotoPage(this.state.currentPage + 1)
  }

  /**
   * Let's say we have 10 pages and we set pageNeighbours to 2
   * Given that the current page is 6
   * The pagination control will look like the following:
   *
   * (1) < {4 5} [6] {7 8} > (10)
   *
   * (x) => terminal pages: first and last page(always visible)
   * [x] => represents current page
   * {...x} => represents page neighbours
   */
  fetchPageNumbers = () => {
    const currentPage = this.state.currentPage
    const pageNeighbours = this.props.pageNeighbours
    const totalPages = this.totalPages

    /**
     * totalNumbers: the total page numbers to show on the control
     * totalBlocks: totalNumbers + 2 to cover for the left(<) and right(>) controls
     */
    const totalNumbers = this.props.pageNeighbours * 2 + 3
    const totalBlocks = totalNumbers + 2

    if (totalPages > totalBlocks) {
      const startPage = Math.max(2, currentPage - pageNeighbours)
      const endPage = Math.min(totalPages - 1, currentPage + pageNeighbours)

      let pages = range(startPage, endPage)

      /**
       * hasLeftSpill: has hidden pages to the left
       * hasRightSpill: has hidden pages to the right
       * spillOffset: number of hidden pages either to the left or to the right
       */
      const hasLeftSpill = startPage > 2
      const hasRightSpill = totalPages - endPage > 1
      const spillOffset = totalNumbers - (pages.length + 1)

      switch (true) {
        // handle: (1) < {5 6} [7] {8 9} (10)
        case hasLeftSpill && !hasRightSpill: {
          const extraPages = range(startPage - spillOffset, startPage - 1)
          pages = [LEFT_PAGE, ...extraPages, ...pages]
          break
        }

        // handle: (1) {2 3} [4] {5 6} > (10)
        case !hasLeftSpill && hasRightSpill: {
          const extraPages = range(endPage + 1, endPage + spillOffset)
          pages = [...pages, ...extraPages, RIGHT_PAGE]
          break
        }

        // handle: (1) < {4 5} [6] {7 8} > (10)
        case hasLeftSpill && hasRightSpill:
        default: {
          pages = [LEFT_PAGE, ...pages, RIGHT_PAGE]
          break
        }
      }

      return [1, ...pages, totalPages]
    }

    return range(1, totalPages)
  }

  render() {
    if (!this.props.totalRecords || this.totalPages === 1) return null

    const { currentPage } = this.state
    const pages = this.fetchPageNumbers()
    const isFirstPage = currentPage === 1
    const isLastPage = currentPage === pages.length - 1

    return (
      <>
        <CustomPag>
          <ul className="pagination">
            <li className="page-item">
              <a
                aria-label="Previous"
                className={`page-link ${isFirstPage ? 'disable' : ''}`}
                href="#"
                onClick={this.handleMoveLeft}
              >
                <span aria-hidden="true">&lt;</span>
              </a>
            </li>
            {pages.map((page, index) => {
              if (page === RIGHT_PAGE || page === LEFT_PAGE) {
                return (
                  <li className="page-item">
                    <span>...</span>
                  </li>
                )
              }
              return (
                <li className={`page-item${currentPage === page ? ' active' : ''}`} key={index}>
                  <a className="page-link" href="#" onClick={this.handleClick(page)}>
                    {page}
                    <span />
                  </a>
                </li>
              )
            })}
            <li className="page-item">
              <a
                aria-label="Next"
                className="page-link disabled"
                href="#"
                onClick={this.handleMoveRight}
              >
                <span aria-hidden="true">&gt;</span>
              </a>
            </li>
          </ul>
        </CustomPag>
      </>
    )
  }
}

const CustomPag = styled.nav`
  ul.pagination li.page-item a.page-link > span {
    display: flex;
    align-items: center;
    justify-content: center
  }
  ul.pagination li.page-item span {
    display: flex;
    align-items: center;
    justify-content: center
  }
  ul.pagination {
    display: flex;
    margin-top: 0;
    margin-bottom: 0;
    list-style-type: none;
    rgba(0, 0, 0, 0.87);
    padding: 0;
  };
  ul.pagination li.page-item.active a.page-link {
    color: #fff !important;
  };
  ul.pagination li.page-item.active > a.page-link {
    background-color: #2196f3 !important;
  };
  ul.pagination a.page-link {
    position: relative;
    padding: 0.5rem 0.8rem;
    min-width: 32px;
    height: 32px;
    border-radius: 16px;
    line-height: 1.43;
    text-align: center;
    box-shadow: none !important;
    color: rgba(0, 0, 0, 1);
    font-size: 0.875rem;
    font-weight: 400;
    text-decoration: none;
    display: flex;
    justify-content: center;
    align-items: center;
  };

  ul.pagination li.page-item:not(.active) > a.page-link:hover{
    background-color: #1976d21f !important;
  }
  ul.pagination a.page-link span {
    z-index: -100;
    overflow: hidden;
    position: absolute;
    border-radius: inherit;
    pointer-events: none;
    width: 32px;
    height: 32px;
}
  }
`

Pagination.propTypes = {
  totalRecords: PropTypes.number.isRequired,
  pageLimit: PropTypes.number,
  pageNeighbours: PropTypes.number,
  onPageChanged: PropTypes.func
}

export { Pagination }
