import React, { FC } from 'react'
import {
  Button,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions
} from '@material-ui/core'
import { DisplayTypeEnum } from 'src/features/displays/enum/display.enum'

interface TProps {
  name: string
  item: string | DisplayTypeEnum
  onDelete(itemId: string, currentPage?: number): void
  isOpen: boolean
  handleClose(): void
  itemId: string
  currentPage?: number
  body2?: string
}

export const DeleteModal: FC<TProps> = ({
  item,
  name,
  onDelete,
  isOpen,
  handleClose,
  itemId,
  currentPage,
  body2
}) => {
  return (
    <Dialog open={isOpen}>
      <DialogTitle>{name}</DialogTitle>

      <DialogContent>
      
        {/* {Object.values(DisplayTypeEnum).includes(item) && (
          <>
            <Typography>Are you sure you want to delete this {item}?</Typography>

            <Typography variant="body2">
              {body2 || `You will not be able to recover this ${item}`}
            </Typography>
          </>
        )} */}

        {item == DisplayTypeEnum.display && (
          <>
            <Typography>Are you sure you want to permanently delete your display?</Typography>

            <Typography variant="body2">
              Deleting your display will disconnect your account from your display.
            </Typography>
          </>
        )}

        {item == DisplayTypeEnum.group && (
          <>
            <Typography>
              Are you sure you want to delete the display group? The displays will not be deleted.
            </Typography>
          </>
        )}

        {item == DisplayTypeEnum.groupedDisplay && (
          <>
            <Typography>Are you sure you want to remove display from this group?</Typography>
          </>
        )}
      </DialogContent>

      <DialogActions>
        <Button autoFocus onClick={handleClose}>
          Cancel
        </Button>

        <Button
          color="secondary"
          data-test="delete button"
          onClick={() => {
            handleClose()
            onDelete(itemId, currentPage)
          }}
        >
          {item === DisplayTypeEnum.groupedDisplay ? 'Remove' : 'Delete'}
        </Button>
      </DialogActions>
    </Dialog>
  )
}
