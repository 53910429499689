import React, { Component, ReactNode, SyntheticEvent } from 'react'
import PropTypes from 'prop-types'

interface TProps {
  onClickOutside(arg: SyntheticEvent<HTMLElement, MouseEvent>): void
  onDoubleClick(arg: SyntheticEvent<HTMLElement, MouseEvent>): void
  onMouseDownCapture?(arg: SyntheticEvent<HTMLElement, MouseEvent>): void
  useCapture?: boolean
  className?: string
  container?: any
  children?: ReactNode
  style?: React.CSSProperties
}

export class ClickOutside extends Component<TProps, {}> {
  static propTypes = {
    onClickOutside: PropTypes.func.isRequired,
    onDoubleClick: PropTypes.func,
    useCapture: PropTypes.bool,
    className: PropTypes.string,
    container: PropTypes.any,
    children: PropTypes.node,
    style: PropTypes.object
  }

  static defaultProps = {
    onDoubleClick: () => {},
    useCapture: true,
    container: 'div',
    children: null,
    style: {}
  }

  componentDidMount() {
    const { useCapture } = this.props

    document.addEventListener('touchend', this.handle, useCapture)
    document.addEventListener('click', this.handle, useCapture)
  }

  componentWillUnmount() {
    const { useCapture } = this.props

    document.removeEventListener('touchend', this.handle, useCapture)
    document.removeEventListener('click', this.handle, useCapture)
  }

  container: any

  isTouch = false

  handle = (e) => {
    if (e.type === 'touchend') {
      this.isTouch = true
    }
    if (e.type === 'click' && this.isTouch) {
      return
    }
    const { onClickOutside } = this.props
    const el = this.container
    if (!el.contains(e.target)) {
      onClickOutside(e)
    }
  }

  getContainer = (ref) => {
    this.container = ref
  }

  render() {
    const {
      children,
      className,
      container: Root,
      style,
      onDoubleClick,
      onMouseDownCapture
    } = this.props

    return (
      <Root
        onMouseDownCapture={onMouseDownCapture}
        className={className}
        ref={this.getContainer}
        style={style}
        onDoubleClick={onDoubleClick}
      >
        {children}
      </Root>
    )
  }
}
