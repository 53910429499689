import gql from 'graphql-tag'

export const SC_DISPLAYS_PRESENTATIONS_FOR_POP_QUERY = gql`
  query($workspaceId: UUID!) {
    workspace(id: $workspaceId) {
      id
      displays {
        page
        count
        values {
          id
          name
          pixelsH
          pixelsW
          displayGroupId
          timezone {
            jodaId
          }
          schedulingType
          latitude
          longitude
          country {
            name
          }
          city
          street
        }
      }
      displayGroups{
        count
        values {
          id
          name
          pixelsH
          pixelsW
        }
      }
      presentations {
        page
        count
        values {
          id
          name
          type
          config
        }
      }
    }
  }
`

export const SC_GET_POP_RECORDS_WITH_SNAPSHOT_Q = gql`
  query ScGetPoPRecordsWithSnapshotQuery(
    $searchBy: SearchByEnum!
    $searchById: UUID!
    $workspaceId: UUID
    $startDate: String
    $endDate: String
  ) {
    getPoPRecordsWithSnapshot(
      getPoPRecordsWithSnapshotDTO: {
        searchBy: $searchBy
        searchById: $searchById
        workspaceId: $workspaceId
        startDate: $startDate
        endDate: $endDate
      }
    ) {
      popRecords {
        id
        accountId
        displayId
        playerId
        presentationId
        slideId
        startTime
        endTime
        playedCount
        duration
      }
    }
  }
`

export const SC_GET_MERGED_POP_RECORDS_WITH_SNAPSHOT_Q = gql`
  query ScGetMergedPoPRecordsWithSnapshotQuery(
    $searchBy: SearchByEnum!
    $searchById: UUID!
    $workspaceId: UUID!
    $startDate: String
    $endDate: String
  ) {
    getMergedPoPRecordsWithSnapshot(
      getPoPRecordsWithSnapshotDTO: {
        searchBy: $searchBy
        searchById: $searchById
        workspaceId: $workspaceId
        startDate: $startDate
        endDate: $endDate
      }
    ) {
      mergedPoPRecords {
        slideId
        presentationId
        slideSnapshots
        mergedPoPRecordsWithSnapshotsAndTotalData {
          totalPlayedCount
          totalPlayedDurationInMillisecond
          popRecords {
            displayId
            startTime
            endTime
            playedCount
            playedDurationInMillisecond
          }
        }
      }
    }
  }
`
