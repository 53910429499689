import gql from 'graphql-tag'

export const GET_PRESENTATIONS_QUERY = gql`
  query GetPresentation($workspaceId: UUID!, $page: Int, $limit: Int, $sort: String, $filters: presentationFilters) {
    workspace(id: $workspaceId) {
      id
      presentations(page: $page, limit: $limit, sort: $sort, filters: $filters) {
        page
        count
        values {
          id
          name
          config
          createdAt
          updatedAt
          thumb
          defaultSlideDuration
          tags
          type
          isMediaProcessing
        }
      }
    }
  }
`

export const PL_GET_PRESENTATION_Q = gql`
  query PlGetPresentationQuery ($workspaceId: UUID!, $id: UUID!) {
    workspace(id: $workspaceId) {
      id
      presentation(id: $id) {
        id
        name
        config
        slides
        slidesOrder
        defaultSlideDuration
        tags
        type
        isMediaProcessing
      }
    }
  }
`

export const PL_GET_PRESENTATION_TAGS_Q = gql`
  query PlGetPresentationTagsQuery($workspaceId: UUID!) {
    workspace(id: $workspaceId) {
      id
      presentationsTags
    }
  }
`

export const PL_GET_DISPLAY_DIMENSIONS_Q = gql`
  query PlGetDisplayDimensions($workspaceId: UUID!) {
    workspace(id: $workspaceId) {
      id
      presentationsDimensionsUsed {
        width
        height
      }
    }
  }
`

export const PL_SEARCH_UNSPLASH_Q = gql`
  query PlSearchUnsplashQuery ($q: String) {
    unsplash(q: $q)
  }
`

export const PL_SEARCH_GIPHY_Q = gql`
  query PlSearchGiphyQuery ($q: String) {
    giphy(q: $q)
  }
`

export const PL_GET_PRESENTATIONS_DISPLAY_Q = gql`
  query PlGetPresentationsDisplay ($workspaceId: UUID!, $filters: presentationFilters) {
    workspace(id: $workspaceId) {
      id
      presentations(filters: $filters) {
        page
        count
        values {
          id
          name
          config
          updatedAt
          type
          isMediaProcessing
        }
      }
    }
  }
`

export const PL_GET_PEXELS_VIDEO_QUERY = gql`
  query GetPexelsVideo($queryKeyword: String!, $page: Int) {
    pexelsVideosMeta(pexelsMediaQueryDTO: { 
      queryKeyword: $queryKeyword,
      page: $page
    }) {
      resultCount,
      pageCount,
      videos {
        hdVideoId,
        hdVideoUrl,
        sdVideoId,
        sdVideoUrl,
      }
    }
  }
`

export const PL_GET_PEXELS_IMAGE_QUERY = gql`
  query GetPexelsImage($queryKeyword: String!, $page: Int) {
    pexelsImagesMeta(pexelsMediaQueryDTO: { 
      queryKeyword: $queryKeyword,
      page: $page
    }) {
      resultCount,
      pageCount,
      images {
        imageId,
        imageUrl,
        thumbnailUrl,
      }
    }
  }
`