
export const formatDeviceType = (deviceType: string): string => {
  switch (deviceType) {
    case 'WEB_PLAYER':
      return 'Web Player'
    case 'CIRRUS_HARDWARE':
      return 'Cirrus Display'
    case 'LEGACY_DISPLAY':
      return 'Legacy'
    case 'WINDOWS_PLAYER':
      return 'Windows player'
    default:
      return ''
  }
}
