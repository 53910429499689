import React, { FC } from 'react'
import { SvgIconProps } from '@material-ui/core/SvgIcon'
import { SvgIcon } from '@material-ui/core'

export const VideoStreamingIcon: FC<SvgIconProps> = (props) => (

  <SvgIcon {...props}>
    <svg
      fill='none'
      height='24'
      viewBox='0 0 24 24'
      width='24'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M5.95 12.5C5.95 11.645 6.645 10.95 7.5 10.95H9.5V10H7.5C6.12 10 5 11.12 5 12.5C5 13.88 6.12 15 7.5 15H9.5V14.05H7.5C6.645 14.05 5.95 13.355 5.95 12.5ZM8 13H12V12H8V13ZM12.5 10H10.5V10.95H12.5C13.355 10.95 14.05 11.645 14.05 12.5C14.05 13.355 13.355 14.05 12.5 14.05H10.5V15H12.5C13.88 15 15 13.88 15 12.5C15 11.12 13.88 10 12.5 10Z'
        fill='#DADADA'
      />
      <path
        d='M16 6.5V17.5H3.5V6.5H16ZM16.4444 5H3.11111C2.5 5 2 5.525 2 6.16667V17.8333C2 18.475 2.5 19 3.11111 19H16.4444C17.0556 19 17.5556 18.475 17.5556 17.8333V13.75L22 18.4167V5.58333L17.5556 10.25V6.16667C17.5556 5.525 17.0556 5 16.4444 5Z'
        fill='#DADADA'
      />
    </svg>
  </SvgIcon>
)
