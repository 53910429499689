import gql from 'graphql-tag'

export const SEARCH_USER_BY_EMAIL_Q = gql`
  query SearchUserByEmail($email: String!) {
    searchUserByEmail(email: $email) {
        firstName
        lastName
        email
    }
  }   
`

export const GET_PROVIDER_SUBMISSION_Q = gql`
    query GetProviderSubmission(
        $queryGetProviderSubmissionDTO: QueryGetProviderSubmissionDTO!
    ) {
        getProviderSubmission(
            queryGetProviderSubmissionDTO: $queryGetProviderSubmissionDTO
        ) {
            userId
            companyName
            phoneNumber
            status
            createdAt
            updatedAt
            user {
                id
                firstName
                lastName
                email
                role
                isVerified
                isDeleted
                updatedAt
                createdAt
            }
        }
    }
`