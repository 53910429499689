export const getVideoThumbnail = (file: File): Promise<Blob> => {
  return new Promise((resolve) => {
    const fileReader = new FileReader()

    fileReader.onload = () => {
      const blob = new Blob([fileReader.result], { type: file.type })
      const url = URL.createObjectURL(blob)
      const video = document.createElement('video')

      const snapImage = (): Promise<Blob> => {
        return new Promise((resolve) => {
          const canvas = document.createElement('canvas')
          const { videoWidth, videoHeight } = video
          const biggerSide = 600
          const ratio = videoWidth / videoHeight
          if (ratio > 1) {
            canvas.width = biggerSide
            canvas.height = biggerSide / ratio
          } else if (ratio < 1) {
            canvas.width = biggerSide * ratio
            canvas.height = biggerSide
          } else {
            canvas.width = canvas.height = biggerSide
          }

          canvas
            .getContext('2d')
            .drawImage(video, 0, 0, canvas.width, canvas.height)
          canvas.toBlob(resolve, 'image/jpeg')
        })
      }

      const timeupdate = async () => {
        const thumbBlob = await snapImage()
        if (thumbBlob.size > 10) {
          video.removeEventListener('timeupdate', timeupdate)
          video.pause()
          resolve(thumbBlob)
        }
      }

      video.addEventListener('timeupdate', timeupdate)

      video.addEventListener('loadedmetadata', () => {
        video.currentTime = Math.round(video.duration / 2)
      })

      video.preload = 'metadata'
      video.src = url

      // Load video in Safari / IE11
      video.muted = true
      // @ts-ignore
      video.playsInline = true
    }

    fileReader.readAsArrayBuffer(file)
  })
}
