import React from 'react'
import Raw from 'react-widgets/lib/DateTimePicker'
import styled from 'styled-components'
import dateFnsLocalizer from 'react-widgets-date-fns'
import 'react-widgets/dist/css/react-widgets.css'

dateFnsLocalizer()

type TPickerProps = Omit<React.ComponentProps<typeof Raw>, 'ref'>

interface IProps {
  className?: string
}

const DateTimePickerRaw = ({ className, ...props }: TPickerProps & IProps) => (
  <Raw
    {...props}
    containerClassName={className}
  />
)

export const DateTimePicker = styled(DateTimePickerRaw)`
  font-size: 12px;
`
