import gql from 'graphql-tag'

export const ACCOUNT_UPDATE_USER_INFO_MUTATION = gql`
  mutation AccountUpdateUserInfoMutation ($id: UUID!, $input: UpdateUserInfoInput!) {
    updateUserInfo(id: $id, input: $input) {
      code
      success
      message
      user {
        id
        firstName
        lastName
      }
    }
  }
`

export const ACCOUNT_UPDATE_USER_PASSWORD_MUTATION = gql`
  mutation UpdateUserPassword ($id: UUID!, $input: UpdateUserPasswordInput!) {
    updateUserPassword(id: $id, input: $input) {
      code
      success
      message
      type
    }
  }
`

export const ACCOUNT_SET_USER_PASSWORD_MUTATION = gql`
  mutation SetUserPassword ($id: UUID!, $input: SetUserPasswordInput!) {
    setUserPassword(id: $id, input: $input) {
      code
      success
      message
      type
    }
  }
`
export const ACCOUNT_CHANGE_USER_EMAIL_MUTATION = gql`
  mutation ChangeUserEmail ($id: UUID!, $input: ChangeUserEmailInput!) {
    changeUserEmail(id: $id, input: $input) {
      code
      success
      message
      type
    }
  }
`
export const ACCOUNT_TOGGLE_FEATURE = gql`
  mutation ToggleFeature ($id: UUID, $status: StatusAbEnums!) {
    toggleFeature(id: $id, status: $status) {
      code
      success
      message
      accountFeature {
        id
        accountId
        status
        name
        description
        updated
      }
    }
  }
`

export const ACCOUNT_UPDATE_MUTATION = gql`
  mutation updateAccount ($accountInput: AccountInput!) {
    updateAccount(accountInput: $accountInput) {
      code
      success
      message
      account {
        id
        paypalEmail
      }
    }
  }
`

export const ACCOUNT_UPDATE_PROVIDER_USER_ID_MUTATION = gql`
  mutation UpdateProviderUserId ($providerEmail: String) {
    updateProviderUserId(providerEmail: $providerEmail) {
      code
      success
      message
    }
  }
`