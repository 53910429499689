import React, { FC, useState } from 'react'
import { Paper, Typography, Theme, Button, Dialog } from '@material-ui/core'
import { makeStyles, createStyles, useTheme } from '@material-ui/styles'
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined'
import { useQuery } from 'react-apollo'
import { PW_ACCOUNT_DISPLAYS_SUBSCRIPTION } from 'src/apollo/api/paywall/queries'
import { PriceTable } from 'src/ui'
import { HtmlTooltip } from '../atoms/html-tooltip'
import { StripeStatusEnums } from '../../screenhub-shared/enum/stripe.enum'
import { isActiveSubscription } from '../../helpers/paywallHelper'
import { WorkspaceRoles } from 'src/constants'
import { useUserInfo } from 'src/services'
import { getCurrentWorkspaceFromLocalStorage } from 'src/helpers/local-storage'
import { history } from 'src/app'
import { isHideFeature } from '../../helpers/white-label-helper'
import { WhiteLabelFeaturesEnum } from '../../helpers/white-label.enum'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      // ...theme.typography.button,
      // backgroundColor: theme.palette.background.paper,
      // padding: theme.spacing(1),
    }
  })
)

const usePaperClasses = makeStyles<Theme>({
  root: {
    // XXX: shall combine whole color rendered with theme
    backgroundColor: '#DEEFFC',
    padding: '0 30px',
    width: '100%',
    minHeight: 32,
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
    zIndex: 5,
    justifyContent: 'center',
    whiteSpace: 'nowrap',
    boxShadow: 'none'
  }
})

export const TrialBanner: FC = () => {
  const theme = useTheme()
  const classes = useStyles()
  const paperClasses = usePaperClasses(theme)

  const {
    me: {
      account: { id: accountId }
    }
  } = useUserInfo()

  const memberRole = getCurrentWorkspaceFromLocalStorage()?.memberRole;

  const { data } = useQuery(PW_ACCOUNT_DISPLAYS_SUBSCRIPTION, {
    variables: {
      accountId
    }
  })

  const subscription = data?.getLatestSubscription
  const displays = data?.getDisplays
  const showBanner =
    subscription?.status !== StripeStatusEnums.ACTIVE && memberRole?.name === WorkspaceRoles.OWNER
  const [showPriceTable, setShowPriceTable] = useState(false)
  const handlePriceTableClose = () => {
    setShowPriceTable(false)
  }

  const handleCheckoutClick = async () => {
    if (
      subscription.status === StripeStatusEnums.INCOMPLETE ||
      subscription.status === StripeStatusEnums.INCOMPLETE_EXPIRED ||
      subscription.status === StripeStatusEnums.PAST_DUE ||
      subscription.status === StripeStatusEnums.UNPAID
    ) {
      // go to billing page
      history.push('/billing')
    } else if (subscription.status === StripeStatusEnums.TRIALING ||
               subscription.status === StripeStatusEnums.CANCELED) {
      setShowPriceTable(true)
    }
  }

  const trialEnd = subscription?.trialEnd || subscription?.currentPeriodEnd || Date.now() / 1000
  const daysToExpiry = Math.ceil((trialEnd - Date.now() / 1000) / 86400)
  const isTrialingOrActive = isActiveSubscription(subscription) && trialEnd > 0
  const expiryWarning = isTrialingOrActive && daysToExpiry > 0 && daysToExpiry <= 7
  // trial banner background depends following conditions:
  // more than 7 days to expiry
  // 7 days to expiry
  // expired,
  const bannerWording = () => {
    switch (subscription.status) {
      case StripeStatusEnums.TRIALING:
        if (!isTrialingOrActive) {
          return (
            <>
              <Typography className={classes.root}>Your subscription has expired!</Typography>
              <Button color="primary" component="div" onClick={handleCheckoutClick}>
                CLICK HERE
              </Button>
              <Typography>to checkout.&nbsp;</Typography>
            </>
          )
        } else {
          return (
            <>
              <Typography className={classes.root}>
                {daysToExpiry} days left on your free trial.
              </Typography>
              <Button color="primary" component="div" onClick={handleCheckoutClick}>
                CLICK HERE
              </Button>
              <Typography>to checkout.&nbsp;</Typography>
            </>
          )
        }
      case StripeStatusEnums.PAST_DUE:
      case StripeStatusEnums.UNPAID:
      case StripeStatusEnums.INCOMPLETE:
      case StripeStatusEnums.INCOMPLETE_EXPIRED:
      case StripeStatusEnums.CANCELED:
        return (
          <>
            <Typography className={classes.root}>Your authorization has expired.</Typography>
            <Button color="primary" component="div" onClick={handleCheckoutClick}>
              CLICK HERE
            </Button>
            <Typography>to check the details.&nbsp;</Typography>
          </>
        )
    }
  }

  const tooltipWording = () => {
    if (!isTrialingOrActive) {
      return `Your screens can no longer be connected, but none of your work is lost. Continue to checkout to reactivate your account!`
    } else {
      return `We hope you're enjoying ScreenHub! Checkout your plan before your trial ends to continue creating and publishing content.`
    }
  }

  const isHidePaywall = isHideFeature(WhiteLabelFeaturesEnum.PAYWALL)

  if (isHidePaywall) {
    return <></>
  }

  return subscription && showBanner ? (
    <Paper
      classes={paperClasses}
      elevation={1}
      square
      style={{
        backgroundColor: !isTrialingOrActive ? '#F9DEDC' : expiryWarning ? '#F8EBC8' : '#DEEFFC'
      }}
    >
      {bannerWording()}
      <HtmlTooltip
        title={
          <React.Fragment>
            <Typography style={{ fontSize: 14, fontWeight: 400 }}>{tooltipWording()}</Typography>
            <Typography style={{ display: 'flex', alignItems: 'center', justifyContent: 'right' }}>
              <Button onClick={handleCheckoutClick} style={{ color: '#FFFFFF' }}>
                UPGRADE
              </Button>
            </Typography>
          </React.Fragment>
        }
        interactive={true}
        leaveDelay={100}
        placement="top"
      >
        <InfoOutlinedIcon />
      </HtmlTooltip>
      <Dialog maxWidth="lg" open={showPriceTable} onClose={handlePriceTableClose}>
        <PriceTable subscription={subscription} displays={displays} closeDialog={handlePriceTableClose}/>
      </Dialog>
    </Paper>
  ) : (
    <></>
  )
}
