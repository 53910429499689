import gql from 'graphql-tag'
import { GroupsGroupInfo } from '../../types/GroupsGroupInfo'

export const GROUPS_GROUP_INFO_F = gql<GroupsGroupInfo>`
  fragment GroupsGroupInfo on Group {
    id
    name
    changed
    pixelsH
    pixelsW
    created
    displayType
    imageUrl
    displays {
      id
      name
      pixelsH
      pixelsW
    }
  }
`
