import DebounceLink from 'apollo-link-debounce'
import { onError } from 'apollo-link-error'
import { createUploadLink } from 'apollo-upload-client'
import { WebSocketLink } from 'apollo-link-ws'
import { getRefreshTokenLink } from 'apollo-link-refresh-token'
import { handleError } from '../core'
import { logout, signInPathWithPreviousLocationInQuery } from 'src/helpers/authHelper'
import { RetryLink } from 'apollo-link-retry'

/** Constants to use it in apollo links next */

const { REACT_APP_GRAPHQL_URL, REACT_APP_WS_ENDPOINT } = process.env

const DEFAULT_DEBOUNCE_TIMEOUT = 500
const REFRESH_TOKEN_FETCH_URL = ''
const LINK_ERROR_MESSAGE = 'Server error occurred. Please reload and try again.'

const WS_ENDPOINT = REACT_APP_GRAPHQL_URL.includes('https')
  ? REACT_APP_WS_ENDPOINT.replace(/ws/, 'wss')
  : REACT_APP_WS_ENDPOINT

/** Debounce all requests with default interval
 * @see DebounceLink
 * @see DEFAULT_DEBOUNCE_TIMEOUT
 */
export const debounceLink = new DebounceLink(DEFAULT_DEBOUNCE_TIMEOUT)

/** Handle apollo server errors
 * @see onError
 * @see handleError
 */
export const errorLink = onError(({ graphQLErrors, networkError }) => {
  console.log(networkError, graphQLErrors)

  if (graphQLErrors) {
    graphQLErrors.forEach(error => {
      const errorCode = error?.extensions?.exception?.response?.errorCode || '';
      switch (error.extensions?.code) {
        case 'UNAUTHENTICATED':
          window.location.reload()
          break
        case 'FORBIDDEN':
          handleError({
            error,
            userFriendlyMessage: 'Access denied'
          })
          return
        case 'INTERNAL_SERVER_ERROR':
          if (error?.message.includes('Authentication Failure')) {
            handleError({
              error,
              userFriendlyMessage: `Authentication Failure, Please Login again. Error Code: ${errorCode}`,
              shouldSkipToast: true,
            });

            logout(signInPathWithPreviousLocationInQuery());
          } else if (error?.message.includes('Nucleus is not ready for action')) {
            handleError({
              error,
              userFriendlyMessage: `Controller is busy. Please reload and try again later. Error Code: ${errorCode}`
            })
          }
          else if (error?.message.includes('Cannot return null for non-nullable field Workspace.id')) {
            handleError({
              error,
              userFriendlyMessage: `Workspace not found`
            })
          } else {
            handleError({
              error,
              userFriendlyMessage: `${LINK_ERROR_MESSAGE} Error Code: ${errorCode}`
            })
          }
          return;
        default:
          handleError({
            error,
            userFriendlyMessage: `${LINK_ERROR_MESSAGE} Error Code: ${errorCode}`
          })
      }

      handleError({
        error,
        userFriendlyMessage: `${LINK_ERROR_MESSAGE} Error Code: ${errorCode}`
      })
    })
  }

  if (networkError) {
    handleError({
      error: networkError,
      userFriendlyMessage: 'Network error occurred. Please reload and try again.'
    })
  }
})

/** Link for files upload
 * @see createUploadLink
 */
export const uploadLink = createUploadLink({
  uri: REACT_APP_GRAPHQL_URL,
  credentials: 'include'
})

export const wsLink = new WebSocketLink({
  uri: WS_ENDPOINT,
  options: {
    timeout: 5 * 60 * 1000,
    reconnect: true,
    connectionParams: {
      source: 'screenhub',
      // Use a fixed token first
      // TODO: ask the token from server
      authorization:
        'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpYXQiOjE2NjI0Mjk4Nzh9.CLrRa772_C5vKUrGsnyZgBWWNgomU6jbYnTbED_iar4'
    }
  }
})

/** Token refreshing link */

interface IRefreshTokenResponse {
  data: {
    accessToken: string
  }
}

/** Link for automatically token refreshing based on api response.
 * Tries to make a request to refreshing endpoint and sets new refreshing and access token to
 * httpOnly cookies.
 * @see getRefreshTokenLink
 * @see REFRESH_TOKEN_FETCH_URL
 */
export const tokenRefreshingLink = getRefreshTokenLink({
  authorizationHeaderKey: '',
  isAccessTokenValid: () => true,
  isUnauthenticatedError: graphQLError => graphQLError.extensions?.code === 'UNAUTHENTICATED',
  getRefreshToken: () => undefined,
  getAccessToken: () => undefined,
  fetchNewAccessToken: async () => {
    const { data }: IRefreshTokenResponse = await fetch(REFRESH_TOKEN_FETCH_URL).then(response =>
      response.json()
    )

    if (data.accessToken) {
      return data.accessToken
    } else {
      throw new Error("Token wasn't not found in refreshing promise")
    }
  },
  onFailedRefresh: () => {
    window.location.href = signInPathWithPreviousLocationInQuery();
  }
})

export const retryLink = new RetryLink({
  attempts: (count, operation, error) => {
    // Customize the conditions for retrying
    // You can use the 'count', 'operation', or 'error' parameters to decide if a retry should be attempted
    const cErrors = error?.result?.errors || [];
    for (const currentError of cErrors) {
      switch (currentError?.extensions?.code) {
        case 'UNAUTHENTICATED':
          return false
        case 'INTERNAL_SERVER_ERROR':
          if (currentError?.message.includes('Authentication Failure')) {
            return false
          }
      }
    }
    return count < 5
  },
  delay: (count, operation, error) => {
    // Customize the delay between retries
    return Math.min(count * 1000, 10 * 1000);
  },
});
