import React from 'react'
import { AppBar } from '@material-ui/core'
import { makeStyles, useTheme } from '@material-ui/styles'

const useAppBarClasses = makeStyles({
  root: {
    height: 64
  }
})

export const Header = () => {
  const theme = useTheme()
  const appBarClasses = useAppBarClasses(theme)

  return (
    <AppBar
      classes={appBarClasses}
      position='fixed'
    >
      Application bar
    </AppBar>
  )
}
