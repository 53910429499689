import axios from 'axios'
import { BillingCycle, PlanEnums, StripeStatusEnums } from '../screenhub-shared/enum/stripe.enum'

const { REACT_APP_API_GATEWAY_URL } = process.env

export const isActiveSubscription = (subscription) => {
  return subscription?.status === StripeStatusEnums.ACTIVE ||
         subscription?.status === StripeStatusEnums.TRIALING ||
         subscription?.status === StripeStatusEnums.INCOMPLETE;
}

// XXX: for now it's fixed pricing, 6.99 month & 20.00
export const getItemPrice = (chosenPlan: PlanEnums, billingCycle: BillingCycle) => {
  switch (chosenPlan) {
    case PlanEnums.CELLULAR_PLAN:
    case PlanEnums.PRO_PLAN:
    case PlanEnums.CIRRUS_HARDWARE_PRO_PLAN:
    case PlanEnums.CIRRUS_WINDOWS_PRO_PLAN:
      return billingCycle === BillingCycle.MONTH ? 20.00 : 240.00
    case PlanEnums.PREMIUM_PLAN:
    case PlanEnums.CIRRUS_HARDWARE_PLAN:
    case PlanEnums.CIRRUS_WINDOWS_PLAN:
      return billingCycle === BillingCycle.MONTH ? 6.99 : 83.88
    default:
      return 0
  }
}

export const totalPrice = (qty: number, chosenPlan: PlanEnums, billingCycle: BillingCycle) => {
  return (getItemPrice(chosenPlan, billingCycle) * qty).toFixed(2)
}

export const paidQuantity = (qty: number, plan: PlanEnums, cirrusStandardQty: number): number => {
  switch (plan) {
    case PlanEnums.CIRRUS_HARDWARE_PLAN:
    case PlanEnums.CIRRUS_WINDOWS_PLAN:
      return qty > cirrusStandardQty ? qty - cirrusStandardQty : 0;
    default:
      return qty;
  }
}