import React, { FunctionComponent, ReactNode, SyntheticEvent, Fragment } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import ReactModal from 'react-modal'
import { Close } from '@material-ui/icons'
import { Box, Button } from 'grommet'
import { Toggler } from 'src/core'
import { Modal as MuiModal} from '@material-ui/core';

ReactModal.setAppElement(document.getElementById('root'))

const modalInlineStyles: { overlay: React.CSSProperties, content?: React.CSSProperties } = {
  overlay: {
    zIndex: 100000,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  }
}

const CustomOverlay = styled.div`
  &.ReactModal__Overlay {
    background-color: rgba(0, 0, 0, 0) !important;
    transition: background-color 100ms ease-in-out;
  }

  &.ReactModal__Overlay--after-open {
    background-color: rgba(0, 0, 0, 0.5) !important;
  }

  &.ReactModal__Overlay--before-close {
    background-color: rgba(0, 0, 0, 0) !important;
  }


  .ReactModal__Content {
    transform: scale(0);
    transition: transform 150ms ease-in-out;
  }

  .ReactModal__Content--after-open {
    transform: scale(1);
  }

  .ReactModal__Content--before-close {
    transform: scale(0);
  }
`

const StyledButton = styled(Button)`
  position: absolute;
  right: 4px;
  top: 4px;
`

const StyledBox = styled(Box)`
  color: inherit;
  position: relative;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.2);
`

export type TOnCloseModalFunc = (arg?: SyntheticEvent) => void

export interface ModalProps {
  className?: string
  fullscreen?: boolean
  initiallyOpen?: boolean
  onSetPreview?: (isPreview: boolean) => void
  controller: (arg: { onToggleModal: (arg: boolean | SyntheticEvent) => void }) => void
  children: (arg: { onCloseModal: TOnCloseModalFunc }) => ReactNode
}

export type TRenderFunc = (arg: { onCloseModal: TOnCloseModalFunc }) => void

export const Modal: FunctionComponent<ModalProps> = ({
  className,
  controller,
  initiallyOpen,
  children,
  fullscreen,
  onSetPreview = null
}) => (
  <Toggler
    defaultToggled={initiallyOpen}
    onSetPreview={onSetPreview}
  >
    {({ toggled, onToggle }) => (
      <>
        {controller({ onToggleModal: onToggle })}
        <MuiModal
          classes={className}
          open={toggled}
          onClose={() => onToggle(false)}
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }}
        >
          <StyledBox
            background='white'
            color='inherit'
            direction='column'
            fill={fullscreen}
            pad={
              !fullscreen
                ? {
                  top: 'large',
                  bottom: 'medium',
                  left: 'medium',
                  right: 'medium'
                }
                : null
            }
            round='xsmall'
          >
            <StyledButton
              icon={<Close fontSize='large' />}
              onClick={() => onToggle(false)}
            />

            {children({ onCloseModal: () => onToggle(false) })}
          </StyledBox>
        </MuiModal>
      </>
    )}
  </Toggler>
)

Modal.propTypes = {
  className: PropTypes.string,
  initiallyOpen: PropTypes.bool,
  children: PropTypes.func.isRequired,
  controller: PropTypes.func.isRequired,
  fullscreen: PropTypes.bool
}

Modal.defaultProps = {
  initiallyOpen: false,
  fullscreen: false
}
