import React, { FC, useEffect, useState } from 'react'
import { styled } from '@material-ui/styles'
import AddIcon from '@material-ui/icons/Add'
import RemoveIcon from '@material-ui/icons/Remove'
import { Box, Button, Typography } from '@material-ui/core'
import ReportProblemOutlinedIcon from '@material-ui/icons/ReportProblemOutlined'
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';

interface TProps {
  displayCount?: number
  quantity: number
  cirrusStandardQty?: number
  quantityOnChangedCallback: (newQuantity: number) => void
}

const QtyControlBtn = styled(Button)(({ theme }) => ({
  minWidth: '48px',
  fontSize: '14px'
}))

export const DisplayQuantityInput: FC<TProps> = ({
  quantity,
  displayCount = 1,
  cirrusStandardQty = 0,
  quantityOnChangedCallback
}) => {
  const [newQty, setNewQty] = useState(quantity)
  const [minQtyWarning, setMinQtyWarning] = useState(false)
  const [decreaseDisplaysHint, setDecreaseDisplaysHint] = useState(false)
  const [cirrusStandardQtyHint, setCirrusStandardQtyHint] = useState(false)

  useEffect(() => {
    quantityOnChangedCallback(newQty)
  }, [newQty])

  return (
    <Box style={{ paddingTop: 10, paddingBottom: 10, backgroundColor: '#FFFFFF' }}>
      <Box>
        <Box
          style={{
            display: 'flex',
            alignItems: 'center'
          }}
        >
          <Box
            style={{
              border: '1px solid gray',
              marginRight: '14px',
              borderRadius: '5px'
            }}
          >
            <QtyControlBtn
              onClick={() => {
                if (newQty - 1 < cirrusStandardQty) {
                  setCirrusStandardQtyHint(true)
                }
                if (newQty - 1 < displayCount) {
                  setDecreaseDisplaysHint(false)
                  setMinQtyWarning(true)
                } else {
                  setDecreaseDisplaysHint(true)
                }
                const newQuantity =
                  newQty > displayCount && newQty > 1
                    ? newQty - 1
                    : newQty
                setNewQty(newQuantity)
              }}
            >
              <RemoveIcon />
            </QtyControlBtn>
            <QtyControlBtn>{newQty}</QtyControlBtn>
            <QtyControlBtn
              onClick={() => {
                setMinQtyWarning(!(newQty + 1 >= displayCount))
                setDecreaseDisplaysHint(false)
                setCirrusStandardQtyHint(false)
                const newQuantity = newQty + 1
                setNewQty(newQuantity)
              }}
            >
              <AddIcon />
            </QtyControlBtn>
          </Box>
          <Box>display(s)</Box>
        </Box>
      </Box>
      <Box>
        {cirrusStandardQtyHint && (
          <Box
            marginTop={1}
            padding={1}
            display="flex"
            flexDirection="row"
            style={{
              backgroundColor: '#EAF9F4'
            }}
          >
            <InfoOutlinedIcon style={{ color: '#3D7E5E' }} />
            <Typography variant="body2">
              You currently have FREE {cirrusStandardQty} Standard display(s) in your account.
            </Typography>
          </Box>
        )}
        {minQtyWarning && (
          <Box
            marginTop={1}
            padding={1}
            display="flex"
            flexDirection="row"
            style={{
              backgroundColor: '#FEF9EC'
            }}
          >
            <ReportProblemOutlinedIcon style={{ color: '#F2AD46' }} />
            <Typography variant="body2">
              You currently have {displayCount} display(s) in your account. To decrease display
              quantity, you'll need to remove 1 displays from your account.
            </Typography>
          </Box>
        )}
        {decreaseDisplaysHint && (
          <Box
            marginTop={1}
            padding={1}
            display="flex"
            flexDirection="row"
            style={{
              backgroundColor: '#DEEFFC'
            }}
          >
            <InfoOutlinedIcon style={{ color: '#2079CB' }} />
            <Typography variant="body2">
              Lowering your subscription quantity will take place at the start of the next billing
              cycle.
            </Typography>
          </Box>
        )}
      </Box>
    </Box>
  )
}
