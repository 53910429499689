import React, { FC } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';

const useStyles = makeStyles((theme) => ({
  container: (props: {aspectRatio?: number}) => ({
    display: 'flex',
    width: (props.aspectRatio >= 1) ? '100px' : '50px',
    height: (props.aspectRatio > 1) ? '50px' : '100px',
    flexDirection: 'column',
    alignItems: 'flex-start',
  }),
  lowerContainer: (props: {aspectRatio?: number}) => ({
    display: 'flex',
    width: (props.aspectRatio >= 1) ? '100px' : '50px',
    height: (props.aspectRatio > 1) ? '25px' : '50px',
    alignItems: 'flex-start',
  }),
  upper50: (props: {aspectRatio?: number}) => ({
    height: (props.aspectRatio > 1) ? '25px' : '50px',
    flexShrink: 0,
    alignSelf: 'stretch',
    border: '1px solid var(--Sytetm-Divider, currentColor)',
    borderBottom: 'none',
  }),
  lowerLeft25: (props: {aspectRatio?: number}) => ({
    display: 'flex',
    alignItems: 'flex-start',
    flex: '1 0 0',
    alignSelf: 'stretch',
    border: '1px solid var(--Sytetm-Divider, currentColor)',
    borderRight: 'none',
  }),
  lowerRight25: (props: {aspectRatio?: number}) => ({
    display: 'flex',
    alignItems: 'flex-start',
    flex: '1 0 0',
    alignSelf: 'stretch',
    border: '1px solid var(--Sytetm-Divider, currentColor)',
  }),
  upper50Selected: (props: {aspectRatio?: number}) => ({
    border: '2px solid var(--Sytetm-Divider, #1d93f3)',
    borderBottom: 'none',
  }),
  lowerLeft25Selected: (props: {aspectRatio?: number}) => ({
    border: '2px solid var(--Sytetm-Divider, #1d93f3)',
    borderRight: 'none',
  }),
  lowerRight25Selected: (props: {aspectRatio?: number}) => ({
    border: '2px solid var(--Sytetm-Divider, #1d93f3)',
  }),
}));

export const Layout50To25To25UpsideDownIcon: FC<{isSelected: boolean; aspectRatio?: number}> = ({
  isSelected,
  aspectRatio,
}) => {
  const {
    container,
    lowerContainer,
    upper50,
    lowerLeft25,
    lowerRight25,
    upper50Selected,
    lowerLeft25Selected,
    lowerRight25Selected,
  } = useStyles({ aspectRatio });

  const upper50ClassName = classNames(upper50, { [upper50Selected]: isSelected });
  const lowerLeft25ClassName = classNames(lowerLeft25, { [lowerLeft25Selected]: isSelected });
  const lowerRight25ClassName = classNames(lowerRight25, { [lowerRight25Selected]: isSelected });

  return (
    <div className={container}>
      <div className={upper50ClassName} />
      <div className={lowerContainer}>
        <div className={lowerLeft25ClassName} />
        <div className={lowerRight25ClassName} />
      </div>
    </div>
  );
};
