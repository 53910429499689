import gql from 'graphql-tag'
import * as Fragments from './fragments'
import { CommGetMeQuery, CommGetRememberedAdminUserQuery } from '../../types/CommGetMeQuery'
import { CommGetUsedStorageQuery } from '../../types/CommGetUsedStorageQuery'
import { CommGetAllDisplaysQuery } from '../../types/CommGetAllDisplaysQuery'

export const COMM_GET_ME_Q = gql<CommGetMeQuery>`
  query CommGetMeQuery {
    me {
      ...CommUserInfoFragment
    }
  }
  ${Fragments.COMM_USER_INFO_F}
`

export const COMM_GET_REMEMBERED_ADMIN_USER_Q = gql<CommGetRememberedAdminUserQuery>`
  query CommGetRememberedAdminUserQuery {
    rememberedAdminUser {
      ...CommUserInfoFragment
    }
  }
  ${Fragments.COMM_USER_INFO_F}
`

export const COMM_GET_USED_STORAGE_Q = gql<CommGetUsedStorageQuery>`
  query CommGetUsedStorageQuery {
    usedStorageCurrentUser
  }
`

export const GET_STOCKS_SYMBOLS = gql`
  query GetStocksSymbols {
    getStocksSymbols
  }
`

export const GET_FUEL_SYMBOLS = gql`
    query GetSiteNumberGroupList($username: String!, $password: String!, $siteNumber: Float!) {
        getSiteNumberGroupList( FuelSiteNumberDTO: {username: $username, password: $password, siteNumber: $siteNumber}) {
            symbols {
                name
                groupId
            }
        }
    }
`

export const COMM_GET_DISPLAYS_ME_Q = gql`
  query CommGetDisplaysMeQuery{
    me {
      id
      features {
        id
        name
        description
        status
      }
      account {
        id
        displays {
          id
          name
          emailNotificationsEnabled
        }
      }
    }
  }
`
