import gql from 'graphql-tag'
import { PsGetLatestSubscriptionQuery, PwGetLatestSubscriptionQueryVariables } from 'src/apollo/types/PwLatestSubscription'

export const PW_RETRIEVE_SUBSCRIPTION = gql`
  query PwRetrieveSubscription($accountId: String!) {
    retrieveSubscription(accountId: $accountId) {
      id
      accountId
      stripeSubscriptionId
      qty
      status
      planName
      stripeCustomerId
      cirrusStandardQty
      category
      trialEnd
      billingCycle
    }
  }
`

export const PW_GET_LATEST_SUBSCRIPTION = gql`
  query PwGetLatestSubscription($accountId: String, $displayId: String, $playerId: String) {
    getLatestSubscription(
      getLatestSubscriptionDTO: {
        accountId: $accountId
        displayId: $displayId
        playerId: $playerId
      }
    ) {
      id
      accountId
      stripeSubscriptionId
      qty
      status
      planName
      currentPeriodStart
      currentPeriodEnd
      stripeCustomerId
      cirrusStandardQty
      category
      trialEnd
      billingCycle
    }
  }
`

export const PW_GET_LATEST_CELLULAR_SUBSCRIPTION = gql`
  query PwGetLatestCellularSubscription($accountId: String!) {
    getLatestCellularSubscription(accountId: $accountId) {
      id
      accountId
      stripeSubscriptionId
      qty
      status
      planName
      currentPeriodStart
      currentPeriodEnd
      stripeCustomerId
      cirrusStandardQty
      category
      trialEnd
      billingCycle
    }
  }
`

export const PW_GET_SETUP_INTENT = gql`
  query GetSetupIntentByAccountId($accountId: String!) {
    getCardSetupIntentByAccountId(accountId: $accountId) {
      id
      customer
      client_secret
    }
  }
`

export const PW_GET_STRIPE_CUSTOMER = gql`
  query GetStripeCustomer($stripeCustomerId: String!) {
    getStripeCustomer(stripeCustomerId: $stripeCustomerId) {
      id
      shipping {
        name
        phone
        address {
          city
          country
          line1
          line2
          postal_code
          state
        }
      }
      invoice_settings {
        default_payment_method {
          id
          card {
            brand
            last4
            exp_month
            exp_year
          }
        }
      }
    }
  }
`

export const PW_GET_STRIPE_INVOICES = gql`
  query GetStripeInvoices($stripeCustomerId: String!, $subscriptionId: String!) {
    getStripeInvoices(stripeCustomerId: $stripeCustomerId, subscriptionId: $subscriptionId) {
      id
      total
      number
      created
      status
      description
      hosted_invoice_url
      invoice_pdf
      period_start
      period_end
      lines {
        id
        amount
        currency
        description
        quantity
        price {
          product {
            name
            description
          }
          unit_amount
        }
        period {
          start
          end
        }
      }
      attempted
      charge {
        id
        status
      }
      paid
      subtotal
      starting_balance
      ending_balance
      amount_due
      amount_paid
      amount_remaining
    }
  }
`

export const PW_GET_UPCOMING_STRIPE_INVOICE = gql`
  query GetUpcomingStripeInvoice($stripeCustomerId: String!, $subscriptionId: String!) {
    getUpcomingStripeInvoice(stripeCustomerId: $stripeCustomerId, subscriptionId: $subscriptionId) {
      total
      number
      created
      status
      description
      next_payment_attempt
      period_start
      period_end
      lines {
        id
        amount
        currency
        description
        quantity
        price {
          product {
            name
            description
          }
          unit_amount
        }
        period {
          start
          end
        }
      }
      subtotal
      starting_balance
      ending_balance
      amount_due
      amount_paid
      amount_remaining
    }
  }
`

export const PW_ACCOUNT_DISPLAYS_SUBSCRIPTION = gql`
  query PwAccountDisplaysSubscription(
    $accountId: String!
  ) {
    getDisplays(displayDTO: { accountId: $accountId }) {
      id
      playerId
    }
    getLatestSubscription(getLatestSubscriptionDTO: { accountId: $accountId }) {
      id
      accountId
      stripeSubscriptionId
      qty
      status
      planName
      currentPeriodStart
      currentPeriodEnd
      stripeCustomerId
      cirrusStandardQty
      category
      trialEnd
      billingCycle
    }
  }
`

export const PW_WORKSPACE_SUBSCRIPTION_AND_DISPLAYS = gql`
  query PwWorkspaceSubscriptionAndDisplays(
    $workspaceId: String!
  ) {
    getLatestSubscription(getLatestSubscriptionDTO: { workspaceId: $workspaceId }) {
      id
      accountId
      stripeSubscriptionId
      qty
      status
      planName
      currentPeriodStart
      currentPeriodEnd
      stripeCustomerId
      cirrusStandardQty
      category
      trialEnd
      billingCycle
    }
    getDisplays(displayDTO: { workspaceId: $workspaceId }) {
      id
      name
    }
  }
`

export const PW_GET_LATEST_SUBSCRIPTION_BY_ACCOUNT_ID = gql<PsGetLatestSubscriptionQuery, PwGetLatestSubscriptionQueryVariables>`
  query PwGetLatestSubscriptionByAccountId(
    $accountId: String!
  ) {
    getLatestSubscription(getLatestSubscriptionDTO: { accountId: $accountId }) {
      id
      accountId
      stripeSubscriptionId
      qty
      status
      planName
      currentPeriodStart
      currentPeriodEnd
      stripeCustomerId
      cirrusStandardQty
      category
      trialEnd
      billingCycle
    }
  }
`

export const PW_CURRENT_WORKSPACE_DISPLAYS = gql`
  query PwCurrentWorkspaceDisplays(
    $workspaceId: UUID!
    $filters: displayFilters
  ) {
    workspace(id: $workspaceId) {
      id
      displays(filters: $filters) {
        count
        page
        values {
          id
          name
          pixelsH
          pixelsW
          locationType
          latitude
          longitude
          brightness
          configBrightness
          enableAutoDimming
          power
          playerId
          playerVersion
          deviceType
          timezone {
            id
            jodaId
          }
          color {
            id
            colorType
            description
          }
          defaultPresentation {
            id
            name
          }
          changed
          imageUrl
          playerConnectionDate
          emailNotificationsEnabled
          displayGroupId
          isDisplayGroup
        }
      }
      presentations {
        page
        count
        values {
          id
          name
          config
          updatedAt
          type
          isMediaProcessing
          type
        }
      }
      memberRole {
        id
        name
      }
    }
  }
`

export const PW_GET_DISPLAY_SUBSCRIPTION_STATUS = gql`
  query PwGetDisplaySubscriptionStatus($idsDTO: IdsDTO!) {
    getDisplaySubscriptionStatus(idsDTO: $idsDTO)
  }
`

export const GQL_GET_ANALYTICS_SUBSCRIPTION_OF_CURRENT_WORKSPACE = gql`
  query PwGetLatestAnalyticsSubscription($workspaceId: String) {
    getLatestSubscription(
      getLatestSubscriptionDTO: { workspaceId: $workspaceId, category: ANALYTICS }
    ) {
      id
      accountId
      stripeSubscriptionId
      qty
      status
      planName
      currentPeriodStart
      currentPeriodEnd
      stripeCustomerId
      cirrusStandardQty
      category
      trialEnd
      billingCycle
    }
  }
`;
