import React from 'react'
import { Modal, CircularProgress, Box } from '@material-ui/core'

export const PortalSpinner = () => {
  return (
    <Modal
      disableAutoFocus
      open
    >
      <Box
        alignItems='center'
        display='flex'
        height='100vh'
        justifyContent='center'
        width='100vw'
      >
        <CircularProgress />
      </Box>
    </Modal>
  )
}
