import React, { FC } from 'react'
import { SvgIcon } from '@material-ui/core'
import { SvgIconProps } from '@material-ui/core/SvgIcon'

export const ThermometerIcon: FC<SvgIconProps> = (props) => (
  <SvgIcon
    {...props}
    viewBox='0 0 497.373 497.373'
  >
    <path d='M293.867 334.568V44.437c0-24.503-20.27-44.437-45.18-44.437s-45.18 20.607-45.18 45.936v288.63c-26.135 15.545-42.208 44.078-42.208 75.419 0 48.186 39.202 87.388 87.388 87.388s87.388-39.202 87.388-87.388c-.001-31.348-16.07-59.871-42.208-75.417zm-45.181 146.943c-39.439 0-71.526-32.087-71.526-71.526 0-26.838 14.288-51.126 37.29-63.384a7.911 7.911 0 0 0 3.65-4.091 7.912 7.912 0 0 0 1.268-4.306V45.936c0-16.583 13.151-30.075 29.319-30.075s29.319 12.82 29.319 28.576V127h-44.729a5.286 5.286 0 0 0-5.286 5.288 5.286 5.286 0 0 0 5.286 5.288h44.729v16.916h-18.401a5.286 5.286 0 0 0-5.286 5.288 5.286 5.286 0 0 0 5.286 5.288h18.401v16.92h-18.401a5.286 5.286 0 0 0-5.286 5.288 5.286 5.286 0 0 0 5.286 5.288h18.401v19.031h-44.729a5.286 5.286 0 0 0-5.286 5.288 5.286 5.286 0 0 0 5.286 5.288h44.729v16.918h-18.401a5.286 5.286 0 0 0-5.286 5.288 5.286 5.286 0 0 0 5.286 5.288h18.401v16.918h-18.401a5.286 5.286 0 0 0-5.286 5.288 5.286 5.286 0 0 0 5.286 5.288h18.401v16.918h-44.729a5.286 5.286 0 0 0-5.286 5.288 5.286 5.286 0 0 0 5.286 5.288h44.729v33.554c0 1.475.404 2.856 1.104 4.038a7.905 7.905 0 0 0 3.741 4.324c23.046 12.246 37.362 36.546 37.362 63.419 0 39.436-32.088 71.523-71.527 71.523z' />
  </SvgIcon>
)
