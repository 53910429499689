import React, { FC } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';

const useStyles = makeStyles((theme) => ({
  container: (props: {aspectRatio?: number}) => ({
    display: 'flex',
    width: (props.aspectRatio >= 1) ? '100px' : '50px',
    height: (props.aspectRatio > 1) ? '50px' : '100px',
    flexDirection: 'column',
    alignItems: 'flex-start',
  }),
  upperContainer: (props: {aspectRatio?: number}) => ({
    display: 'flex',
    width: (props.aspectRatio >= 1) ? '100px' : '50px',
    height: (props.aspectRatio > 1) ? `${50 * (1/3)}px` : `${100 * (1/3)}px`,
    alignItems: 'flex-start',
  }),
  lower70: (props: {aspectRatio?: number}) => ({
    height: (props.aspectRatio > 1) ? `${50 * (2/3)}px` : `${100 * (2/3)}px`,
    flexShrink: 0,
    alignSelf: 'stretch',
    border: '1px solid var(--Sytetm-Divider, currentColor)',
    
  }),
  upperLeft15: (props: {aspectRatio?: number}) => ({
    display: 'flex',
    alignItems: 'flex-start',
    flex: '1 0 0',
    alignSelf: 'stretch',
    border: '1px solid var(--Sytetm-Divider, currentColor)',
    borderRight: 'none',
    borderBottom: 'none',
  }),
  upperRight15: (props: {aspectRatio?: number}) => ({
    display: 'flex',
    alignItems: 'flex-start',
    flex: '1 0 0',
    alignSelf: 'stretch',
    border: '1px solid var(--Sytetm-Divider, currentColor)',
    borderBottom: 'none',
  }),
  lower70Selected: (props: {aspectRatio?: number}) => ({
    border: '2px solid var(--Sytetm-Divider, #1d93f3)',
  }),
  upperLeft15Selected: (props: {aspectRatio?: number}) => ({
    border: '2px solid var(--Sytetm-Divider, #1d93f3)',
    borderRight: 'none',
    borderBottom: 'none',
  }),
  upperRight15Selected: (props: {aspectRatio?: number}) => ({
    border: '2px solid var(--Sytetm-Divider, #1d93f3)',
    borderBottom: 'none',
  }),
}));

export const Layout15To15To70Icon: FC<{isSelected: boolean; aspectRatio?: number}> = ({
  isSelected,
  aspectRatio,
}) => {
  const {
    container,
    upperContainer,
    lower70,
    upperLeft15,
    upperRight15,
    lower70Selected,
    upperLeft15Selected,
    upperRight15Selected,
  } = useStyles({ aspectRatio });

  const lower70ClassName = classNames(lower70, { [lower70Selected]: isSelected });
  const upperLeft15ClassName = classNames(upperLeft15, { [upperLeft15Selected]: isSelected });
  const upperRight15ClassName = classNames(upperRight15, { [upperRight15Selected]: isSelected });

  return (
    <div className={container}>
      <div className={upperContainer}>
        <div className={upperLeft15ClassName} />
        <div className={upperRight15ClassName} />
      </div>
      <div className={lower70ClassName} />
    </div>
  );
};
