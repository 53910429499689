import React, { useCallback, useState } from 'react'
import { GoogleMap, Marker, useLoadScript } from '@react-google-maps/api'
import { Box } from '@material-ui/core'
import { toast } from 'react-toastify'
import { PlacesAutocompleteField } from './places-autocomplete-field'
import { LocateMeButton } from './locate-me-button'

const LIBRARIES = ['places']

interface Coords {
  lat: number
  lng: number
}

interface TProps {
  positionCoords: Coords
  onPositionCoordsChange(arg: Coords): void
}

export const GeopositionSelect: React.FC<TProps> = ({ positionCoords, onPositionCoordsChange }) => {
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    libraries: LIBRARIES
  })

  const [viewCoords, setViewCoords] = useState(positionCoords)

  const handleMapClick = useCallback(
    (e) =>
      onPositionCoordsChange({
        lat: e.latLng.lat(),
        lng: e.latLng.lng()
      }),
    [onPositionCoordsChange]
  )

  if (!isLoaded || loadError) return null

  return (
    <Box
      display='flex'
      flexDirection='column'
      height='100%'
      width='100%'
    >
      <Box
        flexShrink={0}
        marginBottom={4}
      >
        <PlacesAutocompleteField
          currentCoords={positionCoords}
          onSelect={(coords) => {
            onPositionCoordsChange(coords)
            setViewCoords(coords)
          }}
        />
      </Box>
      <Box
        flexGrow={1}
        position='relative'
      >
        <GoogleMap
          center={viewCoords}
          mapContainerStyle={{ width: '100%', height: '100%', position: 'relative' }}
          onClick={handleMapClick}
          options={{
            zoomControl: true,
            mapTypeControl: false,
            scaleControl: false,
            streetViewControl: false,
            rotateControl: false,
            fullscreenControl: false
          }}
          zoom={4}
        >
          <Box
            paddingX={3}
            position='absolute'
            right={5}
            top={5}
            width={200}
          >
            <LocateMeButton
              fullWidth
              onError={() =>
                toast.info('You need to give access to your location to use this feature')}
              onLocate={(coords) => {
                onPositionCoordsChange(coords)
                setViewCoords(coords)
              }}
              variant='contained'
            >
              Locate me
            </LocateMeButton>
          </Box>

          <Marker position={positionCoords} />
        </GoogleMap>
      </Box>
    </Box>
  )
}
