import gql from 'graphql-tag'
import { IQuoteAO } from './interfaces/checkout.interface'
import { CollectionAO } from '../../types/common'

export const CHECKOUT_GET_CHECKOUT_QUOTES_Q = gql<{
    getCheckoutQuotes: CollectionAO<IQuoteAO>
}>`
    query GetCheckoutQuotes(
        $queryGetCheckoutQuotesDTO: QueryGetCheckoutQuotesDTO!
    ) {
        getCheckoutQuotes(queryGetCheckoutQuotesDTO: $queryGetCheckoutQuotesDTO) {
            items {
                id
                dealId
                title
                contactEmail
                quoteCreatedAt
                expiredAt
                status
                paymentAt
                signedAt
                quoteOrderStates {
                    id
                    quoteId
                    stateType
                    stateEndTime
                    stateResult
                }
                creditCheckAt
                uploadVoidCheckAt
                voidCheckURL
                driverLicenseFrontURL
                driverLicenseTailURL
                signnowDocumentId
                preSignnowDocumentId
                achAndInsuranceDocumentCreateAt
                achAndInsuranceDocumentSignedAt
                preAchAndInsuranceTemplateDocumentId
                achAndInsuranceDocumentId
            }
            pagination {
                offset
                limit
                totalCount
            }
        }
    }
`

export const CHECKOUT_GET_SIGNED_SIGNNOW_DOCUMENT_DOWNLOAD_URL_Q = gql<{
    getSignedSignNowDocumentDownloadUrl: string
}>`
    query GetSignedSignNowDocumentDownloadUrl($quoteId: String!) {
        getSignedSignNowDocumentDownloadUrl(quoteId: $quoteId)
    }
`
