export type TIconVariant = 'rgb' | 'white' | 'black' | 'rgb-white'

export const getLogoColors = (variant: TIconVariant): [string, string] => {
  switch (variant) {
    case 'rgb':
      return ['#3686d2', '#384142']
    case 'white':
      return ['#ffffff', '#ffffff']
    case 'black':
      return ['#384142', '#384142']
    case 'rgb-white':
      return ['#3686d2', '#ffffff']
    default:
      throw Error(`Cannot use variant ${variant}`)
  }
}
