import React, { FC } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';

const useStyles = makeStyles((theme) => ({
  container: (props: {aspectRatio?: number}) => ({
    display: 'flex',
    width: (props.aspectRatio >= 1) ? '100px' : '50px',
    height: (props.aspectRatio > 1) ? '50px' : '100px',
    flexDirection: 'column',
    alignItems: 'flex-start',
  }),
  lowerContainer: (props: {aspectRatio?: number}) => ({
    display: 'flex',
    width: (props.aspectRatio >= 1) ? '100px' : '50px',
    height: (props.aspectRatio > 1) ? '40px' : '80px',
    alignItems: 'flex-start',
  }),
  upper20: (props: {aspectRatio?: number}) => ({
    height: (props.aspectRatio > 1) ? '10px' : '20px',
    flexShrink: 0,
    alignSelf: 'stretch',
    border: '1px solid var(--Sytetm-Divider, currentColor)',
    borderBottom: 'none',
  }),
  lowerLeft40: (props: {aspectRatio?: number}) => ({
    display: 'flex',
    alignItems: 'flex-start',
    flex: '1 0 0',
    alignSelf: 'stretch',
    border: '1px solid var(--Sytetm-Divider, currentColor)',
    borderRight: 'none',
  }),
  lowerRight40: (props: {aspectRatio?: number}) => ({
    display: 'flex',
    alignItems: 'flex-start',
    flex: '1 0 0',
    alignSelf: 'stretch',
    border: '1px solid var(--Sytetm-Divider, currentColor)',
  }),
  upper20Selected: (props: {aspectRatio?: number}) => ({
    border: '2px solid var(--Sytetm-Divider, #1d93f3)',
    borderBottom: 'none',
  }),
  lowerLeft40Selected: (props: {aspectRatio?: number}) => ({
    border: '2px solid var(--Sytetm-Divider, #1d93f3)',
    borderRight: 'none',
  }),
  lowerRight40Selected: (props: {aspectRatio?: number}) => ({
    border: '2px solid var(--Sytetm-Divider, #1d93f3)',
  }),
}));

export const Layout20To40To40Icon: FC<{isSelected: boolean; aspectRatio?: number}> = ({
  isSelected,
  aspectRatio,
}) => {
  const {
    container,
    lowerContainer,
    upper20,
    lowerLeft40,
    lowerRight40,
    upper20Selected,
    lowerLeft40Selected,
    lowerRight40Selected,
  } = useStyles({ aspectRatio });

  const upper20ClassName = classNames(upper20, { [upper20Selected]: isSelected });
  const lowerLeft40ClassName = classNames(lowerLeft40, { [lowerLeft40Selected]: isSelected });
  const lowerRight40ClassName = classNames(lowerRight40, { [lowerRight40Selected]: isSelected });

  return (
    <div className={container}>
      <div className={upper20ClassName} />
      <div className={lowerContainer}>
        <div className={lowerLeft40ClassName} />
        <div className={lowerRight40ClassName} />
      </div>
    </div>
  );
};
