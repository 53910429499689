const CURRENT_WORKSPACE = 'currentWorkspace'

export const getCurrentWorkspaceFromLocalStorage = () => {
  const storedWorkspace = localStorage.getItem(CURRENT_WORKSPACE)

  if (storedWorkspace) {
    return JSON.parse(storedWorkspace)
  }
}

export const setCurrentWorkspaceToLocalStorage = (currentWorkspace) => {
  localStorage.setItem(CURRENT_WORKSPACE, JSON.stringify(currentWorkspace))
}
