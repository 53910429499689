import React, { FC } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';

const useStyles = makeStyles((theme) => ({
  container: (props: {aspectRatio?: number}) => ({
    display: 'flex',
    width: (props.aspectRatio >= 1) ? '100px' : '50px',
    height: (props.aspectRatio > 1) ? '50px' : '100px',
    flexDirection: 'column',
    alignItems: 'flex-start',
  }),
  upperContainer: (props: {aspectRatio?: number}) => ({
    display: 'flex',
    width: (props.aspectRatio >= 1) ? '100px' : '50px',
    height: (props.aspectRatio > 1) ? '40px' : '80px',
    alignItems: 'flex-start',
  }),
  lower20: (props: {aspectRatio?: number}) => ({
    height: (props.aspectRatio > 1) ? '10px' : '20px',
    flexShrink: 0,
    alignSelf: 'stretch',
    border: '1px solid var(--Sytetm-Divider, currentColor)',
    
  }),
  upperLeft55: (props: {aspectRatio?: number}) => ({
    display: 'flex',
    alignItems: 'flex-start',
    width: (props.aspectRatio >= 1) ? `${100 * (2 / 3)}px` : `${50 * (2 / 3)}px`,
    alignSelf: 'stretch',
    border: '1px solid var(--Sytetm-Divider, currentColor)',
    borderRight: 'none',
    borderBottom: 'none',
  }),
  upperRight25: (props: {aspectRatio?: number}) => ({
    display: 'flex',
    alignItems: 'flex-start',
    flex: '1 0 0',
    alignSelf: 'stretch',
    border: '1px solid var(--Sytetm-Divider, currentColor)',
    borderBottom: 'none',
  }),
  lower20Selected: (props: {aspectRatio?: number}) => ({
    border: '2px solid var(--Sytetm-Divider, #1d93f3)',
  }),
  upperLeft55Selected: (props: {aspectRatio?: number}) => ({
    border: '2px solid var(--Sytetm-Divider, #1d93f3)',
    borderRight: 'none',
    borderBottom: 'none',
  }),
  upperRight25Selected: (props: {aspectRatio?: number}) => ({
    border: '2px solid var(--Sytetm-Divider, #1d93f3)',
    borderBottom: 'none',
  }),
}));

export const Layout55To25To20Icon: FC<{isSelected: boolean; aspectRatio?: number}> = ({
  isSelected,
  aspectRatio,
}) => {
  const {
    container,
    upperContainer,
    lower20,
    upperLeft55,
    upperRight25,
    lower20Selected,
    upperLeft55Selected,
    upperRight25Selected,
  } = useStyles({ aspectRatio });

  const lower20ClassName = classNames(lower20, { [lower20Selected]: isSelected });
  const upperLeft55ClassName = classNames(upperLeft55, { [upperLeft55Selected]: isSelected });
  const upperRight25ClassName = classNames(upperRight25, { [upperRight25Selected]: isSelected });

  return (
    <div className={container}>
      <div className={upperContainer}>
        <div className={upperLeft55ClassName} />
        <div className={upperRight25ClassName} />
      </div>
      <div className={lower20ClassName} />
    </div>
  );
};
