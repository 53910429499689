import React, { FC } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';

const useStyles = makeStyles((theme) => ({
  container: (props: {aspectRatio?: number}) => ({
    display: 'flex',
    width: (props.aspectRatio >= 1) ? '100px' : '50px',
    height: (props.aspectRatio > 1) ? '50px' : '100px',
    flexDirection: 'column',
    alignItems: 'flex-start',
  }),
  lowerContainer: (props: {aspectRatio?: number}) => ({
    display: 'flex',
    width: (props.aspectRatio >= 1) ? '100px' : '50px',
    height: (props.aspectRatio > 1) ? `${50 * (1/3)}px` : `${100 * (1/3)}px`,
    alignItems: 'flex-start',
  }),
  upper70: (props: {aspectRatio?: number}) => ({
    height: (props.aspectRatio > 1) ? `${50 * (2/3)}px` : `${100 * (2/3)}px`,
    flexShrink: 0,
    alignSelf: 'stretch',
    border: '1px solid var(--Sytetm-Divider, currentColor)',
    borderBottom: 'none',
  }),
  lowerLeft10: (props: {aspectRatio?: number}) => ({
    display: 'flex',
    alignItems: 'flex-start',
    width: (props.aspectRatio >= 1) ? `${100 * (1/3)}px` : `${50 * (1/3)}px`,
    alignSelf: 'stretch',
    border: '1px solid var(--Sytetm-Divider, currentColor)',
    borderRight: 'none',
  }),
  lowerMiddle10: (props: {aspectRatio?: number}) => ({
    display: 'flex',
    alignItems: 'flex-start',
    width: (props.aspectRatio >= 1) ? `${100 * (1/3)}px` : `${50 * (1/3)}px`,
    alignSelf: 'stretch',
    border: '1px solid var(--Sytetm-Divider, currentColor)',
    borderRight: 'none',
  }),
  lowerRight10: (props: {aspectRatio?: number}) => ({
    display: 'flex',
    alignItems: 'flex-start',
    width: (props.aspectRatio >= 1) ? `${100 * (1/3)}px` : `${50 * (1/3)}px`,
    alignSelf: 'stretch',
    border: '1px solid var(--Sytetm-Divider, currentColor)',
  }),
  upper70Selected: (props: {aspectRatio?: number}) => ({
    border: '2px solid var(--Sytetm-Divider, #1d93f3)',
    borderBottom: 'none',
  }),
  lowerLeft10Selected: (props: {aspectRatio?: number}) => ({
    border: '2px solid var(--Sytetm-Divider, #1d93f3)',
    borderRight: 'none',
  }),
  lowerMiddle10Selected: (props: {aspectRatio?: number}) => ({
    border: '2px solid var(--Sytetm-Divider, #1d93f3)',
    borderRight: 'none',
  }),
  lowerRight10Selected: (props: {aspectRatio?: number}) => ({
    border: '2px solid var(--Sytetm-Divider, #1d93f3)',
  }),
}));

export const Layout70To10To10To10UpsideDownIcon: FC<{isSelected: boolean; aspectRatio?: number}> = ({
  isSelected,
  aspectRatio,
}) => {
  const {
    container,
    lowerContainer,
    upper70,
    lowerLeft10,
    lowerMiddle10,
    lowerRight10,
    upper70Selected,
    lowerLeft10Selected,
    lowerMiddle10Selected,
    lowerRight10Selected,
  } = useStyles({ aspectRatio });

  const upper70ClassName = classNames(upper70, { [upper70Selected]: isSelected });
  const lowerLeft10ClassName = classNames(lowerLeft10, { [lowerLeft10Selected]: isSelected });
  const lowerMiddle10ClassName = classNames(lowerMiddle10, { [lowerMiddle10Selected]: isSelected });
  const lowerRight10ClassName = classNames(lowerRight10, { [lowerRight10Selected]: isSelected });

  return (
    <div className={container}>
      <div className={upper70ClassName} />
      <div className={lowerContainer}>
        <div className={lowerLeft10ClassName} />
        <div className={lowerMiddle10ClassName} />
        <div className={lowerRight10ClassName} />
      </div>
    </div>
  );
};
