export const loadImage = (src: string): Promise<{ width: number; height: number }> => {
  return new Promise((resolve, reject) => {
    if (!src || src === '') reject()

    const image = new Image()
    image.src = src

    image.onerror = reject
    image.onload = () => {
      const { height, width } = image

      return resolve({ height, width })
    }
  })
}
