import gql from 'graphql-tag'

export const GET_DISCONNECTED_SIGNAL_DATA = gql`
  query($workspaceId: UUID, $limit: Int) {
    getDisconnectedSignalData(workspaceId: $workspaceId, limit: $limit) {
      count
      total
      values {
        displayId
        displayName
        type
        name
        email
        lastConnection
        offline
        longitude
        latitude
        adsEnabled
      }
    }
  }
`

export const GET_HIGHTEMP_SIGNAL_DATA = gql`
  query($workspaceId: UUID, $limit: Int) {
    getHighTemperatureSignalData(
      workspaceId: $workspaceId
      limit: $limit
    ) {
      count
      total
      values {
        displayId
        displayName
        type
        name
        email
        longitude
        latitude
        lastEventTime
        status
        adsEnabled
      }
    }
  }
`

export const GET_ELECTRICALOFF_SIGNAL_DATA = gql`
  query($workspaceId: UUID, $limit: Int) {
    getElectricalOffSignalData(workspaceId: $workspaceId, limit: $limit) {
      count
      total
      values {
        displayId
        displayName
        type
        name
        email
        longitude
        latitude
        lastEventTime
        status
        adsEnabled
      }
    }
  }
`

export const GET_PANELSOUT_SIGNAL_DATA = gql`
  query($workspaceId: UUID, $limit: Int) {
    getPanelsOutSignalData(workspaceId: $workspaceId, limit: $limit) {
      count
      total
      values {
        displayId
        displayName
        type
        name
        email
        longitude
        latitude
        lastEventTime
        status
        adsEnabled
      }
    }
  }
`

export const GET_FIRST_CONNECTION_SIGNAL_DATA = gql`
  query($workspaceId: UUID, $limit: Int) {
    getFirstConnectionSignalData(
      workspaceId: $workspaceId
      limit: $limit
    ) {
      count
      total
      values {
        displayId
        displayName
        firstConnection
        name
        email
        longitude
        latitude
        lastConnection
        status
        adsEnabled
        type
      }
    }
  }
`

export const GET_OUT_OF_VERSION_SIGNAL_DATA = gql`
  query($workspaceId: UUID, $limit: Int) {
    getOutOfVersionSignalData(workspaceId: $workspaceId, limit: $limit) {
      count
      total
      values {
        displayId
        displayName
        playerVersion
        name
        email
        longitude
        latitude
        lastConnection
        status
        adsEnabled
        type
      }
    }
  }
`

export const GET_NO_SCHEDULE_SIGNAL_DATA = gql`
  query($workspaceId: UUID, $limit: Int) {
    getNoScheduleSignalData(workspaceId: $workspaceId, limit: $limit) {
      count
      total
      values {
        displayId
        displayName
        type
        name
        email
        longitude
        latitude
        lastConnection
        status
        adsEnabled
      }
    }
  }
`

export const GET_USER_LOGIN_SIGNAL_DATA = gql`
  query($workspaceId: UUID, $limit: Int) {
    getUserLoginsSignalData(workspaceId: $workspaceId, limit: $limit) {
      count
      total
      values {
        name
        email
        loginCount
      }
    }
  }
`

export const GET_USER_POWER_OFF_SIGNAL_DATA = gql`
  query($workspaceId: UUID, $limit: Int) {
    getUserPowerOffSignalData(workspaceId: $workspaceId, limit: $limit) {
      count
      total
      values {
        name
        email
        displayId
        displayName
        type
        name
        email
        longitude
        latitude
        lastConnection
        count
        status
        adsEnabled
      }
    }
  }
`

export const GET_USER_REBOOTS_SIGNAL_DATA = gql`
  query($workspaceId: UUID, $limit: Int) {
    getUserRebootsSignalData(workspaceId: $workspaceId, limit: $limit) {
      count
      total
      values {
        name
        email
        displayId
        displayName
        type
        name
        email
        longitude
        latitude
        lastConnection
        count
        status
        adsEnabled
      }
    }
  }
`

export const GET_DISPLAY_BY_TYPE_SIGNAL_DATA = gql`
  query($workspaceId: UUID, $limit: Int) {
    getDisplayByTypeSignalData(workspaceId: $workspaceId, limit: $limit) {
      count
      total
      values {
        name
        email
        displayId
        displayName
        type
        name
        email
        longitude
        latitude
        widthXHeight
        lastConnection
        status
        adsEnabled
      }
    }
  }
`

export const GET_DISPLAY_LOCATIONS_SIGNAL_DATA = gql`
  query($workspaceId: UUID, $limit: Int) {
    getDisplayLocationSignalData(
      workspaceId: $workspaceId
      limit: $limit
    ) {
      count
      total
      values {
        name
        email
        displayId
        displayName
        created
        name
        email
        longitude
        latitude
        lastConnection
        status
        adsEnabled
      }
    }
  }
`

export const GET_DISCONNECTED_SIGNAL_LINECHART_DATA = gql`
  query($workspaceId: UUID, $limit: Int) {
    getDisconnectedSignalData(workspaceId: $workspaceId, limit: $limit) {
      count
      total
      values {
        lastConnection
      }
    }
  }
`

export const GET_DASHBOARD_DATA = gql`
  query($workspaceId: UUID, $limit: Int) {
    getDisconnectedSignalData(workspaceId: $workspaceId, limit: $limit) {
      count
      total
      values {
        displayId
        displayName
        longitude
        latitude
        lastConnection
      }
    }
  }
`

export const GET_DISCONNECTED_SIGNAL_MAP_DATA = gql`
  query($workspaceId: UUID, $limit: Int) {
    getDisconnectedSignalData(workspaceId: $workspaceId, limit: $limit) {
      count
      total
      values {
        displayId
        displayName
        longitude
        latitude
        lastConnection
      }
    }
  }
`

export const GET_METRICS_TOTAL_REVENUE_SIGNAL_DATA = gql`
  query($workspaceId: UUID, $limit: Int) {
    getAdMetricsTotalRevenueSignalData(
      workspaceId: $workspaceId
      limit: $limit
    ) {
      max
      min
      count
      total
      values {
        displayId
        displayName
        type
        name
        email
        status
        lastConnection
        longitude
        latitude
        revenue
        pops
        adsEnabled
      }
    }
  }
`

/* DASHBOARD DATA QUERIES */

export const GET_DASHBOARD_DATA_DISCONNECTED = gql`
  query($workspaceId: UUID, $limit: Int) {
    getDisconnectedSignalData(workspaceId: $workspaceId, limit: $limit) {
      total
    }
  }
`

export const GET_DASHBOARD_DATA_HIGH_TEMP = gql`
  query($workspaceId: UUID, $limit: Int) {
    getHighTemperatureSignalData(
      workspaceId: $workspaceId
      limit: $limit
    ) {
      total
    }
  }
`

export const GET_DASHBOARD_DATA_PANELS_OUT = gql`
  query($workspaceId: UUID, $limit: Int) {
    getPanelsOutSignalData(workspaceId: $workspaceId, limit: $limit) {
      total
    }
  }
`

export const GET_DASHBOARD_DATA_ELECTRICAL_OFF = gql`
  query($workspacePath: UUID, $limit: Int) {
    getElectricalOffSignalData(workspaceId: $workspaceId, limit: $limit) {
      total
    }
  }
`

export const GET_DASHBOARD_DATA_OUT_OF_VERSION = gql`
  query($workspaceId: UUID, $limit: Int) {
    getOutOfVersionSignalData(workspaceId: $workspaceId, limit: $limit) {
      total
    }
  }
`

export const GET_DASHBOARD_DATA_NO_SCHEDULE = gql`
  query($workspaceId: UUID, $limit: Int) {
    getNoScheduleSignalData(workspaceId: $workspaceId, limit: $limit) {
      total
    }
  }
`

export const GET_DASHBOARD_DATA_USER_POWER_OFF = gql`
  query($workspaceId: UUID, $limit: Int) {
    getUserPowerOffSignalData(workspaceId: $workspaceId, limit: $limit) {
      sum
    }
  }
`

export const GET_DASHBOARD_DATA_USER_LOGINS = gql`
  query($workspaceId: UUID, $limit: Int) {
    getUserLoginsSignalData(workspaceId: $workspaceId, limit: $limit) {
      sum
    }
  }
`

export const GET_DASHBOARD_DATA_USER_REBOOTS = gql`
  query($workspaceId: UUID, $limit: Int) {
    getUserRebootsSignalData(workspaceId: $workspaceId, limit: $limit) {
      sum
    }
  }
`
export const GET_DASHBOARD_DATA_DISPLAY_BY_TYPE = gql`
  query($workspaceId: UUID, $limit: Int) {
    getDisplayByTypeSignalData(workspaceId: $workspaceId, limit: $limit) {
      total
    }
  }
`
export const GET_DASHBOARD_DATA_DISPLAY_BY_LOCATION = gql`
  query($workspaceId: UUID, $limit: Int) {
    getDisplayLocationSignalData(
      workspaceId: $workspaceId
      limit: $limit
    ) {
      total
    }
  }
`
export const GET_DASHBOARD_DATA_FIRST_CONNECTION = gql`
  query($workspaceId: UUID, $limit: Int) {
    getFirstConnectionSignalData(
      workspaceId: $workspaceId
      limit: $limit
    ) {
      total
    }
  }
`

export const GET_AD_METRICS_TOTAL_REVENUE = gql`
  query($workspaceId: UUID, $limit: Int) {
    getAdMetricsTotalRevenueSignalData(
      workspaceId: $workspaceId
      limit: $limit
    ) {
      sum
    }
  }
`

/* Daily Issue Data Queries */

export const GET_ELECTEMPPANE_DAILY_ISSUE_DATA = gql`
  query($workspaceId: UUID, $issueName: issueNameEnum!) {
    getElecTempPanelDailyIssueCountSignalData(
      workspaceId: $workspaceId
      issueName: $issueName
    ) {
      values {
        date
        count
      }
    }
  }
`
export const GET_ADMETRICS_DAILY_ISSUE_DATA = gql`
  query($workspaceId: UUID) {
    getAdMetricsDailyTotalRevenueSignalData(workspaceId: $workspaceId) {
      values {
        date
        revenue
        pops
      }
    }
  }
`
