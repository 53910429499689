import React, { FC, useEffect, ErrorInfo } from 'react'
import * as Sentry from '@sentry/browser'
import { toast } from 'react-toastify'
import { useUserInfo } from 'src/services'

/** Declare constants that will be used to follow steps based on environment */

const { REACT_APP_SENTRY_DSN, REACT_APP_SENTRY_ENVIRONMENT } = process.env
const DEFAULT_ERROR_MESSAGE = 'Something went wrong'
const isProduction = REACT_APP_SENTRY_ENVIRONMENT === 'production'

const isReporting = isProduction

/** Initialize sentry error service with env-based variables */
export const initSentry = () => {
  if (!isReporting) return

  Sentry.init({
    dsn: REACT_APP_SENTRY_DSN,
    environment: REACT_APP_SENTRY_ENVIRONMENT
  })
}

/** Error handler params */
interface IErrorHandler {
  error: Error
  stack?: ErrorInfo
  userFriendlyMessage?: string
  shouldSkipToast?: boolean
}

/** Error handler that sends errors statistic if reporting allowed based on env variables.
 * Also shows toasting notification in any case
 * @see isReporting
 * @see isProduction
 * @see toast
 * @see IErrorHandler
 */
export const handleError = async ({
  error,
  stack,
  userFriendlyMessage = DEFAULT_ERROR_MESSAGE,
  shouldSkipToast,
}: IErrorHandler) => {
  if (isReporting) {
    Sentry.withScope((scope) => {
      if (stack) scope.setExtra('Component stack', stack)
      Sentry.captureException(error)
    })
  }

  if (!isProduction) {
    console.log(error)
  }

  if (!shouldSkipToast) {
    toast.error(userFriendlyMessage, {
      toastId: userFriendlyMessage,
      autoClose: false
    })
  }
}

/** React Component: set user to sentry after fetching data using apollo-client
 * @see isReporting
 * @see Sentry.User
 * @see Sentry
 */
export const SetSentryUser: FC = ({ children }) => {
  const userInfo = useUserInfo()

  useEffect(() => {
    if (!isReporting) return

    Sentry.configureScope((scope) => {
      if (!userInfo) return

      let sentryUser: Sentry.User | null = null

      if (userInfo.me) {
        sentryUser = {
          id: userInfo.me.id,
          email: userInfo.me.email
        }
      }

      scope.setUser(sentryUser)
    })
  }, [userInfo])

  return (
    <>
      {children}
    </>
  )
}
