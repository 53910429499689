export {
  supportedVideoFormats,
  supportedImageFormats,
  videoFormatsAcceptAttr,
  imageFormatsAcceptAttr,
  videoFormatsUserFriendly,
  imageFormatsUserFriendly
} from './supported-formats'

export {
  COLOR_BLUE,
  COLOR_WHITE,
  COLOR_GRAY,
  COLOR_LIGHT_GRAY,
  COLOR_LIGHT_BLUE,
  COLOR_DARK_GRAY,
  COLOR_DARKER_GRAY,
  COLOR_DARKEST_GRAY,
  COLOR_WHITE_SMOKE,
  COLOR_BLACK
} from './style'

/**
 * Tracking the different signal strings for all 13 signals for consistency
 * and to prevent the possibility of typos
 */

export enum Signals {
  HIGH_TEMP = 'high-temp',
  DISCONNECTED = 'disconnected',
  ELECTRICAL_OFF = 'electrical-off',
  PANELS_OUT = 'panels-out',
  OUT_OF_VERSION = 'out-of-version',
  FIRST_CONNECTION = 'first-connection',
  NO_SCHEDULE = 'no-schedule',
  USER_LOGINS = 'user-logins',
  USER_POWER_OFF = 'user-power-off-panels',
  USER_REBOOTS = 'user-reboot-displays',
  DISPLAY_BY_TYPE = 'display-by-type',
  DISPLAY_LOCATIONS = 'display-locations',
  AD_METRICS = 'ad-metrics'
}


export enum WorkspaceRoles {
  OWNER= 'OWNER',
  MANAGER= 'MANAGER',
  MEMBER = 'MEMBER'
}


export enum DisplayActions {
  AUTO_MAP = 'Auto-Map',
  POWER_ON_PANELS = 'Power Up',
  POWER_OFF_PANELS = 'Cut Power',
  REBOOT = 'Reboot'
}

export const daysOfTheWeeks = ['SUN', 'MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT']

export const oldPlayerVersions = [
  '1.0.9',
  '0.0.62',
  '4.1.1',
  '3.2.3',
  '1.0.2',
  '3.4.2',
  '2.2.1',
  '4.4.3',
  '3.3.6',
  '3.12.1',
  '0.0.61',
  '3.3.0',
  '3.8.7',
  '0.0.53',
  '4.5.7',
  '4.6.15',
  '5.0.1',
  '1.0.1',
  '3.4.1',
  '0.0.57',
  '3.1.0',
  '3.2.0',
  '5.2.3-emergency-alert',
  '3.4.0',
  '3.0.2',
  '2.0.0',
  '3.9.11',
  '3.2.1',
  '1.0.0',
  '4.7.2',
  '0.0.46',
  '3.10.2',
  '4.7.1',
  '0.0.64',
  '3.2.2',
  '3.3.7',
  '4.7.3',
  '5.2.0',
  '4.4.1',
  '0.0.65',
  '4.4.0',
  '0.0.59',
  '3.4.3',
  '0.0.49',
  '4.1.2',
  '0.0.60',
  '3.3.2',
  '5.0.0',
  '1.0.6',
  '0.0.63',
  '5.1.11',
  '0.0.50',
  '0.0.55',
  '0.0.54',
  '3.6.5',
  '4.6.0',
  '3.7.4',
  '3.0.1',
  '3.6.4',
  '4.6.1',
  '0.0.58',
  '2.1.0',
  '3.6.1',
  '1.0.4',
  '2.3.0',
  '0.0.56',
  '3.6.6',
  '0.0.51',
  '1.0.8',
  '1.0.5',
  '4.4.4',
  '5.1.13',
  '2.2.0',
  '5.1.12',
  '18.2.2',
  '1.0.7',
  '1.0.3',
  '3.12.3'
]