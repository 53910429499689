import React, { FC } from 'react'
import { styled } from '@material-ui/styles'
import { Button, Theme } from '@material-ui/core'
import { CellularSubscriptionBanner, GenericAppBar, TrialBanner } from 'src/ui'
import { HtmlTooltip } from '../atoms/html-tooltip'
import { ExpandMore } from '@material-ui/icons'

const StyledButton = styled(Button)<Theme>(({ theme }) => ({
  background: theme.palette.common.white,
  boxShadow: '1px 2px 4px rgba(0, 0, 0, 0.15)',
  paddingRight: theme.spacing(3),
  paddingLeft: theme.spacing(3),
  '&:hover': {
    background: theme.palette.primary.light,
    color: theme.palette.common.white
  }
}))

interface TProps {
  onClick?: (event: any) => void
  pageTitle: string
  buttonTitle: string
  disableButton?: boolean
  showTrialBanner?: boolean
  buttonTooltip?: JSX.Element
  multiButtons?: boolean
  showExpandMore?: boolean
}

export const AppBarWithButton: FC<TProps> = ({
  onClick,
  pageTitle,
  buttonTitle,
  disableButton,
  showTrialBanner = true,
  buttonTooltip,
  multiButtons = false,
  showExpandMore = true
}) => {
  return (
    <div>
      <GenericAppBar
        pageTitle={pageTitle}
        // titleIndent={4}
      >
        {buttonTooltip ? (
          <HtmlTooltip title={buttonTooltip} interactive={true} leaveDelay={100} placement="top">
            <span>
              <StyledButton color="primary" disabled={disableButton} onClick={onClick}>
                {buttonTitle}
                {showExpandMore && <ExpandMore />}
              </StyledButton>
            </span>
          </HtmlTooltip>
        ) : (
          <StyledButton color="primary" disabled={disableButton} onClick={onClick}>
            {buttonTitle}
            {showExpandMore && (
              <ExpandMore style={{ width: '20px', height: '20px', marginLeft: '8px' }} />
            )}
          </StyledButton>
        )}
      </GenericAppBar>
      {showTrialBanner && <TrialBanner />}
      {showTrialBanner && <CellularSubscriptionBanner />}
    </div>
  )
}
