import React, { useState, useEffect, useCallback, useRef } from 'react'
import { AutocompleteField } from './autocomplete-field'

interface TProps {
  currentCoords: { lat: number; lng: number }
  onSelect(arg: { lat: number; lng: number }): void
}

let getAutocomplete: any
let geocoder: any

export const PlacesAutocompleteField: React.FC<TProps> = ({ onSelect, currentCoords }) => {
  const [value, setValue] = useState('')
  const [suggestions, setSuggestions] = useState([])

  const inputRef = useRef<HTMLInputElement>(null)

  useEffect(() => {
    if (!(window as any).google || geocoder) return
    geocoder = new (window as any).google.maps.Geocoder()
    getAutocomplete = new (window as any).google.maps.places.AutocompleteService()
  }, [])

  useEffect(() => {
    if (!geocoder) return
    if (inputRef.current && inputRef.current !== document.activeElement) {
      geocoder.geocode({ location: currentCoords }, (results) => {
        if (!results) return
        for (const result of results) {
          if (result.formatted_address) return setValue(result.formatted_address)
        }
      })
    }
  }, [currentCoords])

  useEffect(() => {
    if (!getAutocomplete || value === '') return

    getAutocomplete.getQueryPredictions({ input: value }, (rawSuggestions) => {
      if (Array.isArray(rawSuggestions)) {
        setSuggestions(rawSuggestions.map((item) => item.description))
      }
    })
  }, [value])

  const handleChange = useCallback((value) => {
    setValue(value)
  }, [])

  const handlerSelect = useCallback(
    (value) => {
      setValue(value)

      if (value !== '') {
        geocoder.geocode({ address: value }, ([result]) => {
          if (!result) return
          onSelect({
            lng: result.geometry.location.lng(),
            lat: result.geometry.location.lat()
          })
        })
      }
    },
    [onSelect]
  )

  return (
    <div>
      <AutocompleteField
        onChange={handleChange}
        onSelect={handlerSelect}
        ref={inputRef}
        suggestions={suggestions}
        value={value}
      />
    </div>
  )
}
