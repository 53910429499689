import styled, { css } from 'styled-components'
import { rgba } from 'polished'

import { StyledTabProps, TabsPanelProps, TJustifyContentDict } from './types'

// Styled tab
export const StyledTab = styled.button<StyledTabProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  border-bottom: 3px solid transparent;
  background: none;
  padding: 6px 8px;
  outline: none;
  cursor: pointer;
  font-size: ${(p) => p.fontSize};
  overflow: auto;

  ${({ active, color, padding, indicatorColor, inativeColor}) => {
    const resultColor = active ? 
      rgba(color, 1) : 
      (inativeColor ? inativeColor : rgba(color, 0.5));
    const resultIndicatorColor = indicatorColor ?
      (active ? rgba(indicatorColor, 1) : (inativeColor ? inativeColor : rgba(indicatorColor, 0.5))) :
      resultColor;
    const borderBottom = active ? `3px solid ${resultIndicatorColor}` : null

    return css`
      color: ${resultColor};
      border-bottom: ${borderBottom};
      padding: ${padding ? padding : '6px 8px'};
    `
  }};
`

// Styled tabs container
const justifyContentDict: TJustifyContentDict = {
  start: 'flex-start',
  end: 'flex-end',
  center: 'center'
}

export const StyledTabsPanel = styled.nav<TabsPanelProps>`
  display: flex;
  align-items: center;
  justify-content: ${(p) => justifyContentDict[p.justify]};
  padding: 12px;

  ${({ gap }) => {
    return css`
      gap: ${gap ? gap : 0};
    `
  }};
`
