export const COLOR_BLUE = '#1D93F3'
export const COLOR_LIGHT_BLUE = '#EAF6FF'
export const COLOR_WHITE = '#FFFFFF'
export const COLOR_BLACK = '#000'
export const COLOR_GRAY = '#303640'
export const COLOR_DARKER_GRAY = '#4f545e'
export const COLOR_DARKER_GRAY_1 = '#3C3F49'
export const COLOR_LIGHT_GRAY = '#DADADA'
export const COLOR_DARK_GRAY = '#8d9091'
export const COLOR_DARKEST_GRAY = '#333'
export const COLOR_WHITE_SMOKE = '#F5F5F5'
