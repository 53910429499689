import React, { FC } from 'react'
import { SvgIconProps } from '@material-ui/core/SvgIcon'
import { SvgIcon } from '@material-ui/core'

export const GroupsIcon: FC<SvgIconProps> = (props) => (
  <SvgIcon {...props}>
    <g id='Layer_1' />
    <g id='Layer_2'>
      <g>
        <path
          d='M21.8,5.1H5.1c-1,0-1.8,0.8-1.8,1.8V18c0,1,0.8,1.8,1.8,1.8h4.6v1.9h7.4v-1.9h4.7c1,0,1.8-0.8,1.8-1.8V6.9C23.7,5.9,22.8,5.1,21.8,5.1z M21.8,18H5.1V6.9h16.7V18z'
        />
        <path d='M2.2,5.2c0-0.7,0.5-1.2,1.2-1.2h17.4c0-1-0.8-1.8-1.8-1.8H2.2c-1,0-1.8,0.8-1.8,1.8v11.1c0,1,0.8,1.8,1.8,1.8h0V5.2z' />
      </g>
    </g>
  </SvgIcon>
)
