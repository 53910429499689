import {
  requiredString,
  email,
  firstName,
  lastName,
  password,
  confirmPassword,
  playlistName,
  playlistDisplay,
  playlistLabels,
  defaultSlideDuration,
  displayGroupName,
  resolution,
  defaultPlaylist,
  requiredMessage,
  phoneNumber
} from 'src/helpers/schema'
import * as yup from 'yup'

export const signInSchema = yup.object().shape({
  email,
  password: requiredString()
})

export const signUpSchema = yup.object().shape({
  email,
  password,
  firstName,
  lastName,
  terms: yup.boolean().oneOf([true], 'checkbox must be checked')
})

export const signUpProviderSchema = signUpSchema.shape({
  phoneNumber,
  companyName: yup.string()
    .ensure()
    .required(requiredMessage),
  password: yup
    .string()
    .matches(/^[a-zA-Z0-9!@#$&()\\-`.+,/\"]*$/, 'can only contain numbers, letters and symbols')
    .min(8, 'must be at least 8 characters')
    .max(30, 'must be at most 30 characters')
})

export const forgotPasswordSchema = yup.object().shape({
  email
})

export const resetPasswordSchema = yup.object().shape({
  password,
  confirmPassword
})

export const passwordInSchema = yup.object().shape({
  password: requiredString()
})

export const pinInSchema = yup.object().shape({
  pin: requiredString()
})

export const newPlaylistSchema = yup.object().shape({
  playlistName,
  playlistDisplay,
  playlistLabels,
  defaultSlideDuration
})

export const newDisplayGroupSchema = yup.object().shape({
  displayGroupName,
  resolution,
  defaultPlaylist
})
