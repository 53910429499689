import React, { useState, useCallback } from 'react'
import { Button, Box, CircularProgress, Theme } from '@material-ui/core'
import { useTheme } from '@material-ui/styles'
import { ButtonProps } from '@material-ui/core/Button'

const MAXIMUM_AGE = 86400000 // 1 day

interface Coords {
  lat: number
  lng: number
}

interface TProps extends ButtonProps {
  onLocate(coords: Coords): void
  onError?(): void
}

export const LocateMeButton: React.FC<TProps> = ({
  onLocate,
  onError,
  onClick,
  ...buttonProps
}) => {
  const [loading, setLoading] = useState(false)
  const theme: Theme = useTheme()
  const handleLocateMe = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      setLoading(true)
      if (onClick) onClick(e)

      navigator.geolocation.getCurrentPosition(
        (position: Position) => {
          const positionCoords: Coords = {
            lat: position.coords.latitude,
            lng: position.coords.longitude
          }
          setLoading(false)
          onLocate(positionCoords)
        },
        () => {
          setLoading(false)
          onError && onError()
        },
        { maximumAge: MAXIMUM_AGE, enableHighAccuracy: false }
      )
    },
    [onClick, onError, onLocate]
  )

  return (
    <Button
      {...buttonProps}
      onClick={handleLocateMe}
    >
      <>
        Locate me
        {loading && (
          <Box
            alignItems='center'
            display='flex'
            height='100%'
            position='absolute'
            right={6}
            top={0}
          >
            <CircularProgress size={theme.spacing(2.5)} />
          </Box>
        )}
      </>
    </Button>
  )
}
