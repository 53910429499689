import gql from 'graphql-tag-ts'
import { CommAccountInfoFragment } from '../../types/CommAccountInfoFragment'
import { CommUserInfoFragment } from '../../types/CommUserInfoFragment'
import { CommDisplayInfoFragment } from '../../types/CommDisplayInfoFragment'

export const COMM_ACCOUNT_INFO_F = gql<CommAccountInfoFragment>`
  fragment CommAccountInfoFragment on Account {
    id
    name
    maxUploadSize
    maxStorage
    optInAds
    paypalEmail
  }
`

export const COMM_USER_INFO_F = gql<CommUserInfoFragment>`
  fragment CommUserInfoFragment on User {
    id
    firstName
    lastName
    email
    domain
    role
    isLegacy
    hasTwoFactorAuthenticationEnabled
    isSuperAdmin
    hasPassword
    featuresOptInAds {
      optInAds
    }
    account {
      ...CommAccountInfoFragment
      providerUser {
        id
        email    
      }  
    }
    features {
      id
      accountId
      name
      description
      status
      updated
      notify
      rules
    }
  }
  ${COMM_ACCOUNT_INFO_F}
`

export const COMM_DISPLAY_F = gql<CommDisplayInfoFragment>`
  fragment CommDisplayInfoFragment on Display {
    id
    name
    pixelsH
    pixelsW
    physicalWidth
    physicalHeight
    venueType
    locationType
    latitude
    longitude
    brightness
    power
    playerId
    playerVersion
    displayType
    timezone {
      id
      jodaId
    }
    color {
      id
    }
    changed
    imageUrl
    emailNotificationsEnabled
    playerConnectionDate
    blacklistAds
    defaultPresentation {
      id
      name
    }
  }
`
