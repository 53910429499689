import React, { FC, useEffect, useState } from 'react'
import { Paper, Typography, Theme, Button, Dialog } from '@material-ui/core'
import { Link } from 'react-router-dom'
import { makeStyles, createStyles, useTheme, withStyles } from '@material-ui/styles'
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined'
import { useLazyQuery, useMutation, useQuery } from 'react-apollo'
import {
  PW_GET_LATEST_CELLULAR_SUBSCRIPTION,
  PW_GET_SETUP_INTENT,
  PW_GET_STRIPE_CUSTOMER
} from '../../apollo/api/paywall/queries'
import {
  UPDATE_DEFAULT_PAYMENT_METHOD,
  ACTIVATE_SUBSCRIPTION
} from '../../apollo/api/paywall/mutations'
import { HtmlTooltip } from '../atoms/html-tooltip'
import { BillingCycle, PlanEnums, StripeStatusEnums } from '../../screenhub-shared/enum/stripe.enum'
import { useUserInfo } from 'src/services'
import { Elements } from '@stripe/react-stripe-js'
import { CardForm } from './card-form'
import { loadStripe } from '@stripe/stripe-js'
import moment from 'moment-timezone'
import { PriceTableStep, ResultDialogButtonBox, ResultDialogTitle } from './price-table'
import { isHideFeature } from '../../helpers/white-label-helper'
import { WhiteLabelFeaturesEnum } from '../../helpers/white-label.enum'


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      // ...theme.typography.button,
      // backgroundColor: theme.palette.background.paper,
      // padding: theme.spacing(1),
    }
  })
)

const usePaperClasses = makeStyles<Theme>({
  root: {
    // XXX: shall combine whole color rendered with theme
    backgroundColor: '#DEEFFC',
    padding: '0 30px',
    width: '100%',
    minHeight: 32,
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
    zIndex: 5,
    justifyContent: 'center',
    whiteSpace: 'nowrap',
    boxShadow: 'none'
  }
})

const useInnerPaperClasses = makeStyles<Theme>({
  root: {
    width: '350px',
    padding: '24px',
    backgroundColor: 'white'
  }
})

export const CellularSubscriptionBanner: FC = () => {
  const theme = useTheme()
  const classes = useStyles()
  const paperClasses = usePaperClasses(theme)
  const innerPaperClasses = useInnerPaperClasses(theme)
  const {
    me: {
      account: { id: accountId }
    }
  } = useUserInfo()

  const [updateDefaultPaymentMethod] = useMutation(UPDATE_DEFAULT_PAYMENT_METHOD)
  const [activateSubscription] = useMutation(ACTIVATE_SUBSCRIPTION)
  const [progress, setProgress] = useState<PriceTableStep>(PriceTableStep.CONFIRM_PAYMENT)
  const { data } = useQuery(PW_GET_LATEST_CELLULAR_SUBSCRIPTION, {
    variables: {
      accountId
    }
  })

  const subscription = data?.getLatestCellularSubscription || {}

  const { data: stripeCustomerData } = useQuery(PW_GET_STRIPE_CUSTOMER, {
    skip: !subscription?.stripeCustomerId,
    variables: { stripeCustomerId: subscription.stripeCustomerId }
  })

  const { getStripeCustomer } = stripeCustomerData || {}

  const [getSetupIntent, { data: setupIntentData }] = useLazyQuery(PW_GET_SETUP_INTENT, {
    fetchPolicy: 'network-only'
  })

  const [clientSecret, setClientSecret] = useState(null)
  const [showPaymentDialog, setShowPaymentDialog] = useState(false)
  const [stripePromise, setStripePromise] = React.useState(null)

  const handlePaymentDialogClose = () => {
    setShowPaymentDialog(false)
  }

  const handleCheckoutClick = async () => {
    if (getStripeCustomer?.invoice_settings?.default_payment_method) {
      // activate subscription now
      await activateSubscription({
        variables: {
          accountId,
          stripeSubscriptionId: subscription.stripeSubscriptionId,
          category: 'CELLULAR'
        }
      })
      setProgress(PriceTableStep.SUCCEEDED)
      setShowPaymentDialog(true)
    } else {
      setStripePromise(loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY))
      getSetupIntent({ variables: { accountId } })
      setProgress(PriceTableStep.CONFIRM_PAYMENT)
      setShowPaymentDialog(true)
    }
  }

  const daysToExpiry = moment.unix(subscription.trialEnd).diff(moment(), 'days')

  const BannerWording = () => {
    if (subscription.status === StripeStatusEnums.TRIALING && daysToExpiry > 0) {
      return (
        <>
          <Typography className={classes.root}>
            Your cellular network for {subscription.qty} display(s) will expire in {daysToExpiry}{' '}
            day(s).
          </Typography>
          &nbsp;
          <Typography>Please</Typography>
          <Button color="primary" component="div" onClick={handleCheckoutClick}>
            CLICK HERE
          </Button>
          <Typography>to renew your plans to avoid any service disruption.</Typography>
        </>
      )
    } else if (subscription.status !== StripeStatusEnums.ACTIVE) {
      return (
        <>
          <Typography className={classes.root}>
            Your cellular network for {subscription.qty} display(s) has expired!
          </Typography>
          <Button color="primary" component="div" onClick={handleCheckoutClick}>
            CLICK HERE
          </Button>
          <Typography>to renew your plans to avoid any service disruption.</Typography>
        </>
      )
    }
  }

  const tooltipWording = () => {
    return 'For more information on cancelling your subscription, click the button below to manage your subscription.'
  }

  useEffect(() => {
    if (setupIntentData) {
      const { getCardSetupIntentByAccountId } = setupIntentData
      setClientSecret(getCardSetupIntentByAccountId.client_secret)
    }
  }, [setupIntentData])

  const isHidePaywall = isHideFeature(WhiteLabelFeaturesEnum.PAYWALL)

  if (isHidePaywall) {
    return <></>
  }

  // early return before gather all data
  if (!subscription || !getStripeCustomer) {
    return <></>
  }

  return subscription?.status !== StripeStatusEnums.ACTIVE &&
    subscription?.status !== StripeStatusEnums.CANCELED &&
    // only display expiry info within 10 days
    daysToExpiry <= 30 &&
    daysToExpiry >= -10 ? (
    <Paper
      classes={paperClasses}
      elevation={1}
      square
      style={{
        backgroundColor: subscription.status === StripeStatusEnums.TRIALING ? '#F8EBC8' : '#F9DEDC'
      }}
    >
      <BannerWording />
      <HtmlTooltip
        title={
          <React.Fragment>
            <Typography style={{ fontSize: 14, fontWeight: 400 }}>{tooltipWording()}</Typography>
            <Typography style={{ display: 'flex', alignItems: 'center', justifyContent: 'right' }}>
              <Link to="/billing">
                <Button onClick={handleCheckoutClick} style={{ color: '#FFFFFF' }}>
                  MANAGE
                </Button>
              </Link>
            </Typography>
          </React.Fragment>
        }
        interactive={true}
        leaveDelay={100}
        placement="top"
      >
        <InfoOutlinedIcon />
      </HtmlTooltip>
      <Dialog open={showPaymentDialog} onClose={handlePaymentDialogClose}>
        <Paper classes={innerPaperClasses}>
          {stripePromise && clientSecret && progress === PriceTableStep.CONFIRM_PAYMENT && (
            <Elements
              stripe={stripePromise}
              options={{ clientSecret, appearance: { theme: 'stripe' } }}
            >
              <CardForm
                billingCycle={BillingCycle.YEAR}
                chosenPlan={PlanEnums.CELLULAR_PLAN}
                clientSecret={clientSecret}
                originalPlan={PlanEnums.CELLULAR_PLAN}
                quantity={subscription.qty}
                showBillingCycleInput={true}
                showPrice={true}
                showQuantityInput={false}
                fixedBillingCycle={true}
                succeededCallback={async ({ paymentMethodId }) => {
                  try {
                    const { category, stripeSubscriptionId } = subscription
                    await updateDefaultPaymentMethod({
                      variables: { accountId, paymentMethodId }
                    })
                    await activateSubscription({
                      variables: { accountId, stripeSubscriptionId, category }
                    })
                    setProgress(PriceTableStep.SUCCEEDED)
                  } catch (e) {
                    console.log(
                      'Failed to complete default payment method setup and activate subscription',
                      e
                    )
                  }
                }}
              />
            </Elements>
          )}
          {progress === PriceTableStep.SUCCEEDED && (
            <>
              <ResultDialogTitle variant="h6">
                Your subscription setup is complete! 🎉
              </ResultDialogTitle>
              <ResultDialogButtonBox>
                <Button variant="contained" color="primary" onClick={handlePaymentDialogClose}>
                  Ok, got it
                </Button>
              </ResultDialogButtonBox>
            </>
          )}
        </Paper>
      </Dialog>
    </Paper>
  ) : (
    <></>
  )
}
