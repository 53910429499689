import React, { useContext, FC } from 'react'
import PropTypes from 'prop-types'
import { Theme } from '@material-ui/core'
import { withStyles, withTheme, createStyles, styled } from '@material-ui/styles'
import { compose } from 'ramda'
import AppBar from '@material-ui/core/AppBar'
import IconButton from '@material-ui/core/IconButton'
import Toolbar from '@material-ui/core/Toolbar'
import { LayoutContext } from '../../templates/default-layout'
import BetaLogo from '../../assets/logo.svg'
import { useLocation } from 'react-router'
import { Link } from 'react-router-dom'
import { getWhiteLabelColor } from 'src/helpers/white-label-helper'

const styles = ({ transitions }) =>
  createStyles({
    root: {
      transition: transitions.create(['margin', 'width'], {
        easing: transitions.easing.sharp,
        duration: transitions.duration.leavingScreen
      }),
      '@media print': {
        display: 'none !important'
      }
    },
    menuButton: {
      marginLeft: -8,
      marginRight: 8,
      color: '#fff'
    }
  })

const createGet = (
  { clipped, navVariant, collapsible, collapsed, open, squeezed, navAnchor },
  normal,
  shrink,
  pushed,
  unsqueeze
) => () => {
  if (clipped || navAnchor !== 'left') return normal

  if (navVariant === 'persistent' && open) {
    // open is effect only when
    // navVariant === 'persistent' ||
    // navVariant === 'temporary'
    if (squeezed) {
      return pushed
    }
    return unsqueeze
  }

  if (navVariant === 'permanent') {
    if (collapsible) {
      if (collapsed) return shrink
      return pushed
    }
    return pushed
  }

  return normal
}

interface HeaderProps {
  className?: string
  classes: any
  component?: any
  style?: any
  position?: string
  theme: any
  children: any
  toolbarProps?: any
  menuIcon?: any
}

const LayoutHeaderView: FC<HeaderProps> = ({
  className,
  component: Component,
  classes,
  menuIcon,
  style,
  theme,
  children,
  toolbarProps,
  ...props
}) => {
  const ctx = useContext(LayoutContext)
  const { pathname } = useLocation()

  const {
    clipped,
    collapsedWidth,
    navWidth,
    navVariant,
    collapsible,
    collapsed,
    headerPosition,
    open,
    setOpen,
    setCollapse
  } = ctx

  const getWidth = createGet(
    ctx,
    '100%',
    `calc(100% - ${pathname.endsWith('/lobby') ? 0 : collapsedWidth}px)`,
    `calc(100% - ${pathname.endsWith('/lobby') ? 0 : navWidth}px)`,
    '100%'
  )

  const getMargin = createGet(ctx, 0, collapsedWidth, navWidth, navWidth)

  const isMenuHidden = collapsible && collapsed

  const shouldRenderMenu = !!menuIcon && (isMenuHidden || navVariant !== 'permanent')

  const LogoImage = styled('img')<Theme>({
    objectFit: 'contain',
    width: 150
  })
  return (
    <AppBar
      color="default"
      elevation={0}
      {...props}
      className={`${className} ${classes.root}`}
      position={headerPosition}
      style={{
        ...style,
        zIndex: clipped ? theme.zIndex.drawer + 1 : theme.zIndex.appBar,
        width: getWidth(),
        marginLeft: getMargin(),
        backgroundColor: getWhiteLabelColor()
      }}
    >
      <Toolbar {...toolbarProps}>
        {pathname.endsWith('/lobby') && (
          <Link
            to="/"
            style={{
              display: 'flex'
            }}
          >
            <LogoImage src={BetaLogo} />
          </Link>
        )}
        {shouldRenderMenu && collapsible && collapsed && (
          <IconButton className={classes.menuButton} onClick={setCollapse}>
            {menuIcon.expand}
          </IconButton>
        )}
        {shouldRenderMenu && !collapsible && (
          <IconButton className={classes.menuButton} onClick={setOpen}>
            {open ? menuIcon.hide : menuIcon.show || menuIcon.show}
          </IconButton>
        )}
        {typeof children === 'function' ? children(ctx) : children}
      </Toolbar>
    </AppBar>
  )
}

LayoutHeaderView.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.shape({}).isRequired,
  component: PropTypes.elementType,
  style: PropTypes.shape({}),
  position: PropTypes.string,
  theme: PropTypes.shape({}).isRequired,
  children: PropTypes.oneOfType([PropTypes.func, PropTypes.node]).isRequired,
  toolbarProps: PropTypes.shape({}),
  menuIcon: PropTypes.shape({
    show: PropTypes.node.isRequired,
    hide: PropTypes.node,
    expand: PropTypes.node
  })
}
LayoutHeaderView.defaultProps = {
  className: '',
  component: 'div',
  style: {},
  position: 'relative',
  toolbarProps: {},
  menuIcon: null
}

const enhance = compose(withTheme, withStyles(styles, { name: 'MuiHeader' }))

export const LayoutHeader = enhance(LayoutHeaderView)
