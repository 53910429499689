import React, { FC } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';

const useStyles = makeStyles((theme) => ({
  container: (props: {aspectRatio?: number}) => ({
    display: 'flex',
    width: (props.aspectRatio >= 1) ? '100px' : '50px',
    height: (props.aspectRatio > 1) ? '50px' : '100px',
    flexDirection: 'column',
    alignItems: 'flex-start',
  }),
  upperContainer: (props: {aspectRatio?: number}) => ({
    display: 'flex',
    width: (props.aspectRatio >= 1) ? '100px' : '50px',
    height: (props.aspectRatio > 1) ? '25px' : '50px',
    alignItems: 'flex-start',
  }),
  lower50: (props: {aspectRatio?: number}) => ({
    height: (props.aspectRatio > 1) ? '25px' : '50px',
    flexShrink: 0,
    alignSelf: 'stretch',
    border: '1px solid var(--Sytetm-Divider, currentColor)',
    
  }),
  upperLeft25: (props: {aspectRatio?: number}) => ({
    display: 'flex',
    alignItems: 'flex-start',
    flex: '1 0 0',
    alignSelf: 'stretch',
    border: '1px solid var(--Sytetm-Divider, currentColor)',
    borderRight: 'none',
    borderBottom: 'none',
  }),
  upperRight25: (props: {aspectRatio?: number}) => ({
    display: 'flex',
    alignItems: 'flex-start',
    flex: '1 0 0',
    alignSelf: 'stretch',
    border: '1px solid var(--Sytetm-Divider, currentColor)',
    borderBottom: 'none',
  }),
  lower50Selected: (props: {aspectRatio?: number}) => ({
    border: '2px solid var(--Sytetm-Divider, #1d93f3)',
  }),
  upperLeft25Selected: (props: {aspectRatio?: number}) => ({
    border: '2px solid var(--Sytetm-Divider, #1d93f3)',
    borderRight: 'none',
    borderBottom: 'none',
  }),
  upperRight25Selected: (props: {aspectRatio?: number}) => ({
    border: '2px solid var(--Sytetm-Divider, #1d93f3)',
    borderBottom: 'none',
  }),
}));

export const Layout25To25To50Icon: FC<{isSelected: boolean; aspectRatio?: number}> = ({
  isSelected,
  aspectRatio,
}) => {
  const {
    container,
    upperContainer,
    lower50,
    upperLeft25,
    upperRight25,
    lower50Selected,
    upperLeft25Selected,
    upperRight25Selected,
  } = useStyles({ aspectRatio });

  const lower50ClassName = classNames(lower50, { [lower50Selected]: isSelected });
  const upperLeft25ClassName = classNames(upperLeft25, { [upperLeft25Selected]: isSelected });
  const upperRight25ClassName = classNames(upperRight25, { [upperRight25Selected]: isSelected });

  return (
    <div className={container}>
      <div className={upperContainer}>
        <div className={upperLeft25ClassName} />
        <div className={upperRight25ClassName} />
      </div>
      <div className={lower50ClassName} />
    </div>
  );
};
