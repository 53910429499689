import React from 'react'
import { Box, Button, Theme } from '@material-ui/core'
import SearchIcon from '@material-ui/icons/Search'
import InputBase from '@material-ui/core/InputBase'
import { makeStyles, fade } from '@material-ui/core/styles'

const useStyles = makeStyles<Theme, { needFullWidth: boolean }>(theme => ({
  button: {
    textTransform: 'capitalize'
  },
  searchContainer: {
    display: 'flex',
    justifyContent: 'flex-end'
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.25)
    },
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(1),
      width: ({ needFullWidth }) => (needFullWidth ? '100%' : 'auto')
    }
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: '#626b70'
  },
  inputRoot: {
    color: 'inherit'
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    border: '1px solid #ddd',
    borderRadius: 3,
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.25)
    },
    '&:focus': {
      border: '1px solid #2196f3'
    },
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: ({ needFullWidth }) => (needFullWidth ? '100%' : '12ch'),
      '&:focus': {
        width: ({ needFullWidth }) => (needFullWidth ? '100%' : '20ch')
      }
    }
  }
}))

export const Search = ({ onSearch = null, innerRef, onBlur = null, needFullWidth = false }) => {
  const classes = useStyles({ needFullWidth })

  return (
    <Box>
      <div className={classes.searchContainer}>
        <div className={classes.search}>
          <div className={classes.searchIcon}>
            <SearchIcon />
          </div>
          <InputBase
            fullWidth={needFullWidth}
            classes={{
              root: classes.inputRoot,
              input: classes.inputInput
            }}
            inputProps={{ 'aria-label': 'search' }}
            inputRef={innerRef}
            onChange={onSearch}
            onBlur={onBlur}
            placeholder="Search"
            type="search"
          />
        </div>
      </div>
    </Box>
  )
}
