import React, { FC } from 'react'
import { Fab, Theme } from '@material-ui/core'
import { Add } from '@material-ui/icons'
import { useTheme, makeStyles } from '@material-ui/styles'

const useFabClasses = makeStyles<Theme>({
  root: {
    backgroundColor: '#303540',
    '&:hover': {
      backgroundColor: '#303540'
    }
  }
})

const useIconClasses = makeStyles<Theme>((theme) => ({
  root: {
    fill: theme.palette.common.white
  }
}))

interface TProps {
  onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
  component?: string
}

export const AddFab: FC<TProps> = ({ onClick, component }) => {
  const theme = useTheme()
  const fabClasses = useFabClasses(theme)
  const iconClasses = useIconClasses(theme)

  return (
    <Fab
      aria-label='Add'
      classes={fabClasses}
      component={component as any}
      onClick={onClick}
    >
      <Add classes={iconClasses} />
    </Fab>
  )
}
