import React, { FC } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';

const useStyles = makeStyles((theme) => ({
  container: (props: {aspectRatio?: number}) => ({
    display: 'flex',
    width: (props.aspectRatio >= 1) ? '100px' : '50px',
    height: (props.aspectRatio > 1) ? '50px' : '100px',
    alignItems: 'flex-start',
  }),
  columnContainer: (props: {aspectRatio?: number}) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
  }),
  right500: (props: {aspectRatio?: number}) => ({
    display: 'flex',
    alignItems: 'flex-start',
    width: (props.aspectRatio >= 1) ? `${100 * (1/2)}px` : `${50 * (1/2)}px`,
    alignSelf: 'stretch',
    border: '1px solid var(--Sytetm-Divider, currentColor)',
    borderLeft: 'none',
  }),
  upperLeft166: (props: {aspectRatio?: number}) => ({
    display: 'flex',
    alignItems: 'flex-start',
    width: (props.aspectRatio >= 1) ? `${100 * (1/2)}px` : `${50 * (1/2)}px`,
    height: (props.aspectRatio > 1) ? `${50 * (1/3)}px` : `${100 * (1/3)}px`,
    alignSelf: 'stretch',
    border: '1px solid var(--Sytetm-Divider, currentColor)',
    borderBottom: 'none',
  }),
  middleLeft166: (props: {aspectRatio?: number}) => ({
    display: 'flex',
    alignItems: 'flex-start',
    width: (props.aspectRatio >= 1) ? `${100 * (1/2)}px` : `${50 * (1/2)}px`,
    height: (props.aspectRatio > 1) ? `${50 * (1/3)}px` : `${100 * (1/3)}px`,
    alignSelf: 'stretch',
    border: '1px solid var(--Sytetm-Divider, currentColor)',
    borderBottom: 'none',
  }),
  lowerLeft166: (props: {aspectRatio?: number}) => ({
    display: 'flex',
    alignItems: 'flex-start',
    height: (props.aspectRatio > 1) ? `${50 * (1/3)}px` : `${100 * (1/3)}px`,
    alignSelf: 'stretch',
    border: '1px solid var(--Sytetm-Divider, currentColor)',
  }),
  right500Selected: (props: {aspectRatio?: number}) => ({
    border: '2px solid var(--Sytetm-Divider, #1d93f3)',
    borderLeft: 'none',
  }),
  upperLeft166Selected: (props: {aspectRatio?: number}) => ({
    border: '2px solid var(--Sytetm-Divider, #1d93f3)',
    borderBottom: 'none',
  }),
  middleLeft166Selected: (props: {aspectRatio?: number}) => ({
    border: '2px solid var(--Sytetm-Divider, #1d93f3)',
    borderBottom: 'none',
  }),
  lowerLeft166Selected: (props: {aspectRatio?: number}) => ({
    border: '2px solid var(--Sytetm-Divider, #1d93f3)',
  }),
}));

export const Layout166To500To166To166Icon: FC<{isSelected: boolean; aspectRatio?: number}> = ({
  isSelected,
  aspectRatio,
}) => {
  const {
    container,
    columnContainer,
    right500,
    upperLeft166,
    middleLeft166,
    lowerLeft166,
    right500Selected,
    upperLeft166Selected,
    middleLeft166Selected,
    lowerLeft166Selected,
  } = useStyles({ aspectRatio });

  const upperLeft166ClassName = classNames(upperLeft166, { [upperLeft166Selected]: isSelected });
  const middleLeft166ClassName = classNames(middleLeft166, { [middleLeft166Selected]: isSelected });
  const lowerLeft166ClassName = classNames(lowerLeft166, { [lowerLeft166Selected]: isSelected });
  const right500ClassName = classNames(right500, { [right500Selected]: isSelected });

  return (
    <div className={container}>
        <div className={columnContainer}>
            <div className={upperLeft166ClassName} />
            <div className={middleLeft166ClassName} />
            <div className={lowerLeft166ClassName} />
        </div>
        <div className={right500ClassName} />
    </div>
  );
};
