import gql from 'graphql-tag'

export const MYMEDIA_GET_IMAGES_Q_P = gql`
  query MyMediaGetImagesQueryPagination($workspaceId: UUID!, $limit: Int!, $page: Int!, $sort: String, $filters: mediaFilters){
    workspace(id: $workspaceId) {
      id
      medias(page: $page, limit: $limit, sort: $sort, filters: $filters) {
        page
        count
        values {
          id
          name
          size
          url
          type
          createdAt
          tags
        }
      }
    }
  }
`

export const MYMEDIA_GET_VIDEOS_Q_P = gql`
  query MyMediaGetVideosQueryPagination($workspaceId: UUID!, $limit: Int!, $page: Int!, $sort: String, $filters: mediaFilters){
    workspace(id: $workspaceId) {
      id
      medias(page: $page, limit: $limit, sort: $sort, filters: $filters) {
        page
        count
        values {
          id
          name
          size
          url
          type
          createdAt
          tags
          videoDuration
          videoThumb
        }
      }
    }
  }
`

export const MYMEDIA_GET_MEDIA_TAGS_Q = gql`
  query MyMediaGetMediaTagsQuery($workspaceId: UUID!) {
    workspace(id: $workspaceId) {
      id
      mediasTags 
    }
  }
`
