import React, { FC } from 'react'
import { SvgIconProps } from '@material-ui/core/SvgIcon'
import { SvgIcon } from '@material-ui/core'

export const TemperatureIcon: FC<SvgIconProps> = (props) => (

  <SvgIcon {...props}>
    <svg
      fill='none'
      height='24'
      viewBox='0 0 24 24'
      width='24'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M15 13V5C15 3.34 13.66 2 12 2C10.34 2 9 3.34 9 5V13C7.79 13.91 7 15.37 7 17C7 19.76 9.24 22 12 22C14.76 22 17 19.76 17 17C17 15.37 16.21 13.91 15 13ZM11 5C11 4.45 11.45 4 12 4C12.55 4 13 4.45 13 5H12V6H13V8H12V9H13V11H11V5Z'
        fill='#DADADA'
      />
    </svg>

  </SvgIcon>
)
