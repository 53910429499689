import {
  WhiteLabelHideFeatureMap,
  WhiteLabelFeaturesEnum,
  SUNSHINE_WEBSITE,
  WhiteLabelLogoMap,
  WhiteLabelTitleMap,
  WhiteLabelColorMap
} from './white-label.enum'

export function getWhiteLabelWebsite () {
  if (SUNSHINE_WEBSITE && window.location.href.includes(SUNSHINE_WEBSITE)) {
    return SUNSHINE_WEBSITE
  }

  // No customization
  return null
}

export function isSunshineWebsite () {
  if (SUNSHINE_WEBSITE && window.location.href.includes(SUNSHINE_WEBSITE)) {
    return true
  }

  return false
}

export function getWhiteLabelLogo () {
  const website = getWhiteLabelWebsite()

  if (!website) {
    // No customization
    return WhiteLabelLogoMap.get('DEFAULT')
  }

  return WhiteLabelLogoMap.get(website)
}

export function getWhiteLabelTitle () {
  const website = getWhiteLabelWebsite()

  if (!website) {
    // No customization
    return WhiteLabelTitleMap.get('DEFAULT')
  }

  return WhiteLabelTitleMap.get(website)
}

export function getWhiteLabelColor () {
  const website = getWhiteLabelWebsite()

  if (!website) {
    // No customization
    return WhiteLabelColorMap.get('DEFAULT')
  }

  return WhiteLabelColorMap.get(website)
}

export function isWhiteLabelLogo () {
  const website = getWhiteLabelWebsite()

  if (!website) {
    // No customization
    return false
  }

  return Boolean(WhiteLabelLogoMap.get(website))
}

export function isHideFeature (feature: WhiteLabelFeaturesEnum) {
  const website = getWhiteLabelWebsite()

  if (!website) {
    // No customization
    return false
  }

  const hidingFeatures = WhiteLabelHideFeatureMap.get(website)

  return hidingFeatures?.includes(feature)
}

// export enum WhiteLabelLogoEnum {
//   SUNSHINE = 'https://skylink.sunshine.us.com/Skylink/Styles/Images/logo-v2.png', // logo path
//   // SUNSHINE = 'https://v2.dev.screenhub.com/static/media/logo.4e5f274e.svg', // logo path
//   DEFAULT = 'https://v2.dev.screenhub.com/static/media/logo.4e5f274e.svg'
// }
//
// // TODO: Square logo to match nav-header.tsx line 28
//
// export enum WhiteLabelTitleEnum {
//   SUNSHINE = 'Sunshine',
//   DEFAULT = 'ScreenHub'
// }
//
// export enum WhiteLabelColorEnum {
//   SUNSHINE = '#000000',
//   DEFAULT = '#3B3F4A'
// }
//
export const WHITE_LABEL_SIGN_UP_LOGO_URL = {
  [process.env.REACT_APP_SUNSHINE_WEBSITE ??
  'smartsigncms.screenhub.com']: 'https://screenhub-whitelabel.s3.us-east-2.amazonaws.com/sunshine-logo.svg'
  // ['screenhub.com']: ''
}

export const WHITE_LABEL_CMS_LOGO_URL = {
  [process.env.REACT_APP_SUNSHINE_WEBSITE ??
  'smartsigncms.screenhub.com']: 'https://skylink.sunshine.us.com/Skylink/Styles/Images/logo-v2.png'
  // ['screenhub.com']:
  //   'https://screenhub-whitelabel.s3.us-east-2.amazonaws.com/screenhub-cms-logo.svg'
}

export const WHITE_LABEL_SMALL_LOGO_URL = {
  [process.env.REACT_APP_SUNSHINE_WEBSITE ??
  'smartsigncms.screenhub.com']: 'https://screenhub-whitelabel.s3.us-east-2.amazonaws.com/sunshine-small-logo.svg'
  // ['screenhub.com']: ''
}

export const WHITE_LABEL_NAV_COLOR = {
  [process.env.REACT_APP_SUNSHINE_WEBSITE ?? 'smartsigncms.screenhub.com']: '#000000',
  'screenhub.com': '#3B3F4A'
}

export function getWhiteLabelUrlItemFromDomain (
  url: string,
  domainMap: { [key: string]: string },
  defaultUrl = ''
): string {
  for (const domain in domainMap) {
    if (url.includes(domain)) {
      return domainMap[domain]
    }
  }
  return defaultUrl
}

export function checkWhiteLabelDomainMatch (url: string, domainMap: { [key: string]: string }) {
  for (const domain in domainMap) {
    if (url.includes(domain)) {
      return true
    }
  }
  return false
}
