import { Component, ReactNode } from 'react'

export interface TProps {
  children: (arg: {
    toggled: boolean
    onToggle: (arg?: boolean) => void
  }) => ReactNode
  defaultToggled?: boolean
  onToggle?: (arg?: boolean) => void
  onSetPreview?: (isPreview: boolean) => void
}

export interface TState {
  toggled: boolean
}

export type TOnToggleFunc = (arg: boolean) => void

export class Toggler extends Component<TProps, TState> {
  static defaultProps = {
    defaultToggled: false
  }

  readonly state = {
    toggled: this.props.defaultToggled
  }

  onToggle: TOnToggleFunc = (value) => {
    if (this.props.onSetPreview) {
      typeof value === 'boolean' ? this.props.onSetPreview(false) : this.props.onSetPreview(true)
    }

    this.setState(
      (previousState) => ({
        toggled: typeof value === 'boolean' ? value : !previousState.toggled
      }),
      () => {
        if (this.props.onToggle) {
          this.props.onToggle(this.state.toggled)
        }
      }
    )
  }

  render () {
    return this.props.children({
      toggled: this.state.toggled,
      onToggle: this.onToggle
    })
  }
}
