import gql from 'graphql-tag'

export const SC_ADD_NEW_AD_INTO_CAMPAIGN_SCHEDULE_M = gql`
  mutation ScAddNewAdIntoCampaignSchedule ($displayId: String!, $collectionId: String, $start: String!, $end: String!, $name: String!, $presentationId: String!, $percentageInSlot: PercentageInSlotEnum!) {
    addNewAdIntoCampaignSchedule(addNewAdIntoCampaignScheduleDTO:{displayId: $displayId, collectionId: $collectionId, start: $start, end: $end, name: $name, presentationId: $presentationId, percentageInSlot: $percentageInSlot}) {
      code
      success
      message
    }
  }
`

export const SC_ADD_AD_INTO_SLOT_M = gql`
mutation ScAddAdIntoSlot ($campaignId: String!, $slotIndex: Int!, $adIndex: Int!, $name: String!, $presentationId: UUID!, $percentageInSlot: PercentageInSlotEnum!) {
  addAdIntoSlot(addAdIntoSlotDTO:{campaignId: $campaignId, slotIndex: $slotIndex, adIndex: $adIndex, name: $name, presentationId: $presentationId, percentageInSlot: $percentageInSlot}) {
    code
    success
    message
  }
}
`

export const SC_UPDATE_AD_IN_SLOT_M = gql`
mutation ScUpdateAdInSlot ($adId: String!, $presentationId: String!, $name: String!) {
  updateAdInSlot(updateAdInSlotDTO:{adId: $adId, presentationId: $presentationId, name: $name}) {
    code
    success
    message
  }
}
`

export const SC_UPDATE_SLOT_INDEX_M = gql`
mutation ScUpdateSlotIndex ($campaignId: String!, $slotId: String!, $sourceSlotIndex: Int!, $destinationSlotIndex: Int!) {
  updateSlotIndex(updateSlotIndexDTO:{campaignId: $campaignId, slotId: $slotId, sourceSlotIndex: $sourceSlotIndex, destinationSlotIndex: $destinationSlotIndex}) {
    code
    success
    message
  }
}
`

export const SC_DELETE_AD_M = gql`
mutation ScDeleteAd ($adId: String!) {
  deleteAd(adId: $adId) {
    code
    success
    message
  }
}
`

export const SC_UPDATE_DISPLAY_TYPE_AND_COLLECTION_M = gql`
mutation ScUpdateDisplayTypeAndCollection ($displayId: String!, $schedulingType: SchedulingTypeEnum!, $slotNumber: Int, $slideDuration: Int, $hasConditionalContent: Boolean) {
  updateDisplayTypeAndCollection(updateDisplayTypeAndCollectionDTO:{displayId: $displayId, schedulingType: $schedulingType, slotNumber: $slotNumber, slideDuration: $slideDuration, hasConditionalContent: $hasConditionalContent}) {
    collectionId
    code
    success
    message
  }
}
`

export const SC_UPDATE_CAMPAIGN_SETTING_M = gql`
mutation ScUpdateCampaignSetting ($campaignId: String!, $slotNumber: Int!, $slideDuration: Int!, $manualOverride: Boolean!) {
  updateCampaignSetting(updateCampaignSettingDTO:{campaignId: $campaignId, slotNumber: $slotNumber, slideDuration: $slideDuration, manualOverride: $manualOverride}) {
    code
    success
    message
  }
}
`