import { useParams } from 'react-router'

import { getCurrentWorkspaceFromLocalStorage } from 'src/helpers/local-storage';

const useWorkspaceId = () => {
    const { workspaceId: workspaceIdOnUrl } = useParams<{ workspaceId?: string }>();
  
    let workspaceId;
      
    if (workspaceIdOnUrl) {
        workspaceId = workspaceIdOnUrl ? decodeURIComponent(workspaceIdOnUrl) : workspaceIdOnUrl;
    } else {
        const storedWorkspace = getCurrentWorkspaceFromLocalStorage();
  
        workspaceId = storedWorkspace?.id;
    }
  
    return workspaceId;
}

export default useWorkspaceId;
