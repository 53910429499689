import gql from 'graphql-tag'

export const CREATE_CONDITIONAL_CONTENT_M = gql`
  mutation ScMutationCreateConditionalContent($conditionalContentDTO: ConditionalContentDTO!) {
    createConditionalContent(conditionalContentDTO: $conditionalContentDTO) {
      code
      message
      success
      conditionalContent {
        id
      }
    }
  }
`

export const UPDATE_CONDITIONAL_CONTENT_M = gql`
  mutation ScMutationUpdateConditionalContent(
    $id: String!
    $updateConditionalContentDTO: UpdateConditionalContentDTO!
  ) {
    updateConditionalContent(id: $id, updateConditionalContentDTO: $updateConditionalContentDTO) {
      code
      message
      success
      conditionalContent {
        id
        name
        isActive
        workspaceId
        displayIds
        startDate
        endDate
        createdAt
      }
    }
  }
`

export const COPY_CONDITIONAL_CONTENT_M = gql`
  mutation ScMutationCopyConditionalContent($id: String!) {
    copyConditionalContent(id: $id) {
      code
      message
      success
    }
  }
`

export const DELETE_CONDITIONAL_CONTENT_M = gql`
  mutation ScMutationCopyConditionalContent($id: String!) {
    deleteConditionalContent(id: $id) {
      code
      message
      success
    }
  }
`
