import React, { FC } from 'react'
import { Paper, Theme, Typography } from '@material-ui/core'
import { blue } from '@material-ui/core/colors'
import { makeStyles, useTheme } from '@material-ui/styles'

interface TProps {
  pageTitle: string
  className?: string
  titleIndent?: number
}

const usePaperClasses = makeStyles<Theme>({
  root: {
    backgroundColor: blue[500],
    padding: '0 30px',
    width: '100%',
    minHeight: 64,
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
    zIndex: 5,
    justifyContent: 'space-between'
  }
})

const useTitleClasses = makeStyles<Theme, { titleIndent: number }>((theme) => ({
  root: ({ titleIndent }) => ({
    color: theme.palette.common.white,
    marginLeft: theme.spacing(titleIndent)
  })
}))

export const GenericAppBar: FC<TProps> = ({ pageTitle, className, children, titleIndent }) => {
  const theme = useTheme()
  const paperClasses = usePaperClasses(theme)
  const titleClasses = useTitleClasses({ titleIndent })

  return (
    <Paper
      classes={paperClasses}
      className={className}
      elevation={1}
      square
    >
      <Typography
        classes={titleClasses}
        variant='h5'
      >
        {pageTitle}
      </Typography>
      {children}
    </Paper>
  )
}

GenericAppBar.defaultProps = {
  titleIndent: 0
}
