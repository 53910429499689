import gql from 'graphql-tag'

export const SC_GET_CURRENT_WORKSPACE = gql`
  query ScGetCurrentWorkspace ($workspaceId: UUID!) {
    workspace(id: $workspaceId) {
      id
      name
      userPath
      workspacePath
      memberRole {
        id
        name
      }
    }
  }
`

export const SC_GET_WORKSPACES = gql`
  query ScGetWorkspaces {
    workspaces {
      id
      name
      userPath
      workspacePath
      memberRole {
        id
        name
      }
    }
  }
`
