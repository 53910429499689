import * as yup from 'yup'

export const requiredMessage = 'this field is required'
export const alphanumRegex = /^[a-zA-Z0-9 ]+$/
export const alphanumMessage = 'should only contain letters or numbers'

export const requiredPositiveNumber = yup
  .number()
  .min(0, 'cannot be negative')
  .required(requiredMessage)

export const requiredString = (message = requiredMessage) =>
  yup
    .string()
    .ensure()
    .trim()
    .required(message)

export const optionalEmail = yup
  .string()
  .ensure()
  .trim()
  .email('must be a valid email')

export const email = optionalEmail
  .required(requiredMessage)

export const emailTwo = yup
  .string()
  .ensure()
  .trim()
  .oneOf([yup.ref('email'), null], "emails don't match")

export const firstName = yup
  .string()
  .ensure()
  .required(requiredMessage)
  .matches(alphanumRegex, alphanumMessage)
  .max(30, 'must be at most 30 characters')

export const lastName = yup
  .string()
  .ensure()
  .required(requiredMessage)
  .matches(alphanumRegex, alphanumMessage)
  .max(30, 'must be at most 30 characters')

export const password = yup
  .string()
  .ensure()
  .required(requiredMessage)
  .matches(/^[a-zA-Z0-9!@#$&()\\-`.+,/\"]*$/, 'can only contain numbers, letters and symbols')
  .min(8, 'must be at least 8 characters')
  .max(30, 'must be at most 30 characters')

export const confirmPassword = yup
  .string()
  .ensure()
  .oneOf([yup.ref('password'), null], "passwords don't match")

export const newPassword = yup
  .string()
  .ensure()
  .required(requiredMessage)
  .matches(/^[a-zA-Z0-9!@#$&()\\-`.+,/\"]*$/, 'can only contain numbers, letters and symbols')
  .min(8, 'must be at least 8 characters')
  .max(30, 'must be at most 30 characters')

export const confirmNewPassword = yup
  .string()
  .ensure()
  .oneOf([yup.ref('newPassword'), null], "passwords don't match")

export const playlistName = yup.string().required(requiredMessage)

export const playlistLabels = yup
  .string()
  .nullable()
  .notRequired()

export const playlistDisplay = yup.string().required(requiredMessage)

export const defaultSlideDuration = yup
  .number()
  .required(requiredMessage)
  .integer()
  .min(1000, 'Cannot be null or negative')

export const workspaceRoleId = yup
  .number()
  .required(requiredMessage)
  .integer()

export const displayGroupName = yup.string().required(requiredMessage)

export const resolution = yup
.string()
.required(requiredMessage)

export const defaultPlaylist = yup
.string()
.notRequired()

export const phoneNumber = yup
  .string()
  .matches(/^\d{10}$/, 'Invalid phone number. Must be 10 digits');
