import gql from 'graphql-tag'

export const PL_UPLOAD_IMAGE_M = gql`
  mutation PlUploadImageMutation ($file: Upload, $url: String, $workspaceId: UUID!) {
    uploadImage(file: $file, url: $url, workspaceId: $workspaceId) {
      code
      success
      message
      media {
        id
        url
      }
    }
  }
`

export const PL_UPLOAD_VIDEO_M = gql`
  mutation PlUploadVideoMutation ($video: Upload!, $thumbnail: Upload!, $duration: Int!, $workspaceId: UUID!) {
    uploadVideo(video: $video, thumbnail: $thumbnail, duration: $duration, workspaceId: $workspaceId) {
      code
      success
      message
      media {
        id
        url
        videoThumb
        videoDuration
        size
      }
    }
  }
`

export const PL_DELETE_MEDIA_M = gql`
  mutation PlDeleteMediaMutation ($id: UUID!) {
    deleteMedia(id: $id) {
      success
      message
      id
    }
  }
`

export const PL_CREATE_PRESENTATION_M = gql`
  mutation PlCreatePresentationMutation ($workspaceId: UUID!, $input: PresentationInput!) {
    createPresentation(workspaceId: $workspaceId, input: $input) {
      code
      success
      message
      presentation {
        id
        name
        config
        slides
        workspaceId
        type
      }
    }
  }
`

export const PL_UPDATE_PRESENTATION_M = gql`
  mutation PlUpdatePresentationMutation ($id: UUID!, $input: PresentationInput!) {
    updatePresentation(id: $id, input: $input) {
      code
      success
      message
      presentation {
        id
        name
        config
        slides
        slidesOrder
        type
        isMediaProcessing
      }
    }
  }
`

export const PL_UPDATE_PRESENTATION_SETTINGS_M = gql`
  mutation PlUpdatePresentationMutation ($id: UUID!, $name: String!, $defaultSlideDuration: Int!, $tags: [String]) {
    updatePresentationSettings(id: $id, name: $name, defaultSlideDuration: $defaultSlideDuration, tags: $tags) {
      code
      success
      message
      presentation {
        id
        name
        defaultSlideDuration
        tags
        isMediaProcessing
      }
    }
  }
`

export const PL_DELETE_PRESENTATION_M = gql`
  mutation PlDeletePresentationMutation ($id: UUID!) {
    deletePresentation(id: $id) {
      code
      success
      message
      id
    }
  }
`
