import React from 'react'
import { withRouter, useLocation } from 'react-router-dom'
import { Box } from '@material-ui/core'
import { styled } from '@material-ui/styles'

const IFrameWrapper = styled(Box)(() => ({
  minHeight: '100%'
}))

const CustomerDashboardPageRaw = () => {
  const location = useLocation()
  console.log(location.pathname)

  return (
    <IFrameWrapper>
      <iframe
        height="100%"
        src={`${process.env.REACT_APP_SCREENHUB_CONTROL_URL}/cms-dashboard`}
        title="promo-page"
        width="100%"
      />
    </IFrameWrapper>
  )
}

export const CustomerDashboardPage = withRouter(CustomerDashboardPageRaw)
