import * as ApolloAPI from 'src/apollo/api'
import { useQuery } from 'react-apollo'
import { GetUsedStorageQuery } from 'src/generated/GetUsedStorageQuery'

export const useStorageData = () => {
  const { data, loading, error } = useQuery<GetUsedStorageQuery>(ApolloAPI.COMM_GET_USED_STORAGE_Q)

  if (error) {
    // TODO: handle error
  }

  return { storageData: data, loading }
}
