import gql from 'graphql-tag'

export const RENAME_MEDIA_M = gql`
  mutation RenameMedia ($id: UUID!, $newName: String!) {
    renameMedia(id: $id, newName: $newName) {
      code
      success
      message
      media {
        id
        name
      }
    }
  }
`

export const UPDATE_MEDIA_TAGS = gql`
  mutation updateTags ($id: UUID!, $tags: [String]!) {
    updateTags(id: $id, tags: $tags) {
      code
      success
      message
    }
  }
`
