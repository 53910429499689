import gql from 'graphql-tag'
import { AdAccountInfoFragment } from '../../types/AdAccountInfoFragment'
import { AdVenueInfoFragment } from '../../types/AdVenueInfoFragment'
import { AdFullDisplayAdstashInfoFragment } from '../../types/AdFullDisplayAdstashInfoFragment'

export const AD_ACCOUNT_INFO_F = gql<AdAccountInfoFragment>`
  fragment AdAccountInfoFragment on Account {
    id
    name
    maxUploadSize
    maxStorage
    optInAds
    paypalEmail
  }
`

export const AD_VENUE_F = gql<AdVenueInfoFragment>`
  fragment AdVenueInfoFragment on Venue {
    id
    name
    latitude
    longitude
    typeId
    timezone {
      id
    }
  }
`

export const AD_DISPLAY_ADSTASH_F = gql<AdFullDisplayAdstashInfoFragment>`
  fragment AdFullDisplayAdstashInfoFragment on DisplayAdstash {
    displayId
    display {
      id
      name
    }
    venue {
      id
      name
    }
    externalId
    impressions
    size
    dwellTime
    aspectRatio
    landscape
    audible
    adUnit
    loopLength
    placement
    isApproved
    changed
  }
`
