import gql from 'graphql-tag'
import * as Fragments from './fragments'
import {
  AdDeleteVenueMutation,
  AdDeleteVenueMutationVariables
} from '../../types/AdDeleteVenueMutation'
import {
  AdCreateVenueMutation,
  AdCreateVenueMutationVariables
} from '../../types/AdCreateVenueMutation'
import {
  AdUpdateVenueMutation,
  AdUpdateVenueMutationVariables
} from '../../types/AdUpdateVenueMutation'
import {
  AdCreateDisplayAdstashMutation,
  AdCreateDisplayAdstashMutationVariables
} from '../../types/AdCreateDisplayAdstashMutation'
import {
  AdUpdateDisplayAdstashMutation,
  AdUpdateDisplayAdstashMutationVariables
} from '../../types/AdUpdateDisplayAdstashMutation'
import {
  AdDeleteDisplayAdstashMutation,
  AdDeleteDisplayAdstashMutationVariables
} from '../../types/AdDeleteDisplayAdstashMutation'
import {
  AdDeleteDisplayPhotoMutation,
  AdDeleteDisplayPhotoMutationVariables
} from '../../types/AdDeleteDisplayPhotoMutation'
import {
  AdUploadDisplayPhotoMutation,
  AdUploadDisplayPhotoMutationVariables
} from '../../types/AdUploadDisplayPhotoMutation'
import {
  AdSetAccountOptInAdsMutation,
  AdSetAccountOptInAdsMutationVariables
} from '../../types/AdSetAccountOptInAdsMutation'

export const AD_DELETE_VENUE_M = gql<
  AdDeleteVenueMutation,
  AdDeleteVenueMutationVariables
>`
  mutation AdDeleteVenueMutation ($id: UUID!) {
    deleteVenue(id: $id) {
      id
      code
      success
      message
    }
  }
`

export const AD_CREATE_VENUE_M = gql<
  AdCreateVenueMutation,
  AdCreateVenueMutationVariables
>`
  mutation AdCreateVenueMutation ($input: VenueInput!) {
    createVenue(input: $input) {
      code
      success
      message
      venue {
        ...AdVenueInfoFragment
      }
    }
  }
  ${Fragments.AD_VENUE_F}
`

export const AD_UPDATE_VENUE_M = gql<
  AdUpdateVenueMutation,
  AdUpdateVenueMutationVariables
>`
  mutation AdUpdateVenueMutation ($input: VenueInput!, $id: UUID!) {
    updateVenue(input: $input, id: $id) {
      code
      success
      message
      venue {
        ...AdVenueInfoFragment
      }
    }
  }
  ${Fragments.AD_VENUE_F}
`

export const AD_CREATE_DISPLAY_ADSTASH_M = gql<
  AdCreateDisplayAdstashMutation,
  AdCreateDisplayAdstashMutationVariables
>`
  mutation AdCreateDisplayAdstashMutation ($input: DisplayAdstashCreateInput!) {
    createDisplayAdstash(input: $input) {
      code
      success
      message
      displayAdstash {
        ...AdFullDisplayAdstashInfoFragment
      }
    }
  }
  ${Fragments.AD_DISPLAY_ADSTASH_F}
`

export const AD_UPDATE_DISPLAY_ADSTASH_M = gql<
  AdUpdateDisplayAdstashMutation,
  AdUpdateDisplayAdstashMutationVariables
>`
  mutation AdUpdateDisplayAdstashMutation ($input: DisplayAdstashUpdateInput!, $displayId: UUID!) {
    updateDisplayAdstash(input: $input, displayId: $displayId) {
      code
      success
      message
      displayAdstash {
        ...AdFullDisplayAdstashInfoFragment
      }
    }
  }
  ${Fragments.AD_DISPLAY_ADSTASH_F}
`

export const AD_DELETE_DISPLAY_ADSTASH_M = gql<
  AdDeleteDisplayAdstashMutation,
  AdDeleteDisplayAdstashMutationVariables
>`
  mutation AdDeleteDisplayAdstashMutation ($displayId: UUID!) {
    deleteDisplayAdstash(displayId: $displayId) {
      code
      success
      message
      id
    }
  }
`

export const AD_DELETE_DISPLAY_PHOTO_M = gql<
  AdDeleteDisplayPhotoMutation,
  AdDeleteDisplayPhotoMutationVariables
>`
  mutation AdDeleteDisplayPhotoMutation ($id: UUID!) {
    deleteDisplayPhoto(id: $id) {
      code
      success
      message
      id
    }
  }
`

export const AD_UPLOAD_DISPLAY_PHOTO_M = gql<
  AdUploadDisplayPhotoMutation,
  AdUploadDisplayPhotoMutationVariables
>`
  mutation AdUploadDisplayPhotoMutation ($file: Upload!, $displayId: UUID!) {
    uploadDisplayPhoto(displayId: $displayId, file: $file) {
      code
      success
      message
      displayPhoto {
        id
        displayId
        imageUrl
        created
      }
    }
  }
`

export const AD_SET_ACCOUNT_OPT_IN_ADS_M = gql<
  AdSetAccountOptInAdsMutation,
  AdSetAccountOptInAdsMutationVariables
>`
  mutation AdSetAccountOptInAdsMutation ($accountId: UUID!) {
    setAccountOptInAds(accountId: $accountId) {
      code
      success
      message
      account {
        ...AdAccountInfoFragment
      }
    }
  }
  ${Fragments.AD_ACCOUNT_INFO_F}
`
