import React, { FC } from 'react'
import { SvgIconProps } from '@material-ui/core/SvgIcon'
import { SvgIcon } from '@material-ui/core'

export const NucleusIcon: FC<SvgIconProps> = (props) => (
  <SvgIcon {...props}>
    <svg
      aria-hidden='true'
      className='svg-inline--fa fa-router fa-w-18'
      data-icon='router'
      data-prefix='fas'
      focusable='false'
      role='img'
      viewBox='0 0 576 512'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M528 320H376v-80a16 16 0 0 0-16-16h-16a16 16 0 0 0-16 16v80H48a48 48 0 0 0-48 48v96a48 48 0 0 0 48 48h480a48 48 0 0 0 48-48v-96a48 48 0 0 0-48-48zM96 448a32 32 0 1 1 32-32 32 32 0 0 1-32 32zm96 0a32 32 0 1 1 32-32 32 32 0 0 1-32 32zm-32.59-320.34a15.85 15.85 0 0 0 21.86.64c96.45-86.55 244.24-85.85 341.11.42a16 16 0 0 0 21.84-.63l22.71-22.7a16.11 16.11 0 0 0-.71-23.34C444.81-27.34 259.26-27.34 137.78 82a16.11 16.11 0 0 0-.71 23.34zm46.47 38a16.12 16.12 0 0 0-.86 23.61L227.73 212a15.94 15.94 0 0 0 21.7.78c58.77-49.84 145.8-49.36 205.14.21a16.07 16.07 0 0 0 21.63-.84l22.8-22.8a16.22 16.22 0 0 0-.84-23.63c-83.83-71.91-208.42-71.91-292.28-.06z'
        fill='currentColor'
      />
    </svg>
  </SvgIcon>
)
