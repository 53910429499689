import React, { useState } from 'react'
import {
  useElements,
  useStripe,
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement
} from '@stripe/react-stripe-js'
import { Box, Button, Divider, Typography, useTheme } from '@material-ui/core'
import { DisplayQuantityInput } from '../../features/paywall/molecules/display-quantity-input'
import { BillingCycleInput } from 'src/features/paywall/molecules/billing-cycle-input'
import { BillingCycle, PlanEnums } from 'src/screenhub-shared/enum/stripe.enum'
import { getItemPrice, paidQuantity, totalPrice } from 'src/helpers/paywallHelper'

interface SetupIntentSucceedCallbackProp {
  paymentMethodId?: string
  newDisplayQuantity: number
  newBillingCycle: BillingCycle
}
interface CardFormProp {
  defaultPaymentMethod?: any
  billingCycle?: BillingCycle
  cirrusStandardQty?: number
  originalPlan?: PlanEnums
  chosenPlan?: PlanEnums
  clientSecret: string
  displayCount?: number
  quantity?: number
  showBillingCycleInput?: boolean
  showPrice?: boolean
  showQuantityInput?: boolean
  succeededCallback: (prop: SetupIntentSucceedCallbackProp) => void
  children?: JSX.Element
  fixedBillingCycle?: boolean
}

export const CardForm = (props: CardFormProp) => {
  // const theme = useTheme()
  const {
    billingCycle = BillingCycle.MONTH,
    cirrusStandardQty = 0,
    children,
    chosenPlan = PlanEnums.PREMIUM_PLAN,
    clientSecret,
    defaultPaymentMethod,
    displayCount = 0,
    originalPlan = PlanEnums.PREMIUM_PLAN,
    quantity = 0,
    succeededCallback,
    showBillingCycleInput = true,
    showPrice = true,
    showQuantityInput = true,
    fixedBillingCycle = false,
  } = props
  const [loading, setLoading] = useState(false)
  const stripe = useStripe()
  const elements = useElements()
  const [newQty, setNewQty] = useState(quantity)
  const [newBillingCycle, setNewBillingCycle] = useState<BillingCycle>(billingCycle)
  const [errorMsg, setErrorMsg] = useState('')

  const paidQty = paidQuantity(
    newQty,
    chosenPlan === PlanEnums.PRO_PLAN ? chosenPlan : originalPlan,
    cirrusStandardQty
  )

  const handleSubmit = async event => {
    event.preventDefault()

    if (!stripe || !elements) {
      return
    }

    if (defaultPaymentMethod) {
      succeededCallback({
        paymentMethodId: '',
        newDisplayQuantity: newQty,
        newBillingCycle
      })
      return
    }

    setLoading(true)
    const result = await stripe.confirmCardSetup(clientSecret, {
      payment_method: {
        card: elements.getElement(CardNumberElement)
      }
    })

    if (result.error) {
      console.error(`Failed on Stripe configuration: ${result.error?.message}`)
      console.error(`Stripe error code: ${result.error?.code}`)
      console.error(`Stripe error decline_code: ${result.error?.decline_code}`)
      console.error(`Stripe error type: ${result.error?.type}`)
      setErrorMsg(result.error?.message)
      setLoading(false)
    } else if (result.setupIntent.status === 'succeeded') {
      const { setupIntent } = result
      const { payment_method } = setupIntent
      succeededCallback({
        paymentMethodId: payment_method as string,
        newDisplayQuantity: newQty,
        newBillingCycle
      })
    }
  }

  return (
    <Box display="flex" flexDirection="column">
      <Box display="flex" flex="1" flexDirection="row">
        <Box style={{ width: children ? '350px' : undefined, paddingTop: 10, paddingBottom: 10 }}>
          <Typography>Card</Typography>
          <Box style={{ border: '1px solid #DADADA', borderRadius: 4, padding: 10 }}>
            <CardNumberElement
              onChange={() => {
                setErrorMsg('')
              }}
              options={{
                disabled: !!defaultPaymentMethod,
                placeholder: !!defaultPaymentMethod
                  ? `**** **** **** ${defaultPaymentMethod?.card.last4}`
                  : undefined,
                showIcon: true,
                style: {
                  base: {
                    fontSize: '16px'
                  }
                }
              }}
            />
          </Box>
          <Box
            display="flex"
            style={{
              paddingTop: 10,
              paddingBottom: 10,
              justifyContent: 'space-between'
            }}
          >
            <Box style={{ width: '48%' }}>
              <Typography>Expiration</Typography>
              <Box style={{ border: '1px solid #DADADA', borderRadius: 4, padding: 10 }}>
                <CardExpiryElement
                  onChange={() => {
                    setErrorMsg('')
                  }}
                  options={{
                    disabled: !!defaultPaymentMethod,
                    placeholder: !!defaultPaymentMethod ? '**/**' : undefined,
                    style: {
                      base: {
                        fontSize: '16px'
                      }
                    }
                  }}
                />
              </Box>
            </Box>
            <Box style={{ width: '48%' }}>
              <Typography>CVC</Typography>
              <Box style={{ border: '1px solid #DADADA', borderRadius: 4, padding: 10 }}>
                <CardCvcElement
                  onChange={() => {
                    setErrorMsg('')
                  }}
                  options={{
                    disabled: !!defaultPaymentMethod,
                    placeholder: !!defaultPaymentMethod ? '***' : undefined,
                    style: {
                      base: {
                        fontSize: '16px'
                      }
                    }
                  }}
                />
              </Box>
            </Box>
          </Box>
          {!defaultPaymentMethod && (
            <Typography variant="body2" style={{ margin: '10px 0' }}>
              Please provide your credit card information for the first-time adjustment, although
              reducing your display quantity is free and will begin in the next billing cycle.
            </Typography>
          )}
          {showQuantityInput && (
            <DisplayQuantityInput
              cirrusStandardQty={cirrusStandardQty}
              quantity={newQty}
              displayCount={displayCount}
              quantityOnChangedCallback={function(newQuantity: number): void {
                setNewQty(newQuantity)
              }}
            />
          )}
          {showBillingCycleInput && (
            <BillingCycleInput
              billingCycle={newBillingCycle}
              disabled={fixedBillingCycle}
              onBillingCycleChange={billingCycle => {
                setNewBillingCycle(billingCycle)
              }}
            />
          )}
          {showPrice && (
            <Box style={{ paddingTop: 10, paddingBottom: 10 }}>
              <Typography variant="h6">
                Total ${totalPrice(paidQty, chosenPlan, newBillingCycle)}
              </Typography>
              <Typography variant="body2">
                ${getItemPrice(chosenPlan, newBillingCycle)} x {paidQty} display(s)
                {(originalPlan === PlanEnums.CIRRUS_HARDWARE_PLAN ||
                  originalPlan === PlanEnums.CIRRUS_WINDOWS_PLAN) &&
                cirrusStandardQty
                  ? `, ${cirrusStandardQty} free display(s) included`
                  : ''}
              </Typography>
            </Box>
          )}
        </Box>
        {children && <Divider orientation="vertical" flexItem style={{ margin: 12 }} />}
        {children}
      </Box>
      <Button
        disabled={!stripe || loading}
        variant="contained"
        color="primary"
        onClick={handleSubmit}
        style={{
          marginTop: 10,
          width: '100%'
        }}
      >
        {loading ? 'Processing...' : 'Subscribe'}
      </Button>
      <Typography variant="h6" style={{ color: 'red' }}>
        {errorMsg}
      </Typography>
    </Box>
  )
}
