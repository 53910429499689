import React, { FC, useContext, useRef } from 'react'
import PropTypes from 'prop-types'
import { Theme } from '@material-ui/core/styles'
import { createStyles, makeStyles, useTheme } from '@material-ui/styles'
import { Drawer, IconButton } from '@material-ui/core'
import { LayoutContext } from '../../templates/default-layout'
import { BackBurgerIcon } from '../../molecules/icons'

const useStyles = makeStyles<Theme>((theme) => {
  return createStyles({
    root: {
      '@media print': {
        display: 'none'
      }
    },
    container: {
      overflow: 'hidden',
      display: 'flex',
      flexGrow: 1,
      flexDirection: 'column',
      transition: theme.transitions.create(['width'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen
      })
    },
    content: {
      flexGrow: 1,
      overflow: 'hidden'
    },
    collapseButton: {
      textAlign: 'center',
      borderRadius: 0,
      borderTop: '1px solid',
      borderColor: 'rgba(0,0,0,0.12)',
      [theme.breakpoints.up('sm')]: {
        minHeight: 40
      }
    },
    backBurgerButton: {
      position: 'absolute',
      top: 9,
      zIndex: theme.zIndex.modal + 1
    },
    closeButton: {
      position: 'absolute',
      bottom: theme.spacing(2),
      zIndex: theme.zIndex.modal + 1,
      // background: theme.palette.common.white,
      boxShadow: theme.shadows[2],
      '@media (hover: none)': {
        backgroundColor: theme.palette.grey[300]
      },
      '&:hover': {
        backgroundColor: '#e5e5e5'
      }
    }
  })
})

interface NavProps {
  className?: string
  component?: any
  header?: any
  children: any
}

export const LayoutNavigation: FC<NavProps> = ({
  className,
  component: Component,
  header,
  children,
  ...props
}) => {
  const theme = useTheme<Theme>()
  const classes = useStyles(theme)
  const ctx = useContext(LayoutContext)

  const {
    open,
    setOpen,
    navVariant,
    navAnchor,
    navWidth,
    collapsedWidth,
    collapsible,
    collapsed,
    setCollapse
  } = ctx

  const getWidth = () => {
    if (collapsible && collapsed) return collapsedWidth
    return navWidth
  }

  const contentRef = useRef(null)

  return (
    <>
      <Drawer
        {...props}
        anchor={navAnchor}
        className={`${className} ${classes.root}`}
        onClose={setOpen}
        open={open}
        variant={navVariant}
      >
        <div
          className={classes.container}
          style={{ width: getWidth() }}
        >
          {collapsible && !collapsed && (
            <IconButton
              className={classes.backBurgerButton}
              onClick={setCollapse}
              style={{ right: 8, transition: '0.3s' }}
            >
              <BackBurgerIcon />
            </IconButton>
          )}

          {typeof header === 'function' ? header(ctx) : header}

          <div
            className={classes.content}
            ref={contentRef}
          >
            {typeof children === 'function' ? children(ctx) : children}
          </div>
        </div>
      </Drawer>
    </>
  )
}

LayoutNavigation.propTypes = {
  className: PropTypes.string,
  component: PropTypes.elementType,
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.func]).isRequired,
  header: PropTypes.oneOfType([PropTypes.node, PropTypes.func])
}

LayoutNavigation.defaultProps = {
  className: '',
  component: 'div',
  header: null
}
