import gql from 'graphql-tag'
import * as Fragments from './fragments'
import {
  BoSetUserMutation,
  BoSetUserMutationVariables
} from '../../types/BoSetUserMutation'
import {
  BoSetDisplayAdstashApprovalMutation,
  BoSetDisplayAdstashApprovalMutationVariables
} from '../../types/BoSetDisplayAdstashApprovalMutation'

export const BO_SET_USER_FEATURE_M = gql<
  BoSetUserMutation,
  BoSetUserMutationVariables
  >`
  mutation BoSetUserMutation ($input: UserFeatureInput!) {
    setUserFeature(input: $input) {
      code
      success
      message
      featuresOptInAds {
        optInAds
      }
    }
  }
`

export const BO_SET_DISPLAY_ADSTASH_APPROVAL_M = gql<
  BoSetDisplayAdstashApprovalMutation,
  BoSetDisplayAdstashApprovalMutationVariables
  >`
  mutation BoSetDisplayAdstashApprovalMutation ($displayId: UUID!, $isApproved: Boolean!) {
    setDisplayAdstashApproval(displayId: $displayId, isApproved: $isApproved) {
      code
      success
      message
      displayAdstash {
        ...BoDisplayAdstashInfo
      }
    }
  }
  ${Fragments.BO_DISPLAY_ADSTASH_INFO_F}
`

export const BO_ACCOUNT_UPDATE_USER_AS_ADMIN = gql`
  mutation AccountUpdateUserMutation ($id: UUID!, $input: UpdateUserInput!) {
    updateUserAsAdmin(id: $id, input: $input) {
      code
      success
      message
    }
  }
`
export const USER_ACTION_TOGGLE_USER_FEATURES_ADMIN = gql`
  mutation ToggleUserFeatures ($input: UserActionAbTestingInput!) {
    toggleUserFeatures(input: $input) {
      code
      success
      message
    }
  }
`

export const BO_RESEND_WELCOME_EMAIL = gql`
  mutation ResendWelcomeEmail ($input: ResendWelcomeEmailInput!) {
    resendWelcomeEmail(input: $input){
      code
      success
      message
    }
  }
`

export const BO_CANCEL_SUBSCRIPTION_BY_ACCOUNT = gql`
  mutation CancelSubscriptionByAccount ($accountId: String!) {
    cancelSubscriptionByAccount(accountId: $accountId) {
      accountId
      status
    }
  }
`

export const BO_RESET_DISPLAY_SUBSCRIPTION = gql`
  mutation ResetDisplaySubscription(
    $accountId: String!
    $stripeSubscriptionId: String
    $cirrusStandardQty: Int
    $planName: PlanEnums
    $quantity: Int
  ) {
    resetDisplaySubscription(
      subscriptionPayloadDTO: {
        accountId: $accountId
        stripeSubscriptionId: $stripeSubscriptionId
        cirrusStandardQty: $cirrusStandardQty
        planName: $planName
        quantity: $quantity
      }
    ) {
      accountId
      status
    }
  }
`
