import gql from 'graphql-tag'
import { BoUsersFragment, BoUserWithFeaturesInfoFragment } from '../../types/BoUserWithFeaturesInfoFragment'
import { BoDisplayAdstashInfo } from '../../types/BODisplayAdstashInfo'
import { BoBackOfficeDisplayAdstashInfo } from '../../types/BoBackOfficeDisplayAdstashInfo'
import { CommAccountInfoFragment } from '../../types/CommAccountInfoFragment'

export const BO_ACCOUNT_INFO_F = gql<CommAccountInfoFragment>`
  fragment CommAccountInfoFragment on Account {
    id
    name
    maxUploadSize
    maxStorage
    optInAds
    paypalEmail
  }
`

export const BO_USERS_F = gql<BoUsersFragment>`
  fragment BoUsersFragment on User {
    id
    firstName
    lastName
    email
    created
    changed
    isVerified
    role
    accountId
    hasTwoFactorAuthenticationEnabled
    subscriptionPlan
    subscriptionStatus
    trialEnd
    qty
    cirrusStandardQty
    stripeSubscriptionId
    cellularSubscriptionStatus
    cellularStripeSubscriptionId
    analyticsSubscriptionStatus
    analyticsStripeSubscriptionId
  }
`

export const BO_USER_WITH_FEATURES_INFO_F = gql<BoUserWithFeaturesInfoFragment>`
  fragment BoUserWithFeaturesInfoFragment on UserWithFeatures {
    id
    accountId
    firstName
    lastName
    role
    optInAds
    email
    countAdsDisplays
    countApprovedAdsDisplays
  }
`

export const BO_DISPLAY_ADSTASH_INFO_F = gql<BoDisplayAdstashInfo>`
  fragment BoDisplayAdstashInfo on DisplayAdstash {
    isApproved
    displayId
    impressions
    size
    adUnit
    loopLength
    dwellTime
    venue {
      id
      name
    }
    display {
      id
      name
    }
  }
`

export const BO_BACK_OFFICE_DISPLAY_ADSTASH_INFO_F = gql<BoBackOfficeDisplayAdstashInfo>`
  fragment BoBackOfficeDisplayAdstashInfo on BODisplayAdstash {
    adUnit
    created
    displayId
    displayName
    dwellTime
    impressions
    isApproved
    loopLength
    ownerId
    ownerName
    size
    venueId
    venueName
  }
`
