import gql from 'graphql-tag'

export const WM_DELETE_WORKSPACE_MEMBER = gql`
  mutation deleteWorkspaceMember ($workspaceId: String!, $accountId: String!) {
    deleteWorkspaceMember(workspaceId: $workspaceId, accountId: $accountId) {
      code
      success
      message
    }
  }
`

export const WM_UPDATE_WORKSPACE_MEMBER = gql`
  mutation updateWorkspaceMember ($workspaceId: String!, $accountId: String!, $workspaceRoleId: Int!) {
    updateWorkspaceMember(workspaceId: $workspaceId, accountId: $accountId, workspaceRoleId: $workspaceRoleId) {
      code
      success
      message
    }
  }
`

export const WM_CREATE_WORKSPACE_MEMBER = gql`
  mutation createWorkspaceMember ($workspaceId: String!, $email: String!, $workspaceRoleId: Int!,
    $firstName: String!, $lastName: String!, $domain: String) {
      createWorkspaceMember(workspaceId: $workspaceId, email: $email, workspaceRoleId: $workspaceRoleId,
       firstName: $firstName, lastName: $lastName, domain: $domain) {
      code
      success
      message
    }
  }
`