import React, { FC, useMemo } from 'react'
import { Menu, ChevronLeft } from '@material-ui/icons'
import { ThemeProvider as MuiThemeProvider } from '@material-ui/styles'
import { darkTheme } from 'src/theme'
import { LayoutNavigation } from '../organisms/layout/layout-navigation'
import { LayoutHeader } from '../organisms/layout/layout-header'
import { LayoutContent } from '../organisms/layout/layout-content'
import { BackBurgerIcon } from '../molecules/icons/back-burger-icon'
import { NavContent } from '../organisms/nav-content'
import { NavHeader } from '../molecules/nav-header'
import { HeaderBar } from '../organisms/header-bar'
import { COLOR_DARKER_GRAY_1 } from '../../constants/style'
import { useLocation } from 'react-router'

const isMatchPlayListUrl = url => {
  if (!url.match(/playlists/)) {
    return false
  }

  return url.match(
    /playlists\/(?:[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12})|(?:new)/
  )
}

export const PrivateLayout: FC = ({ children }) => {
  const backBurgerIconStyles = useMemo(
    () => ({
      transform: 'rotate(180deg)'
    }),
    []
  )

  const { pathname } = useLocation()

  return (
    <>
      <LayoutHeader
        style={{ backgroundColor: COLOR_DARKER_GRAY_1 }}
        menuIcon={{
          show: <Menu />,
          hide: <ChevronLeft />,
          expand: <BackBurgerIcon style={backBurgerIconStyles} />
        }}
      >
        <HeaderBar />
      </LayoutHeader>
      {!pathname.endsWith('/lobby') &&
        <MuiThemeProvider theme={darkTheme}>
          <LayoutNavigation
            header={({ collapsed, collapsible }) => (
              <NavHeader collapsed={collapsed} collapsible={collapsible} />
            )}
          >
            <NavContent />
          </LayoutNavigation>
        </MuiThemeProvider>
      }

      <LayoutContent>{children}</LayoutContent>
    </>
  )
}
