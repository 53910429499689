import {
  ACCOUNT_PAGE_URL,
  BACK_OFFICE_PAGE_URL,
  BILLING_PAGE_URL,
  CONDITIONAL_CONTENT_EDITOR_URL,
  CONDITIONAL_CONTENT_ID_PARAM,
  CONDITIONAL_CONTENT_PAGE_URL,
  CUSTOMER_DASHBOARD_URL,
  DISPLAY_ID_PARAM,
  DISPLAY_IN_GROUP_URL,
  DISPLAY_LIST_PAGE_URL,
  DISPLAY_SCHEDULE_URL,
  DISPLAY_URL,
  GROUP_ID_PARAM,
  GROUP_PAGE_URL,
  NEW_CONDITIONAL_CONTENT_URL,
  NEW_PLAYLIST_URL,
  ORDER_TRACKING_PAGE_URL,
  PLAYLIST_ID_PARAM,
  PLAYLIST_PAGE_URL,
  PLAYLIST_URL,
  WORKSPACE_ID_PARAM,
  WORKSPACE_MEMBER_PAGE_URL,
} from '../constants/urlConstants';

const getAccountPageUrl = (workspaceId) => (
    ACCOUNT_PAGE_URL.replace(WORKSPACE_ID_PARAM, encodeURIComponent(workspaceId))
);

const getOrderTrackingPageUrl = (workspaceId) => (
  ORDER_TRACKING_PAGE_URL.replace(WORKSPACE_ID_PARAM, encodeURIComponent(workspaceId))
);

const getBackOfficePageUrl = (workspaceId) => (
    BACK_OFFICE_PAGE_URL.replace(WORKSPACE_ID_PARAM, encodeURIComponent(workspaceId))
);

const getBillingPageUrl = (workspaceId) => (
    BILLING_PAGE_URL.replace(WORKSPACE_ID_PARAM, encodeURIComponent(workspaceId))
);

const getCustomerDashboardUrl = (workspaceId) => (
    CUSTOMER_DASHBOARD_URL.replace(WORKSPACE_ID_PARAM, encodeURIComponent(workspaceId))
);

const getConditionalContentEditorUrl = (workspaceId, conditionContentId) => (
    CONDITIONAL_CONTENT_EDITOR_URL.replace(new RegExp(`${WORKSPACE_ID_PARAM}|${CONDITIONAL_CONTENT_ID_PARAM}`, 'gi'), function(matched){
        const replaceStringObj = {
          [WORKSPACE_ID_PARAM]: encodeURIComponent(workspaceId),
          [CONDITIONAL_CONTENT_ID_PARAM]: encodeURIComponent(conditionContentId),
        };
    
        return replaceStringObj[matched];
    })
);

const getConditionalContentPageUrl = (workspaceId) => (
    CONDITIONAL_CONTENT_PAGE_URL.replace(WORKSPACE_ID_PARAM, encodeURIComponent(workspaceId))
);

const getDisplayUrl = (workspaceId, displayId) => (
    DISPLAY_URL.replace(new RegExp(`${WORKSPACE_ID_PARAM}|${DISPLAY_ID_PARAM}`, 'gi'), function(matched){
        const replaceStringObj = {
        [WORKSPACE_ID_PARAM]: encodeURIComponent(workspaceId),
        [DISPLAY_ID_PARAM]: encodeURIComponent(displayId),
        };

        return replaceStringObj[matched];
    })
);

const getDisplayInGroupUrl = (workspaceId, displayGroupId, displayId) => (
    DISPLAY_IN_GROUP_URL.replace(new RegExp(`${WORKSPACE_ID_PARAM}|${DISPLAY_ID_PARAM}|${GROUP_ID_PARAM}`, 'gi'), function(matched){
        const replaceStringObj = {
        [WORKSPACE_ID_PARAM]: encodeURIComponent(workspaceId),
        [GROUP_ID_PARAM]: encodeURIComponent(displayGroupId),
        [DISPLAY_ID_PARAM]: encodeURIComponent(displayId),
        };

        return replaceStringObj[matched];
    })
);

const getDisplayScheduleUrl = (workspaceId, displayId) => (
    DISPLAY_SCHEDULE_URL.replace(new RegExp(`${WORKSPACE_ID_PARAM}|${DISPLAY_ID_PARAM}`, 'gi'), function(matched){
        const replaceStringObj = {
        [WORKSPACE_ID_PARAM]: encodeURIComponent(workspaceId),
        [DISPLAY_ID_PARAM]: encodeURIComponent(displayId),
        };

        return replaceStringObj[matched];
    })
);

const getDisplayListPageUrl = (workspaceId) => (
    DISPLAY_LIST_PAGE_URL.replace(WORKSPACE_ID_PARAM, encodeURIComponent(workspaceId))
);

const getGroupPageUrl = (workspaceId, displayGroupId) => (
    GROUP_PAGE_URL.replace(new RegExp(`${WORKSPACE_ID_PARAM}|${GROUP_ID_PARAM}`, 'gi'), function(matched){
        const replaceStringObj = {
        [WORKSPACE_ID_PARAM]: encodeURIComponent(workspaceId),
        [GROUP_ID_PARAM]: encodeURIComponent(displayGroupId),
        };

        return replaceStringObj[matched];
    })
);

const getNewConditionalContentUrl = (workspaceId) => (
    NEW_CONDITIONAL_CONTENT_URL.replace(WORKSPACE_ID_PARAM, encodeURIComponent(workspaceId))
);

const getNewPlayListUrl = (workspaceId): string => (
    NEW_PLAYLIST_URL.replace(WORKSPACE_ID_PARAM, encodeURIComponent(workspaceId))
);

const getPlaylistPageUrl = (workspaceId) => (
    PLAYLIST_PAGE_URL.replace(WORKSPACE_ID_PARAM, encodeURIComponent(workspaceId))
);

const getPlaylistUrl = (workspaceId, playlistId) => (
    PLAYLIST_URL.replace(new RegExp(`${WORKSPACE_ID_PARAM}|${PLAYLIST_ID_PARAM}`, 'gi'), function(matched){
        const replaceStringObj = {
        [WORKSPACE_ID_PARAM]: encodeURIComponent(workspaceId),
        [PLAYLIST_ID_PARAM]: encodeURIComponent(playlistId),
        };

        return replaceStringObj[matched];
    })
);

const getWorkspaceMemberPageUrl = (workspaceId) => (
    WORKSPACE_MEMBER_PAGE_URL.replace(WORKSPACE_ID_PARAM, encodeURIComponent(workspaceId))
);

export default {
    getAccountPageUrl,
    getOrderTrackingPageUrl,
    getBackOfficePageUrl,
    getBillingPageUrl,
    getConditionalContentEditorUrl,
    getConditionalContentPageUrl,
    getCustomerDashboardUrl,
    getDisplayUrl,
    getDisplayInGroupUrl,
    getDisplayListPageUrl,
    getDisplayScheduleUrl,
    getGroupPageUrl,
    getNewConditionalContentUrl,
    getNewPlayListUrl,
    getPlaylistPageUrl,
    getPlaylistUrl,
    getWorkspaceMemberPageUrl,
};