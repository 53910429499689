import React from 'react'
import { SketchPicker } from 'react-color'
import { styled } from '@material-ui/styles'
import { Box, Theme } from '@material-ui/core'

const CustomBox = styled(Box)(({ theme }) => ({
  width: '300px',
  padding: '17px',
  background: 'rgb(255, 255, 255)',
  borderRadius: '8px',
  boxShadow: 'rgb(0 0 0 / 15%) 0px 0px 0px 1px, rgb(0 0 0 / 15%) 0px 8px 16px',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-around'
}))

const Header = styled(Box)(({ theme }) => ({
  display: 'flex',
  paddingBottom: '16px',
  alignItems: 'center',
  justifyContent: 'space-between',
  color: 'black'
}))

interface StyledProps {
  theme: Theme,
  disableAlpha?: boolean
}

const StyledSketchPicker = styled(SketchPicker)((props: StyledProps) => {
  const { theme, disableAlpha } = props
  return ({
    display: 'contents',
    // INPUT BOXES
    '& input': {
      height: '40px',
      borderRadius: '8px',
      fontSize: `${disableAlpha ? '18px' : '16px'} !important`
    },
    // COLOR SELECTOR
    '& > div:first-child': {
      height: '149px',
      marginBottom: '16px',
      paddingBottom: '0!important'
    },
    // COLOR SLIDER
    '& > div:nth-child(2)': {
      '& div': {
        borderRadius: '5px',
        height: '12px'
      },
      '& div:last-child': {
        height: 'unset'
      }
    },
    // INPUT SECTION BOTTOM
    '& > div:nth-child(3) > div > div': {
      display: 'flex',
      flexDirection: 'column-reverse'
    },
    // LABELS
    '& span': {
      fontWeight: 700
    },
    // COLOR BOXES
    '& > div:last-child': {
      borderTop: 'none !important',
      margin: '0px -15px!important'
    }
  })
})

export const CustomColorPicker = (props) => (
  <CustomBox>
    <Header>
      <span style={{ fontSize: '18px' }}>
        Color
      </span>
    </Header>
    <StyledSketchPicker
      disableAlpha={props.disableAlpha ? props.disableAlpha : true}
      {...props}
    />
  </CustomBox>
)
