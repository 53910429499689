import gql from 'graphql-tag'

export const WM_GET_WORKSPACE_MEMBER_DATA = gql`
  query WmGetWorkspaceMembers($workspaceId: String!) {
    workspaceMembers(workspaceId: $workspaceId) {
      accountId,
      workspaceRoleId,
      account {
        name
      }
      workspaceRole {
        name
      }
      user {
        firstName
        lastName
        email
      }
    }
  }
`

export const WM_SEARCH_WORKSPACE_MEMBER_BY_EMAIL = gql`
  query WmSearchWorkspaceMemberByEmail ($email: String){
    searchWorkspaceMemberByEmail (email: $email){
      lastName
      email
      firstName
      role
  }
}
`

export const WM_GET_WORKSPACE_ROLES = gql`
  query WmGetWorkspaceRoles {
    workspaceRoles {
      id
      name
    }
  }
`
