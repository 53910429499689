import React, { FC } from 'react'
import { SvgIcon } from '@material-ui/core'
import { SvgIconProps } from '@material-ui/core/SvgIcon'

export const WebpageIcon: FC<SvgIconProps> = (props) => (
  <SvgIcon
    {...props}
  >
    <svg
      fill='none'
      height='24'
      viewBox='0 0 24 24'
      width='24'
      xmlns='http://www.w3.org/2000/svg'
    >
      <mask
        fill='white'
        id='path-1-inside-1'
      >
        <rect
          height='20'
          rx='1'
          width='20'
          x='2'
          y='2'
        />
      </mask>
      <rect
        height='20'
        mask='url(#path-1-inside-1)'
        rx='1'
        stroke='#DADADA'
        strokeWidth='3.2'
        width='20'
        x='2'
        y='2'
      />
      <rect
        fill='#DADADA'
        height='2'
        width='18'
        x='3'
        y='3'
      />
      <path
        d='M16.7989 12.5L14.3333 10.0828L15.43 9.00763L19 12.5L15.43 16L14.3333 14.9172L16.7989 12.5ZM7.20111 12.5L9.66667 14.9172L8.57 15.9924L5 12.5L8.57 9L9.66667 10.0828L7.20111 12.5Z'
        fill='#DADADA'
      />
    </svg>
  </SvgIcon>
)
