export const supportedVideoFormats: string[] = ['mp4', 'webm', 'mpeg']
export const supportedImageFormats: string[] = ['jpg', 'jpeg', 'png', 'gif', 'webp']

const formatToAttr = (formats: string[]) =>
  formats.reduce((acc, item, idx, arr) => {
    if (idx !== arr.length - 1) {
      acc += ` .${item},`
    } else {
      acc += ` .${item}`
    }
    return acc
  }, '')

const formatToUserFriendlyString = (formats: string[]) =>
  formats.reduce((acc, item, idx, arr) => {
    if (idx !== arr.length - 1) {
      acc += ` ${item.toUpperCase()},`
    } else {
      acc += ` ${item.toUpperCase()}`
    }
    return acc
  }, '')

export const videoFormatsAcceptAttr = formatToAttr(supportedVideoFormats)
export const imageFormatsAcceptAttr = formatToAttr(supportedImageFormats)

export const videoFormatsUserFriendly = formatToUserFriendlyString(supportedVideoFormats)
export const imageFormatsUserFriendly = formatToUserFriendlyString(supportedImageFormats)
