import React from 'react'
import Downshift from 'downshift'
import { Paper, Theme, MenuItem, Typography, TextField } from '@material-ui/core'
import { makeStyles, createStyles } from '@material-ui/styles'
import { TextFieldProps } from '@material-ui/core/TextField'
import { MenuItemProps } from '@material-ui/core/MenuItem'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      height: 250
    },
    container: {
      position: 'relative'
    },
    paper: {
      position: 'absolute',
      zIndex: 1,
      marginTop: theme.spacing(1),
      left: 0,
      right: 0
    },
    chip: {
      margin: theme.spacing(0.5, 0.25)
    },
    inputRoot: {
      flexWrap: 'wrap'
    },
    inputInput: {
      width: 'auto',
      flexGrow: 1
    },
    divider: {
      height: theme.spacing(2)
    }
  })
)
// TODO refactor this component
type RenderInputProps = TextFieldProps & {
  classes: ReturnType<typeof useStyles>
  ref?: React.Ref<HTMLDivElement>
}

function renderInput (inputProps: RenderInputProps) {
  const { InputProps, classes, ref, ...other } = inputProps

  return (
    <TextField
      InputProps={{
        inputRef: ref,
        classes: {
          root: classes.inputRoot,
          input: classes.inputInput
        },
        ...InputProps
      }}
      {...other}
    />
  )
}

type Suggestion = string

interface RenderSuggestionProps {
  highlightedIndex: number | null
  index: number
  itemProps: MenuItemProps<'div', { button?: never }>
  selectedItem: Suggestion
  suggestion: Suggestion
}

function renderSuggestion (suggestionProps: RenderSuggestionProps) {
  const { suggestion, index, itemProps, highlightedIndex, selectedItem } = suggestionProps
  const isHighlighted = highlightedIndex === index
  const isSelected = (selectedItem || '').indexOf(suggestion) > -1

  return (
    <MenuItem
      {...itemProps}
      component='div'
      key={suggestion}
      selected={isHighlighted}
      style={{
        fontWeight: isSelected ? 500 : 400
      }}
    >
      <Typography noWrap>{suggestion}</Typography>
    </MenuItem>
  )
}

interface TProps {
  suggestions: Suggestion[]
  onSelect(val: string): void
  onChange(val: string): void
  value: string
}

export const AutocompleteFieldRaw: React.FC<TProps> = (p, ref) => {
  const classes = useStyles({})

  return (
    <Downshift
      inputValue={p.value}
      onChange={p.onChange}
      onSelect={p.onSelect}
    >
      {({
        clearSelection,
        getInputProps,
        getItemProps,
        getLabelProps,
        getMenuProps,
        highlightedIndex,
        isOpen,
        openMenu,
        selectedItem,
        inputValue
      }) => {
        const { onBlur, onChange, onFocus, ...inputProps } = getInputProps({
          onChange: (event: React.ChangeEvent<HTMLInputElement>) => {
            const { value } = event.target

            if (value === '') clearSelection()

            return p.onChange(value)
          },
          onFocus: openMenu,
          placeholder: 'Address',
          value: inputValue
        })

        return (
          <div className={classes.container}>
            {renderInput({
              ref,
              fullWidth: true,
              classes,
              InputLabelProps: getLabelProps({ shrink: true } as any),
              InputProps: { onBlur, onChange, onFocus },
              inputProps
            })}
            <div {...getMenuProps()}>
              {isOpen ? (
                <Paper
                  className={classes.paper}
                  square
                >
                  {p.suggestions.map((suggestion, index) =>
                    renderSuggestion({
                      suggestion,
                      index,
                      itemProps: getItemProps({ item: suggestion }),
                      highlightedIndex,
                      selectedItem
                    })
                  )}
                </Paper>
              ) : null}
            </div>
          </div>
        )
      }}
    </Downshift>
  )
}
export const AutocompleteField = React.forwardRef(AutocompleteFieldRaw)
