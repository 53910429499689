import React, { FC, useMemo, useContext, useState, useCallback } from 'react'
import { Link, NavLinkProps, RouteComponentProps, withRouter, NavLink } from 'react-router-dom'
import { useHistory } from 'react-router';
import {
  Collapse,
  Select,
  MenuItem,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Theme,
  Typography,
  Box,
  Hidden,
  IconButton,
  Dialog,
  FormControl,
  DialogTitle,
  DialogActions,
  DialogContent,
  TextField,
  Button,
  InputLabel,
  OutlinedInput,
  InputAdornment
} from '@material-ui/core'
import { toast } from 'react-toastify'
import { handleError } from 'src/core'
import { formatServerError } from 'src/helpers'
import {
  Event,
  PermMedia,
  Subscriptions,
  Tv,
  People,
  Speed,
  CloudDownload,
  AddCircleOutline,
  HelpOutline,
  CreateOutlined,
  DeleteForever,
  ListAlt,
  DirectionsCar,
  Videocam,
  DashboardOutlined
} from '@material-ui/icons'
import { ThemeProvider, createStyles, makeStyles, useTheme } from '@material-ui/styles'
import { useUserInfo, useRememberedAdminUserInfo } from 'src/services'
import Tooltip from '@material-ui/core/Tooltip'
import { useQuery, useMutation, useSubscription } from 'react-apollo'
import * as ApolloAPI from 'src/apollo/api'
import { MainTheme } from 'src/theme'
import { WorkspaceRoles } from 'src/constants'
import { CirrusIcon } from '../molecules/icons'
import {
  getCurrentWorkspaceFromLocalStorage
} from 'src/helpers/local-storage'
import CastConnectedOutlinedIcon from '@material-ui/icons/CastConnectedOutlined'
import ArrowDownwardOutlinedIcon from '@material-ui/icons/ArrowDownwardOutlined'
import ArrowForwardOutlinedIcon from '@material-ui/icons/ArrowForwardOutlined'
import { LayoutContext } from '../templates/default-layout'
import { isHideFeature } from '../../helpers/white-label-helper'
import { WhiteLabelFeaturesEnum } from '../../helpers/white-label.enum'
import { PeopleCounterPage } from 'src/features/people-counter/people-counter-page'
import * as displayAssets from '../assets'
import { ANALYTIC_SUBSCRIPTION_STATE_CHANGED } from 'src/apollo/api/paywall/subscriptions'

import { useCurrentWorkspace } from 'src/hooks/useCurrentWorkspace';

import {
  WORKSPACE_ID_PARAM,
  CONDITIONAL_CONTENT_SUB_PATH,
  PLAYERLIST_SUB_PATH,
  MARKETING_SUB_PATH,
  DOWNLOAD_PLAYER_SUB_PATH,
  HELP_PAGE_SUB_PATH,
  PEOPLE_COUNTER_SUB_PATH,
  CAR_TRACKER_SUB_PATH,
  DEVICE_MANAGEMENT_SUB_PATH,
  BILLING_SUB_PATH,
  ACCOUNT_SUB_PATH,
  BACK_OFFICE_SUB_PATH,
  WORKSPACE_URL_PATTERN,
  PROOF_OF_PERFORMANCE_SUB_PATH,
  DISPLAY_SUB_PATH,
  SCHEDULE_SUB_PATH,
  MY_MEDIA_SUB_PATH,
  CUSTOMER_DASHBOARD_SUB_PATH,
} from '../../constants/urlConstants';

import urlHelper from 'src/helpers/urlHelper';

function ProductList() {
  const [open, setOpen] = useState(false)

  const handleClick = () => {
    setOpen(!open)
  }

  return (
    <List>
      <ListItem button onClick={handleClick}>
        <ListItemIcon>
          <CastConnectedOutlinedIcon />
        </ListItemIcon>
        <ListItemText primary="People Counter" />
        {open ? <ArrowDownwardOutlinedIcon /> : <ArrowForwardOutlinedIcon />}
      </ListItem>

      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          <Tooltip
            placement="right"
            // title={!isMenuHidden ? '' : primaryText.toUpperCase()}
            title="Report"
          >
            <ListItem
              activeClassName="Mui-selected"
              button
              component={AdapterLink}
              to="/people-counter"
            >
              <ListItemIcon />
              <ListItemText primary="Report" primaryTypographyProps={{ noWrap: true }} />
              <ListItemIcon>
                <ArrowForwardOutlinedIcon />
              </ListItemIcon>
            </ListItem>
          </Tooltip>
        </List>
        <List component="div" disablePadding>
          <Tooltip
            placement="right"
            // title={!isMenuHidden ? '' : primaryText.toUpperCase()}
            title="Report"
          >
            <ListItem
              activeClassName="Mui-selected"
              button
              component={AdapterLink}
              to="/device-counter"
            >
              <ListItemIcon />
              <ListItemText primary="Devices" primaryTypographyProps={{ noWrap: true }} />
              <ListItemIcon>
                <ArrowForwardOutlinedIcon />
              </ListItemIcon>
            </ListItem>
          </Tooltip>
        </List>
      </Collapse>
    </List>
  )
}

const useStyles = makeStyles<Theme>(theme => {
  return createStyles({
    nested: {
      paddingLeft: theme.spacing(9)
    },
    subtitle: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      color: theme.palette.grey[500]
    },
    textBox: {
      display: 'flex',
      overflow: 'hidden',
      flexGrow: 1
    },
    iconsWorkspace: {
      display: 'flex',
      marginLeft: 3,
      height: theme.spacing(4),
      width: theme.spacing(4)
    },
    workspaceRow: {
      display: 'flex',
      justifyContent: 'space-between',
      height: theme.spacing(6),
      alignItems: 'center'
    },
    passwordFormControl: {
      margin: theme.spacing(3),
      width: '90%'
    }
  })
})

const originalMenuItems = [
  {
    requiresOptInAds: false,
    allowedRoles: null,
    primaryText: 'Displays',
    icon: <Tv />,
    to: DISPLAY_SUB_PATH,
  },
  {
    requiresOptInAds: false,
    allowedRoles: null,
    primaryText: 'Playlists',
    icon: <Subscriptions />,
    to: PLAYERLIST_SUB_PATH,
  },
  /*
  {
    requiresOptInAds: true,
    allowedRoles: null,
    primaryText: 'AdPlay',
    icon: <AdsIcon />,
    to: '/adplay',         //For cms/src/features/ads
  },
  */
  {
    requiresOptInAds: false,
    allowedRoles: null,
    primaryText: 'Schedule',
    icon: <Event />,
    to: SCHEDULE_SUB_PATH,
  },
  // {
  //   requiresOptInAds: false,
  //   allowedRoles: null,
  //   primaryText: 'Campaign Schedule',
  //   icon: <Event />,
  //   to: '/campaign-schedule',
  //
  // },
  {
    requiresOptInAds: false,
    allowedRoles: null,
    primaryText: 'My Media',
    icon: <PermMedia />,
    to: MY_MEDIA_SUB_PATH,
  },
  // {
  //   requiresOptInAds: false,
  //   allowedRoles: null,
  //   primaryText: 'Products',
  //   icon: <CirrusIcon />,
  //   to: MARKETING_SUB_PATH,
  // },
  {
    requiresOptInAds: false,
    allowedRoles: null,
    primaryText: 'Downloads',
    icon: <CloudDownload />,
    to: DOWNLOAD_PLAYER_SUB_PATH,
  },
  {
    requiresOptInAds: false,
    allowedRoles: null,
    primaryText: 'Help',
    icon: <HelpOutline />,
    to: HELP_PAGE_SUB_PATH,
  }
]

const menuItems = originalMenuItems.filter(item => {
  if (isHideFeature(WhiteLabelFeaturesEnum.HELP_SECTION) && item.to === HELP_PAGE_SUB_PATH) {
    return false
  }

  if (isHideFeature(WhiteLabelFeaturesEnum.DOWNLOADS) && item.to === DOWNLOAD_PLAYER_SUB_PATH) {
    return false
  }

  if (isHideFeature(WhiteLabelFeaturesEnum.PRODUCT_PAGE) && item.to === MARKETING_SUB_PATH) {
    return false
  }

  return true
})

const menuReportsItems = [
  {
    requiresOptInAds: false,
    allowedRoles: null,
    primaryText: 'Dashboard',
    icon: <DashboardOutlined />,
    to: CUSTOMER_DASHBOARD_SUB_PATH,
  },
  {
    requiresOptInAds: false,
    allowedRoles: null,
    primaryText: 'Proof of Performance',
    icon: <ListAlt />,
    to: PROOF_OF_PERFORMANCE_SUB_PATH,
  }
]

const AdapterLink = React.forwardRef<HTMLAnchorElement, NavLinkProps>((props, ref) => (
  <NavLink innerRef={ref as any} {...props} />
))

const NavigationItem = ({ icon, primaryText, to, isMenuHidden }) => {
  return (
    <Tooltip placement="right" title={!isMenuHidden ? '' : primaryText.toUpperCase()}>
      <ListItem activeClassName="Mui-selected" button component={AdapterLink} to={to}>
        <ListItemIcon>{icon}</ListItemIcon>
        <ListItemText primary={primaryText} primaryTypographyProps={{ noWrap: true }} />
      </ListItem>
    </Tooltip>
  )
}

const hasWorkspacePath = (workspaceId) => {
  const pathPattern = WORKSPACE_URL_PATTERN.replace(WORKSPACE_ID_PARAM, encodeURIComponent(workspaceId));

  return window.location.pathname.includes(pathPattern);
}

const checkForbidChangingWorkspace = () => {
  const path = window.location.pathname

  // In the inside page of the path, probably it's the editor page
  const forbiddenPaths = [
    `${CONDITIONAL_CONTENT_SUB_PATH}/`,
    `${PLAYERLIST_SUB_PATH}/`,
    MARKETING_SUB_PATH,
    DOWNLOAD_PLAYER_SUB_PATH,
    HELP_PAGE_SUB_PATH,
    PEOPLE_COUNTER_SUB_PATH,
    CAR_TRACKER_SUB_PATH,
    DEVICE_MANAGEMENT_SUB_PATH,
    BILLING_SUB_PATH,
    ACCOUNT_SUB_PATH,
    BACK_OFFICE_SUB_PATH,
  ]

  return forbiddenPaths.some(pathToCheck => path.includes(pathToCheck))
}

const NavContentView: FC<RouteComponentProps<any>> = ({ location }) => {
  const ctx = useContext(LayoutContext)

  const { navVariant, collapsible, collapsed } = ctx


  const history = useHistory()

  const isMenuHidden = (collapsible && collapsed) || navVariant === 'persistent'

  // 0 for close, 1 for open in create mode, 2 for open in edit mode
  const [open, setOpen] = useState(0)
  const [name, setName] = useState('')
  const [password, setPassword] = useState('')
  const [isShowPassword, setIsShowPassword] = useState(false)
  const [deleteModalOpen, setDeleteModalOpen] = useState(false)
  const [hasAnalyticsSubscription, setHasAnalyticsSubscription] = useState(false)

  const {
    me: {
      role,
      features,
      hasPassword,
      account: { id: accountId }
    }
  } = useUserInfo()
  const currentWorkspaceData = useCurrentWorkspace();

  const {
    loading: getWorkspacesLoading,
    data: getWorkspacesData,
    refetch: getWorkspacesRefetch
  } = useQuery(ApolloAPI.SC_GET_WORKSPACES, {
    fetchPolicy: 'cache-and-network'
  })

  const [createWorkspace] = useMutation(ApolloAPI.SC_CREATE_WORKSPACE)
  const [updateWorkspace] = useMutation(ApolloAPI.SC_UPDATE_WORKSPACE)
  const [deleteWorkspace] = useMutation(ApolloAPI.SC_DELETE_WORKSPACE)

  let currentWorkspace = currentWorkspaceData?.data

  useQuery(ApolloAPI.GQL_GET_ANALYTICS_SUBSCRIPTION_OF_CURRENT_WORKSPACE, {
    variables: {
      workspaceId: currentWorkspace?.id
    },
    skip: !currentWorkspace?.id,
    onCompleted: ({ getLatestSubscription }) => {
      if (getLatestSubscription) {
        setHasAnalyticsSubscription(getLatestSubscription.status === 'ACTIVE')
      }
    }
  })

  useSubscription(ANALYTIC_SUBSCRIPTION_STATE_CHANGED, {
    skip: !accountId,
    variables: { accountId },
    fetchPolicy: 'no-cache',
    onSubscriptionData: ({
      subscriptionData: {
        data: { analyticSubscriptionStateChanged }
      }
    }) => {
      setHasAnalyticsSubscription(analyticSubscriptionStateChanged?.state === 'ACTIVE')
    }
  })

  if (!currentWorkspace) {
    let storedWorkspace = getCurrentWorkspaceFromLocalStorage()

    if (storedWorkspace && getWorkspacesData?.workspaces) {
      const found = getWorkspacesData.workspaces.find(w => w.id === storedWorkspace.id)

      if (!found) {
        storedWorkspace = null
      }
    }

    currentWorkspace = storedWorkspace || getWorkspacesData?.workspaces[0]
  }

  const featuresOptInAds =
    Array.isArray(features) &&
    !!features.find(
      ({ id, status }) => id === '4945940f-5108-4df1-9490-23a13b289ecc' && status === 'ENABLED'
    )

  const { rememberedAdminUser } = useRememberedAdminUserInfo() || {}

  const filteredMenuItems = useMemo(
    () => items =>
      items.filter(item => {
        const notAllowedByRole = item.allowedRoles && !item.allowedRoles.includes(role)
        const notAllowedByAd = item.requiresOptInAds && !featuresOptInAds

        if (notAllowedByRole || notAllowedByAd) {
          return false
        }

        return true
      }),
    [featuresOptInAds, role]
  )

  const validateName = useCallback(() => {
    return name !== ''
  }, [name])

  const cancelAddWorkspace = useCallback(() => {
    setName('')
    setOpen(0)
  }, [])

  const handleSubmit = useCallback(async () => {
    try {
      if (open === 1) {
        const { data } = await createWorkspace({
          variables: {
            name: name
          }
        })
        if (!data.createWorkspace.success) {
          return handleError({
            error: new Error(
              formatServerError({
                code: data.createWorkspace.code,
                message: data.createWorkspace.message
              })
            ),
            userFriendlyMessage: data.createWorkspace.message
          })
        }

        setName('')
        setOpen(0)
        getWorkspacesRefetch()
        return toast.success('Workspace was successfully created')
      } else {
        const { data } = await updateWorkspace({
          variables: {
            workspaceId: currentWorkspace.id,
            name: name
          }
        })
        if (!data.updateWorkspace.success) {
          return handleError({
            error: new Error(
              formatServerError({
                code: data.updateWorkspace.code,
                message: data.updateWorkspace.message
              })
            ),
            userFriendlyMessage: data.updateWorkspace.message
          })
        }

        history.replace(urlHelper.getDisplayListPageUrl(data.updateWorkspace.workspace.id));
        setName('')
        setOpen(0)
        return toast.success('Workspace was successfully updated')
      }
    } catch (error) {
      return handleError({
        error,
        userFriendlyMessage: 'Error occurred. Workspace was not created.'
      })
    }
  }, [
    createWorkspace,
    currentWorkspace,
    history,
    name,
    open,
    getWorkspacesRefetch,
    updateWorkspace
  ])

  const handleDeleteWorkspace = useCallback(async () => {
    try {
      const { data: deletedData } = await deleteWorkspace({
        variables: {
          workspaceId: currentWorkspace.id,
          password
        }
      })
      if (!deletedData.deleteWorkspace.success) {
        return handleError({
          error: new Error(
            formatServerError({
              code: deletedData.deleteWorkspace.code,
              message: deletedData.deleteWorkspace.message
            })
          ),
          userFriendlyMessage: deletedData.deleteWorkspace.message
        })
      }
      setDeleteModalOpen(false)
      setPassword('')
      setIsShowPassword(false)

      const newCurrentWorkspace =
        getWorkspacesData.workspaces[0].id === currentWorkspace.id
          ? getWorkspacesData.workspaces[1]
          : getWorkspacesData.workspaces[0]

      history.push(urlHelper.getDisplayListPageUrl(newCurrentWorkspace?.id));
      getWorkspacesRefetch()
      return toast.success('Workspace was successfully deleted')
    } catch (error) {
      return handleError({
        error,
        userFriendlyMessage: 'Error occurred. Workspace was not deleted.'
      })
    }
  }, [
    currentWorkspace.id,
    getWorkspacesData.workspaces,
    deleteWorkspace,
    history,
    getWorkspacesRefetch,
    password
  ])

  const handleWorkspaceChange = async event => {
    const workspace = getWorkspacesData.workspaces.find(
      workspace => workspace.id === event.target.value
    );

    const nextWorkspaceId = workspace?.id;
    
    if (nextWorkspaceId) {
      const pathPattern = WORKSPACE_URL_PATTERN.replace(WORKSPACE_ID_PARAM, encodeURIComponent(currentWorkspace?.id));

      if (window.location.pathname.includes(pathPattern)) {
        const newPath = window.location.pathname.replace(
          encodeURIComponent(currentWorkspace?.id),
          encodeURIComponent(nextWorkspaceId),
        );

        history.push(newPath);
      }
    }
  }

  // const [openMonitoring, setOpenMonitoring] = useState(false)

  const theme = useTheme()
  const classes = useStyles(theme)
  const hasWorkspaceUrl = hasWorkspacePath(currentWorkspace?.id);
  const forbidChangingWorkspace = checkForbidChangingWorkspace()
  const isHideUserAdmin = isHideFeature(WhiteLabelFeaturesEnum.USER_ADMIN_PAGE)
  const isHideAnalytics = isHideFeature(WhiteLabelFeaturesEnum.ANALYTICS_PAGE)

  const renderWorkspace = () => {
    return (
      <Box
        className={classes.subtitle}
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
      >
        <Typography display="block" gutterBottom noWrap variant="overline">
          WORKSPACE
        </Typography>
        <Box className={classes.workspaceRow}>
          <Select
            className={classes.textBox}
            disabled={!hasWorkspaceUrl || forbidChangingWorkspace}
            id="workspaces"
            labelId="workspaces-label"
            onChange={handleWorkspaceChange}
            value={currentWorkspace.id}
          >
            {getWorkspacesData.workspaces.map(workspace => (
              <MenuItem key={workspace.id} value={workspace.id}>
                {workspace.name}
              </MenuItem>
            ))}
          </Select>
          <Tooltip title="EDIT WORKSPACE">
            <span>
              <IconButton
                className={classes.iconsWorkspace}
                data-test="edit workspace"
                disabled={
                  !hasWorkspaceUrl || currentWorkspace.memberRole.name !== WorkspaceRoles.OWNER
                }
                onClick={() => {
                  setName(currentWorkspace.name)
                  setOpen(2)
                }}
                size="small"
              >
                <CreateOutlined />
              </IconButton>
            </span>
          </Tooltip>
          <Tooltip title="DELETE WORKSPACE">
            <span>
              <IconButton
                className={classes.iconsWorkspace}
                data-test="delete workspace"
                disabled={
                  !hasWorkspaceUrl ||
                  getWorkspacesData.workspaces.length === 1 ||
                  currentWorkspace.memberRole.name !== WorkspaceRoles.OWNER
                }
                onClick={() => setDeleteModalOpen(true)}
                size="small"
              >
                <DeleteForever />
              </IconButton>
            </span>
          </Tooltip>
        </Box>
        <Box className={classes.workspaceRow}>
          <InputLabel htmlFor="new-workspace"> Create Workspace </InputLabel>
          <Tooltip title="ADD WORKSPACE">
            <span>
              <IconButton
                className={classes.iconsWorkspace}
                data-test="add workspace"
                disabled={!hasWorkspaceUrl}
                onClick={() => setOpen(1)}
                size="small"
                style={{ margin: 0 }}
              >
                <AddCircleOutline />
              </IconButton>
            </span>
          </Tooltip>
        </Box>
      </Box>
    )
  }

  return (
    <List component="nav" style={{ maxHeight: '100%', overflow: 'auto', paddingBottom: '36px' }}>
      <ThemeProvider theme={MainTheme}>
        <Dialog
          aria-labelledby="select-workspace-name"
          onClose={cancelAddWorkspace}
          open={open !== 0}
        >
          <FormControl>
            <DialogTitle title="select-workspace-name">
              Choose a name for your Workspace
            </DialogTitle>
            <DialogContent>
              <div>
                <TextField
                  id="outlined-name"
                  label="Workspace Name"
                  margin="normal"
                  onChange={e => setName(e.target.value)}
                  value={name}
                />
              </div>
            </DialogContent>
            <DialogActions>
              <Button onClick={cancelAddWorkspace} type="button">
                Cancel
              </Button>
              <Button
                color="primary"
                disabled={!validateName()}
                onClick={handleSubmit}
                variant="contained"
              >
                Save
              </Button>
            </DialogActions>
          </FormControl>
        </Dialog>
        <Dialog aria-labelledby="delete-workspace" open={deleteModalOpen}>
          <FormControl>
            <DialogTitle title="delete-workspace">Delete Workspace</DialogTitle>
            <DialogContent>
              <Typography variant="body1">
                Deleting your workspace will permanently delete all displays, playlists and media
                associated with this workspace.
                <br />
                <br />
                {hasPassword
                  ? 'Please enter your password below if you wish to permanently delete yuour workspace.'
                  : 'Please type “permanently delete” below if you wish to permanently delete your workspace.'}
              </Typography>
            </DialogContent>
          </FormControl>
          <FormControl className={classes.passwordFormControl} fullWidth variant="outlined">
            <InputLabel htmlFor="standard-adornment-password">Type "permanently delete"</InputLabel>
            <OutlinedInput
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    edge="end"
                    onClick={() => setIsShowPassword(!isShowPassword)}
                    onMouseDown={() => {}}
                  />
                </InputAdornment>
              }
              id="outlined-adornment-password"
              labelWidth={hasPassword ? 75 : 190}
              onChange={e => setPassword(e.target.value)}
              type="text"
              value={password}
            />
          </FormControl>
          <FormControl>
            <DialogActions>
              <Button onClick={() => setDeleteModalOpen(false)} type="button">
                Cancel
              </Button>
              <Button
                color="secondary"
                data-test="delete button"
                disabled={password.length < 1}
                onClick={handleDeleteWorkspace}
              >
                Delete
              </Button>
            </DialogActions>
          </FormControl>
        </Dialog>
      </ThemeProvider>
      {!getWorkspacesLoading && !currentWorkspaceData?.loading && !isMenuHidden && (
        <Hidden only="sm">
          {renderWorkspace()}
          <Box className={classes.subtitle} display={isMenuHidden ? 'none' : 'block'}>
            <Typography display="block" gutterBottom noWrap variant="overline">
              CONTENT MANAGEMENT
            </Typography>
          </Box>
        </Hidden>
      )}

      {filteredMenuItems(menuItems).map(({ primaryText, icon, to }, key) => (
        <NavigationItem
          icon={icon}
          isMenuHidden={isMenuHidden}
          key={key}
          primaryText={primaryText}
          to={`${WORKSPACE_URL_PATTERN.replace(WORKSPACE_ID_PARAM, encodeURIComponent(currentWorkspace?.id))}${to}`}
        />
      ))}
      {!isHideAnalytics && (
        <Hidden only="sm">
          <Box className={classes.subtitle} display={isMenuHidden ? 'none' : 'block'}>
            <Typography display="block" gutterBottom noWrap variant="overline">
              Analytics
            </Typography>
          </Box>
        </Hidden>
      )}
      {filteredMenuItems([
        {
          requiresOptInAds: false,
          allowedRoles: null,
          primaryText: 'People Counter',
          icon: <People />,
          to: '/people-counter',
          hideItem: isHideAnalytics
        },
        {
          requiresOptInAds: false,
          allowedRoles: null,
          primaryText: 'Car Tracker',
          icon: <DirectionsCar />,
          to: '/car-tracker',
          hideItem: isHideAnalytics
        },
        {
          requiresOptInAds: false,
          allowedRoles: null,
          primaryText: 'Device Management',
          icon: <Videocam />,
          to: '/device-management',
          hideItem: isHideAnalytics
        },
        {
          requiresOptInAds: false,
          allowedRoles: null,
          primaryText: 'Conditional Content',
          icon: <img alt="conditional-content icon" src={displayAssets.conditionContent} />,
          to: '/conditional-content',
          hideItem: isHideAnalytics || !hasAnalyticsSubscription
        }
      ]).map(({ primaryText, icon, to, hideItem }, key) => {
        if (hideItem) return null
        return (
          <NavigationItem
            icon={icon}
            isMenuHidden={isMenuHidden}
            key={key}
            primaryText={primaryText}
            to={`${WORKSPACE_URL_PATTERN.replace(WORKSPACE_ID_PARAM, encodeURIComponent(currentWorkspace?.id))}${to}`}
          />
        )
      })}
      <Hidden only="sm">
        <Box className={classes.subtitle} display={isMenuHidden ? 'none' : 'block'}>
          <Typography display="block" gutterBottom noWrap variant="overline">
            Reports
          </Typography>
        </Box>
      </Hidden>
      {filteredMenuItems(menuReportsItems).map(({ primaryText, icon, to }, key) => (
        <NavigationItem
          icon={icon}
          isMenuHidden={isMenuHidden}
          key={key}
          primaryText={primaryText}
          to={`${WORKSPACE_URL_PATTERN.replace(WORKSPACE_ID_PARAM, encodeURIComponent(currentWorkspace?.id))}${to}`}
        />
      ))}
      {/*<ProductList />*/}

      {/* </Collapse> */}
      {/* BackOffice */}
      {role === 'admin' && !isHideUserAdmin && (
        <>
          <Hidden only="sm">
            <Box className={classes.subtitle} display={isMenuHidden ? 'none' : 'block'}>
              <Typography display="block" gutterBottom noWrap variant="overline">
                Admin Panel
              </Typography>
            </Box>
          </Hidden>
          <List component="div" disablePadding>
            <Tooltip placement="right" title={!isMenuHidden ? '' : 'USERS'}>
              <ListItem
                button
                component={Link}
                selected={location.pathname === '/back-office'}
                to={urlHelper.getBackOfficePageUrl(currentWorkspace?.id)}
              >
                <ListItemIcon>
                  <People />
                </ListItemIcon>
                <ListItemText primaryTypographyProps={{ noWrap: true }}>Users</ListItemText>
              </ListItem>
            </Tooltip>
          </List>
          {/* </Collapse> */}
        </>
      )}
    </List>
  )
}

export const NavContent = withRouter(NavContentView)
