import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'ramda'
import { withWidth } from '@material-ui/core'
import { withStyles, createStyles } from '@material-ui/styles'
import { presets } from '../utils/layout-presets'

const keys = ['xs', 'sm', 'md', 'lg', 'xl']

export const getScreenValue = (ctx, currentScreen, defaultValue) => {
  if (ctx === null || ctx === undefined) {
    return defaultValue
  }

  if (typeof ctx !== 'object') {
    return ctx
  }

  let index = keys.indexOf(currentScreen)

  while (index >= 0) {
    if (ctx[keys[index]] !== undefined) {
      return ctx[keys[index]]
    }
    index -= 1
  }

  return defaultValue
}

const createScreenPropTypes = (valPropTypes) =>
  PropTypes.shape({
    xs: valPropTypes,
    sm: valPropTypes,
    md: valPropTypes,
    lg: valPropTypes,
    xl: valPropTypes
  })

const initialConfig = presets.createCozyLayout({
  headerPosition: 'fixed',
  headerHeight: 64
})

export const LayoutContext = React.createContext(initialConfig)

const styles = createStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    height: '100vh',
    overflowY: 'auto'
  }
})

interface RootState {
  open: boolean
  collapsed: boolean
  setCollapse: (param: any) => void
  setOpen: (param: any) => void
}

interface RootProps {
  className?: string
  classes: any
  component?: any
  config?: any
  width: string
  style?: any
}

class DefaultLayoutView extends React.Component<RootProps, RootState> {
  static defaultProps = {
    className: '',
    component: 'div',
    config: initialConfig
  }

  static propTypes = {
    // from HOC
    classes: PropTypes.shape({}).isRequired,
    width: PropTypes.string.isRequired,
    // general
    className: PropTypes.string,
    component: PropTypes.elementType,
    config: PropTypes.shape({
      clipped: PropTypes.oneOfType([PropTypes.bool, createScreenPropTypes(PropTypes.bool)]),
      collapsible: PropTypes.oneOfType([PropTypes.bool, createScreenPropTypes(PropTypes.bool)]),
      collapsedWidth: PropTypes.oneOfType([
        PropTypes.number,
        createScreenPropTypes(PropTypes.number)
      ]),
      collapsed: PropTypes.bool,
      navVariant: PropTypes.oneOfType([
        PropTypes.oneOf(['permanent', 'persistent', 'temporary']),
        createScreenPropTypes(PropTypes.oneOf(['permanent', 'persistent', 'temporary']))
      ]),
      navWidth: PropTypes.oneOfType([PropTypes.number, createScreenPropTypes(PropTypes.number)]),
      navAnchor: PropTypes.oneOfType([
        PropTypes.oneOf(['left', 'bottom']),
        createScreenPropTypes(PropTypes.oneOf(['left', 'bottom']))
      ]),
      headerPosition: PropTypes.oneOfType([
        PropTypes.oneOf(['static', 'relative', 'sticky', 'fixed', 'absolute']),
        createScreenPropTypes(
          PropTypes.oneOf(['static', 'relative', 'sticky', 'fixed', 'absolute'])
        )
      ]),
      squeezed: PropTypes.oneOfType([PropTypes.bool, createScreenPropTypes(PropTypes.bool)])
    }),
    children: PropTypes.oneOfType([PropTypes.node, PropTypes.func]).isRequired
  }

  constructor (props) {
    super(props)

    this.state = {
      open: false,
      collapsed: false,
      setCollapse: this.setCollapse,
      setOpen: this.setOpen
    }
  }

  setCollapse = (val) =>
    this.setState(({ collapsed }) => ({
      collapsed: typeof val === 'object' ? !collapsed : val
    }))

  setOpen = (val) =>
    this.setState(({ open }) => ({
      open: typeof val === 'object' ? !open : val
    }))

  render () {
    const {
      className,
      component: Component,
      classes,
      width,
      children,
      config,
      ...props
    } = this.props
    const {
      clipped,
      collapsible,
      collapsedWidth,
      navVariant,
      navWidth,
      navAnchor,
      headerPosition,
      headerHeight,
      squeezed
    } = config
    const value = {
      ...this.state,
      clipped: getScreenValue(clipped, width, initialConfig.clipped),
      collapsible: getScreenValue(collapsible, width, initialConfig.collapsible),
      collapsedWidth: getScreenValue(collapsedWidth, width, initialConfig.collapsedWidth),
      navVariant: getScreenValue(navVariant, width, initialConfig.navVariant),
      navWidth: getScreenValue(navWidth, width, initialConfig.navWidth),
      navAnchor: getScreenValue(navAnchor, width, initialConfig.navAnchor),
      headerPosition: getScreenValue(headerPosition, width, initialConfig.headerPosition),
      squeezed: getScreenValue(squeezed, width, initialConfig.squeezed),
      screen: width
    }

    return (
      <LayoutContext.Provider value={value}>
        <Component
          className={`${className} ${classes.root}`}
          style={{ paddingTop: headerHeight }}
          {...props}
        >
          {typeof children === 'function' ? children(value) : children}
        </Component>
      </LayoutContext.Provider>
    )
  }
}

const enhance = compose(
  withWidth(),
  withStyles(styles, { name: 'MuiRoot' })
)

export const DefaultLayout = enhance(DefaultLayoutView)
