import React, { useState } from 'react'
import { SketchPicker } from 'react-color'
import { GradientPickerPopover } from 'react-linear-gradient-picker'
import {
  styled
} from '@material-ui/core'

const rgbToRgba = (rgb, a = 1) => rgb
  .replace('rgb(', 'rgba(')
  .replace(')', `, ${a})`)

const WrappedSketchPicker = ({ onSelect, ...rest }) => {
  return (
    <SketchPicker
      {...rest}
      // In case we want opacity being managed later on, remove 'disableAlpha' on WrappedSketchPicker
      color={rgbToRgba(rest.color, rest.opacity)}
      onChange={c => {
        const { r, g, b, a } = c.rgb
        onSelect(`rgb(${r}, ${g}, ${b})`, a)
      }}
    />
  )
}

const ContainerColorPicker = styled('div')({
  display: 'contents',
  '@global': {
    '.popover': {
      display: 'contents',
      '& .gp > div': {
        padding: '0px !important'
      },
      // INPUT BOXES
      '& input': {
        height: '40px',
        borderRadius: '8px',
        fontSize: '20px !important'
      },
      // INPUT SECTION BOTTOM
      '& > div:nth-child(5) > div > div': {
        display: 'flex',
        flexDirection: 'column-reverse'
      },
      // LABELS
      '& span': {
        fontWeight: 700
      },
      // ANGLE BOX
      '& > div:last-child': {
        borderTop: 'none !important'
      }
    },
    // EXTEND WIDTH OF CONTAINER
    '.sketch-picker': {
      width: '100% !important',
      marginTop: '5px',
      // COLOR SELECTOR
      '& > div:first-child': {
        height: '149px',
        marginBottom: '16px',
        paddingBottom: '0!important',
        width: '100% !important'
      },
      // COLOR SLIDER
      '& > div:nth-child(2)': {
        '& div:first-child': {
          borderRadius: '5px',
          height: '12px'
        }
      },
      // COLOR BOX
      '& > div:last-child': {
        borderTop: 'none !important',
        margin: '0px -15px!important'
      }
    },
    '.trigger': {
      display: 'none'
    },
    '.overlay': {
      display: 'none'
    },
    '.gpw': {
      padding: 0
    }
  }
})

// color and angle values to linear-gradient understable by css
const unFormatBackground = (valueSet) => {
  if (!valueSet) return null
  var linearGradientObject = valueSet.substring(valueSet.indexOf('(') + 1, valueSet.lastIndexOf(')'))
  // Use regex to unmountlinear-gradient rule
  linearGradientObject = linearGradientObject.split(/,(?![^(]*\))(?![^"']*["'](?:[^"']*["'][^"']*["'])*[^"']*$)/)
  const angle = linearGradientObject[0].substring(0, linearGradientObject[0].lastIndexOf('d'))
  linearGradientObject.shift()
  var colors = []
  for (const color of linearGradientObject) {
    colors.push({
      color: color.substring(0, color.lastIndexOf(')') + 1),
      offset: parseInt(color.substring(color.lastIndexOf(' ') + 1, color.lastIndexOf('%'))) / 100
    })
  }
  return { angle, colors }
}

// color and angle values to linear-gradient understable by css
const formatBackground = (angle, valueSet) => {
  var formatted = 'linear-gradient(' + (angle || '90') + 'deg,'
  for (const step of valueSet) {
    formatted += ' ' + step.color + ' ' + (step.offset * 100) + '%,'
  }
  formatted = formatted.slice(0, -1) + ')'
  return formatted
}

export const CustomColorGradientPicker = (props) => {
  const [angle, setAngle] = useState(unFormatBackground(props.gradient).angle
    ? parseInt(unFormatBackground(props.gradient).angle)
    : 90)

  const [palette, setPalette] = useState(unFormatBackground(props.gradient).colors)

  return (
    <ContainerColorPicker>
      <GradientPickerPopover
        style={{ display: 'contents' }}
        {...{
          open: true,
          setOpen: () => {},
          angle: angle,
          setAngle: (e) => {
            if (e === 0) e = 360
            setAngle(e)
            props.onSlideUpdate(formatBackground(e, palette))
          },
          showAnglePicker: true,
          maxStops: 3,
          palette: palette,
          onPaletteChange: (e) => {
            setPalette(e)
            props.onSlideUpdate(formatBackground(angle, e))
          }
        }}
      >
        <WrappedSketchPicker
          disableAlpha
          onSelect
        />
      </GradientPickerPopover>
    </ContainerColorPicker>
  )
}
