import React, { FC } from 'react'
import { SketchPicker, SketchPickerProps } from 'react-color'
import { Box, Theme } from '@material-ui/core'
import { styled } from '@material-ui/styles'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import {
  TNormalizedSlide
} from 'src/screenhub-shared/types'
import { CustomColorGradientPicker } from './custom-color-gradient-picker'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    selected: {
      color: 'rgb(255, 255, 255) !important',
      background: '#1D93F3'
    }
  })
)

const CustomBox = styled(Box)(({ theme }) => ({
  width: '300px',
  padding: '17px',
  background: 'rgb(255, 255, 255)',
  borderRadius: '8px',
  boxShadow: 'rgb(0 0 0 / 15%) 0px 0px 0px 1px, rgb(0 0 0 / 15%) 0px 8px 16px',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-around'
}))

const Header = styled(Box)(({ theme }) => ({
  display: 'flex',
  paddingBottom: '16px',
  alignItems: 'center',
  justifyContent: 'space-between'
}))

const TypeBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  borderRadius: '6px',
  border: '1px solid #1D93F3',
  cursor: 'pointer'
}))

const SubTypeBox = styled(Box)(({ theme }) => ({
  padding: '4px',
  color: '#1D93F3',
  paddingLeft: '12px',
  paddingRight: '12px'
}))

const StyledSketchPicker = styled(SketchPicker)(({ theme }) => ({
  display: 'contents',
  // INPUT BOXES
  '& input': {
    height: '40px',
    borderRadius: '8px',
    fontSize: '20px !important'
  },
  // COLOR SELECTOR
  '& > div:first-child': {
    height: '149px',
    marginBottom: '16px',
    paddingBottom: '0!important'
  },
  // COLOR SLIDER
  '& > div:nth-child(2)': {
    '& div:first-child': {
      borderRadius: '5px',
      height: '12px'
    }
  },
  // INPUT SECTION BOTTOM
  '& > div:nth-child(3) > div > div': {
    display: 'flex',
    flexDirection: 'column-reverse'
  },
  // LABELS
  '& span': {
    fontWeight: 700
  },
  // COLOR BOXES
  '& > div:last-child': {
    borderTop: 'none !important',
    margin: '0px -15px!important'
  }
}))

interface TProps extends SketchPickerProps {
  onChange?: (hex: any) => void
  onChangeComplete: (hex: any) => void
  angle?: number
  setAngle?(open: number): void
  palette?: object
  onUpdateColor?(color: any): void,
  onUpdateGradient?(gradient: any): void,
  onUpdateUseGradient?(useGradient: any): void,
  currentSlide: TNormalizedSlide
}

export const CustomColorPickerWithGradient: FC<TProps> = ({
  onUpdateColor,
  onUpdateGradient,
  onUpdateUseGradient,
  currentSlide
}
) => {
  if (!currentSlide.slideConfig.background) currentSlide.slideConfig.background = 'linear-gradient(90deg,  rgb(0, 0, 0) 0%,  rgb(0, 0, 0) 100%)'
  if (!currentSlide.slideConfig.backgroundColor) currentSlide.slideConfig.backgroundColor = '#000000'
  if (!currentSlide.slideConfig.useGradient) currentSlide.slideConfig.useGradient = false

  const classes = useStyles({})

  return (
    <CustomBox style={{ height: currentSlide.slideConfig.useGradient ? '505px' : '400px' }}>
      <Header>
        <span style={{ fontSize: '18px' }}>
          Color
        </span>
        <TypeBox>
          <SubTypeBox
            className={!currentSlide.slideConfig.useGradient ? classes.selected : ''}
            onClick={() => onUpdateUseGradient(false)}
          >
            Solid
          </SubTypeBox>
          <SubTypeBox
            className={currentSlide.slideConfig.useGradient ? classes.selected : ''}
            onClick={() => onUpdateUseGradient(true)}
          >
            Gradient
          </SubTypeBox>
        </TypeBox>
      </Header>

      {currentSlide.slideConfig.useGradient === false &&
        <StyledSketchPicker
          color={currentSlide.slideConfig.backgroundColor}
          disableAlpha
          onChange={({ hex }) => onUpdateColor(hex)}
          onChangeComplete={({ hex }) => onUpdateColor(hex)}
        />}

      {currentSlide.slideConfig.useGradient === true &&
        <CustomColorGradientPicker
          disableAlpha
          gradient={currentSlide.slideConfig.background}
          onChange={(hex) => onUpdateGradient(hex)}
          onChangeComplete={(hex) => onUpdateGradient(hex)}
          onSlideUpdate={(hex) => onUpdateGradient(hex)}
          open={currentSlide.slideConfig.useGradient}
        />}
    </CustomBox>
  )
}
