import React, { FC } from 'react'
import { Paper, Typography, Theme, Button } from '@material-ui/core'
import { makeStyles, createStyles, useTheme } from '@material-ui/styles'
import { HtmlTooltip } from '../atoms/html-tooltip'
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined'
import { PlanEnums } from 'src/screenhub-shared/enum/stripe.enum'
import { isHideFeature } from '../../helpers/white-label-helper'
import { WhiteLabelFeaturesEnum } from '../../helpers/white-label.enum'

interface TProps {
  onButtonClick: () => void
  subscription: any
}

export const SubscriptionMenu: FC<TProps> = ({ onButtonClick, subscription }) => {
  const usePaperClasses = makeStyles<Theme>({
    root: {
      // backgroundColor: blue[500],
      width: '100%',
      minHeight: 32,
      display: 'flex',
      alignItems: 'center',
      position: 'relative',
      zIndex: 5,
      boxShadow: 'none',
      backgroundColor: 'unset'
    }
  })

  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      root: {
        // ...theme.typography.button,
        // backgroundColor: theme.palette.background.paper,
        // padding: theme.spacing(1),
      }
    })
  )

  const theme = useTheme()
  const classes = useStyles()
  const paperClasses = usePaperClasses(theme)

  let displayNumSuffix = ''

  if (subscription.qty && subscription.qty > 1) {
    displayNumSuffix = 's'
  }

  const isHidePaywall = isHideFeature(WhiteLabelFeaturesEnum.PAYWALL)

  if (isHidePaywall) {
    return <></>
  }

  return (
    <Paper classes={paperClasses} elevation={1} square>
      <Typography className={classes.root}>
        Your current plan includes:&nbsp;
        <b>
          {subscription.qty} Display{displayNumSuffix}
        </b>
        &nbsp;
      </Typography>
      {(subscription.planName === PlanEnums.CIRRUS_HARDWARE_PLAN ||
        subscription.planName === PlanEnums.CIRRUS_WINDOWS_PLAN) && (
        <HtmlTooltip
          title={
            <React.Fragment>
              <Typography style={{ fontSize: 14, fontWeight: 400 }}>{subscription.cirrusStandardQty} FREE display(s) included</Typography>
            </React.Fragment>
          }
          interactive={true}
          leaveDelay={100}
          placement="top"
        >
          <InfoOutlinedIcon />
        </HtmlTooltip>
      )}
      <Button
        color="primary"
        component="div"
        onClick={() => {
          onButtonClick()
        }}
      >
        Manage
      </Button>
    </Paper>
  )
}
