import React, { FC, useMemo } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { Divider, Theme, Box } from '@material-ui/core'
import { styled } from '@material-ui/styles'
import { SunshineSmallLogo, SmallLogo } from 'src/screenhub-shared/logos'
import BetaLogo from '../assets/logo.svg'
import SunshineLogo from '../assets/sunshineLogo.svg'
import { getWhiteLabelColor, isWhiteLabelLogo } from '../../helpers/white-label-helper'

interface NavHeaderProp {
  collapsed: boolean
  collapsible: boolean
}

const LogoWrapper = styled('div')<Theme>({
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start'
})

const LogoImage = styled('img')<Theme>({
  // objectFit: 'contain',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start'
})

export const NavHeader: FC<NavHeaderProp> = ({ collapsed, collapsible }) => {
  const logoComponent = useMemo(
    () =>
      !collapsible || collapsed ? (
        isWhiteLabelLogo() ? (
          <SunshineSmallLogo variant="white" width={32} />
        ) : (
          <SmallLogo variant="white" width={32} />
        )
      ) : // TODO: Generalize this to use the white label logo
      isWhiteLabelLogo() ? (
        <LogoImage src={SunshineLogo} height={42} />
      ) : (
        <LogoImage src={BetaLogo} height={42} width={156} />
      ),
    [collapsed, collapsible]
  )

  return (
    <>
      <div
        style={{
          paddingLeft: !collapsible || collapsed ? 12 : 16,
          transition: '0.3s',
          minHeight: 64,
          backgroundColor: getWhiteLabelColor()
        }}
      >
        <Link to="/">
          <LogoWrapper>{logoComponent}</LogoWrapper>
        </Link>
      </div>
      <Divider />
    </>
  )
}

NavHeader.propTypes = {
  collapsed: PropTypes.bool.isRequired,
  collapsible: PropTypes.bool.isRequired
}
