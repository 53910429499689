import { ApolloClient } from 'apollo-client'
import { setContext } from 'apollo-link-context'
import { InMemoryCache, defaultDataIdFromObject } from 'apollo-cache-inmemory'
import { ApolloLink, split, concat } from 'apollo-link'
import { getMainDefinition } from 'apollo-utilities'
import { debounceLink, errorLink, uploadLink, wsLink, retryLink } from './links'

const composedHttpLinks = ApolloLink.from([debounceLink, uploadLink])

const cache = new InMemoryCache({
  dataIdFromObject: object => {
    switch (object.__typename) {
      case 'Panel':
        // @ts-ignore
        return `${object.__typename}${object.controllerId}${object.portId}${object.id}`
      case 'PanelStatistic':
        // @ts-ignore
        return `${object.__typename}${object.controllerId}${object.portId}${object.panelId}`
      default:
        return defaultDataIdFromObject(object)
    }
  }
})

if (process.env.NODE_ENV === 'development') {
  // @ts-ignore
  window.secretVariableToStoreCache = cache
}

const authLink = setContext((_, { headers }) => {
  const token = localStorage.getItem('token')
  return {
    headers: {
      ...headers,
      source: 'screenhub',
      authorization: token ? `Bearer ${token}` : ''
    }
  }
})

const requestMiddleware = new ApolloLink((operation, forward) => {
  const { operationName, variables } = operation;
  if (
    [
      'MonitoringPostCommandToController'
    ].includes(operationName)
  ) {
    // add request ID
    const requestId = Date.now().toString();
    variables.requestId = requestId;
    // show waiting dialog
  }
  return forward(operation);
})

export const client = new ApolloClient({
  link: ApolloLink.from([
    authLink,
    errorLink,
    retryLink,
    split(
      ({ query }) => {
        const definition = getMainDefinition(query)

        return definition.kind === 'OperationDefinition' && definition.operation === 'subscription'
      },
      wsLink,
      concat(requestMiddleware, composedHttpLinks),
    )
  ]),
  cache
})
