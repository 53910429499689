export enum PlayerCommandEnum {
  reload = 'reload'
}

export enum DisplayTypeEnum {
  display = 'display',
  group = 'group',
  groupedDisplay = 'groupedDisplay'
}

export enum DisplayTabEnum  {
  all = 'all',
  display = 'display',
  group = 'group'
}
