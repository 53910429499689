import gql from 'graphql-tag'
import { COMM_USER_INFO_F } from '../@common/fragments'
import {
  AuthSignInMutation,
  AuthSignInMutationVariables,
  AuthImpersonateUserMutation,
  AuthImpersonateUserMutationVariables
} from '../../types/AuthSignInMutation'
import { AuthSignUpMutation, AuthSignUpMutationVariables } from '../../types/AuthSignUpMutation'
import {
  AuthForgotPasswordMutation,
  AuthForgotPasswordMutationVariables
} from '../../types/AuthForgotPasswordMutation'
import {
  AuthResetPasswordMutation,
  AuthResetPasswordMutationVariables
} from '../../types/AuthResetPasswordMutation'
import {
  AuthGoogleAuthMutation,
  AuthGoogleAuthMutationVariables
} from '../../types/AuthGoogleAuthMutation'
import {
  AuthVerifyEmailMutation,
  AuthVerifyEmailMutationVariables
} from '../../types/AuthVerifyEmailMutation'
import {
  AuthResendVerificationEmailMutation,
  AuthResendVerificationEmailMutationVariables
} from '../../types/AuthResendVerificationEmailMutation'
import {
  UserActionRemoveUserTwoFactorAuthenticationMutation,
  UserActionRemoveUserTwoFactorAuthenticationVariables,
  UserActionSetUserRoleMutation,
  UserActionSetUserRoleVariables,
  UserActionDeleteUserMutation,
  UserActionDeleteUserVariables,
  UserActionAddUserMutation,
  UserActionAddUserVariables,
  UserActionSetUserHardwareMutation,
  UserActionSetUserHardwareVariables
} from '../../types/UserActionMutation'

export const AUTH_SIGNUP_M = gql<
  AuthSignUpMutation,
  AuthSignUpMutationVariables
  >`

  mutation AuthSignUpMutation ($input: SignupUserInput!) {
    signupUser(input: $input) {
      code
      success
      message
      user {
        ...CommUserInfoFragment
      }
    }
  }
  ${COMM_USER_INFO_F}
`

export const AUTH_SIGNUP_PROVIDER_M = gql`
    mutation signupProvider($input: SignupProviderInput!) {
        signupProvider(input: $input) {
            code
            success
            message
            user {
                ...CommUserInfoFragment
            }
        }
    }
    ${COMM_USER_INFO_F}
`

export const AUTH_SIGNIN_M = gql<
  AuthSignInMutation,
  AuthSignInMutationVariables
  >`
  mutation AuthSignInMutation ($input: LoginUserInput!) {
    loginUser(input: $input) {
      code
      success
      message
      type
      user {
        ...CommUserInfoFragment
      }
      jwt
    }
  }
  ${COMM_USER_INFO_F}
`

export const AUTH_FORGOT_PASSWORD_M = gql<
  AuthForgotPasswordMutation,
  AuthForgotPasswordMutationVariables
  >`
  mutation AuthForgotPasswordMutation ($input: EmailInput!) {
    forgotPassword(input: $input) {
      code
      success
      message
    }
  }
`

export const AUTH_RESET_PASSWORD_M = gql<
  AuthResetPasswordMutation,
  AuthResetPasswordMutationVariables
  >`
  mutation AuthResetPasswordMutation ($input: ResetPasswordInput!) {
    resetPassword(input: $input) {
      code
      success
      message
    }
  }
`

export const AUTH_GOOGLE_AUTH_M = gql<
  AuthGoogleAuthMutation,
  AuthGoogleAuthMutationVariables
  >`
  mutation AuthGoogleAuthMutation ($input: SignInGoogle!) {
    authViaGoogle(input: $input) {
      code
      success
      message
      type
      user {
        ...CommUserInfoFragment
      }
      jwt
    }
  }
  ${COMM_USER_INFO_F}
`

export const AUTH_VERIFY_EMAIL_M = gql<
  AuthVerifyEmailMutation,
  AuthVerifyEmailMutationVariables
  >`
  mutation AuthVerifyEmailMutation ($input: VerifyEmailInput!) {
    verifyEmail(input: $input) {
      code
      success
      message
      user {
        ...CommUserInfoFragment
      }
    }
  }
  ${COMM_USER_INFO_F}
`

export const AUTH_RESEND_VERIFICATION_EMAIL_M = gql<
  AuthResendVerificationEmailMutation,
  AuthResendVerificationEmailMutationVariables
  >`
  mutation AuthResendVerificationEmailMutation ($input: EmailInput!) {
    resendVerificationEmail(input: $input) {
      code
      success
      message
    }
  }
`

export const AUTH_IMPERSONATE_USER = gql<
  AuthImpersonateUserMutation,
  AuthImpersonateUserMutationVariables
  >`
  mutation AuthImpersonateMutation ($input: ImpersonateUserInput!) {
    impersonateUser(input: $input) {
      code
      success
      message
      user {
        ...CommUserInfoFragment
      }
      jwt
    }
  }
  ${COMM_USER_INFO_F}
`

export const USER_ACTION_REMOVE_USER_TWO_FACTOR_AUTHENTICATION = gql<
  UserActionRemoveUserTwoFactorAuthenticationMutation,
  UserActionRemoveUserTwoFactorAuthenticationVariables
  >`
  mutation UserActionRemoveUserTwoFactorAuthenticationMutation ($input: UserActionInput!) {
    removeUserTwoFactorAuthentication(input: $input) {
      code
      success
      message
    }
  }
`

interface Create2FAInput {
  pin: String
  password: String
  googleId: String,
  googleToken: String,
}

export const AUTH_CREATE_2FA_M = gql`
  mutation AuthCreate2FA ( $input: Create2FAInput!) {
    create2FA (input: $input) {
      code
      success
      message
      secret
      qrCode
      type
    }
  }
`

interface Delete2FAInput {
  pin: String
  password: String
}

export const AUTH_DELETE_2FA_M = gql`
  mutation AuthCreate2FA ( $input: Delete2FAInput!) {
    delete2FA (input: $input) {
      code
      success
      message
    }
  }
`

export const USER_ACTION_SET_USER_ROLE = gql<
  UserActionSetUserRoleMutation,
  UserActionSetUserRoleVariables
  >`
  mutation UserActionSetUserRoleMutation ($input: UserActionInput!, $role: UserRole!) {
    setUserRole(input: $input, role: $role) {
      code
      success
      message
    }
  }
`

export const USER_ACTION_SET_USER_HARDWARE = gql<
    UserActionSetUserHardwareMutation,
    UserActionSetUserHardwareVariables
>`
  mutation UserActionSetUserHardwareMutation ($input: UserActionInput!) {
    setUserHardware(input: $input) {
      code
      success
      message
    }
  }
`

export const USER_ACTION_DELETE_USER = gql<
  UserActionDeleteUserMutation,
  UserActionDeleteUserVariables
  >`
  mutation UserActionDeleteUserMutation ($input: UserActionInput!) {
    deleteUser(input: $input) {
      code
      success
      message
    }
  }
`

export const USER_ACTION_ADD_USER = gql<
  UserActionAddUserMutation,
  UserActionAddUserVariables
  >`
  mutation UserActionAddUserMutation ($input: AddUserInput!) {
    addUser(input: $input) {
      code
      success
      message
    }
  }
`
