import gql from 'graphql-tag'

export const SC_DELETE_SCHEDULE_EVENT_M = gql`
  mutation ScDeleteScheduleMutation ($eventId: String!, $id: String!, $isGroup: Boolean!, $recurringEventId: String) {
    deleteScheduleEvent(eventId: $eventId, id: $id, isGroup: $isGroup, recurringEventId: $recurringEventId ) {
      code
      success
      message
    }
  }
`

export const SC_CREATE_SCHEDULE_EVENT_M = gql`
  mutation ScCreateScheduleMutation ($input: ScheduleEventInput!) {
    createScheduleEvent(input: $input) {
      code
      success
      message

      scheduleEvent {
        id
        timeZone
        start
        end
        presentation {
          name
          id
        }
        eventType
        recurringEventId
        recurrenceRule
      }
    }
  }
`

export const SC_UPDATE_SCHEDULE_EVENT_M = gql`
  mutation ScUpdateScheduleMutation ($id: UUID!, $input: ScheduleEventInput!) {
    updateScheduleEvent(id: $id, input: $input) {
      code
      success
      message

      scheduleEvent {
        id
        timeZone
        start
        end
        presentation {
          name
          id
        }
        eventType
        recurringEventId
        recurrenceRule
      }
    }
  }
`

export const SC_DELETE_SCHEDULE_GOOGLE_CALENDAR_M = gql`
  mutation ScDeleteScheduleGoogleCalendarMutation ($id: UUID!) {
    deleteScheduleGoogleCalendar(id: $id) {
      code
      success
      message
      id
    }
  }
`
