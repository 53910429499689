import React, { FC, useCallback, useState, useMemo, ChangeEvent } from 'react'
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  ListSubheader,
  ListItemText,
  CircularProgress,
  Typography,
  Fade,
  Select,
  MenuItem,
  Theme,
  IconButton,
  Tooltip,
  InputLabel,
  Chip,
  Checkbox,
  Popper
} from '@material-ui/core'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { TimerInput } from 'src/ui'
import { Form, Field } from 'react-final-form'
import { styled } from '@material-ui/styles'
import { handleError, validateFormWithSchema } from 'src/core'
import { Query } from 'react-apollo'
import { PL_GET_PRESENTATION_TAGS_Q, SC_GET_DISPLAYS } from 'src/apollo/api'
import { useQuery } from '@apollo/react-hooks'
import { formatHeightWidth } from 'src/helpers'
import { format } from 'date-fns'
import { newPlaylistSchema } from 'src/features/auth/common'
import { Close } from '@material-ui/icons'
import { Button as CloseButton } from 'grommet'
import { history } from 'src/app'
import * as displayAssets from '../../ui/assets'
import { SchedulingTypeEnum } from 'src/screenhub-shared/enum/campaign-schedule.enum'

import useWorkspaceId from 'src/hooks/useWorkspaceId';

import urlHelper from 'src/helpers/urlHelper';

interface TProps {
  id?: string
  name?: string
  display?: string
  tags?: string[]
  defaultSlideDuration?: string
  isOpen: boolean
  handleClose(): void
  onSubmit?(newPlaylistData): void
  playlistSchedulingType?: SchedulingTypeEnum
}

const validateForm = validateFormWithSchema({ schema: newPlaylistSchema })

const StyledDialog = styled(Dialog)<Theme>(({ theme }) => ({
  '& .MuiDialog-paper': {
    width: '400px',
    background: 'white'
  },
  '& h2': {
    textOverflow: 'ellipsis',
    overflow: 'hidden'
  }
}))

const Wrapper = styled('div')<Theme>(({ theme }) => ({
  padding: theme.spacing(2)
}))

const FieldWrapper = styled('div')<Theme>(({ theme }) => ({
  width: '100%',
  display: 'flex',
  marginBottom: theme.spacing(2.5)
}))

const SmallIconButton = styled(IconButton)<Theme>(({ theme }) => ({
  marginLeft: theme.spacing(0.5),
  marginBottom: theme.spacing(0.5),
  marginTop: theme.spacing(-0.5),
  padding: theme.spacing(0.5)
}))

const Center = styled('div')({
  width: '100%',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center'
})

const StyledButton = styled(CloseButton)({
  position: 'absolute',
  right: '4px',
  top: '4px'
})

const StyledListItemText = styled(ListItemText)({
  display: 'flex',
  justifyContent: 'space-between',
  '& > span': {
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    maxWidth: '320px',
    overflow: 'hidden'
  }
})

const StyledAutoComplete = styled(Autocomplete)<Theme>(({ theme }) => ({
  '& .MuiChip-root': {
    background: '#EAF6FF',
    borderRadius: 0,
    height: theme.spacing(2.5),
    marginRight: theme.spacing(1),
    marginTop: theme.spacing(1),
    '& > .MuiChip-label': {
      paddingLeft: theme.spacing(0.5),
      paddingRight: theme.spacing(0.5)
    },
    '& .MuiChip-deleteIcon': {
      width: '14px',
      height: '14px',
      margin: '0 5px 0 0px',
      fill: theme.palette.grey[800]
    }
  }
}))

const AutoCompleteRow = styled('div')<Theme>(({ theme }) => ({
  height: theme.spacing(5),
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  background: 'transparent'
}))

const AutoCompleteSpan = styled('span')<Theme>(({ theme }) => ({
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  display: 'block',
  flexGrow: 1,
  flexShrink: 1,
  width: 0
}))

const CustomPopper = styled(Popper)<Theme>(({ theme }) => ({
  boxShadow:
    '0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12)',
  borderRadius: '4px',
  background: 'white',
  maxHeight: theme.spacing(50),
  width: theme.spacing(40),
  display: 'flex',
  position: 'fixed',
  alignItems: 'center',
  '& .MuiPaper-root': {
    backgroundColor: 'white',
    boxShadow: 'none',
    width: '100%'
  },
  '& .MuiAutocomplete-option': {
    backgroundColor: 'white',
    '&:hover': {
      backgroundColor: theme.palette.grey[200]
    }
  },
  '& .MuiAutocomplete-option[data-focus="true"]': {
    backgroundColor: theme.palette.grey[300]
  }
}))

export const NewPlaylistModal: FC<TProps> = ({
  id,
  name,
  tags,
  display,
  defaultSlideDuration,
  isOpen,
  onSubmit,
  handleClose,
  playlistSchedulingType
}) => {
  const workspaceId = useWorkspaceId();

  const [isEditingDuration, setIsEditingDuration] = useState(false)
  const [localTags, setLocalTags] = useState([])
  const [textInputAutocomplete, setTextInputAutocomplete] = useState('')

  // populate form
  const initialValues = useMemo(() => {
    const date = 'Playlist - ' + format(Date.now(), 'd MMMM [h:mma]')
    const values = {
      playlistName: name || date,
      playlistLabels: tags?.join(',') || null,
      playlistDisplay: display || [],
      defaultSlideDuration: defaultSlideDuration ? parseInt(defaultSlideDuration) : 10000
    }

    return values
  }, [defaultSlideDuration, display, name, tags])

  // fetch tag list
  useQuery(PL_GET_PRESENTATION_TAGS_Q, {
    variables: {
      workspaceId,
    },
    onCompleted: data => {
      if (data && data?.workspace?.presentationsTags) {
        setLocalTags(data.workspace.presentationsTags)
      }
    }
  });

  // send modal parameters to editor
  const handleSubmit = newPlaylistData => {
    // if we pass a onSubmit method, we use it to change changes to the current playlist.
    // otherwise, we create a new playlist
    if (onSubmit) {
      onSubmit({
        name: newPlaylistData.playlistName,
        defaultSlideDuration: newPlaylistData.defaultSlideDuration,
        tags: newPlaylistData.playlistLabels ? newPlaylistData.playlistLabels.split(',') : null
      })
      handlePreCloseModale()
    } else {
      const newPlaylistUrl = urlHelper.getNewPlayListUrl(workspaceId);

      newPlaylistData.playlistLabels = newPlaylistData.playlistLabels
        ? newPlaylistData.playlistLabels.split(',')
        : null;

      history.push({
        pathname: newPlaylistUrl,
        state: { dataSet: newPlaylistData, playlistSchedulingType }
      });
    }
  }

  // add tags to the list and save the tags in the list for autocomplete
  // this list will be passed to the editor and on save, the playlist will keep its tags
  const handleAddTags = (value, input, e) => {
    if (!localTags.includes(value)) {
      localTags.push(value)
      if (input.value !== '') {
        input.onChange(input.value + ',' + (e.target as HTMLInputElement).value)
      } else {
        input.onChange((e.target as HTMLInputElement).value)
      }
    }
  }

  const handlePreCloseModale = () => {
    setIsEditingDuration(false)
    handleClose()
  }

  return (
    <Form initialValues={initialValues} onSubmit={handleSubmit} validate={validateForm}>
      {({ handleSubmit, pristine, hasValidationErrors, submitting, values }) => (
        <form onSubmit={handleSubmit}>
          <StyledDialog open={isOpen}>
            <Wrapper>
              <DialogTitle title="select-playlist-dimensions-title">
                {/*  onSubmit is passed only when using an existing playlist: we then display playlist's name */}
                {onSubmit ? name : playlistSchedulingType === SchedulingTypeEnum.CAMPAIGN ? 'New Campaign Playlist' : 'New Playlist'}
                <StyledButton icon={<Close fontSize="large" />} onClick={handlePreCloseModale} />
              </DialogTitle>
              <DialogContent>
                <Field name="playlistName">
                  {({ input }) => (
                    <FieldWrapper>
                      <TextField
                        fullWidth
                        InputLabelProps={{ shrink: true }}
                        label="Playlist Name"
                        onChange={input.onChange}
                        required
                        value={input.value}
                      />
                    </FieldWrapper>
                  )}
                </Field>
                <Field name="playlistDisplay">
                  {({ input }) => (
                    <FieldWrapper style={{ display: display ? 'flex' : 'inline' }}>
                      {display && (
                        <TextField
                          disabled
                          fullWidth
                          InputLabelProps={{ shrink: true }}
                          label="Displays"
                          value={'Resolution: ' + input.value}
                        />
                      )}
                      {!display && (
                        <Query
                          fetchPolicy="network-only"
                          query={SC_GET_DISPLAYS}
                          variables={{
                            workspaceId,
                          }}
                        >
                          {({ loading, data, error }: any) => {
                            if (loading) {
                              return (
                                <Fade
                                  in={loading}
                                  style={{
                                    transitionDelay: '100ms'
                                  }}
                                  unmountOnExit
                                >
                                  <Center>
                                    <CircularProgress />
                                  </Center>
                                </Fade>
                              )
                            }

                            if (error) {
                              handleError({
                                error,
                                userFriendlyMessage:
                                  'Error occurred while trying to load available displays'
                              })

                              return null
                            }

                            const { workspace } = data;
                            const { displays } = workspace;
                            const hasDisplays = displays && displays.values.length !== 0
                            const hasGroups = false
                            const hasGroupsOrDisplays = hasGroups || hasDisplays

                            return (
                              <>
                                {hasDisplays && (
                                  <>
                                    <InputLabel className="MuiInputLabel-shrink">
                                      Resolution:
                                    </InputLabel>
                                    <Select
                                      fullWidth
                                      label='Resolution'
                                      onChange={input.onChange}
                                      style={{ marginBottom: '20px' }}
                                      value={input.value}
                                    >
                                      <ListSubheader>Displays</ListSubheader>

                                      {displays.values.map(display => (
                                        <MenuItem
                                          button
                                          key={display.id}
                                          value={display.pixelsH + ',' + display.pixelsW}
                                        >
                                          <StyledListItemText
                                            primary={display.name}
                                            secondary={`Dimensions: ${formatHeightWidth({
                                              height: display.pixelsH,
                                              width: display.pixelsW
                                            })}`}
                                          />
                                        </MenuItem>
                                      ))}
                                    </Select>
                                  </>
                                )}
                                {!hasGroupsOrDisplays && (
                                  <Typography style={{ marginBottom: '10px', paddingTop: '10px' }}>
                                    You don't have any displays created,&nbsp; so you can't select
                                    dimension for your playlist
                                  </Typography>
                                )}
                              </>
                            )
                          }}
                        </Query>
                      )}
                    </FieldWrapper>
                  )}
                </Field>
                {id && (
                  <Field name="playlistLabels">
                    {({ input }) => (
                      <FieldWrapper>
                        <StyledAutoComplete
                          autoHighlight
                          id="tags-outlined"
                          inputValue={textInputAutocomplete}
                          multiple
                          noOptionsText={
                            <span style={{ color: '#1D93F3', fontSize: '14px' }}>
                              Press enter to create new tag
                            </span>
                          }
                          onChange={(e, newval, reason) => {
                            setTextInputAutocomplete('')
                            input.onChange((newval as Array<string>).join(','))
                          }}
                          openOnFocus
                          options={localTags}
                          PopperComponent={props => {
                            return <CustomPopper {...props} placement="bottom" />
                          }}
                          renderInput={params => (
                            <TextField
                              {...params}
                              InputLabelProps={{ shrink: true }}
                              label="Tags"
                              onChange={e => {
                                if (e.target.value.match(/^[a-zA-Z0-9_-]*$/g)) {
                                  setTextInputAutocomplete(e.target.value)
                                }
                              }}
                              onKeyDown={e => {
                                // @ts-ignore
                                if (e.keyCode === 13 && (e.target as HTMLInputElement).value) {
                                  handleAddTags((e.target as HTMLInputElement).value, input, e)
                                  setTextInputAutocomplete('')
                                }
                              }}
                            />
                          )}
                          renderOption={(option, state) => {
                            return (
                              <AutoCompleteRow>
                                <Checkbox checked={state.selected} color="primary" />
                                <AutoCompleteSpan>{option}</AutoCompleteSpan>
                              </AutoCompleteRow>
                            )
                          }}
                          renderTags={(value, getTagProps) =>
                            value.map((option, index) => (
                              <Chip
                                key={index}
                                {...getTagProps({ index })}
                                deleteIcon={<Close />}
                                label={option}
                              />
                            ))
                          }
                          style={{ width: '100%' }}
                          value={input.value && input.value !== '' ? input.value.split(',') : []}
                        />
                      </FieldWrapper>
                    )}
                  </Field>
                )}
                {playlistSchedulingType !== SchedulingTypeEnum.CAMPAIGN ? (
                  <Field name="defaultSlideDuration">
                    {({ input }) => (
                      <FieldWrapper style={{ display: 'inline' }}>
                        <InputLabel className="MuiInputLabel-shrink">
                          Default Slide Duration:
                        </InputLabel>
                        <Typography component="span" variant="body2">
                          {!isEditingDuration && (
                            <>
                              {new Date(input.value ? Number(input.value) : 10)
                                .toISOString()
                                .substr(11, 8)}
                              <Tooltip title="EDIT DEFAULT SLIDE TIME">
                                <SmallIconButton
                                  data-test="edit default slide time"
                                  onClick={() => setIsEditingDuration(true)}
                                  size="small"
                                >
                                  <img alt="edit player id" src={displayAssets.blueEdit} />
                                </SmallIconButton>
                              </Tooltip>
                            </>
                          )}
                          {isEditingDuration && (
                            <TimerInput
                              isNotModalDesign
                              onChange={input.onChange}
                              // we won't close the modale once it's opened here
                              onCloseModal={null}
                              value={input.value}
                            />
                          )}
                        </Typography>
                      </FieldWrapper>
                    )}
                  </Field>
                ) : null}
              </DialogContent>

              <DialogActions>
                <Button color="primary" onClick={handlePreCloseModale} variant="outlined">
                  Cancel
                </Button>
                <Button
                  color="primary"
                  disabled={pristine || hasValidationErrors || submitting}
                  onClick={handleSubmit}
                  variant="contained"
                >
                  Save
                </Button>
              </DialogActions>
            </Wrapper>
          </StyledDialog>
        </form>
      )}
    </Form>
  )
}
