import gql from "graphql-tag";

export const ANALYTIC_SUBSCRIPTION_STATE_CHANGED = gql`
  subscription AnalyticSubscriptionStateChanged($accountId: String!) {
    analyticSubscriptionStateChanged(accountId: $accountId) {
      accountId
      state
    }
  }
`
