import axios from 'axios'

export const signInPathWithPreviousLocationInQuery = () => {
  if (window.location.pathname.includes('sign-in'))
    return window.location.pathname + window.location.search

  return `/sign-in?redirect=${encodeURIComponent(
    window.location.pathname + window.location.search
  )}`
}

export const logout = (href = '/sign-in') => {
  localStorage.removeItem('token')
  localStorage.removeItem('currentWorkspace')

  window.location.href = href
}

export const getLogoutUrl = () => {
  return `${process.env.REACT_APP_API_GATEWAY_URL}/logout`
}

export const stopImpersonate = async () => {
  const originUrl = window.location.origin
  const logoutUrl = getLogoutUrl()
  const token = localStorage.getItem('token')

  await axios({
    headers: {
      authorization: token ? `Bearer ${token}` : '',
      'content-type': 'application/json'
    },
    method: 'get',
    baseURL: logoutUrl
  }).then(response => {
    const { token } = response.data
    localStorage.setItem('token', token)
    window.location.href = `${originUrl}/back-office`
  })
}

export const logoutViaServer = async () => {
  const logoutUrl = getLogoutUrl()

  const token = localStorage.getItem('token')

  await axios({
    headers: {
      authorization: token ? `Bearer ${token}` : '',
      'content-type': 'application/json'
    },
    method: 'get',
    baseURL: logoutUrl
  }).then(response => {
    logout()
  })
}
