// Big log (wider)
export enum WhiteLabelLogoEnum {
  SUNSHINE = 'https://screenhub-whitelabel.s3.us-east-2.amazonaws.com/sunshine-logo.png', // logo path
  // SUNSHINE = 'https://v2.dev.screenhub.com/static/media/logo.4e5f274e.svg', // logo path
  DEFAULT = 'https://v2.dev.screenhub.com/static/media/logo.4e5f274e.svg'
}

// TODO: Square logo to match nav-header.tsx line 28

export enum WhiteLabelTitleEnum {
  SUNSHINE = 'Sunshine',
  DEFAULT = 'ScreenHub'
}

export enum WhiteLabelColorEnum {
  SUNSHINE = '#000000',
  DEFAULT = '#3B3F4A'
}

export enum WhiteLabelFeaturesEnum {
  TRANSFER_WORKSPACE = 'TRANSFER_WORKSPACE',
  ANNOUNCEMENTS = 'ANNOUNCEMENTS',
  PAYWALL = 'PAYWALL',
  DOWNLOADS = 'DOWNLOADS',
  TWO_FA = 'TWO_FA',
  GOOGLE_LOGIN = 'GOOGLE_LOGIN',
  HELP_SECTION = 'HELP_SECTION',
  QUESTION_BUTTON = 'QUESTION_BUTTON',
  NOTIFICATION = 'NOTIFICATION',
  SIGN_UP_PAGE = 'SIGN_UP_PAGE',
  LOBBY_PAGE = 'LOBBY_PAGE',
  PRODUCT_PAGE = 'PRODUCT_PAGE',
  ANALYTICS_PAGE = 'ANALYTICS_PAGE',
  USER_ADMIN_PAGE = 'USER_ADMIN_PAGE',
}

export const SUNSHINE_HIDE_FEATURES = [
  WhiteLabelFeaturesEnum.TRANSFER_WORKSPACE,
  WhiteLabelFeaturesEnum.ANNOUNCEMENTS,
  WhiteLabelFeaturesEnum.PAYWALL,
  WhiteLabelFeaturesEnum.DOWNLOADS,
  WhiteLabelFeaturesEnum.TWO_FA,
  WhiteLabelFeaturesEnum.GOOGLE_LOGIN,
  WhiteLabelFeaturesEnum.HELP_SECTION,
  WhiteLabelFeaturesEnum.QUESTION_BUTTON,
  WhiteLabelFeaturesEnum.NOTIFICATION,
  WhiteLabelFeaturesEnum.SIGN_UP_PAGE,
  WhiteLabelFeaturesEnum.LOBBY_PAGE,
  WhiteLabelFeaturesEnum.PRODUCT_PAGE,
  WhiteLabelFeaturesEnum.ANALYTICS_PAGE
]

export const SUNSHINE_WEBSITE = process.env.REACT_APP_WHITELABEL_SUNSHINE_WEBSITE

const logoMap = new Map()
const titleMap = new Map()
const colorMap = new Map()
const hideFeatureMap = new Map()

logoMap.set(SUNSHINE_WEBSITE, WhiteLabelLogoEnum.SUNSHINE)
logoMap.set('DEFAULT', WhiteLabelLogoEnum.DEFAULT)

titleMap.set(SUNSHINE_WEBSITE, WhiteLabelTitleEnum.SUNSHINE)
titleMap.set('DEFAULT', WhiteLabelTitleEnum.DEFAULT)

colorMap.set(SUNSHINE_WEBSITE, WhiteLabelColorEnum.SUNSHINE)
colorMap.set('DEFAULT', WhiteLabelColorEnum.DEFAULT)

hideFeatureMap.set(SUNSHINE_WEBSITE, SUNSHINE_HIDE_FEATURES)

export const WhiteLabelHideFeatureMap = hideFeatureMap
export const WhiteLabelLogoMap = logoMap
export const WhiteLabelTitleMap = titleMap
export const WhiteLabelColorMap = colorMap
