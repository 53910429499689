import { ApolloQueryResult } from 'apollo-client/core/types'
import { useState } from 'react'
import { useQuery } from 'react-apollo'
import { SC_GET_CURRENT_WORKSPACE } from 'src/apollo/api'
import { WorkspaceRoles } from 'src/constants'

import useWorkspaceId from 'src/hooks/useWorkspaceId';

interface ICurrentWorkspace {
  id: string
  name: string
  userPath: string
  workspacePath: string
  memberRole: {
    id: string
    name: WorkspaceRoles
  }
}

export interface IGetCurrentWorkspaceData {
  data: ICurrentWorkspace
  loading: boolean
  refetch?: (variables?: any) => Promise<ApolloQueryResult<any>>
}

export const useCurrentWorkspace = (workspaceId?: string): IGetCurrentWorkspaceData => {
  const exiistingWorkspaceId = useWorkspaceId();
  const workspaceIdForRequest = workspaceId ? workspaceId : exiistingWorkspaceId;

  const [res, setRes] = useState<IGetCurrentWorkspaceData | null>(null)
  const { loading, refetch } = useQuery(SC_GET_CURRENT_WORKSPACE, {
    skip: !workspaceIdForRequest,
    variables: {
      workspaceId: workspaceIdForRequest,
    },
    fetchPolicy: 'cache-first',
    onCompleted: ({ workspace }) => {
      if (workspace) {
        setRes({
          data: workspace,
          loading,
          refetch
        })
      }
    }
  });

  return res
}
