import React, { FC } from 'react'
import { SvgIcon } from '@material-ui/core'
import { SvgIconProps } from '@material-ui/core/SvgIcon'

export const StockIcon: FC<SvgIconProps> = (props) => (
  <SvgIcon
    {...props}
  >
    <svg
      fill='none'
      height='24'
      viewBox='0 0 24 24'
      width='24'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M13.7378 12.2151L13.8771 12.3617L14.0222 12.2209L20.49 5.94258L21.8577 7.32816L22.2 7.67498V7.18766V3V2.8H22H17.8669H17.3885L17.7246 3.1405L19.1659 4.60069L13.9307 9.68167L10.8582 6.4473L10.7147 6.2963L10.5697 6.44574L1.8565 15.4214L1.719 15.5631L1.85883 15.7024L2.91072 16.7506L3.05425 16.8937L3.1954 16.7483L10.7005 9.01735L13.7378 12.2151Z'
        fill='#DADADA'
        stroke='#DADADA'
        strokeWidth='0.4'
      />
      <path
        d='M4 18H6V22H4V18Z'
        fill='#DADADA'
      />
      <path
        d='M9 16H11V22H9V16Z'
        fill='#DADADA'
      />
      <path
        d='M14 14H16V22H14V14Z'
        fill='#DADADA'
      />
      <path
        d='M19 12H21V22H19V12Z'
        fill='#DADADA'
      />
    </svg>

  </SvgIcon>
)
