import React, { FC, useCallback, useReducer, useEffect } from 'react'
import { getSeconds, getMinutes } from 'date-fns'
import {
  FormControl,
  FormLabel,
  TextField,
  Button,
  Theme
} from '@material-ui/core'
import { makeStyles, useTheme } from '@material-ui/styles'

const useClasses = makeStyles<Theme>((theme) => ({
  formControl: {
    width: 300
  },
  smallFormControl: {
    flexDirection: 'row'
  },
  textField: {
    width: '30%'
  },
  formLabel: {
    fontSize: 20,
    marginBottom: 20
  },
  container: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  btnsContainer: {
    width: '100%',
    marginTop: 15,
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center'
  },
  blueSvg: {
    fill: theme.palette.primary.main
  },
  iconButtons: {
    height: theme.spacing(6),
    width: theme.spacing(6),
    alignSelf: 'center',
    marginLeft: theme.spacing(1)
  }
}))

interface TState {
  hours: string
  minutes: string
  seconds: string
}

interface TReducerAction {
  type: string
  payload: string
}

const reducer = (state: TState, action: TReducerAction) => {
  switch (action.type) {
    case 'hours':
      return { ...state, hours: action.payload }
    case 'minutes':
      return { ...state, minutes: action.payload }
    case 'seconds':
      return { ...state, seconds: action.payload }
    default:
      throw new Error()
  }
}

interface TProps {
  value: number
  onChange(value: number): void
  onCloseModal(): void
  isNotModalDesign?: boolean
}

export const TimerInput: FC<TProps> = ({ value, onChange, onCloseModal, isNotModalDesign = false }) => {
  const theme = useTheme()
  const classes = useClasses(theme)

  const getState = useCallback(() => {
    const seconds = String(getSeconds(value))
    const minutes = String(getMinutes(value))
    const hours = String(
      value % 3600000 === value ? 0 : Math.floor(value / 3600000)
    )

    return { seconds, minutes, hours }
  }, [value])

  const [state, dispatch] = useReducer(reducer, getState())

  const validateHours = useCallback((value) => {
    const regexp = /^[0-9][0-9]*$/

    return new RegExp(regexp).test(value)
  }, [])

  const validateMinutes = useCallback((value) => {
    const regexp = /^[0-9][0-9]*$/
    const isInRange: boolean = new RegExp(regexp).test(value)

    return isInRange && parseInt(value) < 60
  }, [])

  const validateSeconds = useCallback(
    (value) => {
      const minutes: number = parseInt(state.minutes)
      const hours: number = parseInt(state.hours)

      const regexp = /^[0-9][0-9]*$/
      const isInRange: boolean = new RegExp(regexp).test(value)

      if (hours > 0 || minutes > 0) {
        return isInRange && parseInt(value) < 60
      }

      return isInRange && parseInt(value) < 60 && parseInt(value) > 2
    },
    [state.hours, state.minutes]
  )

  const onHandleChange = useCallback(
    (actionType: string) => (e) => {
      dispatch({ type: actionType, payload: e.target.value })
    },
    []
  )

  const handleSubmit = useCallback(() => {
    const { hours, minutes, seconds }: TState = state

    const parsedHours: number = parseInt(hours)
    const parsedMinutes: number = parseInt(minutes)
    const parsedSeconds: number = parseInt(seconds)

    const value: number =
      (parsedHours * 3600 + parsedMinutes * 60 + parsedSeconds) * 1000

    onChange(value)
    if (!isNotModalDesign) onCloseModal()
  }, [isNotModalDesign, onChange, onCloseModal, state])

  useEffect(() => {
    if (isNotModalDesign) {
      if (!validateHours(state.hours) ||
    !validateMinutes(state.minutes) ||
    !validateSeconds(state.seconds)) {
        onChange(-1)
      } else { handleSubmit() }
    }
  }, [handleSubmit, isNotModalDesign, onChange, state, validateHours, validateMinutes, validateSeconds])

  return (
    <FormControl className={isNotModalDesign ? classes.smallFormControl : classes.formControl}>
      {!isNotModalDesign &&
        <FormLabel
          className={classes.formLabel}
          htmlFor='timer-input'
        >
        Slide Duration
        </FormLabel>}
      <div className={classes.container}>
        <TextField
          className={classes.textField}
          error={!validateHours(state.hours)}
          id='outlined-hours'
          label='Hours'
          margin='normal'
          onChange={onHandleChange('hours')}
          value={state.hours}
          variant='outlined'
        />
        <TextField
          className={classes.textField}
          error={!validateMinutes(state.minutes)}
          id='outlined-minutes'
          label='Minutes'
          margin='normal'
          onChange={onHandleChange('minutes')}
          value={state.minutes}
          variant='outlined'
        />
        <TextField
          className={classes.textField}
          error={!validateSeconds(state.seconds)}
          id='outlined-seconds'
          label='Seconds'
          margin='normal'
          onChange={onHandleChange('seconds')}
          value={state.seconds}
          variant='outlined'
        />
      </div>
      {!isNotModalDesign &&
        <div className={classes.btnsContainer}>
          <Button
            color='primary'
            disabled={
              !validateHours(state.hours) ||
              !validateMinutes(state.minutes) ||
              !validateSeconds(state.seconds)
            }
            onClick={handleSubmit}
            variant='contained'
          >
          Save
          </Button>
        </div>}
    </FormControl>
  )
}
