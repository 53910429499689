export const WORKSPACE_URL_PATTERN = '/workspace/:workspaceId';

export const CONDITIONAL_CONTENT_ID_PARAM = ':conditionalContentId';
export const DISPLAY_ID_PARAM = ':displayId';
export const GROUP_ID_PARAM = ':displayGroupId';
export const PLAYLIST_ID_PARAM = ':playlistId';
export const WORKSPACE_ID_PARAM = ':workspaceId';

export const NEW_SUB_PATH = '/new';

export const DISPLAY_SUB_PATH = '/displays';
export const DISPLAY_LIST_PAGE_URL = `${WORKSPACE_URL_PATTERN}${DISPLAY_SUB_PATH}`;
export const DISPLAY_URL = `${WORKSPACE_URL_PATTERN}${DISPLAY_SUB_PATH}/${DISPLAY_ID_PARAM}`;

export const DISPLAY_IN_GROUP_SUB_PATH = `/group/${GROUP_ID_PARAM}/display/${DISPLAY_ID_PARAM}`;
export const DISPLAY_IN_GROUP_URL = `${WORKSPACE_URL_PATTERN}${DISPLAY_SUB_PATH}${DISPLAY_IN_GROUP_SUB_PATH}`;

export const GROUP_PAGE_SUB_PATH = `/group/${GROUP_ID_PARAM}`;
export const GROUP_PAGE_URL = `${WORKSPACE_URL_PATTERN}${DISPLAY_SUB_PATH}${GROUP_PAGE_SUB_PATH}`;

export const PLAYERLIST_SUB_PATH = '/playlists';
export const PLAYLIST_PAGE_URL = `${WORKSPACE_URL_PATTERN}${PLAYERLIST_SUB_PATH}`;
export const PLAYLIST_URL = `${WORKSPACE_URL_PATTERN}${PLAYERLIST_SUB_PATH}/${PLAYLIST_ID_PARAM}`;
export const NEW_PLAYLIST_URL = `${WORKSPACE_URL_PATTERN}${PLAYERLIST_SUB_PATH}${NEW_SUB_PATH}`;

export const CONDITIONAL_CONTENT_SUB_PATH = '/conditional-content';
export const CONDITIONAL_CONTENT_PAGE_URL = `${WORKSPACE_URL_PATTERN}${CONDITIONAL_CONTENT_SUB_PATH}`;
export const CONDITIONAL_CONTENT_EDITOR_URL = `${WORKSPACE_URL_PATTERN}${CONDITIONAL_CONTENT_SUB_PATH}/${CONDITIONAL_CONTENT_ID_PARAM}`;
export const NEW_CONDITIONAL_CONTENT_URL = `${WORKSPACE_URL_PATTERN}${CONDITIONAL_CONTENT_SUB_PATH}${NEW_SUB_PATH}`;

export const ACCOUNT_SUB_PATH = '/account';
export const ACCOUNT_PAGE_URL = `${WORKSPACE_URL_PATTERN}${ACCOUNT_SUB_PATH}`;

export const ORDER_TRACKING_SUB_PATH = '/order-tracking';
export const ORDER_TRACKING_PAGE_URL = `${WORKSPACE_URL_PATTERN}${ORDER_TRACKING_SUB_PATH}`;

export const BILLING_SUB_PATH = '/billing';
export const BILLING_PAGE_URL = `${WORKSPACE_URL_PATTERN}${BILLING_SUB_PATH}`;

export const WORKSPACE_MEMBER_PAGE_URL = `${WORKSPACE_URL_PATTERN}/workspace-member`;

export const SCHEDULE_SUB_PATH = '/schedule';
export const DISPLAY_SCHEDULE_URL = `${WORKSPACE_URL_PATTERN}${SCHEDULE_SUB_PATH}/${DISPLAY_ID_PARAM}`;

export const PROOF_OF_PERFORMANCE_SUB_PATH = '/pop';
export const PROOF_OF_PERFORMANCE_PAGE_URL = `${WORKSPACE_URL_PATTERN}${PROOF_OF_PERFORMANCE_SUB_PATH}`;

export const MY_MEDIA_SUB_PATH = '/mymedia';
export const MY_MEDIA_PAGE_URL = `${WORKSPACE_URL_PATTERN}${MY_MEDIA_SUB_PATH}`;

export const BACK_OFFICE_SUB_PATH = '/back-office';
export const BACK_OFFICE_PAGE_URL = `${WORKSPACE_URL_PATTERN}${BACK_OFFICE_SUB_PATH}`;

export const HELP_PAGE_SUB_PATH = '/help';
export const HELP_PAGE_URL = `${WORKSPACE_URL_PATTERN}${HELP_PAGE_SUB_PATH}`;

export const DOWNLOAD_PLAYER_SUB_PATH = '/downloads';
export const DOWNLOAD_PLAYER_PAGE_URL = `${WORKSPACE_URL_PATTERN}${DOWNLOAD_PLAYER_SUB_PATH}`;

export const MARKETING_SUB_PATH = '/marketing';
export const MARKETING_PAGE_URL = `${WORKSPACE_URL_PATTERN}${MARKETING_SUB_PATH}`;

export const PEOPLE_COUNTER_SUB_PATH = '/people-counter';
export const PEOPLE_COUNTER_PAGE_URL = `${WORKSPACE_URL_PATTERN}${PEOPLE_COUNTER_SUB_PATH}`;

export const CAR_TRACKER_SUB_PATH = '/car-tracker';
export const CAR_TRACKER_PAGE_URL = `${WORKSPACE_URL_PATTERN}${CAR_TRACKER_SUB_PATH}`;

export const DEVICE_MANAGEMENT_SUB_PATH = '/device-management';
export const DEVICE_MANAGEMENT_PAGE_URL = `${WORKSPACE_URL_PATTERN}${DEVICE_MANAGEMENT_SUB_PATH}`;

export const CUSTOMER_DASHBOARD_SUB_PATH = '/dashboard';
export const CUSTOMER_DASHBOARD_URL = `${WORKSPACE_URL_PATTERN}${CUSTOMER_DASHBOARD_SUB_PATH}`;

export const INVALID_WORKSPACE_URL = '/invalid-workspace';
