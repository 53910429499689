import React, { FC } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';

const useStyles = makeStyles((theme) => ({
  container: (props: {aspectRatio?: number}) => ({
    display: 'flex',
    width: (props.aspectRatio >= 1) ? '100px' : '50px',
    height: (props.aspectRatio > 1) ? '50px' : '100px',
    flexDirection: 'column',
    alignItems: 'flex-start',
  }),
  upper20: (props: {aspectRatio?: number}) => ({
    height: (props.aspectRatio > 1) ? '10px' : '20px',
    flexShrink: 0,
    alignSelf: 'stretch',
    border: '1px solid var(--Sytetm-Divider, currentColor)',
    borderBottom: 'none',
  }),
  lower80: (props: {aspectRatio?: number}) => ({
    display: 'flex',
    alignItems: 'flex-start',
    flex: '1 0 0',
    alignSelf: 'stretch',
    border: '1px solid var(--Sytetm-Divider, currentColor)',
  }),
  upper20Selected: (props: {aspectRatio?: number}) => ({
    border: '2px solid var(--Sytetm-Divider, #1d93f3)',
    borderBottom: 'none',
  }),
  lower80Selected: (props: {aspectRatio?: number}) => ({
    border: '2px solid var(--Sytetm-Divider, #1d93f3)',
  }),
}));

export const Layout20To80Icon: FC<{isSelected: boolean; aspectRatio?: number}> = ({
  isSelected,
  aspectRatio,
}) => {
  const {
    container,
    upper20,
    lower80,
    upper20Selected,
    lower80Selected,
  } = useStyles({ aspectRatio });

  const upper20ClassName = classNames(upper20, { [upper20Selected]: isSelected });
  const lower80ClassName = classNames(lower80, { [lower80Selected]: isSelected });

  return (
    <div className={container}>
      <div className={upper20ClassName} />
      <div className={lower80ClassName} />
    </div>
  );
};
