export enum StripeStatusEnums {
  ACTIVE = 'ACTIVE',
  CANCELED = 'CANCELED',
  INCOMPLETE = 'INCOMPLETE',
  INCOMPLETE_EXPIRED = 'INCOMPLETE_EXPIRED',
  PAST_DUE = 'PAST_DUE',
  TRIALING = 'TRIALING',
  UNPAID = 'UNPAID'
}

export enum PlanEnums {
  LEGACY_TRIAL_PLAN = 'LEGACY_TRIAL_PLAN',
  PREMIUM_PLAN = 'PREMIUM_PLAN',
  CIRRUS_WINDOWS_PLAN = 'CIRRUS_WINDOWS_PLAN',
  CIRRUS_HARDWARE_PLAN = 'CIRRUS_HARDWARE_PLAN',
  PRO_PLAN = 'PRO_PLAN',
  CIRRUS_HARDWARE_PRO_PLAN = 'CIRRUS_HARDWARE_PRO_PLAN',
  CIRRUS_WINDOWS_PRO_PLAN = 'CIRRUS_WINDOWS_PRO_PLAN',
  CELLULAR_PLAN = 'CELLULAR_PLAN',
  ANALYTICS_PLAN = 'ANALYTICS_PLAN',
  WHITELABEL_PRO_PLAN = 'WHITELABEL_PRO_PLAN',
}

export enum BillingCycle {
  MONTH = 'MONTH',
  YEAR = 'YEAR'
}

export enum SubscriptionCategoryEnums {
  DISPLAY = 'DISPLAY',
  CELLULAR = 'CELLULAR',
  ANALYTICS = 'ANALYTICS',
}
