import gql from 'graphql-tag'

export const SUB_PL_PRESENTATION_UPDATED = gql`
  subscription PresentationUpdated($presentationIds: [UUID]) {
    presentationUpdated(presentationIds: $presentationIds) {
      id
      name
      config
      slides
      slidesOrder
      createdAt
      updatedAt
      isMediaProcessing
    }
  }
`

export const SUB_PL_PRESENTATION_PROGRESS_UPDATED = gql`
  subscription PresentationProgressUpdated($presentationIds: [UUID]) {
    presentationProgressUpdated(presentationIds: $presentationIds) {
      id
      assets {
        assetId
        assetName
        isProcessing
        isOptimized
        isUploaded
        isErrorOccurred
      }
    }
  }
`
