import React, { FC } from 'react'
import { useMutation } from 'react-apollo'
import { Theme } from '@material-ui/core'
import { createStyles, makeStyles, useTheme } from '@material-ui/styles'
import Button from '@material-ui/core/Button'
import Checkbox from '@material-ui/core/Checkbox'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import FormControlLabel from '@material-ui/core/FormControlLabel'

import { ACCOUNT_TOGGLE_FEATURE } from '../../apollo/api'
import { COLOR_WHITE } from '../../constants'
import { getCurrentWorkspaceFromLocalStorage } from '../../helpers/local-storage'

interface TProps {
  me: any
  subscription: any
}

const useStyles = makeStyles<Theme>(() => {
  return createStyles({
    paper: {
      backgroundColor: COLOR_WHITE
    }
  })
})

export const NewFeatModal: FC<TProps> = ({ me, subscription }) => {
  const theme = useTheme()
  const classes = useStyles(theme)
  const [checked, setChecked] = React.useState(false)
  const [open, setOpen] = React.useState(true)
  const [toggleFeature] = useMutation(ACCOUNT_TOGGLE_FEATURE)

  const features = me?.features

  const descriptionElementRef = React.useRef<HTMLElement>(null)
  React.useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef
      if (descriptionElement !== null) {
        descriptionElement.focus()
      }
    }
  }, [open])

  const handleCheckRead = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(event.target.checked)
  }

  const handleClose = featureId => {
    try {
      toggleFeature({
        variables: {
          id: featureId,
          status: 'DISABLED'
        }
      })
    } catch (error) {}

    setOpen(false)
  }

  const checkFeatureRules = (feature, subscription) => {
    const { rules, notify } = feature

    if (!notify) {
      return false
    }

    if (!rules) {
      return true
    }

    const { subscriptionRules, workspaceRules, isLegacy } = JSON.parse(rules)

    if (isLegacy !== null && isLegacy !== undefined) {
      if (isLegacy !== me.isLegacy) {
        return false
      }
    }

    if (workspaceRules) {
      const { memberRole } = workspaceRules

      if (memberRole && memberRole.id !== getCurrentWorkspaceFromLocalStorage()?.memberRole?.id) {
        return false
      }
    }

    if (subscriptionRules) {
      if (!subscription) {
        return false
      }

      const { planNames, statuses } = subscriptionRules

      if (planNames && planNames.length > 0) {
        if (!planNames.includes(subscription?.planName)) {
          return false
        }
      }

      if (statuses && statuses.length > 0) {
        if (!statuses.includes(subscription?.status)) {
          return false
        }
      }
    }

    return true
  }

  const getNotifyFeature = () => {
    if (!features) {
      return
    }

    for (let i = 0; i < features.length; i++) {
      const feat = features[i]

      if (checkFeatureRules(feat, subscription)) {
        return feat
      }
    }
  }

  const notifyFeature = getNotifyFeature()

  if (!notifyFeature) {
    return <></>
  }

  return (
    <Dialog
      aria-describedby='scroll-dialog-description'
      aria-labelledby='scroll-dialog-title'
      classes={{ paper: classes.paper }}
      disableBackdropClick
      fullWidth
      onClose={() => setOpen(false)}
      open={open}
      scroll='paper'
    >

      <DialogTitle id='scroll-dialog-title'>{notifyFeature.name || 'Important Message'}</DialogTitle>
      <DialogContent dividers>
        {notifyFeature.description && (
          <div
            dangerouslySetInnerHTML={{ __html: notifyFeature.description }}
          />
        )}
        <FormControlLabel
          control={<Checkbox color='primary' onChange={handleCheckRead}/>}
          label='I have read and acknowledged the new announcement above'
        />
      </DialogContent>
      <DialogActions>
        <Button
          color='primary'
          disabled={!checked}
          onClick={() => handleClose(notifyFeature.id)}
          variant='contained'
        >
          Accept
        </Button>
      </DialogActions>
    </Dialog>
  )
}
