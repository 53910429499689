import gql from 'graphql-tag'
import * as Fragments from './fragments'
import { AdGetVenueQuery, AdGetVenueQueryVariables } from '../../types/AdGetVenueQuery'
import { AdGetVenuesQuery } from '../../types/AdGetVenuesQuery'
import { AdGetDisplaysQuery } from '../../types/AdGetDisplaysQuery'
import {
  AdGetDisplayAdstashQuery,
  AdGetDisplayAdstashQueryVariables
} from '../../types/AdGetDisplayAdstashQuery'
import {
  AdGetDisplaysAdstashQuery,
  AdGetDisplaysAdstashQueryVariables
} from '../../types/AdGetDisplaysAdstashQuery'

export const AD_GET_VENUE_Q = gql<AdGetVenueQuery, AdGetVenueQueryVariables>`
  query AdGetVenueQuery ($id: UUID!) {
    venue(id: $id) {
      ...AdVenueInfoFragment
    }
  }
  ${Fragments.AD_VENUE_F}
`

export const AD_GET_VENUES_Q = gql<AdGetVenuesQuery>`
  query AdGetVenuesQuery {
    venuesCurrentUser {
      ...AdVenueInfoFragment
    }
  }
  ${Fragments.AD_VENUE_F}
`

export const AD_GET_DISPLAY_ADSTASH_Q = gql<
  AdGetDisplayAdstashQuery,
  AdGetDisplayAdstashQueryVariables
>`
  query AdGetDisplayAdstashQuery ($id: UUID!) {
    display(id: $id) {
      id
      locationType
      latitude
      longitude
      photos {
        id
        displayId
        imageUrl
        created
      }
      displayAdstash {
        ...AdFullDisplayAdstashInfoFragment
      }
    }
  }
  ${Fragments.AD_DISPLAY_ADSTASH_F}
`

export const AD_GET_DISPLAYS_ADSTASH_Q = gql<
  AdGetDisplaysAdstashQuery,
  AdGetDisplaysAdstashQueryVariables
>`
  query AdGetDisplaysAdstashQuery ($page: Int, $limit: Int) {
    displaysAdstashCurrentUser(page: $page, limit: $limit) {
      ...AdFullDisplayAdstashInfoFragment
    }
  }
  ${Fragments.AD_DISPLAY_ADSTASH_F}
`
