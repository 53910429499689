export const getImageFileUrl = (file: File): Promise<{ imageUrl: string }> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader()

    reader.onload = () => resolve({ imageUrl: reader.result as string })

    reader.onerror = (error) => reject(error)

    reader.readAsDataURL(file)
  })
