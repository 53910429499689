import { createGlobalStyle } from 'styled-components'
import { createMuiTheme } from '@material-ui/core'
import blue from '@material-ui/core/colors/blue'
import 'typeface-roboto'

interface TFontWeights {
  [key: string]: number
}

const colors = {
  primary: '#3686D2',
  secondary: '#20C595',
  tertiary: '#ED6A5A',
  quaternary: '#F4F1BB',
  quinary: '#9BC1BC',
  error: '#f5222d',
  success: '#7bca64',
  greys: [
    '#FAFAFA',
    '#F5F5F5',
    '#EEEEEE',
    '#E0E0E0',
    '#BDBDBD',
    '#9E9E9E',
    '#757575',
    '#616161',
    '#424242',
    '#212121'
  ],
  white: '#F5F5F5'
}

/*
  Main Material UI theme for the app
 */
export const muiTheme = createMuiTheme({
  palette: {
    background: {
      paper: '#ededed'
    },
    primary: blue
  }
})

/*
  CustomMain Material UI theme for the app
 */
export const MainTheme = createMuiTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1920
    }
  },
  palette: {
    primary: blue,
    background: {
      paper: '#ededed',
      default: colors.white
    },
    action: {
      selected: blue[500]
    },
    grey: {
      100: '#F5F5F5',
      200: '#EEEEEE',
      300: '#DADADA',
      400: '#8D9091',
      500: '#696B6C',
      600: '#4F545E',
      800: '#303640'
    }
  }
})

/*
  SideBar Material UI theme for the app
 */
export const darkTheme = createMuiTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1920
    }
  },
  palette: {
    type: 'dark',
    primary: blue,
    secondary: {
      light: '#41444E',
      main: '#303540'
    },
    background: {
      paper: '#303540',
      default: '#41444E'
    },
    action: {
      selected: blue[500]
    },
    grey: {
      100: '#F5F5F5',
      200: '#EEEEEE',
      300: '#DADADA',
      400: '#8D9091',
      500: '#696B6C',
      600: '#4F545E',
      800: '#303640'
    }
  }
})

/*
  Header Material UI theme for the app
 */
export const headerTheme = createMuiTheme({
  palette: {
    primary: blue,
    background: {
      paper: '#F5F5F5'
    }
  }
})

interface TEdgeSize {
  none: string
  hair: string
  xxsmall: string
  xsmall: string
  small: string
  medium: string
  large: string
  xlarge: string
}

const fontWeights: TFontWeights = {
  regular: 400,
  semi: 600,
  bold: 700,
  extra: 800
}

export const collapsedSidebarWidth = 58

const radius = '3px'

const breakpoints = ['560px', '728px', '1095px', '1350px']

const fontSizes = [12, 14, 16, 20, 24, 32, 48, 64, 96, 128]

const shadows = [
  '0px 0px 10px 0px rgba(0, 0, 0, 0.1)',
  '0px 0px 4px 0px rgba(0, 0, 0, 0.5)',
  '0px 15px 25px -15px rgba(0, 0, 0, 0.1)'
]

const edgeSize: TEdgeSize = {
  none: '0px',
  hair: '1px',
  xxsmall: '3px',
  xsmall: '6px',
  small: '12px',
  medium: '24px',
  large: '48px',
  xlarge: '96px'
}

export const theme = {
  fontWeights,
  colors,
  breakpoints,
  radius,
  fontSizes,
  shadows,
  edgeSize
}

export const GlobalStyles = createGlobalStyle`
  .user-select-hack {
    /* needed for rotatable.js  */
    * {
      ::selection {background: transparent;}
      ::-moz-selection {background: transparent;}
    }
  }
  * ::-webkit-scrollbar {
    background: transparent; 
    width: 16px;
  };
  * {
    ::-webkit-scrollbar-track {
      background: transparent; 
      width: 16px;
      border: solid 4px transparent;
      box-shadow: inset 0 0 16px 16px transparent;
    };
    ::-webkit-scrollbar-track:active {
      background: rgba(170, 170, 170, 0.5); 
    };
    ::-webkit-scrollbar-thumb {
      box-shadow: inset 0 0 16px 16px rgba(0, 0, 0, 0.5);
      border-radius: 16px;
      border: solid 4px transparent;
    };
    scrollbar-color: rgba(0, 0, 0, 0.5) transparent;
    scrollbar-width: thin;
  };
  @page {
      size: A4;
      margin: 0;
    };
`
