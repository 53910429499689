import gql from 'graphql-tag'
import * as Fragments from './fragments'
import {
  GroupsCreateGroupMutation,
  GroupsCreateGroupMutationVariables
} from '../../types/GroupsCreateGroupMutation'
import {
  GroupsDeleteGroupMutation,
  GroupsDeleteGroupMutationVariables
} from '../../types/GroupsDeleteGroupMutation'
import {
  GroupsUpdateGroupMutation,
  GroupsUpdateGroupMutationVariables
} from '../../types/GroupsUpdateGroupMutation'
import {
  GroupsUpdateGroupPowerMutation,
  GroupsUpdateGroupPowerMutationVariables
} from '../../types/GroupsUpdateGroupPowerMutation'
import {
  GroupsUploadGroupThumbnailMutation,
  GroupsUploadGroupThumbnailMutationVariables
} from '../../types/GroupsUploadGroupThumbnailMutation'
import {
  GroupsDeleteGroupThumbnailMutation,
  GroupsDeleteGroupThumbnailMutationVariables
} from '../../types/GroupsDeleteGroupThumbnailMutation'

export const GROUPS_CREATE_GROUP_M = gql<
  GroupsCreateGroupMutation,
  GroupsCreateGroupMutationVariables
>`
  mutation GroupsCreateGroupMutation ($input: GroupCreateInput!) {
    createGroup(input: $input) {
      code
      success
      message
      group {
        ...GroupsGroupInfo
      }
    }
  }
  ${Fragments.GROUPS_GROUP_INFO_F}
`

export const GROUPS_DELETE_GROUP_M = gql<
  GroupsDeleteGroupMutation,
  GroupsDeleteGroupMutationVariables
>`
  mutation GroupsDeleteGroupMutation ($id: UUID!) {
    deleteGroup(id: $id) {
      code
      success
      message
      id
    }
  }
`

export const GROUPS_UPDATE_GROUP_M = gql<
  GroupsUpdateGroupMutation,
  GroupsUpdateGroupMutationVariables
>`
  mutation GroupsUpdateGroupMutation ($id: UUID!, $input: GroupUpdateInput!) {
    updateGroup(id: $id, input: $input) {
      code
      success
      message
      group {
        ...GroupsGroupInfo
      }
    }
  }
  ${Fragments.GROUPS_GROUP_INFO_F}
`

export const GROUPS_UPDATE_GROUP_POWER_M = gql<
  GroupsUpdateGroupPowerMutation,
  GroupsUpdateGroupPowerMutationVariables
>`
  mutation GroupsUpdateGroupPowerMutation ($id: UUID!, $power: DisplayPower!) {
    setGroupPower(id: $id, power: $power) {
      id
      success
    }
  }
`

export const GROUPS_UPLOAD_GROUP_THUMBNAIL_M = gql<
  GroupsUploadGroupThumbnailMutation,
  GroupsUploadGroupThumbnailMutationVariables
>`
  mutation GroupsUploadGroupThumbnailMutation ($id: UUID!, $file: Upload!) {
    uploadGroupThumbnail(id: $id, file: $file) {
      success
      group {
        id
        imageUrl
      }
    }
  }
`

export const GROUPS_DELETE_GROUP_THUMBNAIL_M = gql<
  GroupsDeleteGroupThumbnailMutation,
  GroupsDeleteGroupThumbnailMutationVariables
>`
  mutation GroupsDeleteGroupThumbnailMutation ($id: UUID!) {
    deleteGroupThumbnail(id: $id) {
      success
    }
  }
`
