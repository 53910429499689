import * as ApolloAPI from 'src/apollo/api'
import { useQuery } from 'react-apollo'

export const useUserInfoWithRefetch = () => {
  const { data, refetch, loading } = useQuery(ApolloAPI.COMM_GET_ME_Q, {
    skip: !window.localStorage.getItem('token'),
    fetchPolicy: 'network-only'
  })
  return { data, refetch, loading }
}

export const useUserInfoCacheRefetch = () => {
  const { data, refetch } = useQuery(ApolloAPI.COMM_GET_ME_Q, { fetchPolicy: 'cache-first' })
  return { data, refetch }
}

export const useRememberedAdminUserInfo = () => {
  const { data } = useQuery(ApolloAPI.COMM_GET_REMEMBERED_ADMIN_USER_Q, {
    fetchPolicy: 'cache-only'
  })
  return data
}

export const useUserInfo = () => {
  const { data } = useQuery(ApolloAPI.COMM_GET_ME_Q, { fetchPolicy: 'cache-only' })
  return data
}
