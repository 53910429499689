import React, { FC } from 'react'
import { createBrowserHistory } from 'history'
import { Router } from 'react-router'
import { ApolloProvider } from 'react-apollo'
import { ToastContainer } from 'react-toastify'
import CssBaseline from '@material-ui/core/CssBaseline'
import { ThemeProvider } from '@material-ui/styles'
import 'web-animations-js'
import { muiTheme, GlobalStyles } from 'src/theme'
import { ErrorBoundary, AuthVerify } from 'src/ui'
import { initSentry, SetSentryUser } from 'src/core'
import './react-toastify.css'
import { client } from './apollo/client-config'
import { Features } from './features'

export const history = createBrowserHistory()
initSentry()

export const App: FC = () => (
  <ThemeProvider theme={muiTheme}>
    <ApolloProvider client={client}>
      <GlobalStyles />
      <CssBaseline />
      <ToastContainer />

      <SetSentryUser>
        <ErrorBoundary>
          <Router history={history}>
            <Features />
            <AuthVerify />
          </Router>
        </ErrorBoundary>
      </SetSentryUser>
    </ApolloProvider>
  </ThemeProvider>
)
