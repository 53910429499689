import Tooltip from '@material-ui/core/Tooltip';
import { Theme } from '@material-ui/core'
import { withStyles } from '@material-ui/styles'

export const HtmlTooltip = withStyles((theme: Theme) => ({
  tooltip: {
    background: '#606061',
    color: '#FAFAFA',
    width: 272,
    fontSize: theme.typography.pxToRem(14),
    border: 'none',
    borderRadius: 4,
  },
}))(Tooltip);
