import gql from 'graphql-tag'

export const SC_GET_DISPLAY_SCHEDULES_Q = gql`
  query ScGetDisplaySchedulesQuery(
    $workspaceId: UUID!
    $displayId: UUID!
    $start: Datetime!
    $end: Datetime!
  ) {
    workspace(id: $workspaceId) {
      id
      display(id: $displayId) {
        id
        schedules(start: $start, end: $end) {
          id
          start
          end
          presentation {
            id
            name
            config
            slides
            slidesOrder
            createdAt
            updatedAt
          }
          recurringEventId
          recurrenceRule
          eventType
          isAllDay
          timeZone
        }
      }
    }
  }
`

export const SC_GET_GROUP_SCHEDULES_Q = gql`
  query ScGetGroupQuery($groupId: UUID!, $start: Datetime!, $end: Datetime!) {
    scheduleGroup(groupId: $groupId, start: $start, end: $end) {
      id
      start
      end
      presentation {
        id
        name
        config
        slides
        slidesOrder
        createdAt
        updatedAt
      }
      recurringEventId
      recurrenceRule
      isAllDay
      timeZone
    }
  }
`

export const SC_DISPLAYS_QUERY = gql`
query SC_DISPLAYS_QUERY ($workspaceId: UUID!) {
    workspace(id: $workspaceId) {
      id
      displays {
        page
        count
        values {
          id
          name
          pixelsH
          pixelsW
          adsEnabled
          timezone {
            jodaId
          }
          schedulingType
          isDisplayGroup
          displayGroupId
        }
      }
      displayGroups {
        page
        count
        values {
          id
          name
          pixelsH
          pixelsW
          timezone {
            jodaId
          }
          schedulingType
          isDisplayGroup
        }
      }
    }
  }
`