import gql from 'graphql-tag'
import * as Fragments from './fragments'

export const GROUPS_GET_GROUPS_AND_DISPLAYS_Q = gql`
  query GroupsGetGroupsAndDisplaysQuery {
    groupsCurrentUser {
      ...GroupsGroupInfo
    }
  }
  ${Fragments.GROUPS_GROUP_INFO_F}
`

export const GROUPS_GET_GROUP_Q = gql`
  query GroupsGetGroupQuery ($id: UUID!) {
    group(id: $id) {
      ...GroupsGroupInfo
    }
  }
  ${Fragments.GROUPS_GROUP_INFO_F}
`

export const GROUPS_GET_GROUPS_Q = gql`
  query GroupsGetGroupsQuery {
    groupsCurrentUser {
      ...GroupsGroupInfo
    }
  }
  ${Fragments.GROUPS_GROUP_INFO_F}
`
