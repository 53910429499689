import React, { FC, useMemo } from 'react'
import { styled } from '@material-ui/styles'
import { Link } from 'react-router-dom'
import { AddFab, GenericAppBar } from 'src/ui'

const FabWrapper = styled('div')({
  order: -1,
  position: 'relative',
  top: '50%'
})
const LinkWrapper = styled(Link)({
  order: -1,
  position: 'relative',
  top: '50%'
})

interface TProps {
  onFabClick?(): void
  pageTitle: string
  fabLinkTo?: string
}

export const AppBarWithFab: FC<TProps> = ({
  onFabClick,
  pageTitle,
  fabLinkTo
}) => {
  const isLink = useMemo(() => (
    fabLinkTo !== undefined
  ), [fabLinkTo])

  return (
    <GenericAppBar
      pageTitle={pageTitle}
      titleIndent={4}
    >
      {isLink ? (
        <LinkWrapper to={fabLinkTo}>
          <AddFab component='div' />
        </LinkWrapper>
      ) : (
        <FabWrapper>
          <AddFab onClick={onFabClick} />
        </FabWrapper>
      )}
    </GenericAppBar>
  )
}
