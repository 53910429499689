import React, { FC } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';

const useStyles = makeStyles((theme) => ({
  container: (props: {aspectRatio?: number}) => ({
    display: 'flex',
    width: (props.aspectRatio >= 1) ? '100px' : '50px',
    height: (props.aspectRatio > 1) ? '50px' : '100px',
    alignItems: 'flex-start',
  }),
  columnContainer: (props: {aspectRatio?: number}) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
  }),
  left70: (props: {aspectRatio?: number}) => ({
    display: 'flex',
    alignItems: 'flex-start',
    width: (props.aspectRatio >= 1) ? `${100 * (2/3)}px` : `${50 * (2/3)}px`,
    alignSelf: 'stretch',
    border: '1px solid var(--Sytetm-Divider, currentColor)',
    borderRight: 'none',
  }),
  upperRight10: (props: {aspectRatio?: number}) => ({
    display: 'flex',
    alignItems: 'flex-start',
    width: (props.aspectRatio >= 1) ? `${100 * (1/3)}px` : `${50 * (1/3)}px`,
    height: (props.aspectRatio > 1) ? `${50 * (1/3)}px` : `${100 * (1/3)}px`,
    alignSelf: 'stretch',
    border: '1px solid var(--Sytetm-Divider, currentColor)',
    borderBottom: 'none',
  }),
  middleRight10: (props: {aspectRatio?: number}) => ({
    display: 'flex',
    alignItems: 'flex-start',
    width: (props.aspectRatio >= 1) ? `${100 * (1/3)}px` : `${50 * (1/3)}px`,
    height: (props.aspectRatio > 1) ? `${50 * (1/3)}px` : `${100 * (1/3)}px`,
    alignSelf: 'stretch',
    border: '1px solid var(--Sytetm-Divider, currentColor)',
    borderBottom: 'none',
  }),
  lowerRight10: (props: {aspectRatio?: number}) => ({
    display: 'flex',
    alignItems: 'flex-start',
    height: (props.aspectRatio > 1) ? `${50 * (1/3)}px` : `${100 * (1/3)}px`,
    alignSelf: 'stretch',
    border: '1px solid var(--Sytetm-Divider, currentColor)',
  }),
  left70Selected: (props: {aspectRatio?: number}) => ({
    border: '2px solid var(--Sytetm-Divider, #1d93f3)',
    borderRight: 'none',
  }),
  upperRight10Selected: (props: {aspectRatio?: number}) => ({
    border: '2px solid var(--Sytetm-Divider, #1d93f3)',
    borderBottom: 'none',
  }),
  middleRight10Selected: (props: {aspectRatio?: number}) => ({
    border: '2px solid var(--Sytetm-Divider, #1d93f3)',
    borderBottom: 'none',
  }),
  lowerRight10Selected: (props: {aspectRatio?: number}) => ({
    border: '2px solid var(--Sytetm-Divider, #1d93f3)',
  }),
}));

export const Layout70To10To10To10Icon: FC<{isSelected: boolean; aspectRatio?: number}> = ({
  isSelected,
  aspectRatio,
}) => {
  const {
    container,
    columnContainer,
    left70,
    upperRight10,
    middleRight10,
    lowerRight10,
    left70Selected,
    upperRight10Selected,
    middleRight10Selected,
    lowerRight10Selected,
  } = useStyles({ aspectRatio });

  const left70ClassName = classNames(left70, { [left70Selected]: isSelected });
  const upperRight10ClassName = classNames(upperRight10, { [upperRight10Selected]: isSelected });
  const middleRight10ClassName = classNames(middleRight10, { [middleRight10Selected]: isSelected });
  const lowerRight10ClassName = classNames(lowerRight10, { [lowerRight10Selected]: isSelected });

  return (
    <div className={container}>
        <div className={left70ClassName} />
        <div className={columnContainer}>
            <div className={upperRight10ClassName} />
            <div className={middleRight10ClassName} />
            <div className={lowerRight10ClassName} />
        </div>
    </div>
  );
};
