export const getVideoDuration = (file: File): Promise<number> => {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader()

    fileReader.addEventListener('load', () => {
      const blob = new Blob([fileReader.result], { type: file.type })
      const url = URL.createObjectURL(blob)
      const video = document.createElement('video')

      video.addEventListener('loadedmetadata', () => {
        const { duration } = video
        const [secs, ms] = duration.toFixed(3).split('.')
        const durationInMs = Number(secs) * 1000 + Number(ms)
        resolve(durationInMs)
      })

      video.addEventListener('error', (err) => reject(err))

      video.preload = 'metadata'
      video.src = url
    })

    fileReader.readAsArrayBuffer(file)
  })
}
